import React, { useState } from "react";
import { Button } from "@material-ui/core";

import { convertGoogleLocationData } from "helpers/locationHelper";

import StyledGoogleAutocomplete from "shared/StyledGoogleAutocomplete";
import CustomCheckbox from "shared/CustomCheckbox";
import StyledChip from "shared/StyledChip";
import ModalWrapper from "shared/ModalWrapper";
import SelectLocation from "shared/SelectLocation";

import ExpertModeCard from "../../components/ExpertModeCard";

import "./styles.scss";
import StyledAutocomplete from "../../../../../shared/StyledAutocomplete";

const target = [
  {
    label: "All countries and territories",
    value: "all"
  },
  {
    label: "US",
    value: "us"
  },
  {
    label: "Enter another location",
    value: "custom"
  }
];

const targetType = [
  {
    label: "Include",
    value: "include"
  }
];

const Location = ({ input }) => {
  const [locationType, setLocationType] = useState(targetType[0].value);
  const [openLocationModal, setOpenLocationModal] = useState(false);

  const handleDeleteChip = val => {
    return input?.onChange({
      ...input?.value,
      [locationType]: input?.value[locationType].filter(place => !place.formattedAddress.includes(val.formattedAddress))
    });
  };

  return (
    <ExpertModeCard title="Location" className="location-card-wrapper">
      <div className="card-subtitle">Select locations to target</div>
      {target?.map(val => (
        <CustomCheckbox
          key={val.value}
          onChange={() => input?.onChange({ ...input?.value, type: val.value })}
          checked={val.value === input?.value?.type}
          isTypeRadio
          label={val.label}
        />
      ))}
      {input?.value?.type === "custom" && (
        <div className="custom-location-box">
          <div className="card-subtitle">Enter a location to target</div>
          {targetType?.map(val => (
            <CustomCheckbox
              key={val.value}
              onChange={() => setLocationType(val.value)}
              checked={val.value === locationType}
              isTypeRadio
              label={val.label}
            />
          ))}
          <div className="autocomplete-box">
            <StyledAutocomplete
              onSelect={place => {
                return input?.onChange({
                  ...input?.value,
                  [locationType]: [...input?.value[locationType], convertGoogleLocationData(place)]
                });
              }}
            />
            {locationType === "include" && (
              <Button className="open-modal-btn" onClick={() => setOpenLocationModal(true)}>
                Advanced search
              </Button>
            )}
          </div>
          {input?.value[locationType]?.length > 0 && (
            <div className="chip-box">
              {input?.value[locationType]?.map(val => (
                <StyledChip label={val.formattedAddress} onDelete={() => handleDeleteChip(val)} />
              ))}
              {input?.value?.radiusLocation?.formattedAddress && (
                <StyledChip
                  label={input?.value?.radiusLocation?.formattedAddress}
                  onDelete={() => input?.onChange({ ...input?.value, radiusLocation: { radius: 1 } })}
                />
              )}
            </div>
          )}
        </div>
      )}
      <ModalWrapper open={openLocationModal} handleClose={() => setOpenLocationModal(false)}>
        <div className="location-map-modal-content">
          <SelectLocation
            locations={input?.value}
            handleSetLocations={input?.onChange}
            handleClose={() => setOpenLocationModal(false)}
            isExpertMode
          />
        </div>
      </ModalWrapper>
    </ExpertModeCard>
  );
};

export default Location;
