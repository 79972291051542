import React from "react";
import { PieChart, Pie, Cell } from "recharts";

import { getRoundValue } from "helpers/statisticHelpFunctions";

import DiagramSelect from "shared/DiagramSelect";

import "./styles.scss";

const StyledPieChart = ({ data, isVerticalAlign, typeOptions, typeState, setTypeState, inPercent }) => {
  const getPercentValue = item => {
    const totalAmount = data?.reduce((acc, value) => acc + Number(value.value), 0);
    if (totalAmount) {
      return (item.value * 100) / totalAmount;
    }
    return 0;
  };

  return (
    <div className="styled-pie-chart-wrapper">
      <div className={`pie-chart-info-box ${isVerticalAlign ? "vertical-align" : ""}`}>
        {isVerticalAlign && (
          <DiagramSelect options={typeOptions} value={typeState} onChange={val => setTypeState(val)} isDefault />
        )}
        <div className="styled-pie-chart-gradient-wrapper">
          <div className="gradient" />
          <PieChart width={180} height={180} className="styled-pie-chart">
            <Pie
              minAngle={2}
              data={data}
              cx="50%"
              cy="50%"
              innerRadius={70}
              outerRadius={90}
              paddingAngle={3}
              animationBegin={0}
              dataKey="value"
            >
              {data?.map((val, index) => (
                <Cell key={`cell-${index}`} fill={val.color} />
              ))}
            </Pie>
          </PieChart>
        </div>
        <div className="chart-values">
          {data?.map((val, i) => (
            <div key={i.toString()} className="chart-value-item">
              <div className="item-label">
                <div className="value-indicator" style={{ background: val.color }} />
                {val.label}
              </div>
              {inPercent ? `${getRoundValue(getPercentValue(val))}%` : val.value}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default StyledPieChart;
