import { takeEvery, call, put, select } from "redux-saga/effects";
import { dsToast } from "shared/dsToast";
import BeginnerCampaignCreation from "services/beginnerCampaignCreation";

function* handlePrevStep({ payload }) {
  try {
    const { campaignId, prevState } = yield select(state => state.beginnerMode);
    const prevStep = yield call(BeginnerCampaignCreation.prevStep, { campaignId, prevState });
    yield put({ type: "SET_PREV_STEP", payload: prevStep.campaignCreation.prevState });
    payload();
  } catch (e) {
    console.log(e);
    yield dsToast.error("Sorry, something went wrong");
  }
}

function* handleClearBeginnerStore() {
  try {
    yield put({ type: "SET_CAMPAIGN_ID", payload: null });
    yield put({ type: "SET_GOAL", payload: null });
    yield put({ type: "SET_AD_GROUP_ID", payload: null });
    yield put({ type: "SET_PREV_STEP", payload: null });
    yield put({ type: "SET_DESCRIBE_BUSINESS", payload: { name: "", websiteUrl: "" } });
    yield put({
      type: "SET_LOCATIONS",
      payload: {
        currentTab: 0,
        isUS: true,
        radiusLocation: { radius: 1 },
        isInBulk: false,
        postalCodes: [],
        include: [],
        exclude: []
      }
    });
    yield put({ type: "SET_TARGET_ADS", payload: [] });
    yield put({ type: "SET_BUDGET", payload: 0 });
    yield put({ type: "SET_ESTIMATE", payload: { imps: { min: 0, max: 0 }, clicks: { min: 0, max: 0 } } });
    yield put({ type: "SET_UPLOAD_AD", payload: { url: "", files: [], customFiles: [] } });
    yield put({ type: "SET_CARD_ID", payload: null });
    yield put({ type: "SET_CAMPAIGN_NAME", payload: "" });
  } catch (e) {
    console.log(e);
  }
}

export default function* beginnerModeSagas() {
  yield takeEvery("HANDLE_PREV_STEP", handlePrevStep);
  yield takeEvery("CLEAR_BEGINNER_STORE", handleClearBeginnerStore);
}
