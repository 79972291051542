import React from "react";

import StyledSelect from "shared/StyledSelect";

import ExpertModeCard from "../../components/ExpertModeCard";

import "./styles.scss";

const focus = [
  {
    label: "None",
    value: "None"
  },
  {
    label: "CPM",
    value: "Conversions"
  }
];

const conversions = [
  {
    label: "None",
    value: "None"
  },
  {
    label: "Automatically maximize conversions",
    value: "Automatically maximize conversions"
  }
];

const Bidding = ({ input }) => {
  return (
    <ExpertModeCard title="Bidding" className="bidding-card-wrapper">
      <StyledSelect
        value={input?.value?.focusOn}
        onChange={e => input?.onChange({ ...input?.value, focusOn: e.target.value })}
        options={focus}
        label="What do you want to focus on?"
        helperText="Recommended for your campaign goal"
      />
      <StyledSelect
        value={input?.value?.conversions}
        onChange={e => input?.onChange({ ...input?.value, conversions: e.target.value })}
        options={conversions}
        label="How do you want to get conversions?"
        helperText="Recommended for your campaign goal"
      />
      <div className="card-subtitle">Pay for</div>
      <div className="pay-for">CPM</div>
      <div className="card-text-box">
        Conversions are actions on your website, app, or store that you define.
        <br />
        <br />
        Manually set the amount you want to bid for each click, and Google Ads will adjust your bids to increase
        conversions.
        <br />
        <br />
        Select the event you want to be charged for. For example, if you select clicks, your campaign will be charged
        when someone clicks on your ad
      </div>
    </ExpertModeCard>
  );
};

export default Bidding;
