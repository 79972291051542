import React, { useEffect, useState } from "react";
import moment from "moment";

import CustomCheckbox from "shared/CustomCheckbox";
import StyledTextField from "shared/StyledTextField";
import { ProdDatePicker, CalendarPopover } from "shared/CustomDatePicker";

import ExpertModeCard from "../../components/ExpertModeCard";

import "./styles.scss";

const StartEndDates = ({ input }) => {
  const [openStartCalendar, setOpenStartCalendar] = useState(null);
  const [openEndCalendar, setOpenEndCalendar] = useState(null);

  useEffect(() => {
    if (input?.value?.startDate > input?.value?.endDate) {
      return input?.onChange({ ...input?.value, endDate: new Date(moment(input?.value?.startDate).add(1, "days")) });
    }
  }, [input?.value?.startDate]);

  return (
    <ExpertModeCard title="Start and end dates" className="start-end-dates-card-wrapper" isDefaultOpen={false}>
      <StyledTextField
        label="Start date"
        value={moment(input?.value?.startDate).format("YYYY.MM.DD")}
        onClick={e => setOpenStartCalendar(e.currentTarget)}
      />
      <CalendarPopover vertical={44} horizontal="left" state={openStartCalendar} setState={setOpenStartCalendar}>
        <ProdDatePicker
          selected={input?.value?.startDate}
          onChange={date => input?.onChange({ ...input?.value, startDate: date })}
          dateFormat="YYYY.MM.DD"
        />
      </CalendarPopover>
      <div className="card-subtitle">End date</div>
      <CustomCheckbox
        onChange={() => input?.onChange({ ...input?.value, endDate: "none" })}
        checked={input?.value?.endDate === "none"}
        isTypeRadio
        label="None"
      />
      <div className="end-date-box">
        <CustomCheckbox
          onClick={e => setOpenEndCalendar(e.currentTarget)}
          checked={moment(input?.value?.endDate).isValid()}
          isTypeRadio
          label={
            moment(input?.value?.endDate).isValid() ? moment(input?.value?.endDate).format("YYYY.MM.DD") : "YYYY.MM.DD"
          }
        />
        <CalendarPopover vertical={44} state={openEndCalendar} setState={setOpenEndCalendar}>
          <ProdDatePicker
            selected={moment(input?.value?.endDate).isValid() ? input?.value?.endDate : input?.value?.startDate}
            onChange={date => input?.onChange({ ...input?.value, endDate: date })}
            dateFormat="YYYY.MM.DD"
            minDate={input?.value?.startDate}
          />
        </CalendarPopover>
      </div>
      <div className="card-text-box">
        Use end date none for always on advertising or schedule a stop date for this campaign.
      </div>
    </ExpertModeCard>
  );
};

export default StartEndDates;
