import React, { useState, useCallback } from "react";
import { connect } from "react-redux";
import axios from "axios";
import TextField from "@material-ui/core/TextField/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import debounce from "helpers/debounce";
import Loader from "shared/Loader";

import Arrow from "assets/images/arrowDropDownBlue.svg";
import ArrowWhite from "assets/images/arrowDropDownWhite.svg";
import EmptyIcon from "assets/images/smallEmptyIcon.svg";

import "./styles.scss";

let source = axios.CancelToken.source();

const StyledAutocomplete = ({ className, label, multiple, userInfo, onSelect, inputVal, setInputVal }) => {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);

  const handleGetOptions = val => {
    setLoading(true);
    // check if previous request is pending, cancel it
    if (source) {
      source.cancel();
    }
    source = axios.CancelToken.source();
    const params = {
      q: val,
      polygon_geojson: 0,
      format: "json",
      countrycodes: "US",
      "accept-language": "en-US"
    };
    axios
      .get("https://nominatim.openstreetmap.org/search.php", { params, cancelToken: source.token })
      .then(response => {
        setOptions(response?.data);
        setLoading(false);
        source = null;
      })
      .catch(e => {
        if (!axios.isCancel(e)) {
          setLoading(false);
        }
      });
  };

  const getOptionsDebounce = useCallback(debounce(handleGetOptions, 100), []);

  const getProps = () => {
    if (setInputVal) {
      return {
        inputValue: inputVal,
        onInputChange: (event, newInputValue) => {
          setInputVal(newInputValue);
          setLoading(true);
          getOptionsDebounce(newInputValue);
        }
      };
    }
    return {
      inputValue,
      onInputChange: (event, newInputValue) => {
        setInputValue(newInputValue);
        setLoading(true);
        getOptionsDebounce(newInputValue);
      }
    };
  };

  return (
    <div className={`autocomplete-select-wrapper ${className || ""} ${multiple ? "multipleSelect" : ""}`}>
      {label && <div className="autocomplete-select-label">{label}</div>}
      <Autocomplete
        {...getProps()}
        className="autocomplete-select"
        disableClearable
        loading={loading}
        loadingText={
          <div className="autocomplete-loader-box">
            <Loader empty isDashboardContent />
          </div>
        }
        noOptionsText={
          <div className="no-option-autocomplete-box">
            <img src={EmptyIcon} alt="" />
            No result
          </div>
        }
        multiple={multiple}
        renderInput={params => <TextField {...params} />}
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
          onSelect(newValue);
        }}
        getOptionLabel={option => option?.display_name || ""}
        popupIcon={<img src={userInfo?.isDarkTheme ? ArrowWhite : Arrow} alt="" />}
        filterOptions={op => op.filter(val => val.osm_type !== "way")}
        options={Array.isArray(options) ? options : []}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  userInfo: state.userProfile.userInfo
});

export default React.memo(connect(mapStateToProps)(StyledAutocomplete));
