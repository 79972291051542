import React from "react";

// import CustomCheckbox from "shared/CustomCheckbox";

import Keywords from "../../components/Keywords";

import "./styles.scss";

// const type = [
//   {
//     label:
//       "Audience: Show ads to people likely to be interested in these keywords and also on webpages, apps, and videos related to these keywords",
//     value: "audience"
//   },
//   {
//     label: "Content: Only show ads on webpages, apps, and videos related to these keywords",
//     value: "content"
//   }
// ];

const TargetedKeywords = ({ input }) => {
  return (
    <div className="targeted-keywords-card-wrapper">
      <div className="targeted-keywords-card-head">Edit targeted keywords</div>
      <Keywords input={input} />
      {/*<div className="radio-btn-box">*/}
      {/*  {type.map(val => (*/}
      {/*    <CustomCheckbox*/}
      {/*      label={val.label}*/}
      {/*      checked={input?.value?.type === val.value}*/}
      {/*      isTypeRadio*/}
      {/*      onChange={() => input?.onChange({ ...input?.value, type: val.value })}*/}
      {/*    />*/}
      {/*  ))}*/}
      {/*</div>*/}
    </div>
  );
};

export default TargetedKeywords;
