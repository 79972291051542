import { handleActions } from "redux-actions";
import {
  setCampaignId,
  setGoal,
  setAdGroupId,
  setPrevState,
  setDescribeBusiness,
  setLocations,
  setTargetAds,
  setBudget,
  setEstimate,
  setUploadAd,
  setCardId,
  setCampaignName
} from "redux/actions/beginnerMode";

const initialState = {
  campaignId: null,
  goal: null,
  adGroupId: null,
  prevState: null,
  describeBusiness: {
    name: "",
    websiteUrl: ""
  },
  locations: {
    currentTab: 0,
    isUS: true,
    radiusLocation: {
      radius: 1
    },
    geometries: [],
    isInBulk: false,
    postalCodes: [],
    include: [],
    exclude: []
  },
  targetAds: [],
  budget: 0,
  estimate: {
    imps: { min: 0, max: 0 },
    clicks: { min: 0, max: 0 }
  },
  uploadAd: {
    url: "",
    files: [],
    customFiles: []
  },
  cardId: null,
  campaignName: ""
};

const handleSetCampaignId = (state, { payload }) => {
  return { ...state, campaignId: payload };
};

const handleSetGoal = (state, { payload }) => {
  return { ...state, goal: payload };
};

const handleSetAdGroupId = (state, { payload }) => {
  return { ...state, adGroupId: payload };
};

const handleSetPrevState = (state, { payload }) => {
  return { ...state, prevState: payload };
};

const handleSetDescribeBusiness = (state, { payload }) => {
  return { ...state, describeBusiness: payload };
};

const handleSetLocations = (state, { payload }) => {
  return { ...state, locations: payload };
};

const handleSetTargetAds = (state, { payload }) => {
  return { ...state, targetAds: payload };
};

const handleSetBudget = (state, { payload }) => {
  return { ...state, budget: payload };
};

const handleSetEstimate = (state, { payload }) => {
  return { ...state, estimate: payload };
};

const handleSetUploadAd = (state, { payload }) => {
  return { ...state, uploadAd: payload };
};

const handleSetCardId = (state, { payload }) => {
  return { ...state, cardId: payload };
};

const handleSetCampaignName = (state, { payload }) => {
  return { ...state, campaignName: payload };
};

const reducer = handleActions(
  {
    [setCampaignId]: handleSetCampaignId,
    [setGoal]: handleSetGoal,
    [setAdGroupId]: handleSetAdGroupId,
    [setPrevState]: handleSetPrevState,
    [setDescribeBusiness]: handleSetDescribeBusiness,
    [setLocations]: handleSetLocations,
    [setTargetAds]: handleSetTargetAds,
    [setBudget]: handleSetBudget,
    [setEstimate]: handleSetEstimate,
    [setUploadAd]: handleSetUploadAd,
    [setCardId]: handleSetCardId,
    [setCampaignName]: handleSetCampaignName
  },
  { ...initialState }
);

export default reducer;
