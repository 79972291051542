import React from "react";
import CustomCheckbox from "shared/CustomCheckbox";

import ExpertModeCard from "../../components/ExpertModeCard";

import "./styles.scss";

export const rotation = [
  {
    label: "Optimize: Prefer best performing ads",
    value: "0"
  },
  {
    label: "Do not optimize: Rotate ads indefinitely",
    value: "1"
  },
  {
    label: "Optimize for conversions",
    value: "2"
  },
  {
    label: "Rotate evenly",
    value: "3"
  }
];

const AdRotation = ({ input }) => {
  return (
    <ExpertModeCard title="Ad rotation" className="ad-rotation-card-wrapper" isDefaultOpen={false}>
      <div className="card-subtitle">Optimize: Prefer best performing ads</div>
      {rotation?.map(val => (
        <CustomCheckbox
          key={val.value}
          onChange={() => input?.onChange(val.value)}
          checked={val.value === input?.value}
          isTypeRadio
          label={val.label}
        />
      ))}
      <div className="card-text-box">
        Show ads that are expected to get more clicks or conversions. Recommended for most advertisers.
      </div>
    </ExpertModeCard>
  );
};

export default AdRotation;
