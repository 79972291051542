import React from "react";
import Header from "shared/Header";
import LogoLink from "shared/Header/LogoLink";
import "./styles.scss";

const TermsCond = () => {
  return (
    <div className="terms-cond-page">
      <Header leftSideContent={<LogoLink />} withoutMenuBtn />
      <div className="terms-content">
        <div className="terms-title">Searched Use and Advertising Terms and Conditions</div>
        <div className="terms-subtitle">
          Please be aware that these Terms include, among other things, a binding arbitration provision that requires
          you to submit to binding and final arbitration on an individual basis to resolve disputes, rather than jury
          trials or class actions.
        </div>
        <div className="content-text">
          These Searched Use and Advertising Terms and Conditions (“<span>Terms</span>”) are entered into by Datasys
          Group, Inc. (“<span>Datasys</span>”) and the User executing these Terms or that accepts these Terms
          electronically (“<span>Customer</span>”). These Terms govern Customer’s participation in Datasys’ advertising
          services as offered on Searched.com (“<span>Services</span>”).
        </div>
        <div className="content-text">
          1. Datasys offers the Searched platform to Customers who are interested in advertising to specific audiences.
          During the process, the Customer will be responsible for uploading, or otherwise submitting and providing
          advertising materials (collectively, “<span>Ads</span>”), during the advertising process. The Customer hereby
          authorizes Datasys and its affiliates to place Customer’s Ads on any content or property (each a “
          <span>Property</span>”) provided by Datasys, its affiliates or third parties on behalf of Datasys. Customer is
          responsible for: (i) Ads, (ii) Ads targeting decisions (e.g., keywords) (“<span>Targets</span>”), (iii)
          destinations to which Ads direct viewers along with the related URLs, waypoints, and redirects (“
          <span>Locations</span>”), and solely responsible for (iv) services and products advertised on Locations
          (collectively, “<span>Products</span>”). Customer acknowledges and accepts that Datasys and its affiliates may
          reject or remove a specific Target, Ad, or Location at any time for any or no reason. Datasys and its
          affiliates may modify or cancel Services at any time. To supplement Customer’s information, Datasys may
          provide some or all of the information in 1(ii)-(iii), or may supplement the information, all at its sole
          discretion.
        </div>
        <div className="content-text">
          2. Customer is solely responsible for its use of the Services (e.g., access to and use of the Services and
          safeguarding usernames and passwords) (“<span>Use</span>”). Service Use is subject to applicable Datasys
          instructions and policies made available by Datasys to Customer on the Searched.com website (or as displayed
          by Datasys as part of the advertising process), the Datasys.com website or in these Terms (as modified from
          time to time, “<span>Policies</span>”).
        </div>
        <div className="content-text">
          3. <span>Testing</span>. Customer authorizes Datasys and its affiliates to periodically conduct tests that may
          affect Customer’s Use of the Services, including Ad formatting, Targets, Destinations, quality, ranking,
          performance, and pricing. To ensure the timeliness and validity of test results, Customer authorizes Datasys
          to conduct such tests without notice or compensation to Customer.
        </div>
        <div className="content-text">
          5. <span>Ad Cancellation</span>. Unless a Policy, the Searched user interface, or an agreement referencing
          these Terms (an “<span>SOW or Insertion Order</span>”) provides otherwise, either party may stop any Ad at any
          time. Cancelled Ads may take up to 2 business days to be removed. Customer shall be responsible for the
          payment of any charges incurred prior to the stop.
        </div>
        <div className="content-text">
          6. Use of the Services and Searched.com is at Customers’ own risk. The Services may be affected by numerous
          factors beyond Datasys’ control, and may not be continuous, uninterrupted, or secure. Although Datasys will do
          its best to prevent any issues, security and privacy risks cannot be eliminated. Login Information may not
          prevent unauthorized access. Datasys is not responsible for the use of the Services by Customer.{" "}
          <span>
            THE SERVICES ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS. THERE ARE NO OTHER WARRANTIES,
            REPRESENTATIONS, OR CONDITIONS OF ANY KIND, EXPRESS OR IMPLIED, WRITTEN OR ORAL, ARISING BY STATUTE,
            OPERATION OF LAW, COURSE OF DEALING, USAGE OF TRADE, OR OTHERWISE WITH RESPECT TO THE SERVICES. DATASYS
            FURTHER EXPRESSLY DISCLAIMS GUARANTEE OF CONTINUED AVAILABILITY OF THE SERVICES, OR ANY IMPLIED WARRANTY OR
            CONDITION OF MERCHANTABILITY, SATISFACTORY QUALITY, DURABILITY OR FITNESS FOR A PARTICULAR PURPOSE, TITLE,
            OR NON-INFRINGEMENT. NO REPRESENTATION OR OTHER AFFIRMATION OF FACT INCLUDING BUT NOT LIMITED TO MARKETING
            LITERATURE OR COLLATERAL, OR STATEMENTS REGARDING PERFORMANCE OF THE SERVICES BY DATASYS, SHALL BE
            CONSIDERED TO BE A WARRANTY OR REPRESENTATION, AND SHOULD NOT BE RELIED UPON AND IS NOT BINDING UPON THE
            COMPANY.
          </span>
        </div>
        <div className="content-text">
          7. Customer warrants that (a) Customer holds, and hereby grants Datasys, its affiliates, the rights in Ads,
          Destinations, and Targets for Datasys, its affiliates to operate the Datasys Services, and (b) all information
          and authorizations provided by Customer are complete, correct and current. Customer authorizes Datasys and its
          affiliates to automate retrieval and analysis of, and create test credentials to access, Destinations for the
          purposes of the Services. By providing any mobile or other telephone number to Datasys in connection with the
          Services, Customer authorizes Datasys, its affiliates and their agents to call and send text messages (for
          which standard message and data rates may apply) to the provided telephone numbers, including by an automatic
          telephone dialing system, for purposes of the Services. However, Datasys may rely on this permission to
          initiate autodialed calls or text messages for marketing purposes. Customer further authorizes Datasys, its
          affiliates and their agents to send email to Customer for purposes of the Services. Customer warrants that it
          is authorized to act on behalf of, and has bound to these Terms, each third party, if any, for which Customer
          advertises in connection with these Terms (“<span>Advertiser</span>”) and any references to Customer in these
          Terms will also apply to Advertiser, as applicable. If for any reason Customer has not bound an Advertiser to
          these Terms, Customer will be liable for performing any obligation Advertiser would have had under these Terms
          had Advertiser been bound. If Customer is using the Services on its own behalf to advertise, for that use
          Customer will be deemed to be both Customer and Advertiser. Datasys may, upon request of an Advertiser, share
          Advertiser-specific information with Advertiser.
        </div>
        <div className="content-text">
          8. Customer authorizes Datasys to modify Ads as necessary for the proper placement of the Ads. Customer will
          not, and will not authorize any third party to, (i) generate automated, fraudulent or otherwise invalid
          impressions, inquiries, clicks or conversions, (ii) conceal conversions for Services where they are required
          to be disclosed, (iii) use any automated means or form of scraping or data extraction to access, query or
          otherwise collect Datasys advertising-related information from any Property except as expressly permitted by
          Datasys, or (iv) attempt to interfere with the functioning of the Services. Customer will direct
          communications regarding Ads on Partner Properties under these Terms only to Datasys. Datasys has the right to
          reject any Ad at its discretion. Customer will not provide Ads that contain or connect to malware, spyware,
          unwanted software or any other malicious code, or knowingly breach or circumvent any security measures.
          Customer understands and agrees that Ads promoting credit card applications, or financial services with
          accredited institutions must clearly provide sufficient disclosure regarding associated fees, including APR
          percentages, transaction fees, interest rates and the physical address of the entity offering the product
          within the ad's landing page. Ads promoting credit cards, loans or insurance services must not directly
          request the input of a person’s financial information, including credit card information. The following Ad
          types are prohibited: Alcohol, Gambling, Illegal Products or Services, Discriminatory Practices, Tobacco and
          Related Products, Drugs & Drug- Related Products, Unsafe Supplements, Weapons, Ammunition, or Explosives,
          Adult Products or Services, Adult Content, Third-Party Infringement, Sensational Content, Misinformation,
          Controversial Content, Non-Functional Landing Page, Cheating and Deceitful Practices, Poor Grammar &
          Profanity, Nonexistent Functionality, Payday Loans, Paycheck Advances, and Bail Bonds, Multilevel Marketing,
          Penny Auctions, Misleading Claims, Low Quality or Disruptive Content, Spyware or Malware, Automatic Animation,
          Unacceptable Business Practices, Circumventing Systems, Prohibited Financial Products and Services, or any
          other topic prohibited from time to time by Datasys without notice at its sole discretion.
        </div>
        <div className="content-text">
          9. Customer will pay all amounts agreed to when using the Services for ads to be placed, along with any
          applicable taxes, if any (“the Order”). The amount Customer owes for each Order will be determined in the
          reasonable sole discretion of Datasys. Datasys will require that Customer provide credit card details prior to
          using the Services. The Customer’s credit card will be pre-authorized prior to Customer’s use of the Services.
          Customer is responsible for (i) maintaining the security of Customer’s advertising account, and (ii)
          maintaining an updated credit card on file with Datasys while an Ad campaign is active. Customer understands
          that they will be charged for any Orders placed through Searched.com and/or the Services. Customer agrees that
          Datasys can charge Customer’s credit card for the amount that Customer agreed to, through budgets or
          otherwise, when setting up or placing the Ads through the Services. Customer can cancel an Order at any time,
          but Customer Ads may run for 2 business days after notification, and Customer is still responsible for paying
          for all Ads that run. The amounts charged by Datasys may be subject to and include applicable taxes and
          levies, including without limitation withholding taxes. Customer is responsible for bearing and remitting any
          taxes that apply to Customer transactions. Customer will indemnify and hold Datasys harmless from and against
          any claim arising out of Customer’s failure to do so. If Customer payment method fails or Customer account is
          past due, Datasys may take additional steps to collect past due amounts. Customer will pay all expenses
          associated with such collection, including reasonable attorneys' fees. Past due amounts will accrue interest
          at 1% per month or the lawful maximum, whichever is less. Customer will either be an invoiced or non-invoiced
          Customer. Invoiced Customers are those for whom Datasys sets a maximum spending limit and issues invoices on a
          periodic basis for payment in accordance with the applicable invoicing terms. Non-invoiced Customers are those
          who must make payments at the time of purchase itself. In its sole discretion, Datasys may classify Customers
          as invoiced clients based on factors such as, but not limited to, ad spend and creditworthiness.
        </div>
        <div className="content-text">
          The customer’s credit card will be charged in increments as Ads are placed. No party may offset any payment
          due under these Terms against any other payment to be made under these Terms. Datasys may, in its sole
          discretion, extend, revise or revoke credit at any time. Datasys is not obligated to deliver any Ads in excess
          of any credit limit. If, during an active campaign, Datasys fails to deliver Ads or fails to deliver Ads to
          the Customer selected Targets or Destinations, then Customer’s sole remedy is to make a claim for advertising
          credits within 30 days from the date of incident, after which Datasys will issue the credits following claim
          validation which must be used by the Use-By Date. Customer understands that third parties may generate
          impressions or clicks on Customer’s Ads for prohibited or improper purposes and if that happens, Customer’s
          sole remedy is to make a claim for advertising credits within 30 days from the date of incident. TO THE
          FULLEST EXTENT PERMITTED BY LAW, (A) CUSTOMER WAIVES ALL CLAIMS RELATING TO ANY SERVICE CHARGES UNLESS A CLAIM
          IS MADE WITHIN THE CLAIM PERIOD AND (B) THE ISSUANCE OF ADVERTISING CREDITS (IF ANY) IS AT DATASYS’S
          REASONABLE DISCRETION AND IF ISSUED, MUST BE USED BY THE USE-BY DATE.
        </div>
        <div className="content-text">
          11. Limitation of Liability. TO THE FULLEST EXTENT PERMITTED BY LAW REGARDLESS OF THE THEORY OR TYPE OF CLAIM:
          (a) DATASYS AND ITS RESPECTIVE AFFILIATES WILL NOT BE HELD LIABLE UNDER THESE TERMS OR ARISING OUT OF OR
          RELATED TO THESE TERMS FOR ANY DAMAGES OTHER THAN DIRECT DAMAGES, EVEN IF DATASYS IS AWARE OR SHOULD KNOW THAT
          SUCH OTHER TYPES OF DAMAGES ARE POSSIBLE AND EVEN IF DIRECT DAMAGES DO NOT SATISFY A REMEDY; AND (b) DATASYS,
          AND THEIR RESPECTIVE AFFILIATES WILL NOT BE HELD LIABLE FOR DAMAGES UNDER THESE TERMS OR ARISING OUT OF OR
          RELATED TO PERFORMANCE OF THESE TERMS FOR ANY GIVEN EVENT OR SERIES OF CONNECTED EVENTS IN THE AGGREGATE OF
          MORE THAN THE AMOUNT PAYABLE TO DATASYS BY CUSTOMER UNDER THE TERMS IN THE THIRTY DAYS BEFORE THE DATE OF THE
          ACTIVITY FIRST GIVING RISE TO THE CLAIM.
        </div>
        <div className="content-text">
          12. Customer will defend and indemnify Datasys, its Partners, agents, affiliates, and licensors against all
          liabilities, damages, losses, costs, fees (including legal fees), and expenses relating to any third-party
          allegation or legal proceeding to the extent arising out of or related to Ads, Targets, Destinations,
          Services, Use or any breach of these Terms by Customer. Partners are intended third-party beneficiaries of
          this Section.
        </div>
        <div className="content-text">
          13. Datasys may make material changes to these Terms at any time without notice. The changes to the Terms will
          not apply retroactively and will become effective immediately upon posting. After termination of this
          Agreement by either Party, (i) campaigns not cancelled and new campaigns may be run and reserved and (ii)
          continued Service Use is, in each case, subject to Datasys’s terms and conditions then in effect for the
          Services. Datasys may suspend Customer’s ability to participate in the Services at any time. In all cases, the
          running of any Customer campaigns after termination is in Datasys’s sole discretion.
        </div>
        <div className="content-text">
          14. Other than Customer’s failure to pay any amounts due for use of the Services, any dispute, controversy or
          claim arising out of or relating in any way to the Customer’s use of the Services including without limitation
          any dispute concerning the construction, validity, interpretation, enforceability or breach of this Agreement
          or any of the policies regarding the Services, shall be exclusively resolved by binding arbitration upon a
          Party’s submission of the dispute to arbitration. In the event of a dispute, controversy or claim arising out
          of or relating in any way to the Customer’s use of the Services, the complaining Party shall notify the other
          Party in writing thereof. Within thirty (30) days of such notice, management level representatives of both
          Parties shall meet at an agreed location to attempt to resolve the dispute in good faith. Should the dispute
          not be resolved within thirty (30) days after such notice, the complaining Party shall seek remedies
          exclusively through arbitration. The demand for arbitration shall be made within a reasonable time after the
          claim, dispute or other matter in question has arisen, and in no event shall it be made after two years from
          when the aggrieved party knew or should have known of the controversy, claim, dispute or breach. This
          agreement to arbitrate shall be specifically enforceable. A Party may apply to the Courts in Palm Beach
          County, FL for interim or conservatory relief, including without limitation a proceeding to compel
          arbitration. The arbitration shall be conducted by one arbitrator. If the Parties are not able to agree upon
          the selection of an arbitrator, within twenty days of commencement of an arbitration proceeding by service of
          a demand for arbitration, the arbitrator shall be selected by the American Arbitration Association or a state
          or federal court judge in Palm Beach County, Florida shall select the arbitrator in accordance with the terms
          of this agreement. The arbitration shall be conducted in accordance with the then existing Commercial Rules of
          the American Arbitration Association. The arbitration shall be conducted in Palm Beach County, Florida. The
          laws of the State of Florida shall be applied in any arbitration proceedings, without regard to principles of
          conflict of laws. It is the intent of the parties that, barring extraordinary circumstances, arbitration
          proceedings will be concluded within one hundred and twenty days from the date the arbitrator is appointed.
          The arbitrator may extend this time limit in the interests of justice. Failure to adhere to this time limit
          shall not constitute a basis for challenging the award. Except as may be required by law, neither a party nor
          its representatives may disclose the existence, content, or results of any arbitration hereunder without the
          prior written consent of both parties. The Parties shall not be entitled to discovery in the arbitration. The
          Parties shall exchange a copy of all exhibits for the arbitration hearing and shall identify each witness who
          will testify at the arbitration, with a summary of the anticipated testimony of such witness 10 days before
          the arbitration hearing. The arbitrator shall not have authority to award punitive/consequential/special/
          indirect damages. The arbitrators shall be entitled to issue injunctive and other equitable relief. The
          arbitrator shall award interest from the time of the breach to the time of award at the rate of 18%. The cost
          of the arbitration proceeding and any proceeding in court to confirm or to vacate any arbitration award, as
          applicable (including, without limitation, reasonable attorneys’ fees and costs), shall be borne by the
          unsuccessful party, as determined by the arbitrators, and shall be awarded as part of the arbitrator’s award.
          It is specifically understood and agreed that any party may enforce any award rendered pursuant to the
          arbitration provisions of this Section by bringing suit in any court of competent jurisdiction in Palm Beach
          County. The parties agree that the arbitrator shall have authority to grant injunctive or other forms of
          equitable relief to any party. This Section shall survive the termination or cancellation of this Agreement.
          Each party shall pay its own proportionate share of arbitrator fees and expenses and the arbitration fees and
          expenses of the American Arbitration Association. The arbitrator shall be entitled to award the foregoing
          arbitration and administrative fees and expenses as damages in his/her discretion. In the event Customer fails
          to pay any or all of the amount due to Datasys for use of the Services, Datasys may bypass arbitration and
          initiate an action in State or Federal Court in Palm Beach County and Customer agrees and accepts venue and
          jurisdiction in said locations.
        </div>
        <div className="content-text">
          15. ALL CLAIMS ARISING OUT OF OR RELATING TO THESE TERMS OR THE SERVICES WILL BE GOVERNED BY FLORIDA LAW.
          EXCEPT AS PROVIDED IN SECTION 14 ABOVE, ALL CLAIMS ARISING OUT OF OR RELATING TO THESE TERMS OR THE SERVICES
          WILL BE LITIGATED EXCLUSIVELY IN THE FEDERAL OR STATE COURTS OF PALM BEACH COUNTY, FLORIDA; THE PARTIES
          CONSENT TO PERSONAL JURISDICTION IN THESE COURTS. Customer will not seek an injunction based on patent
          infringement in connection with the Services in any proceeding filed while these Terms are in effect, and for
          one year after any termination of these Terms. These Terms are the parties’ entire agreement relating to their
          subject matter and supersede any prior or contemporaneous agreements on those subjects. Customer may not make
          any public statement regarding the relationship contemplated by these Terms (except when required by law).
          Except as provided in Section 14, all notices of termination or breach must be in writing and addressed to the
          other party’s Legal Department (or if it is not known if the other party has a Legal Department then to the
          other party's primary contact or other address on file). The email address for notices being sent to Datasys’s
          Legal Department is legal@Datasys.com. Except as provided in Section 14, all other notices to Customer will be
          in writing and sent to an email address associated with Customer’s account. Except as provided in Section 14,
          all other notices to Datasys will be in writing and addressed to Customer’s primary contact at Datasys or
          other method made available by Datasys. Notice will be treated as given on receipt, as confirmed by written or
          electronic means. These notice requirements do not apply to legal service of process, which is instead
          governed by applicable law, nor do they apply to Section 14. Except for modifications to these Terms by
          Datasys, any amendment must be agreed to by both parties and must expressly state that it is amending these
          Terms. Neither party will be treated as having waived any rights by not exercising (or by delaying the
          exercise of) any rights under these Terms. Except as provided in Section 14, if any provision of these Terms
          is found unenforceable, that provision will be severed and the balance of the Terms will remain in full force
          and effect. Neither party may assign any of its rights or obligations under these Terms without the written
          consent of the other party, except to an affiliate but only where (i) the assignee agrees in writing to be
          bound by these Terms, (ii) the assigning party remains liable for obligations under these Terms if the
          assignee defaults on them, and (iii) the assigning party has notified the other party of the assignment. Any
          other attempt to transfer or assign is void. Except as provided in Sections 12 and 14, there are no
          third-party beneficiaries to these Terms. These Terms do not create any agency, partnership or joint venture
          among the parties. Sections 6 through 15 will survive termination of these Terms. Except for payment
          obligations, no party or its affiliates are liable for failure or delay in performance to the extent caused by
          circumstances beyond its reasonable control.
        </div>
      </div>
    </div>
  );
};

export default TermsCond;
