import React from "react";
import { toast } from "react-toastify";
import Success from "assets/images/toastSuccess.svg";
import Error from "assets/images/toastError.svg";
import Close from "assets/images/toastClose.svg";
import "./styles.scss";

const ToastContent = ({ type, title }) => {
  return (
    <div className={`custom-toast-wrapper ${type}`}>
      <div className="status-icon-box">
        <img src={type === "success" ? Success : Error} alt="" />
      </div>
      <div id="toast-text" className={`toast-text ${title?.length >= 58 ? "small-font" : ""}`}>
        {title}
      </div>
      <div className="close-wrapper">
        <img src={Close} alt="" />
      </div>
    </div>
  );
};

export const dsToast = {
  success: label => toast.success(<ToastContent type="success" title={label} />),
  error: label => toast.error(<ToastContent type="error" title={label} />)
};
