import React, { useEffect, useState } from "react";
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from "recharts";
import { formatterAxisData } from "helpers/statisticHelpFunctions";

import "./styles.scss";

const StyledAreaChart = ({ data, isGoal }) => {
  const [maxValue, setMaxValue] = useState(100);

  useEffect(() => {
    const numberArr = [...data.map(val => val.blueData), ...data.map(val => val.pinkData)];
    setMaxValue(Math.max.apply(null, numberArr));
  }, [data]);

  const getYWidth = val => {
    if (val?.length >= 4) {
      return 36;
    }
    return 32;
  };

  return (
    <ResponsiveContainer width="100%" height="100%" className="styled-area-chart">
      <AreaChart width="100%" height="100%" data={data}>
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="-11.57%" stopColor="rgb(33, 81, 255)" stopOpacity={0.1} />
            <stop offset="100%" stopColor="rgb(33, 81, 255)" stopOpacity={0} />
          </linearGradient>
          {!isGoal && (
            <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor="rgb(240, 174, 225)" stopOpacity={0.31} />
              <stop offset="100%" stopColor="rgb(240, 174, 225)" stopOpacity={0} />
            </linearGradient>
          )}
        </defs>
        <XAxis dataKey="label" axisLine={false} tickLine={false} tickCount={6} height={20} />
        <YAxis
          axisLine={false}
          tickLine={false}
          width={getYWidth(formatterAxisData(maxValue))}
          tickFormatter={formatterAxisData}
        />
        <CartesianGrid vertical={false} />
        <Tooltip
          formatter={(value, name) => [value, name === "pinkData" ? data[0].pinkLabel : data[0].blueLabel]}
          contentStyle={{
            border: "1px solid #E2E4F2",
            borderRadius: 6,
            boxShadow: "0 6px 32px rgba(0, 0, 0, 0.17)",
            padding: "10px 16px",
            fontFamily: "SegoeUI-bold"
          }}
          labelStyle={{
            color: "#242424",
            fontSize: 14,
            marginBottom: 5
          }}
          itemStyle={{
            fontSize: 12,
            padding: 0
          }}
        />
        <Area type="monotone" dataKey="blueData" stroke="#1F4FFF" fillOpacity={1} fill="url(#colorUv)" />
        {!isGoal && (
          <Area type="monotone" dataKey="pinkData" stroke="#F0AEE1" fillOpacity={1} fill="url(#colorPv)" />
        )}
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default StyledAreaChart;
