import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Loader from "./index";

const useStyles = makeStyles(() => ({
  localLoader: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    "& .MuiCircularProgress-colorPrimary": {
      color: "#213991"
    }
  }
}));

export default () => {
  const classes = useStyles();

  return (
    <div className={classes.localLoader}>
      <Loader isLoading empty />
    </div>
  );
};
