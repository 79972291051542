import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Button } from "@material-ui/core";
import { dsToast } from "shared/dsToast";

import { PIXELS } from "pathnameVariables";
import TrackingPixel from "services/trackingPixel";
import { goalsTableColumns } from "constants/tableColumns";

import DashboardContent from "shared/DashboardContent";
import DashboardTableCard from "shared/DashboardTableCard";
import ModalWrapper from "shared/ModalWrapper";
import Header from "shared/Header";

import GoalCreationModal from "./GoalCreationModal";

import "./styles.scss";

const Pixels = () => {
  const location = useLocation();
  const { push } = useHistory();
  const pixelId = new URLSearchParams(location.search).get("id");
  const [openCreationModal, setOpenCreationModal] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [goalRows, setGoalRows] = useState([]);
  const [selectedGoal, setSelectedGoal] = useState(null);

  const handleGetGoals = () => {
    TrackingPixel.getPixelGoals(pixelId)
      .then(data => {
        if (!data?.length) {
          setIsEmpty(true);
        } else {
          setIsEmpty(false);
        }
        const columnName = index => ({ columnName: goalsTableColumns[index].value });
        setGoalRows(
          data.map(val => ({
            rowName: val.name,
            columns: [
              { label: val.name, isFirst: true, noIcon: true, ...columnName(0) },
              {
                label: (
                  <div className="pixel-table-btn-box">
                    <Button
                      className="full-width-btn"
                      onClick={() => {
                        setSelectedGoal(val);
                        setOpenCreationModal(true);
                      }}
                    >
                      Details
                    </Button>
                  </div>
                ),
                ...columnName(1)
              }
            ]
          }))
        );
      })
      .catch(() => dsToast.error("We cannot get pixel goals. Please try again later."))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (pixelId) {
      setIsLoading(true);
      handleGetGoals();
    }
  }, [pixelId]);

  const handleCloseModal = () => {
    setOpenCreationModal(false);
    setTimeout(() => setSelectedGoal(null), 500);
  };

  return (
    <>
      <Header
        leftSideContent={
          <div className="pixel-head-btn-box">
            <Button onClick={() => push(PIXELS)}>Back</Button>
            <Button onClick={() => setOpenCreationModal(true)}>Add a goal</Button>
          </div>
        }
      />
      <DashboardContent isLoading={isLoading} isEmpty={isEmpty} emptyTitle="There is no data">
        <DashboardTableCard rows={goalRows} columns={goalsTableColumns} withoutToolbar fullHeight />
      </DashboardContent>
      <ModalWrapper handleClose={handleCloseModal} open={openCreationModal}>
        <GoalCreationModal
          handleClose={handleCloseModal}
          goalInfo={selectedGoal}
          handleGetGoals={handleGetGoals}
          pixelId={pixelId}
        />
      </ModalWrapper>
    </>
  );
};

export default Pixels;
