import makeApiCall from "../helpers/makeApiCall";
import urlBase64ToUint8Array from "../helpers/urlBase64ToUint8Array";

export default class SndServiceWorker {
  static async getServiceWorkerRegistration() {
    await navigator.serviceWorker.register(`/web-push.js`, { scope: "/" });
    return navigator.serviceWorker.ready;
  }

  static async getPushNotificationSubscription(serviceWorkerRegistration) {
    return serviceWorkerRegistration.pushManager.getSubscription();
  }

  static async subscribeOnPushNotifications(vapidPublicKey, serviceWorkerRegistration) {
    const subscription = await serviceWorkerRegistration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: urlBase64ToUint8Array(vapidPublicKey)
    });
    const toSendJson = subscription.toJSON();
    await makeApiCall({
      url: "/web-push/subscribe",
      method: "POST",
      data: {
        auth: toSendJson.keys.auth,
        p256dh: toSendJson.keys.p256dh,
        endpoint: toSendJson.endpoint
      },
      headers: {
        "content-type": "application/json"
      }
    });
  }

  static async unsubscribeOnPushNotifications() {
    const serviceWorkerRegistration = await SndServiceWorker.getServiceWorkerRegistration();
    const subscription = await SndServiceWorker.getPushNotificationSubscription(serviceWorkerRegistration);
    await subscription.unsubscribe();
    const toSendJson = subscription.toJSON();
    await makeApiCall({
      url: "/web-push/unsubscribe",
      method: "POST",
      data: {
        auth: toSendJson.keys.auth,
        p256dh: toSendJson.keys.p256dh,
        endpoint: toSendJson.endpoint
      },
      headers: {
        "content-type": "application/json"
      }
    });
  }

  static async requestPushNotificationPermissions() {
    if ("serviceWorker" in navigator && window.Notification) {
      return Notification.requestPermission();
    }
    return Promise.resolve("denied");
  }

  static async getVapidPublicKeyAsync() {
    return makeApiCall({
      url: "/web-push/vapid-public-key",
      headers: {
        "content-type": "application/json"
      }
    });
  }
}
