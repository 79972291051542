import { ApiService } from "services/ApiService";

export default class BeginnerCampaignCreation {
  static async initBeginnerCreation(userId) {
    return ApiService._requestAsync({
      url: `/campaign-creation-beginner/init/${userId}`,
      method: "POST"
    });
  }

  static async prevStep(data) {
    return ApiService._requestAsync({
      url: "/campaign-creation-beginner/back",
      method: "POST",
      data
    });
  }

  static async selectBeginnerGoal(data) {
    return ApiService._requestAsync({
      url: `/campaign-creation-beginner/goal`,
      method: "POST",
      data
    });
  }

  static async sendDescribeBusiness(data) {
    return ApiService._requestAsync({
      url: `/campaign-creation-beginner/describe-business`,
      method: "POST",
      data
    });
  }

  static async sendLocations(data) {
    return ApiService._requestAsync({
      url: `/campaign-creation-beginner/location`,
      method: "POST",
      data
    });
  }

  static async sendTargetAds(data) {
    return ApiService._requestAsync({
      url: `/campaign-creation-beginner/target-add`,
      method: "POST",
      data
    });
  }

  static async sendBudget(data) {
    return ApiService._requestAsync({
      url: `/campaign-creation-beginner/budget`,
      method: "POST",
      data
    });
  }

  static async sendUploadAd(data) {
    return ApiService._requestAsync({
      url: `/campaign-creation-beginner/write-add`,
      method: "POST",
      data
    });
  }

  static async sendPayment(data) {
    return ApiService._requestAsync({
      url: `/campaign-creation-beginner/payment`,
      method: "POST",
      data
    });
  }

  static async confirmCampaign(data) {
    return ApiService._requestAsync({
      url: `/campaign-creation-beginner/complete`,
      method: "POST",
      data
    });
  }
}
