import React from "react";

import BlueLogo from "../../assets/images/blue-logo.svg";

import "./logo.scss";

export default ({ src = BlueLogo, position = "relative", logoTextClass = "", zIndex = 1 }) => {
  return (
    <div className="logo" style={{ position, zIndex }}>
      <img src={src} alt="" />
      <a href="/" className={`search-deploy ${logoTextClass}`}>
        Searched
      </a>
    </div>
  );
};
