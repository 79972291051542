import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import { dsToast } from "shared/dsToast";

import TrackingPixel from "services/trackingPixel";
import { pixelTableColumns } from "constants/tableColumns";
import { PIXEL_GOALS } from "pathnameVariables";

import DashboardContent from "shared/DashboardContent";
import DashboardTableCard from "shared/DashboardTableCard";
import ModalWrapper from "shared/ModalWrapper";
import Header from "shared/Header";

import GoalIcon from "assets/images/goalBlue.svg";
import SetupIcon from "assets/images/setupWhite.svg";

import SetupPixelModal from "./SetupPixelModal";

import "./styles.scss";

const Pixels = () => {
  const { push } = useHistory();
  const [isEmpty, setIsEmpty] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [tableRows, setTableRows] = useState([]);
  const [setupPixel, setSetupPixel] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    TrackingPixel.getPixelsList()
      .then(data => {
        if (!data?.length) {
          setIsEmpty(true);
        } else {
          setIsEmpty(false);
        }
        const columnName = index => ({ columnName: pixelTableColumns[index].value });
        setTableRows(
          data.map(val => ({
            rowName: val.url.split("?")[0],
            columns: [
              { label: val.url.split("?")[0], isFirst: true, noIcon: true, ...columnName(0) },
              { label: val.status ? "Active" : "Not active", ...columnName(1) },
              {
                label: (
                  <div className="pixel-table-btn-box">
                    <Button className="pixel-btn" onClick={() => setSetupPixel(val)}>
                      Setup
                      <img src={SetupIcon} alt="" />
                    </Button>
                    <Button className="pixel-btn" onClick={() => push(`${PIXEL_GOALS}?id=${val.id}`)}>
                      Goals
                      <img src={GoalIcon} alt="" />
                    </Button>
                  </div>
                ),
                ...columnName(2)
              }
            ]
          }))
        );
      })
      .catch(() => dsToast.error("We cannot get pixels. Please try again later."))
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <>
      <Header />
      <DashboardContent isLoading={isLoading} isEmpty={isEmpty} emptyTitle="There is no data">
        <DashboardTableCard rows={tableRows} columns={pixelTableColumns} withoutToolbar fullHeight />
        <ModalWrapper handleClose={() => setSetupPixel(null)} open={setupPixel}>
          <SetupPixelModal handleClose={() => setSetupPixel(null)} pixel={setupPixel} />
        </ModalWrapper>
      </DashboardContent>
    </>
  );
};

export default Pixels;
