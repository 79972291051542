import React from "react";

export const DownloadIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="transparent" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.5 12.5V15.8333C17.5 16.2754 17.3244 16.6993 17.0118 17.0118C16.6993 17.3244 16.2754 17.5 15.8333 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V12.5"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.83325 8.3335L9.99992 12.5002L14.1666 8.3335"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M10 12.5V2.5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const FilterIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="transparent" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path d="M3.33325 17.4998V11.6665" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M3.33325 8.33333V2.5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10 17.5V10" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10 6.66667V2.5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M16.6667 17.5002V13.3335" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M16.6667 10V2.5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M0.833252 11.6665H5.83325" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7.5 6.6665H12.5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M14.1667 13.3335H19.1667" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="20" height="20" fill="transparent" />
      </clipPath>
    </defs>
  </svg>
);

export const LocationIcon = () => (
  <svg width="20" height="24" viewBox="0 0 20 24" fill="transparent" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19 10C19 17 10 23 10 23C10 23 1 17 1 10C1 7.61305 1.94821 5.32387 3.63604 3.63604C5.32387 1.94821 7.61305 1 10 1C12.3869 1 14.6761 1.94821 16.364 3.63604C18.0518 5.32387 19 7.61305 19 10Z"
      stroke="#C7CDE3"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 13C11.6569 13 13 11.6569 13 10C13 8.34315 11.6569 7 10 7C8.34315 7 7 8.34315 7 10C7 11.6569 8.34315 13 10 13Z"
      stroke="#C7CDE3"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const SpecificIcon = () => (
  <svg width="24" height="22" viewBox="0 0 24 22" fill="transparent" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 5V21L8 17L16 21L23 17V1L16 5L8 1L1 5Z"
      stroke="#C7CDE3"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M8 1V17" stroke="#C7CDE3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M16 5V21" stroke="#C7CDE3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
