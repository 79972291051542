import React from "react";
import TopLeft from "assets/images/topLeftImg.svg";
import BottomRight from "assets/images/bottomRight.svg";
import Icon from "assets/images/helpFooterIcon.svg";
import BigLogo from "assets/images/bigLogo.svg";
import "./styles.scss";

const HelpFooter = ({ isWhiteBg }) => {
  return (
    <div className={`help-footer-wrapper ${isWhiteBg ? "white-bg" : ""}`}>
      <img src={TopLeft} alt="" className="top-left-img" />
      <img src={BottomRight} alt="" className="bottom-right-img" />
      <div className="help-footer-content">
        <div className="help-footer-info-box">
          <div className="logo-box">
            <img src={BigLogo} alt="" />
          </div>
          <div className="info-content">
            <div className="help-footer-title">Need help?</div>
            <div className="help-footer-text">
              Call for free ad setup help at{" "}
              <a target="_blank" href="tel:800-111-1111">
                800-111-1111
              </a>{" "}
              09:00 - 06:00 EST (GMT-5)
            </div>
            <a className="help-footer-link" target="_blank" href="https://help.datasys.com">
              More help options
            </a>
          </div>
        </div>
        <img src={Icon} alt="" className="help-footer-main-icon" />
      </div>
    </div>
  );
};

export default HelpFooter;
