import React from "react";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";

import { CAMPAIGNS } from "pathnameVariables";

import NoDataImage from "assets/images/noDataImage.svg";

import "./styles.scss";

export const InactiveAccountModal = () => {
  const { push } = useHistory();

  return (
    <div className="inactive-account-modal-content">
      <img src={NoDataImage} alt="" />
      Your account is inactive
      <br />
      Please contact Searched support
      <Button onClick={() => push(CAMPAIGNS)}>Dashboard</Button>
    </div>
  );
};

export default InactiveAccountModal;
