import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

import { NOT_ACCESS, EMAIL_NOT_CONFIRM } from "pathnameVariables";
import { getUserInfo } from "redux/actions/userProfile";
import AccountScopeService from "services/accountScope.service";
import GlobalLoader from "shared/GlobalLoader";
import useMe from "customHooks/useMe";

const PrivateRoute = ({ component: Component, history, handleGetUserInfo }) => {
  const [isLoading, setLoading] = useState(true);
  const [isAuthenticated, setAuthenticated] = useState(false);
  const me = useMe();

  useEffect(() => {
    (async () => {
      if (me.isBlocked) {
        history.push(NOT_ACCESS);
        return;
      }
      const isEmailConfirmed = await AccountScopeService.isEmailConfirmed();
      if (!isEmailConfirmed) {
        history.push(EMAIL_NOT_CONFIRM);
      } else {
        handleGetUserInfo();
        setAuthenticated(true);
      }
      setLoading(false);
    })();
  }, []);

  return (
    <>
      <GlobalLoader isLoading={isLoading} />
      {isAuthenticated ? <Component /> : null}
    </>
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.elementType,
  location: PropTypes.instanceOf(Object)
};

PrivateRoute.defaultProps = {
  component: {},
  location: {}
};

const mapDispatchToProps = dispatch => ({
  handleGetUserInfo: payload => dispatch(getUserInfo(payload))
});

export default connect(null, mapDispatchToProps)(withRouter(PrivateRoute));
