// @flow
import makeApiCall from "../helpers/makeApiCall";

export default class AccountScopeService {
  static isAdmin(role: number): boolean {
    return role === 2;
  }

  // TODO: Return back async checking, need to refactor other app to remove this function
  static getMeAsync(): { userId: number, accountId: number, role: number, auth0UserId: string, accountRole: string } {
    return makeApiCall({
      url: "/users/me/scope",
      tokenRequired: true
    });
  }

  // TODO: Return back async checking, need to refactor other app to remove this function
  static async isAdminAsync(): Promise<boolean> {
    const me = await this.getMeAsync();
    return me.role === 2;
  }

  static async isEmailConfirmed(): Promise<any> {
    return makeApiCall({
      url: "/users/is-email-confirm",
      tokenRequired: true
    });
  }
}
