import React from "react";
import { Button } from "@material-ui/core";
import "./styles.scss";

const SimpleBtn = ({ children, ...restProps }) => {
  return (
    <Button {...restProps} className="simple-btn">
      {children}
    </Button>
  );
};

export default SimpleBtn;
