import React from "react";

import { onlyNumberString } from "helpers/onlyNumberString";

import CustomCheckbox from "shared/CustomCheckbox";
import StyledSelect from "shared/StyledSelect";
import StyledTextField from "shared/StyledTextField";

import ExpertModeCard from "../../components/ExpertModeCard";

import "./styles.scss";

const frequency = [
  {
    label: "Let Searched optimize how often your ads show (recommended)",
    value: "optimize"
  },
  {
    label: "Set a limit",
    value: "custom"
  }
];

const limitPer = [
  {
    label: "day",
    value: "0"
  },
  {
    label: "week",
    value: "1"
  },
  {
    label: "month",
    value: "2"
  }
];

const limitFor = [
  {
    label: "each ad",
    value: "0"
  },
  {
    label: "each ad group",
    value: "1"
  },
  {
    label: "the whole campaign",
    value: "2"
  }
];

const FrequencyCapping = ({ input }) => {
  return (
    <ExpertModeCard title="Frequency capping" className="frequency-capping-card-wrapper" isDefaultOpen={false}>
      <div className="card-subtitle">Limit how many times your ads show to the same user</div>
      {frequency?.map(val => (
        <CustomCheckbox
          key={val.value}
          onChange={() => input?.onChange({ ...input?.value, frequencyType: val.value })}
          checked={val.value === input?.value?.frequencyType}
          isTypeRadio
          label={val.label}
        />
      ))}
      {input?.value?.frequencyType === "custom" && (
        <div className="card-field-set">
          <StyledSelect
            className="long-field"
            label="Limit impressions for"
            options={limitFor}
            value={input?.value?.limit?.for}
            onChange={e => input?.onChange({ ...input?.value, limit: { ...input?.value?.limit, for: e.target.value } })}
          />
          <div className="text-between-fields">to</div>
          <StyledTextField
            className="short-field"
            value={input?.value?.limit?.to}
            onChange={e => {
              if (onlyNumberString(e.target.value) || e.target.value === "") {
                return input?.onChange({
                  ...input?.value,
                  limit: { ...input?.value?.limit, to: e.target.value }
                });
              }
            }}
          />
          <div className="text-between-fields">per</div>
          <StyledSelect
            className="short-field"
            options={limitPer}
            value={input?.value?.limit?.per}
            onChange={e => input?.onChange({ ...input?.value, limit: { ...input?.value?.limit, per: e.target.value } })}
          />
        </div>
      )}
    </ExpertModeCard>
  );
};

export default FrequencyCapping;
