import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { dsToast } from "shared/dsToast";
import { connect } from "react-redux";

import { BEGINNER_DESCRIBE_BUS, EXPERT_GOAL, TERMS } from "pathnameVariables";
import { setCampaignId, setGoal } from "redux/actions/beginnerMode";
import BeginnerCampaignCreation from "services/beginnerCampaignCreation";

import Loader from "shared/Loader";
import GoalCard from "shared/GoalCard";

import Goal1 from "assets/images/beginnerGoal1.svg";
import Goal2 from "assets/images/beginnerGoal2.svg";
import Goal3 from "assets/images/beginnerGoal3.svg";
import Goal4 from "assets/images/beginnerGoal4.svg";
import SmallGoal1 from "assets/images/beginnerSmallGoal1.svg";
import SmallGoal2 from "assets/images/beginnerSmallGoal2.svg";
import SmallGoal3 from "assets/images/beginnerSmallGoal3.svg";
import SmallGoal4 from "assets/images/beginnerSmallGoal4.svg";

import "./styles.scss";

export const goals = [
  {
    name: "Build your brand",
    goalType: 3,
    icon: Goal1,
    smallIcon: SmallGoal1
  },
  {
    name: "Get more calls",
    goalType: 0,
    icon: Goal2,
    smallIcon: SmallGoal2
  },
  {
    name: "Get more website sales or sign-ups",
    goalType: 1,
    icon: Goal3,
    smallIcon: SmallGoal3
  },
  {
    name: "Get more visits to your physical location",
    goalType: 2,
    icon: Goal4,
    smallIcon: SmallGoal4
  }
];

const SelectGoal = ({ userData, handleSetCampaignId, handleSetGoal, campaignId }) => {
  const { push } = useHistory();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (userData) {
      BeginnerCampaignCreation.initBeginnerCreation(userData.accountId).then(data => {
        handleSetCampaignId(data.campaignCreation.campaignId);
        setIsLoading(false);
      });
    }
  }, [userData]);

  const handleSelectGoal = goal => {
    setIsLoading(true);
    BeginnerCampaignCreation.selectBeginnerGoal({ campaignId, goalType: goal.goalType })
      .then(data => {
        handleSetCampaignId(data.campaignCreation.campaignId);
        handleSetGoal(goal.goalType);
        push(BEGINNER_DESCRIBE_BUS);
      })
      .catch(() => {
        setIsLoading(false);
        dsToast.error("Sorry, something went wrong. Try again later");
      });
  };

  return (
    <div className="beginner-goal-selection-wrapper">
      {isLoading ? (
        <Loader empty isDashboardContent />
      ) : (
        <div className="beginner-goal-selection-content">
          <div className="beginner-goal-selection-title">
            What’s your main advertising goal?
          </div>
          <div className="beginner-goal-selection-subtitle">
            Ads that focus on a specific goal help you
            <br />
            get the results you want
          </div>
          <div className="beginner-goals-list">
            {goals?.map(val => (
              <GoalCard key={val.goalType} onClick={() => handleSelectGoal(val)} title={val.name} img={val.icon} />
            ))}
          </div>
          <div className="to-beginner-mode">
            Are you a professional marketer? <Link to={EXPERT_GOAL}>Switch to expert mode</Link>
          </div>
          <div className="terms-link-box">
            <Link to={TERMS}>Terms and Conditions</Link>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  campaignId: state.beginnerMode.campaignId,
  userData: state.userProfile.userInfo
});

const mapDispatchToProps = dispatch => ({
  handleSetCampaignId: payload => dispatch(setCampaignId(payload)),
  handleSetGoal: payload => dispatch(setGoal(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectGoal);
