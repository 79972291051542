import React from "react";
import { Modal, Backdrop } from "@material-ui/core";
import { useSpring, animated } from "react-spring/web.cjs";

import "./styles.scss";

const Fade = React.forwardRef(function Fade(props, ref) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    }
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});

const ModalWrapper = ({ open, handleClose, children }) => {
  return (
    <Modal
      open={Boolean(open)}
      onClose={handleClose}
      className="modal-wrapper"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      <Fade in={Boolean(open)}>
        <div className="modal-content">{children}</div>
      </Fade>
    </Modal>
  );
};

export default ModalWrapper;
