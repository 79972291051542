import React, { useState } from "react";
import { connect } from "react-redux";

import UserProfileInfo from "services/userProfile";

import CustomSwitcher from "shared/CustomSwitcher";

import SettingsCard from "../components/SettingsCard";

import "./styles.scss";

const Notifications = ({ notificationSettings, userInfo }) => {
  const [notifications, setNotifications] = useState(notificationSettings);

  const handleChange = (key, newValue) => {
    UserProfileInfo.updateNotificationSettings({ [key]: newValue }).then(data => setNotifications(data));
  };

  return (
    <div className="notifications-settings-wrapper">
      <SettingsCard title="Notification Emails">
        <CustomSwitcher
          label="User sign up"
          value={notifications.mailUserSignUp}
          onChange={newValue => handleChange("mailUserSignUp", newValue)}
        />
        <CustomSwitcher
          label="Campaign status changes"
          value={notifications.mailCampaignStatusChanged}
          onChange={newValue => handleChange("mailCampaignStatusChanged", newValue)}
        />
        <CustomSwitcher
          label="Billing transactions"
          value={notifications.mailBillingTransactions}
          onChange={newValue => handleChange("mailBillingTransactions", newValue)}
        />
      </SettingsCard>
      <SettingsCard
        title="Web Push Notifications"
        className={`web-push-card ${userInfo?.hasWebPushPermission ? "not-flex" : ""}`}
      >
        {!userInfo?.hasWebPushPermission && (
          <div className="web-push-info">To use Web Push notifications please grant the permission for this site</div>
        )}
        <CustomSwitcher
          disabled={!userInfo?.hasWebPushPermission}
          label="Start/stop campaign"
          value={notifications.webPushStartStopCampaign}
          onChange={newValue => handleChange("webPushStartStopCampaign", newValue)}
        />
      </SettingsCard>
    </div>
  );
};

const mapStateToProps = state => ({
  userInfo: state.userProfile.userInfo
});

export default connect(mapStateToProps)(Notifications);
