import React from "react";
import { connect } from "react-redux";
import { Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";

import Arrow from "assets/images/arrowDropDownBlue.svg";
import ArrowWhite from "assets/images/arrowDropDownWhite.svg";

import "./styles.scss";

const AdditionalSettingsContainer = ({ children, userInfo }) => {
  return (
    <Accordion className="additional-settings-container">
      <AccordionSummary
        className="additional-settings-head"
        expandIcon={<img src={userInfo?.isDarkTheme ? ArrowWhite : Arrow}alt="" />}
      >
        <div className="expert-mode-card-title">Additional settings</div>
      </AccordionSummary>
      <AccordionDetails className="expert-mode-card-body">{children}</AccordionDetails>
    </Accordion>
  );
};

const mapStateToProps = state => ({
  userInfo: state.userProfile.userInfo
});

export default connect(mapStateToProps)(AdditionalSettingsContainer);
