import { handleActions } from "redux-actions";
import { setSearchInputValue, setOpenSubUserModal, setSubUsersList } from "redux/actions/admin";

const initialState = {
  searchInputValue: "",
  openSubUserModal: false,
  subUsersList: null
};

const handleSetSearchInputValue = (state, { payload }) => {
  return { ...state, searchInputValue: payload };
};

const handleSetOpenSubUserModal = (state, { payload }) => {
  return { ...state, openSubUserModal: payload };
};

const handleSetSubUsersList = (state, { payload }) => {
  return { ...state, subUsersList: payload };
};

const reducer = handleActions(
  {
    [setSearchInputValue]: handleSetSearchInputValue,
    [setOpenSubUserModal]: handleSetOpenSubUserModal,
    [setSubUsersList]: handleSetSubUsersList
  },
  { ...initialState }
);

export default reducer;
