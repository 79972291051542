import React from "react";
import { connect } from "react-redux";
import { Select, MenuItem, FormControl } from "@material-ui/core";

import Arrow from "assets/images/arrowDropDownBlue.svg";
import ArrowWhite from "assets/images/arrowDropDownWhite.svg";

import "./styles.scss";

const StyledSelect = ({
  input,
  label,
  options,
  className,
  valueFormat,
  labelFormat,
  helperText,
  userInfo,
  ...restProps
}) => {
  const props = {
    className: "styled-select",
    IconComponent: () => <img src={userInfo?.isDarkTheme ? ArrowWhite : Arrow} alt="" />,
    MenuProps: {
      anchorOrigin: {
        vertical: 33,
        horizontal: "center"
      },
      transformOrigin: {
        vertical: "top",
        horizontal: "center"
      },
      className: "styled-select-popover",
      getContentAnchorEl: null
    }
  };
  const getValue = val => (valueFormat ? valueFormat(val) : val?.value);

  const getLabel = val => (labelFormat ? labelFormat(val) : val?.label);

  return (
    <div className={`styled-select-wrapper ${className || ""}`}>
      {label && <div className="styled-select-label">{label}</div>}
      <FormControl>
        {input ? (
          <Select {...input} {...props}>
            {options?.map(val => (
              <MenuItem disabled={val.disabled} value={getValue(val)}>
                {getLabel(val)}
              </MenuItem>
            ))}
          </Select>
        ) : (
          <Select {...restProps} {...props}>
            {options?.map(val => (
              <MenuItem disabled={val.disabled} value={getValue(val)}>
                {getLabel(val)}
              </MenuItem>
            ))}
          </Select>
        )}
      </FormControl>
      {helperText && <div className="select-helper-text">{helperText}</div>}
    </div>
  );
};

const mapStateToProps = state => ({
  userInfo: state.userProfile.userInfo
});

export default connect(mapStateToProps)(StyledSelect);
