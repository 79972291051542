import React, { useState, useRef, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { Button } from "@material-ui/core";

import { setOpenMobileMenu } from "redux/actions/campaigns";

import Arrow from "assets/images/arrowDropDownBlue.svg";
import ArrowWhite from "assets/images/arrowDropDownWhite.svg";
import PlusIcon from "assets/images/whitePlus.svg";

import "./styles.scss";

const MenuLink = ({ label, href, subLinks, onBtnClick, handleOpenMobileMenu, userInfo }) => {
  const ref = useRef();
  const location = useLocation();
  const [isListOpen, setIsListOpen] = useState(false);

  const isActive = pathname => location.pathname === pathname;

  useEffect(() => {
    if (subLinks?.find(val => val.href === location.pathname)) {
      setIsListOpen(true);
    }
  }, []);

  return (
    <div className="multi-link-wrapper">
      {subLinks ? (
        <>
          <Button className={`show-list-btn ${isListOpen ? "active" : ""}`} onClick={() => setIsListOpen(!isListOpen)}>
            {label}
            <img src={userInfo?.isDarkTheme ? ArrowWhite : Arrow} alt="" />
          </Button>
          <div className="list-wrapper" style={{ height: isListOpen ? `${ref?.current?.clientHeight}px` : "0" }}>
            <div className="links-list" ref={ref}>
              {subLinks?.map(val => (
                <Link
                  onClick={() => handleOpenMobileMenu(false)}
                  key={val.label}
                  to={val.href}
                  className={`menu-link ${isActive(val.href) ? "active" : ""}`}
                >
                  {val.label}
                </Link>
              ))}
            </div>
          </div>
        </>
      ) : (
        <>
          <Link
            onClick={() => handleOpenMobileMenu(false)}
            to={href}
            className={`menu-link ${isActive(href) ? "active" : ""}`}
          >
            {label}
          </Link>
          {onBtnClick && (
            <Button className="link-btn" onClick={onBtnClick}>
              <img src={PlusIcon} alt="" />
            </Button>
          )}
        </>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  userInfo: state.userProfile.userInfo
});

const mapDispatchToProps = dispatch => ({
  handleOpenMobileMenu: payload => dispatch(setOpenMobileMenu(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(MenuLink);
