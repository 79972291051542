import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, TextField, SwipeableDrawer } from "@material-ui/core";

import { BEGINNER_TARGET_ADS, BEGINNER_UPLOAD_AD } from "pathnameVariables";
import BeginnerCampaignCreation from "services/beginnerCampaignCreation";
import { onlyNumberString } from "helpers/onlyNumberString";
import CampaignsCreation from "services/campaignCreation";
import { setBudget, setPrevState, setEstimate, handlePrevStep } from "redux/actions/beginnerMode";

import StyledSlider from "shared/StyledSlider";
import Loader from "shared/Loader";

import ImpsIcon from "assets/images/impressionsIcon.svg";
import ImpsIconWhite from "assets/images/impressionsIconWhite.svg";
import ClicksIcon from "assets/images/clicksIcon.svg";
import ClicksIconWhite from "assets/images/clicksIconWhite.svg";

import StepContent from "../components/StepContent";
import BeginnerCard from "../components/BeginnerCard";
import StepBtnBox from "../components/StepBtnBox";

import "./styles.scss";
import TipIcon from "../../../assets/images/tipIcon.svg";
import CloseIcon from "../../../assets/images/closeBlue.svg";

const Budget = ({
  campaignId,
  handleSetPrevState,
  handleSetBudget,
  budget,
  adGroupId,
  handleSetEstimate,
  estimate,
  handlePreviousStep,
  userInfo,
  clientWidth
}) => {
  const { push } = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [cpm, setCpm] = useState(0);
  const [openEstimate, setOpenEstimate] = useState(false);

  const handleBack = () => {
    setIsLoading(true);
    handlePreviousStep(() => push(BEGINNER_TARGET_ADS));
  };

  const handleNextStep = () => {
    setIsLoading(true);
    const budgetData = {
      campaignId,
      adGroupId,
      currency: "USD",
      moneyAmount: Number(budget)
    };
    BeginnerCampaignCreation.sendBudget(budgetData).then(data => {
      handleSetPrevState(data.campaignCreation.prevState);
      push(BEGINNER_UPLOAD_AD);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    CampaignsCreation.getCPM().then(data => setCpm(data.cpm));
  }, []);

  useEffect(() => {
    const countOfImpPerDay = Number(budget) / cpm;
    const minCpm = countOfImpPerDay * 30;
    const maxCpm = minCpm * 1.43;

    handleSetEstimate({
      imps: { min: minCpm, max: maxCpm },
      clicks: { min: Math.round(maxCpm * 0.01), max: Math.round(maxCpm * 0.05) }
    });
  }, [budget, cpm]);

  const estimateComponent = () => (
    <div className="budget-estimate-box">
      <Button className="close-btn" onClick={() => setOpenEstimate(false)}>
        <img src={CloseIcon} alt="" />
      </Button>
      <div className="estimate-box-title">Estimated perfomance</div>
      <div className="estimate-item">
        <img src={userInfo?.isDarkTheme ? ImpsIconWhite : ImpsIcon} alt="" />
        <div className="item-value">
          <div>{`${estimate.imps.min} - ${estimate.imps.max} impressions`}</div>
          <div>per month</div>
        </div>
      </div>
      <div className="estimate-item">
        <img src={userInfo?.isDarkTheme ? ClicksIconWhite : ClicksIcon} alt="" />
        <div className="item-value">
          <div>{`${estimate.clicks.min} - ${estimate.clicks.max} clicks`}</div>
          <div>per month</div>
        </div>
      </div>
      <div className="estimate-description">
        Estimated monthly performance is calculated based on businesses with similar ad settings and budget
      </div>
    </div>
  );

  return (
    <div className="beginner-budget-step-wrapper">
      {isLoading ? (
        <Loader empty isDashboardContent />
      ) : (
        <>
          <div className="budget-content-wrapper">
            <StepContent title="Set your budget" className="budget-content">
              <BeginnerCard>
                <div className="budget-amount-box">
                  <div className="input-box">
                    <div className="currency">$</div>
                    <TextField
                      value={budget}
                      onChange={e => {
                        if (onlyNumberString(e.target.value) || e.target.value === "") {
                          handleSetBudget(onlyNumberString(e.target.value) || e.target.value);
                        }
                      }}
                    />
                    <div className="input-label">per day average</div>
                  </div>
                  <div className="monthly-estimate">{`$${budget * 31} monthly maximum`}</div>
                </div>
                <StyledSlider
                  min={0}
                  step={1}
                  max={1000}
                  value={Number(budget)}
                  onChange={(e, newValue) => handleSetBudget(newValue)}
                />
              </BeginnerCard>
              <StepBtnBox>
                <Button onClick={handleBack}>Back</Button>
                <Button disabled={!Number(budget)} onClick={handleNextStep}>
                  Confirm
                </Button>
              </StepBtnBox>
            </StepContent>
          </div>
          {clientWidth > 1060 ? (
            estimateComponent()
          ) : (
            <>
              <SwipeableDrawer
                open={openEstimate}
                anchor="right"
                onClose={() => setOpenEstimate(false)}
                onOpen={() => setOpenEstimate(true)}
                className="estimate-drawer-wrapper"
              >
                {estimateComponent()}
              </SwipeableDrawer>
              <Button onClick={() => setOpenEstimate(true)} className="open-estimate-btn">
                <img src={TipIcon} alt="" />
              </Button>
            </>
          )}
        </>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  clientWidth: state.userProfile.clientWidth,
  userInfo: state.userProfile.userInfo,
  campaignId: state.beginnerMode.campaignId,
  adGroupId: state.beginnerMode.adGroupId,
  budget: state.beginnerMode.budget,
  estimate: state.beginnerMode.estimate
});

const mapDispatchToProps = dispatch => ({
  handleSetPrevState: payload => dispatch(setPrevState(payload)),
  handleSetBudget: payload => dispatch(setBudget(payload)),
  handlePreviousStep: payload => dispatch(handlePrevStep(payload)),
  handleSetEstimate: payload => dispatch(setEstimate(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(Budget);
