import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";

import { EXPERT_GOAL, EXPERT_CREATION } from "pathnameVariables";

import LogoLink from "shared/Header/LogoLink";
import Header from "shared/Header";
import ModalWrapper from "shared/ModalWrapper";
import InactiveAccountModal from "shared/InactiveAccountModal";

import GoalSelection from "./GoalSelection";
import CampaignCreation from "./CampaignCreation";

import "./styles.scss";

export const ExpertMode = ({ userInfo }) => {
  const [isOpenInactiveModal, setIsOpenInactiveModal] = useState(false);

  useEffect(() => {
    if (userInfo && userInfo?.account && !userInfo?.account?.isActive) {
      setIsOpenInactiveModal(true);
    } else if (userInfo) {
      setIsOpenInactiveModal(false);
    }
  }, [userInfo]);

  return (
    <div className="expert-mode-wrapper">
      <Header leftSideContent={<LogoLink />} withoutMenuBtn />
      <ModalWrapper handleClose={() => {}} open={isOpenInactiveModal}>
        <InactiveAccountModal />
      </ModalWrapper>
      <Switch>
        <Route exact path={EXPERT_GOAL} component={GoalSelection} />
        <Route exact path={EXPERT_CREATION} component={CampaignCreation} />
      </Switch>
    </div>
  );
};

const mapStateToProps = state => ({
  userInfo: state.userProfile.userInfo
});

export default connect(mapStateToProps)(ExpertMode);
