import React, { useState } from "react";

import DropZoneCard from "shared/DropZoneCard";

import Size300x250 from "assets/images/300x250.svg";
import Size320x50 from "assets/images/320x50.svg";
import Size160x600 from "assets/images/160x600.svg";
import Size728x90 from "assets/images/728x90.svg";

import "./styles.scss";

const initialExpectedImages = [
  {
    title: "300 x 250",
    subtitle: "Medium Rectange",
    defaultIcon: Size300x250,
    expectedImgSize: { width: 300, height: 250 }
  },
  {
    title: "320 x 50",
    subtitle: "Mobile Leaderboard",
    defaultIcon: Size320x50,
    expectedImgSize: { width: 320, height: 50 }
  },
  {
    title: "160 x 600",
    subtitle: "Wide Skyscraper",
    defaultIcon: Size160x600,
    expectedImgSize: { width: 160, height: 600 }
  },
  {
    title: "728 x 90",
    subtitle: "Leaderboard",
    defaultIcon: Size728x90,
    expectedImgSize: { width: 728, height: 90 }
  }
];

const AdsDropFilesZone = ({ rootObj, onChange }) => {
  const [expectedImages, setExpectedImages] = useState(initialExpectedImages);

  const handleAddFile = (data, i) => {
    setExpectedImages(
      expectedImages.map((value, index) => {
        if (index === i) {
          return { ...value, imgInfo: data };
        }
        return value;
      })
    );
    return onChange({ ...rootObj, files: [...rootObj?.files, data] });
  };

  const handleDeleteFile = (val, i) => {
    setExpectedImages(
      expectedImages.map((value, index) => {
        if (index === i) {
          return { ...value, imgInfo: null };
        }
        return value;
      })
    );
    return onChange({
      ...rootObj,
      files: rootObj?.files.filter(value => value.data !== val.imgInfo?.data)
    });
  };

  return (
    <div className="ads-drop-files-zone-wrapper">
      {expectedImages?.map((val, i) => (
        <DropZoneCard
          key={i.toString()}
          title={val.title}
          subtitle={val.subtitle}
          cardIcon={val.defaultIcon}
          expectedImgSize={val.expectedImgSize}
          handleSave={data => handleAddFile(data, i)}
          imgInfo={val.imgInfo}
          clearDropZone={() => handleDeleteFile(val, i)}
          handleSaveAsCustom={data => {
            return onChange({ ...rootObj, customFiles: [data, ...rootObj?.customFiles] });
          }}
        />
      ))}
      {rootObj?.customFiles?.map((val, i) => (
        <DropZoneCard
          key={i.toString()}
          title={`${val.width} x ${val.height}`}
          subtitle={val.name}
          handleSave={data => {
            return onChange({
              ...rootObj,
              customFiles: rootObj?.customFiles?.map((value, index) => (index === i ? data : value))
            });
          }}
          imgInfo={val}
          isCustom
          clearDropZone={() => {
            return onChange({
              ...rootObj,
              customFiles: rootObj?.customFiles?.filter(value => value.data !== val.data)
            });
          }}
        />
      ))}
      <DropZoneCard
        isEmptyBtn
        handleSave={data =>
          onChange({ ...rootObj, customFiles: [...rootObj?.customFiles, data] })
        }
      />
    </div>
  );
};

export default AdsDropFilesZone;
