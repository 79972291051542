import React from "react";
import { Button } from "@material-ui/core";
import "./styles.scss";

const TabSelection = ({ tabs, onChange, value }) => {
  return (
    <div className="tab-selection-wrapper">
      {tabs?.map(val => (
        <Button
          disabled={val.disabled}
          className={value.value === val.value ? "active" : ""}
          key={val.value}
          onClick={() => onChange(val)}
        >
          {val.label}
        </Button>
      ))}
    </div>
  );
};

export default TabSelection;
