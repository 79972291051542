import { uniq, groupBy } from "lodash";
import moment from "moment";

export const getArrSum = arr => {
  let sum = 0;
  arr.forEach(val => (sum += val));
  return sum;
};

export const getRoundValue = val => Math.round(val * 100) / 100;

export const getChartCommonData = value => ({
  clicks: getRoundValue(getArrSum(value?.map(stats => stats.metrics.clicks))),
  convs_rate: getRoundValue(getArrSum(value?.map(stats => stats.metrics.convs_rate))),
  cpm: getRoundValue(getArrSum(value?.map(stats => stats.metrics.cpm))),
  imps: getRoundValue(getArrSum(value?.map(stats => stats.metrics.imps)))
});

export const formatterAxisData = val => {
  if (val < 999) {
    return val;
  }
  if (val < 1000000) {
    return `${Math.round(val / 1000)}K`;
  }
  if (val < 10000000) {
    return `${(val / 1000000).toFixed(2)}M`;
  }
  if (val < 1000000000) {
    return `${Math.round(val / 1000000)}M`;
  }
  if (val < 1000000000000) {
    return `${Math.round(val / 1000000000)}B`;
  }
  return "1T+";
};

export const convertStatistic = (data, rowName, rowName2, format) => {
  const arrayOfArrays = data.map(val => {
    const arr = Array.isArray(val.statisticDetails) ? val.statisticDetails : Object.values(val.statisticDetails);
    return arr.map(value => ({ ...value, campaign: val.campaign }));
  });
  let rawDataset = [];
  arrayOfArrays.forEach(val => {
    rawDataset = [...rawDataset, ...val];
    return val;
  });
  const getRowName = val => {
    if (moment(val.dimensions[rowName]).isValid()) {
      return `${moment(val.dimensions[rowName]).format(format || "MMM DD, YYYY")} | ${val.dimensions[rowName2]}`;
    }
    return `${val.dimensions[rowName]} | ${val.dimensions[rowName2]}`;
  };
  const rowVariants = rawDataset.map(val => {
    if (rowName2) {
      return getRowName(val);
    }
    return val.dimensions[rowName];
  });
  return uniq(rowVariants).map(val => {
    return {
      name: val,
      data: rawDataset.filter(value => {
        if (rowName2) {
          return getRowName(value) === val;
        }
        return value.dimensions[rowName] === val;
      })
    };
  });
};

export const allCampaignsData = (rawData, key, parentKey = "statisticDetails") => {
  let allLocations = [];
  rawData.forEach(val => (allLocations = uniq([...allLocations, ...val[parentKey].map(value => value[key])])));
  return allLocations?.map(val => {
    const metrics = {
      clicks: 0,
      convs_rate: 0,
      cpm: 0,
      imps: 0,
      total_cost: 0
    };
    rawData.forEach(value =>
      value[parentKey]?.forEach(vl => {
        if (vl[key] === val) {
          Object.keys(metrics).forEach(key => {
            metrics[key] += getRoundValue(vl.metrics[key]);
          });
        }
      })
    );
    return {
      label: val,
      ...metrics,
      cpm: metrics.cpm.toFixed(2)
    };
  });
};

export const getAreaChartData = (rawData, expectedValue, statisticKey, dateFormat, delimiter) => {
  const sumByDate = rawData[0]?.[statisticKey]?.map(val => {
    const metrics = {
      clicks: 0,
      convs_rate: 0,
      cpm: 0,
      imps: 0
    };
    Object.keys(metrics).forEach(key =>
      rawData.forEach(
        value => (metrics[key] += value[statisticKey].find(dateDetails => dateDetails.date === val.date).metrics[key])
      )
    );
    return {
      label: val.date,
      blueData: getRoundValue(metrics[expectedValue.blue.value]),
      pinkData: getRoundValue(metrics[expectedValue.pink.value]),
      blueLabel: expectedValue.blue.label,
      pinkLabel: expectedValue.pink.label
    };
  });
  const currentGroup = groupBy(sumByDate, it =>
    moment(it.label)
      .startOf(delimiter || "day")
      .format("MM-DD-YYYY")
  );
  return Object.keys(currentGroup).map(val => {
    const values = {
      label: moment(val.replace('-','/')).format(dateFormat || "MMM DD"),
      blueLabel: currentGroup[val][0].blueLabel,
      pinkLabel: currentGroup[val][0].pinkLabel,
      blueData: 0,
      pinkData: 0
    };
    currentGroup[val].forEach(value => {
      values.blueData += value.blueData;
      values.pinkData += value.pinkData;
      return value;
    });
    return {
      ...values,
      blueData: getRoundValue(values.blueData),
      pinkData: getRoundValue(values.pinkData)
    };
  });
};
