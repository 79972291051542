import React, { useEffect, useState } from "react";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";

import {
  BEGINNER_GOAL,
  BEGINNER_DESCRIBE_BUS,
  BEGINNER_LOCATION,
  BEGINNER_TARGET_ADS,
  BEGINNER_BUDGET,
  BEGINNER_UPLOAD_AD,
  BEGINNER_PAYMENT,
  BEGINNER_CONFIRM
} from "pathnameVariables";

import Header from "shared/Header";
import LogoLink from "shared/Header/LogoLink";
import ModalWrapper from "shared/ModalWrapper";
import InactiveAccountModal from "shared/InactiveAccountModal";

import SelectGoal from "./SelectGoal";
import DescribeBusiness from "./DescribeBusiness";
import Location from "./Location";
import TargetAds from "./TargetAds";
import Budget from "./Budget";
import UploadAd from "./UploadAd";
import Payment from "./Payment";
import ConfirmCreation from "./ConfirmCreation";

import "./styles.scss";

const BeginnerMode = ({ campaignId, me, userInfo }) => {
  const { push } = useHistory();
  const { pathname } = useLocation();
  const [isOpenInactiveModal, setIsOpenInactiveModal] = useState(false);

  useEffect(() => {
    if (pathname !== BEGINNER_GOAL && !campaignId) {
      push(BEGINNER_GOAL);
    }
  }, []);

  useEffect(() => {
    if (userInfo && userInfo?.account && !userInfo?.account?.isActive) {
      setIsOpenInactiveModal(true);
    } else if (userInfo) {
      setIsOpenInactiveModal(false);
    }
  }, [userInfo]);

  return (
    <div className="beginner-mode-wrapper">
      <Header leftSideContent={<LogoLink />} withoutMenuBtn />
      <ModalWrapper handleClose={() => {}} open={isOpenInactiveModal}>
        <InactiveAccountModal />
      </ModalWrapper>
      <Switch>
        <Route exact path={BEGINNER_GOAL} component={SelectGoal} />
        <Route exact path={BEGINNER_DESCRIBE_BUS} component={DescribeBusiness} />
        <Route exact path={BEGINNER_LOCATION} component={Location} />
        <Route exact path={BEGINNER_TARGET_ADS} component={TargetAds} />
        <Route exact path={BEGINNER_BUDGET} component={Budget} />
        <Route exact path={BEGINNER_UPLOAD_AD} component={UploadAd} />
        {me?.accountRole !== "account-user" && <Route exact path={BEGINNER_PAYMENT} component={Payment} />}
        <Route exact path={BEGINNER_CONFIRM} component={ConfirmCreation} />
      </Switch>
    </div>
  );
};

const mapStateToProps = state => ({
  me: state.me,
  userInfo: state.userProfile.userInfo,
  campaignId: state.beginnerMode.campaignId
});

export default connect(mapStateToProps)(BeginnerMode);
