import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { withRouter } from "react-router-dom";
import { dsToast } from "shared/dsToast";
import { useDispatch } from "react-redux";
import emailNotConformed from "../../assets/images/emailNotConformed.png";
import Logo from "../../shared/Logo";
import useStyles from "./style";
import makeApiCall from "../../helpers/makeApiCall";
import { logout } from "../../containers/components/Auth/actions";

const Confirm = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  async function resendEmail() {
    try {
      await makeApiCall({ url: "/users/resend-confirmation-code" });
      dsToast.success(
        "Email was send. Check your email. If You will not find this latter you can resend again in 3 minutes."
      );
    } catch (e) {
      dsToast.error(e.response.data.message);
    }
  }

  function handleLogout() {
    dispatch(logout());
  }

  return (
    <div className={classes.container}>
      <CssBaseline />
      <div className={classes.linearGradientBG} />
      <div className={classes.paper}>
        <>
          <Logo />
          <div>
            <img className={classes.image} src={emailNotConformed} alt="email not confirmed" />
          </div>
          <div className={classes.message}>Please verify your email address</div>
          <div className={classes.description}>We’ve sent a confirmation letter to your email.</div>
          <div className={classes.line} />
          <div className={classes.resendEmail}>
            Didn't get the email?{" "}
            <a href="javascript:void(0)" onClick={resendEmail}>
              Resend Email
            </a>
          </div>
          <div className={classes.logout}>
            Not your account?{" "}
            <a href="javascript:void(0)" onClick={handleLogout}>
              Logout
            </a>
          </div>
        </>
      </div>
    </div>
  );
};

export default withRouter(Confirm);
