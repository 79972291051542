import React, { useEffect, useState } from "react";
import { dsToast } from "shared/dsToast";
import { connect } from "react-redux";

import CampaignsService from "services/campaigns";
import { diagramSelectOptions, genderTableColumns } from "constants/tableColumns";
import { campaignStatusVariants } from "constants/campaignStatusVariants";
import { parseCampaignStatus } from "helpers/parseCampaignStatus";

import DashboardContent from "shared/DashboardContent";
import DashboardDiagramWrapper from "shared/DashboardDiagramCard";
import DashboardTableCard from "shared/DashboardTableCard";
import CustomVerticalBarChart from "shared/CustomVerticalBarChart";
import { sumStatistic } from "../Age";

const Gender = ({ statisticParams }) => {
  const [isEmpty, setIsEmpty] = useState(false);
  const [campaignsStatus, setCampaignsStatus] = useState(campaignStatusVariants[0]);
  const [isLoading, setIsLoading] = useState(true);
  const [initialStatisticData, setInitialStatisticData] = useState([]);
  const [statisticData, setStatisticData] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  const [convertStatisticData, setConvertStatisticData] = useState([]);
  const [diagramDataType, setDiagramDataType] = useState(diagramSelectOptions[0]);
  const [diagramData, setDiagramData] = useState([]);

  const getData = data => {
    const columnName = index => ({ columnName: genderTableColumns[index].value });
    return sumStatistic(data, "sumGenderStatistic", "gender")?.map(val => {
      const rowName = val.label === "u" ? "Unknown" : val.label === "f" ? "Female" : "Male";
      return {
        rowName,
        columns: [
          { label: rowName, isFirst: true, noIcon: true, ...columnName(0) },
          { label: val.clicks || 0, ...columnName(1) },
          { label: val.convs_rate || 0, ...columnName(2) },
          { label: val.cpm?.toFixed(2) || 0, ...columnName(3) },
          { label: val.imps || 0, ...columnName(4) }
        ]
      };
    });
  };

  useEffect(() => {
    setDiagramData(
      convertStatisticData?.map(val => ({
        ...val,
        value: val[diagramDataType.value],
        tooltip: diagramDataType.label,
        label: val.label === "f" ? "Female" : val.label === "m" ? "Male" : "Unknown"
      }))
    );
  }, [convertStatisticData, diagramDataType]);

  useEffect(() => {
    setIsLoading(true);
    CampaignsService.getCampaignsStatistic("demographics", statisticParams)
      .then(data => {
        setInitialStatisticData(data);
        setConvertStatisticData(sumStatistic(data, "sumGenderStatistic", "gender"));
        if (!getData(data)?.length) {
          setIsEmpty(true);
        } else {
          setIsEmpty(false);
        }
      })
      .catch(() => dsToast.error("We cannot get gender statistics. Please try again later."))
      .finally(() => setIsLoading(false));
  }, [statisticParams]);

  useEffect(() => {
    setTableRows(getData(statisticData));
  }, [statisticData]);

  useEffect(() => {
    setStatisticData(
      initialStatisticData.filter(val => {
        if (campaignsStatus?.statusCode) {
          return parseCampaignStatus(val.campaign)?.statusCode === campaignsStatus?.statusCode;
        }
        return val;
      })
    );
  }, [campaignsStatus, initialStatisticData]);

  return (
    <DashboardContent isLoading={isLoading} isEmpty={isEmpty} emptyTitle="Try to select other dates">
      <DashboardDiagramWrapper
        pinkSelect={diagramDataType}
        setPinkSelect={setDiagramDataType}
        options={diagramSelectOptions}
      >
        <CustomVerticalBarChart data={diagramData} />
      </DashboardDiagramWrapper>
      <DashboardTableCard
        rows={tableRows}
        columns={genderTableColumns}
        setCampaignsStatus={setCampaignsStatus}
        campaignsStatus={campaignsStatus}
      />
    </DashboardContent>
  );
};

const mapStateToProps = state => ({
  statisticParams: state.campaigns.statisticParams
});

export default connect(mapStateToProps)(Gender);
