import React from "react";
import { useLocation } from "react-router-dom";

import { PIXELS, PIXEL_GOALS } from "pathnameVariables";

import Header from "shared/Header";

import "./styles.scss";

const DashboardContentWrapper = ({ children, headerLeftContent }) => {
  const { pathname } = useLocation();
  return (
    <div className="dashboard-content-wrapper">
      {pathname !== PIXELS && pathname !== PIXEL_GOALS && <Header leftSideContent={headerLeftContent} />}
      {children}
    </div>
  );
};

export default DashboardContentWrapper;
