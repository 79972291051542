import React, { useState } from "react";
import { connect } from "react-redux";
import { Button, Popover } from "@material-ui/core";

import Logo from "assets/images/mainLogo.svg";
import LogoWhite from "assets/images/mainLogoWhite.svg";
import Arrow from "assets/images/arrowDropDownBlue.svg";
import ArrowWhite from "assets/images/arrowDropDownWhite.svg";
import DefaultIcon from "assets/images/defaultProdIcon.svg";

import "./styles.scss";

const projects = [
  {
    name: "Searched",
    link: "https://searched.datasys.com/",
    icon: Logo
  },
  {
    name: "V-profiler",
    link: "https://vprofiler.datasys.com",
    icon: DefaultIcon
  }
];

const ProdSwitcher = ({ userInfo }) => {
  const [openPopover, setOpenPopover] = useState(null);
  return (
    <>
      <Button
        className={`prod-switcher-wrapper ${openPopover ? "active" : ""}`}
        onClick={e => setOpenPopover(e.currentTarget)}
      >
        <img src={userInfo?.isDarkTheme ? LogoWhite : Logo} alt="" className="prod-logo" />
        <div className="prod-name">Searched</div>
        <img
          src={userInfo?.isDarkTheme ? ArrowWhite : Arrow}
          alt=""
          className={`prod-arrow ${openPopover ? "active" : ""}`}
        />
      </Button>
      <Popover
        open={Boolean(openPopover)}
        anchorEl={openPopover}
        onClose={() => setOpenPopover(null)}
        className="prod-switcher-popover"
        anchorOrigin={{
          vertical: 48,
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
        {projects.map(val => (
          <Button key={val.name} className="prod-btn" onClick={() => window.open(val.link, "_blank")}>
            <img src={val.icon} alt="" />
            {val.name}
          </Button>
        ))}
      </Popover>
    </>
  );
};

const mapStateToProps = state => ({
  userInfo: state.userProfile.userInfo
});

export default connect(mapStateToProps)(ProdSwitcher);
