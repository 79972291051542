import React, { useEffect, useState } from "react";
import { Switch, Route, useLocation, useHistory } from "react-router-dom";
import { Tab, Tabs } from "@material-ui/core";
import { connect } from "react-redux";

import { PROFILE_SETTINGS, BALANCE, NOTIFICATIONS } from "pathnameVariables";
import UserProfileInfo from "services/userProfile";

import Header from "shared/Header";
import LogoLink from "shared/Header/LogoLink";
import Loader from "shared/Loader";

import Profile from "./Profile";
import Balance from "./Balance";
import Notifications from "./Notifications";

import "./styles.scss";

const tabProps = i => ({
  id: `wrapped-tab-${i}`,
  "aria-controls": `wrapped-tabpanel-${i}`
});

const UserSettings = ({ me }) => {
  const { pathname } = useLocation();
  const { push } = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [transactionHistory, setTransactionHistory] = useState([]);
  const [paymentCardsList, setPaymentCardsList] = useState([]);
  const [notificationSettings, setNotificationSettings] = useState({});

  const getTransactionHistory = () => {
    UserProfileInfo.getTransactionHistory().then(data => setTransactionHistory(data));
  };

  const getPaymentCardsList = () => {
    UserProfileInfo.getPaymentCards().then(data => setPaymentCardsList(data));
  };

  useEffect(() => {
    Promise.all([
      UserProfileInfo.getTransactionHistory().then(data => setTransactionHistory(data)),
      UserProfileInfo.getPaymentCards().then(data => setPaymentCardsList(data)),
      UserProfileInfo.getNotificationSettings().then(data => setNotificationSettings(data))
    ]).finally(() => setIsLoading(false));
  }, []);

  return (
    <div className="user-settings-page-wrapper">
      <Header leftSideContent={<LogoLink />} withoutMenuBtn />
      <div className="user-settings-page-content">
        <div className="user-settings-title">Settings</div>
        <Tabs value={pathname} onChange={(e, val) => push(val)} className="settings-tabs">
          <Tab value={PROFILE_SETTINGS} label="Profile settings" {...tabProps(PROFILE_SETTINGS)} />
          {me?.accountRole === "account-admin" && (
            <Tab value={BALANCE} label="Balance" {...tabProps(BALANCE)} />
          )}
          <Tab value={NOTIFICATIONS} label="Notifications" {...tabProps(NOTIFICATIONS)} />
        </Tabs>
        <div className="subpage-scroll-container">
          {isLoading ? (
            <Loader empty isDashboardContent />
          ) : (
            <div className="subpage-box">
              <Switch>
                <Route exact path={PROFILE_SETTINGS} component={Profile} />
                {me?.accountRole === "account-admin" && (
                  <Route
                    exact
                    path={BALANCE}
                    component={() => (
                      <Balance
                        transactionHistory={transactionHistory}
                        getTransactionHistory={getTransactionHistory}
                        paymentCardsList={paymentCardsList}
                        getPaymentCardsList={getPaymentCardsList}
                      />
                    )}
                  />
                )}
                <Route
                  exact
                  path={NOTIFICATIONS}
                  component={() => <Notifications notificationSettings={notificationSettings} />}
                />
              </Switch>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  me: state.me
});

export default connect(mapStateToProps)(UserSettings);
