import axios from "axios";
import config from "config";
import { dsToast } from "shared/dsToast";
import { SndAuth0Client } from "security/Auth0Client";

export const getImgSize = file => {
  return new Promise(resolve => {
    const img = new Image();
    img.src = URL.createObjectURL(file);
    img.onload = () => {
      const { width, height } = img;
      resolve({
        width,
        height
      });
    };
  });
};

export const toBase64Async = async file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
};

export const convertImageFileToAppFileFormat = async file => {
  const { width, height } = await getImgSize(file);
  const base64 = await toBase64Async(file);
  return {
    data: base64,
    fileBlob: base64,
    imageUrl: URL.createObjectURL(file),
    fileType: "image",
    width,
    height,
    name: file.name
  };
};

export const loadFileByUrlAsFileAsync = async url => {
  const accessToken = await SndAuth0Client.getTokenSilentlyAsync();
  return axios({
    url: config.api.endpoint(`/img-banner-proxy/image?url=${url}`),
    method: "GET",
    responseType: "blob",
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  })
    .then(res => {
      const blob = res.data;
      const name = url.split("/").pop();
      const { type } = blob;
      const file = new File([blob], name, { type });
      const reader = new FileReader();
      return new Promise(resolve => {
        reader.addEventListener("loadend", () => {
          resolve({
            data: file,
            fileBlob: reader.result,
            imageUrl: URL.createObjectURL(file)
          });
        });
        reader.readAsDataURL(blob);
      });
    })
    .catch(err => {
      console.log(`Cannot load file ${url}`, err);
    });
};

export const downloadFile = async (url, fileName) => {
  const accessToken = await SndAuth0Client.getTokenSilentlyAsync();

  return axios({
    url: config.api.endpoint(url),
    method: "GET",
    responseType: "blob",
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  })
    .then(res => {
      const blob = res.data;
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      const href = window.URL.createObjectURL(blob);
      a.href = href;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(href);
    })
    .catch(() => dsToast.error("Sorry, something went wrong. Please try again later"));
};
