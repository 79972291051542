import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import moment from "moment";

import { PIXELS } from "pathnameVariables";
import TrackingPixel from "services/trackingPixel";

import DiagramSelect from "shared/DiagramSelect";
import StyledAreaChart from "shared/StyledAreaChart";
import Loader from "shared/Loader";

import EmptyIcon from "assets/images/smallEmptyIcon.svg";

import StatisticCard from "../../components/StatisticCard";

const testData = [
  {
    count: 100,
    date: "05-25-2021"
  },
  {
    count: 97,
    date: "05-26-2021"
  }
];

const Goals = ({ goalsList, statisticParams }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [selectedGoal, setSelectedGoal] = useState({});
  const [selectedGoalStatistic, setSelectedGoalStatistic] = useState([]);

  useEffect(() => {
    if (goalsList?.length) {
      setSelectedGoal(goalsList[0]);
    }
  }, [goalsList]);

  useEffect(() => {
    if (selectedGoal.value) {
      setIsLoading(true);
      TrackingPixel.getGoalStatistic(selectedGoal.value, statisticParams)
        .then(data => {
          setSelectedGoalStatistic(
            data?.map(val => ({
              blueData: val.count,
              blueLabel: "People visited",
              label: moment(val.date).format("MMM DD")
            }))
          );
        })
        .catch(() => setSelectedGoalStatistic([]))
        .finally(() => setIsLoading(false));
    } else {
      setIsLoading(false);
    }
  }, [selectedGoal]);

  return (
    <StatisticCard
      withExpandAbility
      title="Goals"
      btnTitle="Pixels"
      href={PIXELS}
      id="goal-statistic"
      secondHeaderContent={
        goalsList?.length > 0 && (
          <div className="data-selection-box">
            <DiagramSelect options={goalsList} value={selectedGoal} onChange={val => setSelectedGoal(val)} isDefault />
          </div>
        )
      }
    >
      {isLoading ? (
        <Loader empty isDashboardContent />
      ) : (
        <>
          {!selectedGoalStatistic?.length ? (
            <div className="overview-statistic-card-empty full-height">
              <img src={EmptyIcon} alt="" />
              <div>There is no data</div>
              <div className="empty-card-subtitle">
                {!goalsList?.length ? "You have not pixel goals yet" : "Try to select other goal or dates"}
              </div>
            </div>
          ) : (
            <StyledAreaChart data={selectedGoalStatistic} isGoal />
          )}
        </>
      )}
    </StatisticCard>
  );
};

const mapStateToProps = state => ({
  statisticParams: state.campaigns.statisticParams
});

export default connect(mapStateToProps)(Goals);
