import React, { useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { dsToast } from "shared/dsToast";
import { Button } from "@material-ui/core";

import { setPrevState, setCampaignName, handlePrevStep, clearBeginnerStore } from "redux/actions/beginnerMode";
import { BEGINNER_PAYMENT, CAMPAIGNS, BEGINNER_UPLOAD_AD } from "pathnameVariables";
import BeginnerCampaignCreation from "services/beginnerCampaignCreation";

import Loader from "shared/Loader";
import StyledTextField from "shared/StyledTextField";
import StyledChip from "shared/StyledChip";
import { RenderMap } from "shared/SelectLocation";

import ImpsIcon from "assets/images/impressionsIconBlue.svg";
import ImpsIconWhite from "assets/images/impressionsIconWhite.svg";
import ClicksIcon from "assets/images/clicksIconBlue.svg";
import ClicksIconWhite from "assets/images/clicksIconWhite.svg";

import HelpFooter from "../components/HelpFooter";
import StepBtnBox from "../components/StepBtnBox";
import { goals } from "../SelectGoal";

import "./styles.scss";

const ConfirmCreation = ({
  campaignId,
  adGroupId,
  handlePreviousStep,
  campaignName,
  handleSetCampaignName,
  estimate,
  budget,
  goal,
  uploadAd,
  targetAds,
  locations,
  handleClearBeginnerStore,
  userInfo,
  me
}) => {
  const { push } = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const handleBack = () => {
    setIsLoading(true);
    handlePreviousStep(() => push(me?.accountRole === "account-user" ? BEGINNER_UPLOAD_AD : BEGINNER_PAYMENT));
  };

  const handleCreateCampaign = () => {
    setIsLoading(true);
    const campaignData = {
      adGroupId,
      campaignId,
      name: campaignName
    };
    BeginnerCampaignCreation.confirmCampaign(campaignData)
      .then(() => {
        push(CAMPAIGNS);
        dsToast.success("The campaign was created successfully");
        handleClearBeginnerStore();
      })
      .catch(() => {
        dsToast.error("Sorry, something went wrong. Please try again later");
        setIsLoading(false);
      });
  };

  const getLocationText = () => {
    if (!locations?.currentTab) {
      return `${
        locations?.isUS ? "throughout the United States" : `in ${locations?.radiusLocation?.formattedAddress}`
      }`;
    }
    return (
      <>
        {locations?.include?.length && (
          <>
            <br />
            Include: {locations?.include?.map(val => val.formattedAddress).join(" | ")}
          </>
        )}
        {locations?.exclude?.length && (
          <>
            <br />
            Exclude: {locations?.exclude?.map(val => val.formattedAddress).join(" | ")}
          </>
        )}
        {locations?.postalCodes?.length && (
          <>
            <br />
            <div className="postal-codes-wrapper">
              <div>Postal codes:</div>
              <div className="codes-list">
                <span>{locations?.postalCodes?.join(", ")}</span>
              </div>
            </div>
          </>
        )}
      </>
    );
  };

  return (
    <div className="beginner-confirm-step-wrapper">
      {isLoading ? (
        <Loader empty isDashboardContent />
      ) : (
        <div className="confirm-content-wrapper">
          <div className="confirm-content">
            <div className="confirm-step-title">Review your campaign settings</div>
            <StyledTextField
              className="name-field"
              label="Campaign name"
              value={campaignName}
              onChange={e => handleSetCampaignName(e.target.value)}
            />
            <div className="review-wrapper">
              <div className="review-card">
                <div className="card-title">Estimated performance</div>
                <div className="estimate-box">
                  <div className="estimate-item">
                    <img src={userInfo?.isDarkTheme ? ImpsIconWhite : ImpsIcon} alt="" />
                    <div className="item-value">
                      <div>{`${estimate?.imps?.min} - ${estimate?.imps?.max} impressions`}</div>
                      <div>per month</div>
                    </div>
                  </div>
                  <div className="estimate-item">
                    <img src={userInfo?.isDarkTheme ? ClicksIconWhite : ClicksIcon} alt="" />
                    <div className="item-value">
                      <div>{`${estimate?.clicks?.min} - ${estimate?.clicks?.max} clicks`}</div>
                      <div>per month</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="review-content">
                <div className="review-card">
                  <div className="card-title">Budget</div>
                  <div className="items-list">
                    <div className="budget-item">
                      <div className="budget-item-value">${budget}</div>
                      <div className="budget-item-subtitle">daily average</div>
                    </div>
                    <div className="budget-item">
                      <div className="budget-item-value">${budget * 31}</div>
                      <div className="budget-item-subtitle">monthly maximum</div>
                    </div>
                  </div>
                </div>
                <div className="review-card">
                  <div className="card-title">Campaign goal</div>
                  <div className="selected-goal">
                    <img src={goals?.find(val => val.goalType === goal)?.smallIcon} alt="" />
                    {goals?.find(val => val.goalType === goal)?.name}
                  </div>
                </div>
                {[...uploadAd?.files, ...uploadAd?.customFiles].length > 0 && (
                  <div className="review-card">
                    <div className="card-title">Your ad preview</div>
                    <div className="items-list">
                      {[...uploadAd?.files, ...uploadAd?.customFiles].map(val => (
                        <div className="ad-image-item">
                          <img src={val?.imageUrl} alt="" />
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
              <div className="review-content">
                <div className="review-card">
                  <div className="card-title">Locations</div>
                  <div className="card-subtitle">
                    Show ad to people interested in your products or services {getLocationText()}
                  </div>
                  <div className="locations-map-box">
                    <RenderMap locations={locations} />
                  </div>
                </div>
                {targetAds?.length > 0 && (
                  <div className="review-card">
                    <div className="card-title">Keywords</div>
                    <div className="keywords-list">
                      {targetAds?.map((val, i) => (
                        <StyledChip label={val} key={i.toString()} />
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <StepBtnBox>
              <Button onClick={handleBack}>Back</Button>
              <Button disabled={!campaignName} onClick={handleCreateCampaign}>
                Create campaign
              </Button>
            </StepBtnBox>
          </div>
          <HelpFooter isWhiteBg />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  me: state.me,
  userInfo: state.userProfile.userInfo,
  campaignId: state.beginnerMode.campaignId,
  adGroupId: state.beginnerMode.adGroupId,
  goal: state.beginnerMode.goal,
  campaignName: state.beginnerMode.campaignName,
  budget: state.beginnerMode.budget,
  estimate: state.beginnerMode.estimate,
  uploadAd: state.beginnerMode.uploadAd,
  locations: state.beginnerMode.locations,
  targetAds: state.beginnerMode.targetAds
});

const mapDispatchToProps = dispatch => ({
  handleSetPrevState: payload => dispatch(setPrevState(payload)),
  handlePreviousStep: payload => dispatch(handlePrevStep(payload)),
  handleClearBeginnerStore: () => dispatch(clearBeginnerStore()),
  handleSetCampaignName: payload => dispatch(setCampaignName(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmCreation);
