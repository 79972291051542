import React, { useEffect, useState } from "react";
import { Button, SwipeableDrawer } from "@material-ui/core";
import { Field, reduxForm, getFormValues, initialize } from "redux-form";
import { connect } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { dsToast } from "shared/dsToast";
import moment from "moment";

import { CAMPAIGNS } from "pathnameVariables";
import CampaignsService from "services/campaigns";
import CampaignsCreation from "services/campaignCreation";
import { loadFileByUrlAsFileAsync, convertImageFileToAppFileFormat } from "helpers/fileHelper";

import Loader from "shared/Loader";

import UsersIcon from "assets/images/usersIcon.svg";
import UsersIconWhite from "assets/images/usersIconWhite.svg";
import TipIcon from "assets/images/tipIcon.svg";
import CloseIcon from "assets/images/closeBlue.svg";

import AdditionalSettingsContainer from "./components/AdditionalSettingsContainer";
import MultiCardContainer from "./components/MultiCardContainer";
import Name from "./cards/Name";
import Location from "./cards/Location";
import Languages from "./cards/Languages";
import Bidding from "./cards/Bidding";
import Budget from "./cards/Budget";
import Devices from "./cards/Devices";
import AdRotation from "./cards/AdRotation";
import AdSchedule from "./cards/AdSchedule";
import FrequencyCapping from "./cards/FrequencyCapping";
import StartEndDates from "./cards/StartEndDates";
import ContentExclusions from "./cards/ContentExclusions";
import KeywordExpansion from "./cards/KeywordExpansion";
import Ads from "./cards/Ads";
import Audiences from "./cards/Audiences";
import Demographics, { age, gender, income, parental } from "./cards/Demographics";
import TargetedKeywords from "./cards/TargetedKeywords";
import Payment from "./cards/Payment";

import "./styles.scss";
import UserProfileInfo from "../../../services/userProfile";

const usCoords = {
  city: null,
  country: "United States",
  countryCode: "US",
  formattedAddress: "United States",
  lat: 37.09024,
  lng: -95.712891,
  postalCode: null,
  radius: 0,
  region: null,
  regionCode: null
};

let CampaignCreation = ({ formValues, initialize, userInfo, clientWidth, me }) => {
  const location = useLocation();
  const { push } = useHistory();
  const campaignId = new URLSearchParams(location.search).get("id");
  const [isEstimateOpen, setIsEstimateOpen] = useState(false);
  const isUpdate = new URLSearchParams(location.search).get("update");
  const [isLoading, setIsLoading] = useState(true);
  const [campaignInfo, setCampaignInfo] = useState(null);
  const [languages, setLanguages] = useState([]);
  const [contentExclusions, setContentExclusions] = useState({});
  const [deviceTypeOptions, setDeviceTypeOptions] = useState([]);
  const [deviceOptions, setDeviceOptions] = useState([]);
  const [osOptions, setOsOptions] = useState([]);
  const [networkOptions, setNetworkOptions] = useState([]);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [cpm, setCpm] = useState(0);
  const [paymentCardsList, setPaymentCardsList] = useState([]);

  useEffect(() => {
    CampaignsCreation.getCPM().then(data => setCpm(data.cpm));
  }, []);

  const getImpressions = () => {
    return (Number(formValues?.budget?.average) / Number(cpm)) * 7;
  };

  useEffect(() => {
    if (campaignId) {
      Promise.all([
        UserProfileInfo.getPaymentCards().then(data => setPaymentCardsList(data)),
        CampaignsService.getCampaignData(campaignId)
          .then(data => setCampaignInfo(data))
          .catch(() => dsToast.error("Sorry, we cannot get campaign data. Try again later")),
        CampaignsCreation.getLanguages()
          .then(data => setLanguages(data.map(val => ({ ...val, code: val.code.toUpperCase() }))))
          .catch(() => dsToast.error("We cannot get languages")),
        CampaignsCreation.getDeviceTypeOptions()
          .then(data => setDeviceTypeOptions(data))
          .catch(() => dsToast.error("We cannot get device type options")),
        CampaignsCreation.getContentExclusions()
          .then(data => setContentExclusions(data))
          .catch(() => dsToast.error("We cannot get exclusions options")),
        CampaignsCreation.getNetworkOptions()
          .then(data => setNetworkOptions(data))
          .catch(() => dsToast.error("We cannot get networks options")),
        CampaignsCreation.getOSOptions()
          .then(data => setOsOptions(data))
          .catch(() => dsToast.error("We cannot get OS options")),
        CampaignsCreation.getDeviceOptions()
          .then(data => {
            data.forEach(val => {
              CampaignsCreation.getDeviceModelOptions(val.id).then(deviceModel =>
                setDeviceOptions(state => [...state, ...deviceModel])
              );
            });
          })
          .catch(() => dsToast.error("We cannot get device options"))
      ]).finally(() => setIsLoading(false));
    }
  }, [campaignId]);

  useEffect(() => {
    if (uploadedImages.length) {
      initialize({ ...formValues, ads: { ...formValues.ads, customFiles: uploadedImages } });
    }
  }, [uploadedImages]);

  useEffect(() => {
    if (campaignInfo) {
      if (campaignInfo.adGroups?.length) {
        campaignInfo.adGroups[0].newAds[0].images.forEach(val => {
          loadFileByUrlAsFileAsync(val.imageUrl).then(data => {
            convertImageFileToAppFileFormat(data.data).then(image => setUploadedImages(state => [...state, image]));
          });
        });
      }
      const getLocation = () => {
        const { targetLocation } = campaignInfo;
        const isUS =
          !targetLocation?.excludes.length &&
          targetLocation?.includes.length === 1 &&
          !targetLocation?.includes[0].city;
        if (targetLocation) {
          return {
            type: targetLocation?.isAllLocations ? "all" : isUS ? "us" : "custom",
            include: !isUS || !targetLocation?.isAllLocations ? targetLocation?.includes : [],
            exclude: !isUS || !targetLocation?.isAllLocations ? targetLocation?.excludes : [],

            currentTab: 0,
            geometries: [],
            isInBulk: false,
            postalCodes: [],
            radiusLocation: { radius: 1 }
          };
        }
        return {
          type: "us",
          include: [],
          exclude: [],

          currentTab: 0,
          geometries: [],
          isInBulk: false,
          postalCodes: [],
          radiusLocation: { radius: 1 }
        };
      };
      const getLanguages = () => {
        if (campaignInfo.targetLanguages.length) {
          return campaignInfo.targetLanguages.map(val =>
            languages.find(value => value.code.toUpperCase() === val.languageCodeISO.toUpperCase())
          );
        }
        return [{ code: "EN", name: "English", nativeName: "English" }];
      };
      const getDevices = () => {
        if (campaignInfo.targetNetworkDevices && !campaignInfo.targetNetworkDevices.isAllNetworkDevices) {
          return {
            forShow: "custom",
            deviceType: campaignInfo.targetNetworkDevices.selectedDeviceTypes,
            os: campaignInfo.targetNetworkDevices.selectedOperatingSystemFamilyList,
            deviceModels: campaignInfo.targetNetworkDevices.selectedDeviceModelList,
            networks: campaignInfo.targetNetworkDevices.selectedNetworkList
          };
        }
        return { forShow: "all", deviceType: [], os: [], deviceModels: [], networks: [] };
      };
      const getFrequencyCapping = () => {
        if (campaignInfo.adFrequencyCapping?.limitFor) {
          return {
            frequencyType: "custom",
            limit: {
              for: campaignInfo.adFrequencyCapping?.limitFor?.toString(),
              per: campaignInfo.adFrequencyCapping?.limitPer?.toString(),
              to: campaignInfo.adFrequencyCapping?.to?.toString()
            }
          };
        }
        return { frequencyType: "optimize", limit: { for: "0", to: "1", per: "0" } };
      };
      const getDemographics = () => {
        if (campaignInfo.adGroups?.length) {
          return {
            genders: campaignInfo.adGroups[0].demographicGender.map(val => val.gender),
            incomeRanges: campaignInfo.adGroups[0].demographicIncomeRange.map(val => val.incomeRange),
            ageRanges: campaignInfo.adGroups[0].demographicAgeRange.map(val => val.ageRange),
            parentalStatus: campaignInfo.adGroups[0].demographicParentalStatus.map(val => val.parentalStatus)
          };
        }
        return {
          genders: gender.map(val => val.value),
          incomeRanges: income.map(val => val.value),
          ageRanges: age.map(val => val.value),
          parentalStatus: parental.map(val => val.value)
        };
      };
      const getAds = () => {
        if (campaignInfo.adGroups?.length) {
          const ads = campaignInfo.adGroups[0].newAds[0];
          return {
            url: ads.url.split("?")[0],
            suffixes: ads.url.split("?")[1]
              ? ads.url
                  .split("?")[1]
                  ?.split("&")
                  .map(val => {
                    return {
                      name: val.split("=")[0],
                      value: val.split("=")[1]
                    };
                  })
              : [{ name: "", value: "" }],
            files: [],
            customFiles: []
          };
        }
        return { url: "", suffixes: [{ name: "", value: "" }], files: [], customFiles: [] };
      };
      const getContentExclusions = () => {
        if (campaignInfo.contentExclusions.length) {
          return {
            digitalContent: campaignInfo.contentExclusions.filter(val => val.category === "digital_content_label"),
            contentType: campaignInfo.contentExclusions.filter(val => val.category === "content_type"),
            sensitiveContent: campaignInfo.contentExclusions.filter(val => val.category === "sensitive_content")
          };
        }
        return { digitalContent: [], contentType: [], sensitiveContent: [] };
      };
      initialize({
        name: campaignInfo.name || `Campaign #${campaignInfo.id}`,
        location: getLocation(),
        languages: getLanguages(),
        bidding: {
          focusOn: campaignInfo.bidings[0]?.focusOn.length > 1 ? campaignInfo.bidings[0]?.focusOn : "Conversions",
          conversions:
            campaignInfo.bidings[0]?.consversationMethod.length > 1
              ? campaignInfo.bidings[0]?.consversationMethod
              : "Automatically maximize conversions"
        },
        budget: { average: campaignInfo.budget?.amount || "", method: campaignInfo.deliveryMethod?.toString() },
        devices: getDevices(),
        adRotation: campaignInfo.adRotation.toString(),
        adSchedule: campaignInfo.adSchedules.length ? campaignInfo.adSchedules : [{ days: "9", from: "1", to: "23" }],
        frequencyCapping: getFrequencyCapping(),
        startEndDates: {
          startDate: campaignInfo.campaignStartDate || new Date(),
          endDate: campaignInfo.campaignEndDate || "none"
        },
        contentExclusions: getContentExclusions(),
        audiences: {
          keywords: campaignInfo.adGroups?.length ? campaignInfo.adGroups[0].adGroupAudience?.keywords : [],
          csvFiles: []
        },
        demographics: getDemographics(),
        targetedKeywords: {
          keywords: campaignInfo.adGroups?.length
            ? campaignInfo.adGroups[0]?.adGroupContentSettings?.contentKeyWords
            : [],
          type: "audience"
        },
        keywordExpansion: campaignInfo.adGroups?.length ? campaignInfo.adGroups[0]?.expansionReach : 7,
        ads: getAds()
      });
    }
  }, [campaignInfo]);

  const handleCreateCampaign = () => {
    const getUrl = () => {
      if (formValues.ads.suffixes.find(val => !val.name || !val.value)) {
        return formValues.ads.url;
      }
      return `${formValues.ads.url}?${formValues.ads.suffixes.map(val => `${val.name}=${val.value}`).join("&")}`;
    };
    const data = {
      adGroupBid: "0",
      adGroupName: "none",
      adGroupUrl: getUrl(),
      adRotation: Number(formValues.adRotation),
      adSchedule: formValues.adSchedule.map(val => ({ ...val, days: Number(val.days) })),
      adUrl: getUrl(),
      audience: { ...formValues.audiences, csvFiles: [] },
      bid: "0",
      biding: {
        adGroupName: "none",
        payFor: "click",
        conversationMethod: formValues.bidding.conversions,
        focusOn: formValues.bidding.focusOn
      },
      budget: {
        currency: "USD",
        deliveryMethod: Number(formValues.budget.method),
        moneyAmount: Number(formValues.budget.average)
      },
      campaignId: Number(campaignInfo.id),
      campaignRunInterval: {
        endDate: moment(formValues.startEndDates.endDate).isValid() ? formValues.startEndDates.endDate : "",
        startDate: formValues.startEndDates.startDate
      },
      contentExclusions: [
        ...formValues.contentExclusions.digitalContent,
        ...formValues.contentExclusions.contentType,
        ...formValues.contentExclusions.sensitiveContent
      ],
      contentSettings: { keywords: formValues.targetedKeywords.keywords, placements: [] },
      demographics: formValues.demographics,
      expansionReach: formValues.keywordExpansion.toString(),
      files: [...formValues.ads.files, ...formValues.ads.customFiles],
      frequencyCapping: {
        isGoogle: formValues.frequencyCapping.frequencyType === "optimize",
        limitFor: Number(formValues.frequencyCapping.limit.for),
        limitPer: Number(formValues.frequencyCapping.limit.per),
        to: Number(formValues.frequencyCapping.limit.to)
      },
      languageISOCodes: formValues.languages.map(val => val.code.toUpperCase()),
      locations: {
        exclude: formValues.location.exclude,
        include: formValues.location.type === "us" ? [usCoords] : formValues.location.include,
        isAllLocations: formValues.location.type === "all",
        polygons: [],
        zipCodeList: formValues?.location.isInBulk ? formValues.location.postalCodes : []
      },
      name: formValues.name,
      targetNetworkDevices: {
        isAllNetworkDevices: formValues.devices.forShow === "all",
        selectedDeviceModelList: formValues.devices.forShow === "all" ? deviceOptions : formValues.devices.deviceModels,
        selectedDeviceTypes: formValues.devices.forShow === "all" ? deviceTypeOptions : formValues.devices.deviceType,
        selectedNetworkList: formValues.devices.forShow === "all" ? networkOptions : formValues.devices.networks,
        selectedOperatingSystemFamilyList: formValues.devices.forShow === "all" ? osOptions : formValues.devices.os
      }
    };
    const errorAction = elemId => {
      const elem = document.getElementById(elemId);
      elem.scrollIntoView({ block: "center", behavior: "smooth" });
      elem.style.boxShadow = "0 2px 11px #f63e49";
      setTimeout(() => {
        elem.style.boxShadow = "0 2px 5px rgba(0, 0, 0, 0.03)";
      }, 1600);
    };
    if (!formValues.name) {
      errorAction("name-card");
      return dsToast.error("Please enter campaign name");
    }
    if (!formValues.budget.average) {
      errorAction("budget-card");
      return dsToast.error("Please indicate your budget");
    }
    if (!formValues.ads.url) {
      errorAction("ads-card");
      return dsToast.error("Please enter url address");
    }
    if (me?.accountRole !== "account-user" && !paymentCardsList?.length) {
      errorAction("payment-card");
      return dsToast.error("Please add payment card");
    }
    if (me?.accountRole !== "account-user" && !paymentCardsList?.find(val => val.paymentMethodType === "primary")) {
      errorAction("payment-card");
      return dsToast.error("Please select payment card");
    }
    setIsLoading(true);
    const catchAction = () => {
      dsToast.error("Sorry, something went wrong. Please try again later");
      setIsLoading(false);
    };
    if (isUpdate) {
      const { campaignRunInterval, ...restData } = data;
      const updateData = {
        ...restData,
        startDate: formValues.startEndDates.startDate,
        endDate: moment(formValues.startEndDates.endDate).isValid() ? formValues.startEndDates.endDate : ""
      };
      CampaignsCreation.updateCampaign(campaignInfo.id, updateData)
        .then(() => dsToast.success("Campaign was updates successfully"))
        .catch(() => dsToast.error("Sorry, something went wrong. Please try again later"))
        .finally(() => setIsLoading(false));
    } else {
      CampaignsCreation.createCampaign(data)
        .then(() => {
          CampaignsCreation.completeCampaignCreation(Number(campaignId))
            .then(() => {
              dsToast.success("Campaign was created successfully");
              push(CAMPAIGNS);
            })
            .catch(catchAction);
        })
        .catch(catchAction);
    }
  };

  const estimateContent = () => (
    <div className="campaign-creation-side-bar">
      <Button className="close-estimate-btn" onClick={() => setIsEstimateOpen(false)}>
        <img src={CloseIcon} alt="" />
      </Button>
      <div className="side-bar-title">Weekly estimates</div>
      <div className="impressions-card">
        <div className="impressions-card-head">
          <img src={userInfo?.isDarkTheme ? UsersIconWhite : UsersIcon} alt="" />
          Available impressions
        </div>
        <div className="card-text">Based on your targeting and settings</div>
        <div className="card-impressions">
          <div>Impressions:</div>
          {getImpressions()}
        </div>
      </div>
    </div>
  );

  return (
    <div className="campaign-creation-wrapper">
      {isLoading ? (
        <Loader empty isDashboardContent />
      ) : (
        <>
          {formValues && (
            <>
              <div id="scroll-box" className="campaign-creation-content">
                <div className="campaign-creation-cards-list">
                  <div className="campaign-creation-title">{isUpdate ? "Update campaign" : "Create your campaign"}</div>
                  <div className="goal-type-box">
                    <div className="type-item">
                      <div className="type-name">Type:</div>
                      <div className="type-info">{campaignInfo?.campaignTypeDef?.name}</div>
                    </div>
                    <div className="type-item">
                      <div className="type-name">Goal:</div>
                      <div className="type-info">{campaignInfo?.campaignGoalDef?.description}</div>
                    </div>
                  </div>
                  <Field name="name" component={Name} />
                  <Field name="location" component={Location} />
                  <Field name="languages" component={Languages} languages={languages} />
                  <Field name="bidding" component={Bidding} />
                  <Field name="budget" component={Budget} />
                  <AdditionalSettingsContainer>
                    <Field
                      name="devices"
                      component={Devices}
                      deviceTypeOptions={deviceTypeOptions}
                      deviceOptions={deviceOptions}
                      osOptions={osOptions}
                      networkOptions={networkOptions}
                    />
                    <Field name="adRotation" component={AdRotation} />
                    <Field name="adSchedule" component={AdSchedule} />
                    <Field name="frequencyCapping" component={FrequencyCapping} />
                    <Field name="startEndDates" component={StartEndDates} />
                    <Field name="contentExclusions" component={ContentExclusions} contentExclusions={contentExclusions} />
                  </AdditionalSettingsContainer>
                  <MultiCardContainer
                    title="People: who you want to reach"
                    subtitle="Define your Audiences, Demographics, or both"
                  >
                    <Field name="audiences" component={Audiences} />
                    <Field name="demographics" component={Demographics} />
                  </MultiCardContainer>
                  <MultiCardContainer
                    title="Content: where you want your ads to show"
                    subtitle="Narrow your reach with Keywords, Topics, or Placements"
                  >
                    <Field name="targetedKeywords" component={TargetedKeywords} />
                  </MultiCardContainer>
                  <Field name="keywordExpansion" component={KeywordExpansion} />
                  {me?.accountRole !== "account-user" && (
                    <Payment paymentCardsList={paymentCardsList} setPaymentCardsList={setPaymentCardsList} />
                  )}
                  <Field name="ads" component={Ads} />
                  <div className="button-box">
                    <Button onClick={handleCreateCampaign}>{isUpdate ? "Update campaign" : "Create campaign"}</Button>
                    <Button onClick={() => push(CAMPAIGNS)}>Cancel</Button>
                  </div>
                </div>
              </div>
              {clientWidth > 1060 ? (
                estimateContent()
              ) : (
                <>
                  <SwipeableDrawer
                    open={isEstimateOpen}
                    anchor="right"
                    onClose={() => setIsEstimateOpen(false)}
                    onOpen={() => setIsEstimateOpen(true)}
                    className="estimate-drawer-wrapper"
                  >
                    {estimateContent()}
                  </SwipeableDrawer>
                  <Button onClick={() => setIsEstimateOpen(true)} className="open-estimate-btn">
                    <img src={TipIcon} alt="" />
                  </Button>
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

CampaignCreation = reduxForm({
  form: "campaign-settings"
})(CampaignCreation);

const mapStateToProps = state => ({
  me: state.me,
  userInfo: state.userProfile.userInfo,
  clientWidth: state.userProfile.clientWidth,
  formValues: getFormValues("campaign-settings")(state)
});

const mapDispatchToProps = () => ({
  initialize
});

export default connect(mapStateToProps, mapDispatchToProps)(CampaignCreation);
