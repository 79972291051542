import { ApiService } from "services/ApiService";

export default class CampaignsCreation {
  static async initExpertCreation(userId) {
    return ApiService._requestAsync({
      url: `/campaign-creation/init/${userId}`,
      method: "POST"
    });
  }

  static async sendCampaignGoal(data) {
    return ApiService._requestAsync({
      url: `/campaign-creation/goal`,
      method: "POST",
      data
    });
  }

  static async updateCampaign(campaignId, data) {
    return ApiService._requestAsync({
      url: `/campaigns/${campaignId}`,
      method: "PUT",
      data
    });
  }

  static async getLanguages() {
    return ApiService._requestAsync({
      url: `/campaigns/languages`,
      method: "GET"
    });
  }

  static async getContentExclusions() {
    return ApiService._requestAsync({
      url: `/campaign-creation/exclusion-options`,
      method: "GET"
    });
  }

  static async getDeviceTypeOptions() {
    return ApiService._requestAsync({
      url: `/campaign-creation/device-type-options`,
      method: "GET"
    });
  }

  static async getNetworkOptions() {
    return ApiService._requestAsync({
      url: `/campaign-creation/network-options`,
      method: "GET"
    });
  }

  static async getOSOptions() {
    return ApiService._requestAsync({
      url: `/campaign-creation/operating-system-family-options`,
      method: "GET"
    });
  }

  static async getDeviceOptions() {
    return ApiService._requestAsync({
      url: `/campaign-creation/device-make-options`,
      method: "GET"
    });
  }

  static async getDeviceModelOptions(deviceId) {
    return ApiService._requestAsync({
      url: `/campaign-creation/device-model-options?manufacturerId=${deviceId}`,
      method: "GET"
    });
  }

  static async getServicesKeywords(value) {
    return ApiService._requestAsync({
      url: `/campaigns/keywords?country_code=US&seeds=${value}&limit=10`,
      method: "GET"
    });
  }

  static async getWebsiteKeywords(url) {
    return ApiService._requestAsync({
      url: `/campaigns/metadata`,
      method: "POST",
      data: {
        url
      }
    });
  }

  static async createCampaign(data) {
    return ApiService._requestAsync({
      url: `/campaign-creation/settings`,
      method: "POST",
      data
    });
  }

  static async completeCampaignCreation(campaignId) {
    return ApiService._requestAsync({
      url: `/campaign-creation/complete`,
      method: "POST",
      data: {
        campaignId
      }
    });
  }

  static async getCPM() {
    return ApiService._requestAsync({
      url: `/general-settings/config?name=cpm`,
      method: "GET"
    });
  }

  static async getGeoCoverageByZipCodes(zipCodes) {
    const query = !zipCodes?.length ? "" : zipCodes?.map(val => `zipCode=${val}`).join("&");
    return ApiService._requestAsync({
      url: `/campaign-creation/geo-coverage?${query}`,
      method: "GET"
    });
  }
}
