import React, { useState } from "react";
import { Button } from "@material-ui/core";
import { connect } from "react-redux";
import { dsToast } from "shared/dsToast";

import UserProfileInfo from "services/userProfile";
import DefaultAvatar from "assets/images/defaultAvatar.svg";
import EditIcon from "assets/images/editGray.svg";

import ModalWrapper from "shared/ModalWrapper";
import StyledTextField from "shared/StyledTextField";

import SettingsCard from "../components/SettingsCard";
import EditProfileModal from "./EditProfileModal";

import "./styles.scss";

const DataItem = ({ label, value }) => {
  return (
    <div className="data-item">
      <div className="item-label">{label}</div>
      <div className="item-value">{value}</div>
    </div>
  );
};

const Profile = ({ userInfo }) => {
  const [openEditProfileModal, setOpenEditProfileModal] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [disabledResetBtn, setDisabledResetBtn] = useState(false);

  const handleResetPassword = () => {
    setDisabledResetBtn(true);
    UserProfileInfo.updatePassword({ email: userInfo?.email, newPassword, repeatPassword })
      .then(() => {
        dsToast.success("Password was changed successful");
        setNewPassword("");
        setRepeatPassword("");
      })
      .catch(() => dsToast.error("Something went wrong, Please try again later"))
      .finally(() => setDisabledResetBtn(false));
  };

  return (
    <div className="profile-settings-wrapper">
      <SettingsCard>
        <Button className="edit-btn" onClick={() => setOpenEditProfileModal(true)}>
          <img src={EditIcon} alt="" />
        </Button>
        <div className="user-name-box">
          <img src={DefaultAvatar} alt="" />
          <div className="user-name">{`${userInfo?.firstName} ${userInfo?.lastName}`}</div>
          <div className="user-email">{userInfo?.email}</div>
        </div>
        <div className="other-user-info-box">
          {userInfo?.nameOfBusiness && <DataItem label="Name of business" value={userInfo?.nameOfBusiness} />}
          {userInfo?.address1 && <DataItem label="Address #1" value={userInfo?.address1} />}
          {userInfo?.address2 && <DataItem label="Address #2" value={userInfo?.address2} />}
          {(userInfo?.city || userInfo?.state || userInfo?.zip) && (
            <div className="common-data-items">
              {userInfo?.city && <DataItem label="City" value={userInfo?.city} />}
              {userInfo?.state && <DataItem label="State" value={userInfo?.state} />}
              {userInfo?.zip && <DataItem label="Zip code" value={userInfo?.zip} />}
            </div>
          )}
          {userInfo?.phone && <DataItem label="Phone" value={userInfo?.phone} />}
          {userInfo?.url && <DataItem label="URL" value={userInfo?.url} />}
          {userInfo?.instagram && <DataItem label="Instagram" value={userInfo?.instagram} />}
          {userInfo?.facebook && <DataItem label="Facebook" value={userInfo?.facebook} />}
        </div>
      </SettingsCard>
      <SettingsCard title="Reset Password">
        <StyledTextField
          type="password"
          label="New password"
          value={newPassword}
          onChange={e => setNewPassword(e.target.value)}
        />
        <StyledTextField
          type="password"
          label="Repeat new password"
          value={repeatPassword}
          onChange={e => setRepeatPassword(e.target.value)}
        />
        <Button
          disabled={newPassword !== repeatPassword || disabledResetBtn || !newPassword}
          className="reset-btn"
          onClick={handleResetPassword}
        >
          Reset password
        </Button>
      </SettingsCard>
      <ModalWrapper handleClose={() => setOpenEditProfileModal(false)} open={openEditProfileModal}>
        <EditProfileModal handleClose={() => setOpenEditProfileModal(false)} />
      </ModalWrapper>
    </div>
  );
};

const mapStateToProps = state => ({
  userInfo: state.userProfile.userInfo
});

export default connect(mapStateToProps)(Profile);
