import React from "react";
import { Button } from "@material-ui/core";
import "./styles.scss";

const CustomSwitcher = ({ label, value, onChange, ...restProps }) => {
  return (
    <div className="custom-switcher-wrapper">
      {label && <div className="custom-switcher-label">{label}</div>}
      <Button {...restProps} onClick={() => onChange(!value)} className={`custom-switcher ${value ? "active" : ""}`} />
    </div>
  );
};

export default CustomSwitcher;
