import { handleActions } from "redux-actions";
import { authorizeAsync } from "./actions";

const InitialState = {
  isAuthLoading: true,
  isAuth: false,
  accountId: null,
  role: null,
  auth0UserId: null,
  userId: null,
  accountRole: null,
  isBlocked: false
};

const handleAuthSuccess = (state, { payload }) => {
  return {
    ...state,
    isAuthLoading: false,
    isAuth: true,
    accountId: payload.accountId,
    role: payload.role,
    auth0UserId: payload.auth0UserId,
    userId: payload.userId,
    accountRole: payload.accountRole
  };
};

const handleAuthFail = (state, err) => {
  if (err.payload.response.data.statusCode === 403) {
    return {
      ...state,
      isBlocked: true,
      isAuthLoading: false,
      isAuth: false
    };
  }
  return { ...state, isAuthLoading: false, isAuth: false };
};

export default handleActions(
  {
    [authorizeAsync.success]: handleAuthSuccess,
    [authorizeAsync.fail]: handleAuthFail
  },
  { ...InitialState }
);
