import React, { useEffect, useState } from "react";
import { dsToast } from "shared/dsToast";
import { connect } from "react-redux";

import CampaignsService from "services/campaigns";
import { diagramSelectOptions, diagramSortOptions, OSTableColumns } from "constants/tableColumns";
import { campaignStatusVariants } from "constants/campaignStatusVariants";
import { parseCampaignStatus } from "helpers/parseCampaignStatus";
import { getAreaChartData, allCampaignsData } from "helpers/statisticHelpFunctions";

import DashboardContent from "shared/DashboardContent";

import DashboardDiagramWrapper from "shared/DashboardDiagramCard";
import DashboardTableCard from "shared/DashboardTableCard";
import StyledAreaChart from "shared/StyledAreaChart";

const OperatingSystem = ({ statisticParams }) => {
  const [isEmpty, setIsEmpty] = useState(false);
  const [campaignsStatus, setCampaignsStatus] = useState(campaignStatusVariants[0]);
  const [isLoading, setIsLoading] = useState(true);
  const [initialStatisticData, setInitialStatisticData] = useState([]);
  const [statisticData, setStatisticData] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  const [blueSelect, setBlueSelect] = useState(diagramSelectOptions[0]);
  const [pinkSelect, setPinkSelect] = useState(diagramSelectOptions[1]);
  const [diagramData, setDiagramData] = useState([]);
  const [diagramSort, setDiagramSort] = useState(diagramSortOptions[0]);

  const getData = data => {
    const columnName = index => ({ columnName: OSTableColumns[index].value });
    return allCampaignsData(data, "operating_system_family_name")?.map(val => {
      return {
        rowName: val.label,
        columns: [
          { label: val.label, isFirst: true, noIcon: true, ...columnName(0) },
          { label: val.clicks, ...columnName(1) },
          { label: val.convs_rate, ...columnName(2) },
          { label: val.cpm, ...columnName(3) },
          { label: val.imps, ...columnName(4) }
        ]
      };
    });
  };

  useEffect(() => {
    setIsLoading(true);
    CampaignsService.getCampaignsStatistic("os", statisticParams)
      .then(data => {
        setInitialStatisticData(data);
        if (!getData(data)?.length) {
          setIsEmpty(true);
        } else {
          setIsEmpty(false);
        }
      })
      .catch(() => dsToast.error("We cannot get gender statistics. Please try again later."))
      .finally(() => setIsLoading(false));
  }, [statisticParams]);

  useEffect(() => {
    setTableRows(getData(statisticData));
  }, [statisticData]);

  useEffect(() => {
    setStatisticData(
      initialStatisticData.filter(val => {
        if (campaignsStatus?.statusCode) {
          return parseCampaignStatus(val.campaign)?.statusCode === campaignsStatus?.statusCode;
        }
        return val;
      })
    );
  }, [campaignsStatus, initialStatisticData]);

  useEffect(() => {
    if (initialStatisticData && blueSelect && pinkSelect && diagramSort) {
      const expectedCampaignValue = {
        blue: blueSelect,
        pink: pinkSelect
      };
      setDiagramData(
        getAreaChartData(
          initialStatisticData,
          expectedCampaignValue,
          "statisticByDate",
          diagramSort.dateFormat,
          diagramSort.value
        )
      );
    }
  }, [initialStatisticData, blueSelect, pinkSelect, diagramSort]);

  return (
    <DashboardContent isLoading={isLoading} isEmpty={isEmpty} emptyTitle="Try to select other dates">
      <DashboardDiagramWrapper
        pinkSelect={pinkSelect}
        setPinkSelect={setPinkSelect}
        blueSelect={blueSelect}
        setBlueSelect={setBlueSelect}
        options={diagramSelectOptions}
        sortOptions={diagramSortOptions}
        setSortValue={setDiagramSort}
        sortValue={diagramSort}
      >
        <StyledAreaChart data={diagramData} />
      </DashboardDiagramWrapper>
      <DashboardTableCard
        rows={tableRows}
        columns={OSTableColumns}
        setCampaignsStatus={setCampaignsStatus}
        campaignsStatus={campaignsStatus}
      />
    </DashboardContent>
  );
};

const mapStateToProps = state => ({
  statisticParams: state.campaigns.statisticParams
});

export default connect(mapStateToProps)(OperatingSystem);
