import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";

import CustomCheckbox from "shared/CustomCheckbox";

import Close from "assets/images/closeBlue.svg";
import DevicesIcon from "assets/images/devicesIcon.svg";

import "./styles.scss";

const DeviceModal = ({ title, handleClose, options, input, inputKey }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    if (input?.value[inputKey].length) {
      setSelectedOptions(input?.value[inputKey]);
    }
  }, [input?.value]);

  return (
    <div className="device-modal-content">
      <div className="device-modal-head">
        {title}
        <Button onClick={handleClose}>
          <img src={Close} alt="" />
        </Button>
      </div>
      <div className="modal-tool-bar">
        <CustomCheckbox
          checked={options?.length === selectedOptions.length}
          onChange={() => setSelectedOptions(options)}
          label="Select all"
        />
        <div className="tool-bar-right-part">
          {selectedOptions.length} items selected
          <Button onClick={() => setSelectedOptions([])}>Clear all</Button>
        </div>
      </div>
      <div className="device-list">
        <div className="options-list">
          {options?.map(val => (
            <CustomCheckbox
              key={val.id}
              onChange={() => {
                if (selectedOptions.find(value => value.id === val.id)) {
                  return setSelectedOptions(selectedOptions.filter(value => value.id !== val.id));
                }
                return setSelectedOptions([...selectedOptions, val]);
              }}
              checked={selectedOptions.find(value => value.id === val.id)}
              label={val.name}
            />
          ))}
        </div>
        <div className="selected-options-list">
          {selectedOptions.map(val => (
            <CustomCheckbox
              key={val.id}
              onChange={() => setSelectedOptions(selectedOptions.filter(value => value.id !== val.id))}
              checked
              label={val.name}
            />
          ))}
          {selectedOptions.length === 0 && (
            <div className="is-empty-box">
              <img src={DevicesIcon} alt="" />
              Select some options
              <br />
              from the list
            </div>
          )}
        </div>
      </div>
      <div className="device-modal-footer">
        <Button onClick={handleClose}>Discard</Button>
        <Button
          onClick={() => {
            handleClose();
            return input?.onChange({ ...input?.value, [inputKey]: selectedOptions });
          }}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default DeviceModal;
