import React, { useEffect, useState } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Link, withRouter } from "react-router-dom";
import queryString from "query-string";
import Logo from "../../shared/Logo";
import useStyles from "./style";
import makeApiCall from "../../helpers/makeApiCall";
import Loader from "../../shared/Loader";

const Confirm = ({ match, location }) => {
  const classes = useStyles();
  const [isEmailConfirmed, setEmailConfirmed] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [msgError, setErrorMsg] = useState("");

  useEffect(() => {
    const params = queryString.parse(location.search);
    makeApiCall({
      url: `/users/email-confirm?${queryString.stringify(params)}`
    })
      .then(() => {
        setEmailConfirmed(true);
      })
      .catch(e => {
        setEmailConfirmed(false);
        setErrorMsg(e.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <div className={classes.container}>
      <CssBaseline />
      <div className={classes.linearGradientBG} />
      <div className={classes.paper}>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <Logo />
            {isEmailConfirmed ? (
              <>
                <div className={classes.signInSalutation}>
                  Email confirmed! You can login to the app by link below
                </div>
              </>
            ) : (
              <div className={classes.signInSalutation}>{msgError}</div>
            )}
            <Link to="/" className={classes.linkToLogin}>
              Go to the app
            </Link>
          </>
        )}
      </div>
    </div>
  );
};

export default withRouter(Confirm);
