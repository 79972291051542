import { handleActions } from "redux-actions";
import { setCampaignsList, setStatisticParams, setOpenMobileMenu } from "redux/actions/campaigns";
import moment from "moment";

const initialState = {
  campaignsList: null,
  statisticParams: {
    startDate: new Date(moment().subtract(7, "d")),
    endDate: new Date(),
    campaign: null
  },
  isOpenMobileMenu: false
};

const handleSetCampaignsList = (state, { payload }) => {
  return { ...state, campaignsList: payload };
};

const handleSetStatisticParams = (state, { payload }) => {
  return { ...state, statisticParams: payload };
};

const handleOpenMobileMenu = (state, { payload }) => {
  return { ...state, isOpenMobileMenu: payload };
};

const reducer = handleActions(
  {
    [setCampaignsList]: handleSetCampaignsList,
    [setStatisticParams]: handleSetStatisticParams,
    [setOpenMobileMenu]: handleOpenMobileMenu
  },
  { ...initialState }
);

export default reducer;
