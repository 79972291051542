import { applyMiddleware, createStore, combineReducers } from "redux";
import createSagaMiddleware from "redux-saga";
import { reducer as formReducer } from "redux-form";
import { composeWithDevTools } from "redux-devtools-extension";

import meReducer from "containers/components/Auth/reducer";
import campaigns from "redux/reducers/campaigns";
import userProfile from "redux/reducers/userProfile"
import admin from "redux/reducers/admin";
import beginnerMode from "redux/reducers/beginnerMode";

import rootSaga from "./rootSagas";

const sagaMiddleware = createSagaMiddleware();

const combinedReducers = combineReducers({
  me: meReducer,
  form: formReducer,
  campaigns,
  userProfile,
  admin,
  beginnerMode
});

const store = createStore(combinedReducers, composeWithDevTools(applyMiddleware(sagaMiddleware)));

sagaMiddleware.run(rootSaga);

export default store;
