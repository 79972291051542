import React, { useEffect, useState } from "react";
import { TextField, Button } from "@material-ui/core";
import { ContextMenuTrigger } from "react-contextmenu";
import moment from "moment";

import { parseCampaignStatus } from "helpers/parseCampaignStatus";

import CampaignContextMenu from "shared/DashboardSideMenu/CampaignContextMenu";

import TableToolBar from "./TableToolBar";

import "./styles.scss";

const initialColumnsState = (rows, columns) => {
  if (rows && !rows[0]?.campaignInfo) {
    return columns?.map((val, i) => {
      if (i === 0) {
        return { ...val, isShow: true, columnFilter: "", sort: "top" };
      }
      return { ...val, isShow: true, columnFilter: "", sort: null };
    });
  }
  return columns?.map(val => ({ ...val, isShow: true, columnFilter: "", sort: null }));
};

const DashboardTableCard = ({
  columns,
  rows,
  campaignsStatus,
  setCampaignsStatus,
  withoutToolbar,
  fullHeight,
  getCampaigns,
  setIsLoading
}) => {
  const [isShowFilters, setIsShowFilters] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState([]);

  useEffect(() => {
    if (columns && rows) {
      setSelectedColumns(initialColumnsState(rows, columns));
    }
  }, [columns, rows]);

  const rowsFilterByStatus = val => {
    if (campaignsStatus?.statusCode) {
      if (val.campaignInfo) {
        return parseCampaignStatus(val.campaignInfo)?.statusCode === campaignsStatus?.statusCode;
      }
      return val;
    }
    return val;
  };

  const rowsFilterByText = val => {
    let filter = true;
    selectedColumns.forEach((value, index) => {
      filter =
        filter &&
        val.columns[index].label
          ?.toString()
          ?.toLowerCase()
          ?.includes(selectedColumns[index].columnFilter.toLowerCase());
      return value;
    });
    return filter;
  };

  const getTableData = () => {
    return rows?.map(val => {
      const columnsData = {};
      val.columns.forEach(column => {
        columnsData[column.columnName] = column.label;
        return column;
      });
      return {
        ...columnsData,
        ...val.campaignInfo
      };
    });
  };

  const rowsSort = (a, b) => {
    const sortColumn = selectedColumns.find(val => val.sort);
    if (sortColumn) {
      const getValue = elem => {
        if (sortColumn.value === "date") {
          const rightColumn = elem.columns.find(val => val.columnName === sortColumn?.value);
          if (rightColumn?.sortDate) {
            return rightColumn?.sortDate;
          }
          return moment(rightColumn?.label.replace(" | ", " "));
        }
        return elem.columns.find(val => val.columnName === sortColumn?.value)?.label;
      };
      if (sortColumn?.sort === "bottom") {
        return getValue(a) > getValue(b) ? 1 : -1;
      }
      return getValue(a) < getValue(b) ? 1 : -1;
    }
    if (a.campaignInfo) {
      return new Date(a.campaignInfo.createdAt) < new Date(b.campaignInfo.createdAt) ? 1 : -1;
    }
    return new Date(a.rowName) < new Date(b.rowName) ? 1 : -1;
  };

  return (
    <div className={`dashboard-table-card-wrapper ${fullHeight ? "full-height" : ""}`}>
      {!withoutToolbar && (
        <TableToolBar
          campaignsStatus={campaignsStatus}
          setCampaignsStatus={setCampaignsStatus}
          selectedColumns={selectedColumns}
          setSelectedColumns={setSelectedColumns}
          initialColumnsState={initialColumnsState(rows, columns)}
          isShowFilters={isShowFilters}
          setIsShowFilters={setIsShowFilters}
          tableData={getTableData()}
        />
      )}
      <div className="custom-table-wrapper">
        <div className="custom-table-head">
          {selectedColumns
            ?.filter(val => val.isShow)
            .map((column, index) => (
              <div
                key={column.value}
                className={`head-column ${column.value === columns[0].value ? "first-head-column" : ""}`}
              >
                <div
                  className="column-name"
                  onClick={() => {
                    if (column.value !== "actions") {
                      setSelectedColumns(
                        selectedColumns.map((val, i) => {
                          if (i === index) {
                            return { ...val, sort: val.sort === "top" ? "bottom" : "top" };
                          }
                          return { ...val, sort: null };
                        })
                      );
                    }
                  }}
                >
                  {column.label}
                  {column.value !== "actions" && (
                    <div
                      className={`sort-arrow ${column.sort ? "active" : ""} ${column.sort === "top" ? "to-top" : ""}`}
                    />
                  )}
                </div>
                <div className={`filter-input-box ${isShowFilters ? "active" : ""}`}>
                  <TextField
                    value={column.columnFilter}
                    className="filter-input"
                    placeholder="Search"
                    onChange={e => {
                      setSelectedColumns(
                        selectedColumns
                          .filter(val => val.isShow)
                          .map((val, i) => {
                            if (i === index) {
                              return { ...val, columnFilter: e.target.value };
                            }
                            return val;
                          })
                      );
                    }}
                  />
                </div>
              </div>
            ))}
        </div>
        <div className="custom-table-body">
          {rows
            ?.filter(val => rowsFilterByStatus(val) && rowsFilterByText(val))
            .sort(rowsSort)
            .map(val => {
              return (
                <div key={val.campaignInfo?.id || val.rowName} className="custom-table-row">
                  {val.columns
                    .filter(column => selectedColumns.find(col => col.value === column.columnName)?.isShow)
                    .map(column => (
                      <div key={column.columnName} className={`body-column ${column.isFirst ? "first-column" : ""}`}>
                        {column.isFirst && !column.noIcon && (
                          <div className="status-icon-box">
                            <img src={parseCampaignStatus(val.campaignInfo)?.icon} alt="" />
                          </div>
                        )}
                        {column.onClick ? (
                          <>
                            {column.withContext ? (
                              <ContextMenuTrigger id={val?.campaignInfo?.id}>
                                <Button onClick={column.onClick} className="column-label btn">
                                  {column.label}
                                </Button>
                              </ContextMenuTrigger>
                            ) : (
                              <Button onClick={column.onClick} className="column-label btn">
                                {column.label}
                              </Button>
                            )}
                          </>
                        ) : (
                          <div className="column-label">{column.label}</div>
                        )}
                      </div>
                    ))}
                  {
                    val.columns.find(column => column.withContext) && (
                      <CampaignContextMenu
                        campaign={val?.campaignInfo}
                        setIsLoading={setIsLoading}
                        getCampaigns={getCampaigns}
                      />
                    )
                  }
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default DashboardTableCard;
