/* eslint-disable */
import { HttpService } from "./HttpService";
import { SndAuth0Client } from "../security/Auth0Client";
import config from "../config";

export class ApiService {
  static async getMyAccountScopeAsync() {
    return ApiService._requestAsync({ url: "/users/me/scope" });
  }

  static async registerAccountAsync({ email, firstName, lastName, userId }) {
    return ApiService._requestAsync({
      url: "/auth/register",
      method: "POST",
      data: {
        userId,
        email,
        firstName,
        lastName
      }
    });
  }

  static async _requestAsync({
                               url,
                               method = "GET",
                               data = {},
                               headers = {},
                               responseType = "json"
                             }) {
    const accessToken = await SndAuth0Client.getTokenSilentlyAsync();
    return HttpService.requestAsync({
      url: config.api.endpoint(url),
      method,
      data,
      responseType,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
        ...headers
      }
    });
  }
}
