import React from "react";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createBrowserHistory } from "history";
import { Router, Switch, Route, Redirect } from "react-router-dom";

import {
  EMAIL_CONFIRM,
  EMAIL_NOT_CONFIRM,
  NOT_ACCESS,
  WELCOME,
  OVERVIEW,
  DASHBOARD,
  EXPERT_MODE,
  USER_SETTINGS,
  BEGINNER_MODE,
  TERMS
} from "pathnameVariables";
import store from "redux/store";
import { setClientWidth } from "redux/actions/userProfile";

import PrivateRoute from "containers/PrivateRoute";
import Auth from "containers/components/Auth";
import InvitedUser from "pages/InvitedUser";
import Confirm from "pages/Confirm";
import EmailNotConfirmed from "pages/EmailNotConfirmed";
import HasNoAccess from "pages/HasNoAccess";
import Dashboard from "pages/Dashboard";
import ExpertMode from "pages/ExpertMode";
import UserSettings from "pages/UserSettings";
import BeginnerMode from "pages/BeginnerMode";
import TermsCond from "pages/Terms&Cond";

const history = createBrowserHistory();

const App = () => {
  window.onresize = () => store.dispatch(setClientWidth(document.documentElement.clientWidth));
  return (
    <Provider store={store}>
      <Router history={history}>
        <Switch>
          <Route path={WELCOME} component={InvitedUser} />
          <Auth domain="id.datasys.com" audience="api.searchndeploy.com" clientId="ZNE0d2UEVYTU2KcBC80QAiO03TLxDH8F">
            <PrivateRoute
              path="/"
              component={() => (
                <Switch>
                  <Route path={TERMS} component={TermsCond} />
                  <Route path={DASHBOARD} component={Dashboard} />
                  <Route path={EXPERT_MODE} component={ExpertMode} />
                  <Route path={BEGINNER_MODE} component={BeginnerMode} />
                  <Route path={USER_SETTINGS} component={UserSettings} />
                  <Route component={() => <Redirect to={OVERVIEW} />} />
                </Switch>
              )}
            />
            <Route exact path={EMAIL_CONFIRM} component={Confirm} />
            <Route exact path={EMAIL_NOT_CONFIRM} component={EmailNotConfirmed} />
            <Route exact path={NOT_ACCESS} component={HasNoAccess} />
            <ToastContainer
              position="top-right"
              autoClose={3000}
              hideProgressBar
              closeOnClick
              draggable
              pauseOnHover
            />
          </Auth>
        </Switch>
      </Router>
    </Provider>
  );
};

export default App;
