export default [
  {
    dark: "#222228",
    current: "#FFFFFF",
    name: "--white"
  },
  {
    dark: "#F6F8FF",
    current: "#242424",
    name: "--semi-black"
  },
  {
    dark: "rgba(0,0,0, .4)",
    current: "rgba(255,255,255, .4)",
    name: "--opacity-white"
  },
  {
    dark: "#222228",
    current: "#F7F7FD",
    name: "--light-gray1"
  },
  {
    dark: "#ADADAD",
    current: "#ADADAD",
    name: "--light-gray2"
  },
  {
    dark: "#C7CDE3",
    current: "#C7CDE3",
    name: "--light-gray3"
  },
  {
    dark: "#F0F3FF",
    current: "#F0F3FF",
    name: "--light-gray4"
  },
  {
    dark: "#F1F1FC",
    current: "#F1F1FC",
    name: "--light-gray5"
  },
  {
    dark: "#E8E8F9",
    current: "#E8E8F9",
    name: "--light-gray6"
  },
  {
    dark: "#82848D",
    current: "#B1BAD8",
    name: "--light-gray7"
  },
  {
    dark: "#D3D7E9",
    current: "#D3D7E9",
    name: "--gray"
  },
  {
    dark: "rgba(226, 228, 242, 0.3)",
    current: "rgba(226, 228, 242, 0.3)",
    name: "--opacity-gray"
  },
  {
    dark: "#1A1B1E",
    current: "rgba(247, 247, 253, 0.5)",
    name: "--opacity-gray2"
  },
  {
    dark: "#F6F8FF",
    current: "#213991",
    name: "--blue"
  },
  {
    dark: "#214BE5",
    current: "#214BE5",
    name: "--dark-blue"
  },
  {
    dark: "#1F3276",
    current: "#1F3276",
    name: "--dark-blue2"
  },
  {
    dark: "#324DB1",
    current: "#1F4FFF",
    name: "--bright-blue"
  },
  {
    dark: "#B7B8BD",
    current: "#6979B4",
    name: "--light-blue"
  },
  {
    dark: "rgba(105, 121, 180, .5)",
    current: "rgba(105, 121, 180, .5)",
    name: "--opacity-blue"
  },
  {
    dark: "#9ADBA8",
    current: "#9ADBA8",
    name: "--green"
  },
  {
    dark: "#EBD042",
    current: "#EBD042",
    name: "--yellow"
  },
  {
    dark: "#FFA93F",
    current: "#FFA93F",
    name: "--orange"
  },
  {
    dark: "#F0AEE1",
    current: "#F0AEE1",
    name: "--pink"
  },
  {
    dark: "#FAEEF9",
    current: "#FAEEF9",
    name: "--semi-pink"
  },
  {
    dark: "#F73E49",
    current: "#F73E49",
    name: "--red1"
  },
  {
    dark: "#F63E49",
    current: "#F63E49",
    name: "--light-red"
  },
  {
    dark: "rgba(246, 62, 73, 0.1)",
    current: "rgba(246, 62, 73, 0.1)",
    name: "--opacity-red"
  },
  {
    dark: "rgba(183, 184, 189, .08)",
    current: "rgba(33, 57, 145, .07)",
    name: "--gray-border1"
  },
  {
    dark: "rgba(183, 184, 189, .08)",
    current: "rgba(33, 57, 145, .1)",
    name: "--gray-border2"
  },
  {
    dark: "rgba(226, 228, 242, .1)",
    current: "#E2E4F2",
    name: "--dark-gray-border"
  },
  {
    dark: "rgba(53, 53, 53, 0.8)",
    current: "rgba(53, 53, 53, 0.8)",
    name: "--backdrop"
  },
  {
    dark: "#222228",
    current: "#FFFFFF",
    name: "--card-bg"
  },
  {
    dark: "#1A1B1E",
    current: "#F7F7FD",
    name: "--main-bg"
  },
  {
    dark: "#1A1B1E",
    current: "#FFFFFF",
    name: "--body-bg"
  },
  {
    dark: "#1A1B1E",
    current: "#FFFFFF",
    name: "--default-select-bg"
  },
  {
    dark: "#1A1B1E",
    current: "#F1F1FC",
    name: "--main-bg-hover"
  },
  {
    dark: "#213991",
    current: "#213991",
    name: "--blue-btn-bg"
  },
  {
    dark: "#FFFFFF",
    current: "#FFFFFF",
    name: "--blue-btn-color"
  },
  {
    dark: "#213991",
    current: "#213991",
    name: "--scroll-color"
  },
  {
    dark:
      "linear-gradient(193deg, rgba(26,27,30,.5) 0%, rgba(26,27,30,0) 38%, rgba(26,27,30,.5) 69%, rgba(26,27,30,0) 100%)",
    current:
      "linear-gradient(193deg, rgba(255,255,255,.5) 0%, rgba(255,255,255,0) 38%, rgba(255,255,255,.5) 69%, rgba(255,255,255,0) 100%)",
    name: "--pie-chart-shadow"
  },
  {
    dark: "0 6px 32px rgba(255, 255, 255, 0.17)",
    current: "0 6px 32px rgba(0, 0, 0, 0.17)",
    name: "--loader-shadow"
  }
];
