import React, { useState } from "react";
import { Button } from "@material-ui/core";

import CustomCheckbox from "shared/CustomCheckbox";
import ModalWrapper from "shared/ModalWrapper";

import InfoIcon from "assets/images/infoIcon.svg";

import ExpertModeCard from "../../components/ExpertModeCard";
import DeviceModal from "./deviceModal";

import "./styles.scss";

const devices = [
  {
    label: "Show on all devices",
    value: "all"
  },
  {
    label: "Set specific targeting for devices",
    value: "custom"
  }
];

const Devices = ({ input, deviceTypeOptions, deviceOptions, osOptions, networkOptions }) => {
  const [openOsModal, setOpenOsModal] = useState(false);
  const [openDeviceModal, setOpenDeviceModal] = useState(false);
  const [openNetworkModal, setOpenNetworkModal] = useState(false);
  return (
    <ExpertModeCard title="Devices" className="devices-card-wrapper" isDefaultOpen={false}>
      <div className="card-subtitle">On what devices to show</div>
      {devices?.map(val => (
        <CustomCheckbox
          key={val.value}
          onChange={() => input?.onChange({ ...input?.value, forShow: val.value })}
          checked={val.value === input?.value?.forShow}
          isTypeRadio
          label={val.label}
        />
      ))}
      {input?.value?.forShow === "custom" && (
        <>
          <div className="device-type-info">
            <img src={InfoIcon} alt="" />
            To simplify device targeting, app, app interstitial, and web targeting have been combined under the new
            options "Mobile phones" and "Tablets".
          </div>
          {deviceTypeOptions?.map(val => (
            <CustomCheckbox
              key={val.type}
              onChange={() => {
                if (input?.value?.deviceType?.find(value => value.type === val.type)) {
                  return input?.onChange({
                    ...input?.value,
                    deviceType: input?.value?.deviceType.filter(value => value.type !== val.type)
                  });
                }
                return input?.onChange({ ...input?.value, deviceType: [...input?.value?.deviceType, val] });
              }}
              checked={input?.value?.deviceType?.find(value => value.type === val.type)}
              label={val.name}
            />
          ))}
          <div>
            <Button disabled={!osOptions?.length} className="device-modal-btn" onClick={() => setOpenOsModal(true)}>
              <div className="btn-title">Operating systems</div>
              <div className="btn-subtitle">{input?.value?.os?.length} operation systems selected</div>
            </Button>
            <Button
              disabled={!deviceOptions?.length}
              className="device-modal-btn"
              onClick={() => setOpenDeviceModal(true)}
            >
              <div className="btn-title">Device models</div>
              <div className="btn-subtitle">{input?.value?.deviceModels?.length} device models selected</div>
            </Button>
            <Button
              disabled={!networkOptions?.length}
              className="device-modal-btn"
              onClick={() => setOpenNetworkModal(true)}
            >
              <div className="btn-title">Networks</div>
              <div className="btn-subtitle">{input?.value?.networks?.length} networks selected</div>
            </Button>
            <ModalWrapper open={openOsModal} handleClose={() => setOpenOsModal(false)}>
              <DeviceModal
                handleClose={() => setOpenOsModal(false)}
                title="Choose operating system"
                options={osOptions}
                input={input}
                inputKey="os"
              />
            </ModalWrapper>
            <ModalWrapper open={openDeviceModal} handleClose={() => setOpenDeviceModal(false)}>
              <DeviceModal
                handleClose={() => setOpenDeviceModal(false)}
                title="Choose device models"
                options={deviceOptions}
                input={input}
                inputKey="deviceModels"
              />
            </ModalWrapper>
            <ModalWrapper open={openNetworkModal} handleClose={() => setOpenNetworkModal(false)}>
              <DeviceModal
                handleClose={() => setOpenNetworkModal(false)}
                title="Choose networks"
                options={networkOptions}
                input={input}
                inputKey="networks"
              />
            </ModalWrapper>
          </div>
        </>
      )}
      <div className="card-text-box">
        Device targeting lets you choose the types of devices where your ad can appear
      </div>
    </ExpertModeCard>
  );
};

export default Devices;
