import CampaignRunning from "assets/images/running.svg";
import CampaignReady from "assets/images/ready.svg";
import CampaignValidate from "assets/images/validate.svg";
import CampaignRejected from "assets/images/rejected.svg";
import CampaignBlocked from "assets/images/blocked.svg";
import { campaignStatusVariants } from "constants/campaignStatusVariants";

export const parseCampaignStatus = campaign => {
  const getStatus = code => campaignStatusVariants.find(val => val.statusCode === code);
  if (!campaign?.isActive) {
    return {
      status: getStatus(1).status,
      colorName: "red",
      color: "#FF3F3F",
      icon: CampaignBlocked,
      statusCode: getStatus(1).statusCode
    };
  }
  if (campaign?.status === 1) {
    return {
      status: getStatus(2).status,
      colorName: "red",
      color: "#FF3F3F",
      icon: CampaignRejected,
      statusCode: getStatus(2).statusCode
    };
  }
  if (campaign?.isRunning && campaign?.status === 2) {
    return {
      status: getStatus(3).status,
      colorName: "green",
      color: "#9ADBA8",
      icon: CampaignRunning,
      statusCode: getStatus(3).statusCode
    };
  }
  if (!campaign?.isRunning && campaign?.status === 2) {
    return {
      status: getStatus(4).status,
      colorName: "yellow",
      color: "#EBD042",
      icon: CampaignReady,
      statusCode: getStatus(4).statusCode
    };
  }
  if (campaign?.status === 0) {
    return {
      status: getStatus(5).status,
      colorName: "gray",
      color: "#ADADAD",
      icon: CampaignValidate,
      statusCode: getStatus(5).statusCode
    };
  }
  return {};
};
