import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { dsToast } from "shared/dsToast";
import { Button, TextField } from "@material-ui/core";
import { MapContainer, TileLayer, Marker, LayerGroup, Circle, CircleMarker, Polygon } from "react-leaflet";
import { uniq } from "lodash";
import axios from "axios";

import CampaignsCreation from "services/campaignCreation";
import { convertGoogleLocationData } from "helpers/locationHelper";

import StyledGoogleAutocomplete from "shared/StyledGoogleAutocomplete";
import StyledSlider from "shared/StyledSlider";
import CustomCheckbox from "shared/CustomCheckbox";
import StyledChip from "shared/StyledChip";
import Loader from "shared/Loader";
import StyledAutocomplete from "shared/StyledAutocomplete";

import { LocationIcon, SpecificIcon } from "assets/images/svgComponents";
import TipIcon from "assets/images/tipIcon.svg";
import CloseIcon from "assets/images/closeBlue.svg";

import BeginnerCard from "pages/BeginnerMode/components/BeginnerCard";
import StepBtnBox from "pages/BeginnerMode/components/StepBtnBox";

import "./styles.scss";
import useCurrentLocation from "../../helpers/useUserLocation";
import getMapZoomByLocationType from "../../helpers/getMapZoomByLocationType";

const convertLatLng = coordinates => {
  if (!Array.isArray(coordinates[0])) {
    return coordinates.reverse();
  }
  return coordinates.map(nestedCoordinates => {
    return convertLatLng(nestedCoordinates);
  });
};

export const RenderMap = ({ mapCenter, locations, switcherValue }) => {
  const [mapInstance, setMapInstance] = useState(null);

  const { location: currentLocation, error: currentError } = useCurrentLocation({
    enableHighAccuracy: true,
    timeout: 1000 * 60 * 1,
    maximumAge: 1000 * 3600 * 24,
  });

  //
  // SO SORRY FOR THIS PART, WILL REMOVE TOMORROW (TODAY 21 JUL 2021)
  //


  const [isFirstInited, setIsFirstInited] = useState(false)

  useEffect(() => {
    if (mapInstance && currentLocation && !isFirstInited && !locations?.radiusLocation?.formattedAddress) {
      // Check if radius location has lat, lng
      if (currentLocation?.latitude && currentLocation?.longitude) {
        const center = [currentLocation.latitude, currentLocation.longitude];
        // Set Leaflet map center
        if (mapInstance) {
          mapInstance.setView(center, 11)
          setIsFirstInited(true);
        }
      }
    }
  }, [currentLocation])

  //
  // END OF SO SORRY FOR THIS PART, WILL REMOVE TOMORROW (TODAY 21 JUL 2021)
  //


  useEffect(() => {
    if (mapInstance) {
      // Check if radius location mode and something selected as center
      if (locations?.currentTab === 0 && locations?.radiusLocation?.formattedAddress) {
        const radiusLocation = locations.radiusLocation;

        // Check if radius location has lat, lng
        if (radiusLocation?.lat && radiusLocation?.lng) {
          const center = [radiusLocation.lat, radiusLocation.lng];
          // Set Leaflet map center
          if (mapInstance) {
            mapInstance.setView(center, 11)
          }
        }
      }

      // Check if custom location mode
      if (locations?.currentTab === 1 && locations?.geometries?.length) {
        const lastSelectedLocation = locations.geometries[locations.geometries.length - 1];

        if (lastSelectedLocation?.coords?.length && lastSelectedLocation.geometryType) {
          mapInstance.setView(lastSelectedLocation.coords, getMapZoomByLocationType(lastSelectedLocation.geometryType));
        }
      }
    }
  }, [locations, mapInstance])

  return (
    <MapContainer whenCreated={setMapInstance} center={mapCenter || [39.5, -98.35]} zoom={3} style={{ height: "100%" }} scrollWheelZoom={true}>
      <TileLayer url="https://api.maptiler.com/maps/a04fdde1-83c5-4716-8439-893ca960d1fd/{z}/{x}/{y}.png?key=9obMmy4rP98lP4L0gCCw" />
      {locations?.currentTab === 0 ? (
        <>
          {locations?.radiusLocation?.lat && locations?.radiusLocation?.lng && (
            <>
              <Marker position={[locations?.radiusLocation?.lat, locations?.radiusLocation?.lng]} />
              <LayerGroup>
                <Circle
                  center={[locations?.radiusLocation?.lat, locations?.radiusLocation?.lng]}
                  pathOptions={{ color: "blue", fillColor: "blue" }}
                  radius={locations?.radiusLocation?.radius * 1609.34}
                />
              </LayerGroup>
            </>
          )}
        </>
      ) : (
        <LayerGroup>
          {switcherValue !== "exclude" &&
            locations?.geometries?.map(placeGeometry => {
              return (
                <Polygon
                  key={placeGeometry.formattedAddress}
                  pathOptions={{ color: "blue", fillColor: "blue" }}
                  positions={placeGeometry.coordinates}
                />
              );
            })}
        </LayerGroup>
      )}

      {
        currentLocation && (
          <CircleMarker
            center={[currentLocation?.latitude, currentLocation?.longitude]}
            pathOptions={{ color: 'blue', fillColor: 'blue' }}
            radius={5}
          />
        )
      }
    </MapContainer>
  );
};

const SelectLocation = ({
  handleBack,
  handleConfirm,
  handleSetLocations,
  locations,
  isLoading,
  isExpertMode,
  handleClose,
  clientWidth
}) => {
  const [postalCodes, setPostalCodes] = useState("");
  const [switcherValue, setSwitcherValue] = useState("include");
  const [inputGeo, setInputGeo] = useState(null);
  const [placeValue, setPlaceValue] = useState("");

  useEffect(() => {
    setPostalCodes(locations?.postalCodes?.join("\r\n"));
  }, [locations?.postalCodes]);

  const postalCodesInputOnBlur = () => {
    const codesArr = uniq(
      postalCodes
        .split(/\r?\n/)
        .filter(v => v.length > 0)
        .map(e => e.trim())
    );
    handleSetLocations({
      ...locations,
      geometries: locations.geometries.filter(value => value.formattedAddress || codesArr.includes(value.zipCode)),
      postalCodes: codesArr
    });
  };

  const handleGetGeoByZipCodes = () => {
    CampaignsCreation.getGeoCoverageByZipCodes(locations?.postalCodes)
      .then(data => {
        const result = data
          ?.map(val => ({ ...val?.geo?.geometry, zipCode: val.postalCode }))
          .filter(val => !locations?.geometries?.find(value => value.zipCode === val.zipCode));
        const formattedResult = result?.map(val => ({ ...val, coordinates: convertLatLng(val.coordinates) }));
        handleSetLocations({ ...locations, geometries: [...formattedResult, ...locations?.geometries] });
      })
      .catch(() => dsToast.error("Sorry, we cannot get geo coverage by zip codes. Please try again later"));
  };

  useEffect(() => {
    if (locations?.postalCodes) {
      handleGetGeoByZipCodes();
    }
  }, [locations?.postalCodes]);

  useEffect(() => {
    if (inputGeo) {
      handleSetLocations({ ...locations, geometries: [...locations?.geometries, inputGeo] });
    }
  }, [inputGeo]);

  const handleSearchLocation = place => {
    const params = {
      place_id: place?.place_id,
      format: "json",
      polygon_geojson: 1
    };
    axios.get("https://nominatim.openstreetmap.org/details.php", { params }).then(response => {
      if (response?.data && response?.data?.geometry && response?.data?.geometry?.type !== "Point") {
        setInputGeo({
          ...response?.data?.geometry,
          coordinates: convertLatLng(response?.data?.geometry?.coordinates),
          formattedAddress: place?.display_name,
          coords: response?.data?.centroid?.coordinates?.reverse(),
          geometryType: response?.data?.type || "country"
        });
      }
    });
  };

  const getIsDisabled = () => {
    if (!locations?.currentTab) {
      return !locations?.isUS && !locations?.radiusLocation?.formattedAddress;
    }
    if (locations?.isInBulk) {
      return !locations?.include?.length || !locations?.postalCodes?.length;
    }
    return !locations?.include?.length;
  };

  useEffect(() => {
    if (!placeValue) {
      handleSetLocations({ ...locations, radiusLocation: { radius: 1 } });
    }
  }, [placeValue]);

  return (
    <div className="select-location-wrapper">
      {isLoading ? (
        <Loader empty isDashboardContent />
      ) : (
        <>
          {clientWidth > 1060 && (
            <div className="location-map-box">
              <RenderMap locations={locations} switcherValue={switcherValue} />
            </div>
          )}
          <div className={`location-step-content-wrapper ${isExpertMode ? "expert" : ""}`}>
            {isExpertMode && (
              <Button className="close-modal-btn" onClick={handleClose}>
                <img src={CloseIcon} alt="" />
              </Button>
            )}
            <div className="location-step-content">
              <div className="location-step-title">Where are your customers?</div>
              <div className="location-tab-panel">
                <Button
                  className={!locations?.currentTab ? "active" : ""}
                  onClick={() => handleSetLocations({ ...locations, currentTab: 0 })}
                >
                  <LocationIcon />
                  {clientWidth > 767 ? "Set up a radius around your business" : "Set up a radius"}
                </Button>
                <Button
                  className={locations?.currentTab ? "active" : ""}
                  onClick={() => handleSetLocations({ ...locations, currentTab: 1 })}
                >
                  <SpecificIcon />
                  Set up specific areas
                </Button>
              </div>
              {clientWidth < 1060 && (
                <div className="mobile-location-map-mox">
                  <RenderMap locations={locations} switcherValue={switcherValue} />
                </div>
              )}
              {!locations?.currentTab ? (
                <>
                  {!isExpertMode && (
                    <CustomCheckbox
                      label="United States"
                      checked={locations?.isUS && !locations?.radiusLocation?.formattedAddress}
                      onChange={newValue => {
                        handleSetLocations({ ...locations, isUS: newValue, radiusLocation: { radius: 1 } });
                        setPlaceValue("");
                      }}
                    />
                  )}
                  <StyledAutocomplete
                    label="What's your company address?"
                    inputVal={placeValue}
                    setInputVal={setPlaceValue}
                    onSelect={place =>
                      handleSetLocations({
                        ...locations,
                        radiusLocation: convertGoogleLocationData(place, locations?.radiusLocation?.radius)
                      })
                    }
                  />
                  <BeginnerCard className="slider-box">
                    <div className="slider-value">
                      {`${locations?.radiusLocation?.radius} mile${locations?.radiusLocation?.radius > 1 ? "s" : ""}`}
                    </div>
                    <StyledSlider
                      min={1}
                      max={65}
                      value={locations?.radiusLocation?.radius}
                      onChange={(e, newValue) =>
                        handleSetLocations({
                          ...locations,
                          radiusLocation: { ...locations?.radiusLocation, radius: newValue }
                        })
                      }
                    />
                    <div className="slider-min-max-values">
                      <span>1</span>
                      <span>65</span>
                    </div>
                  </BeginnerCard>
                </>
              ) : (
                <>
                  <CustomCheckbox
                    label="Add locations in bulk"
                    checked={locations?.isInBulk}
                    onChange={newValue => handleSetLocations({ ...locations, isInBulk: newValue })}
                  />
                  <div className={`location-bulk-box ${locations?.isInBulk ? "active" : ""}`}>
                    <div className="codes-field-label">Locations - postal codes. Indicate them one per line</div>
                    <TextField
                      className="postal-codes-field"
                      multiline
                      rows={6}
                      value={postalCodes}
                      onChange={e => setPostalCodes(e.target.value)}
                      onBlur={postalCodesInputOnBlur}
                    />
                  </div>
                  <div className="include-exclude-field-box">
                    <StyledAutocomplete
                      label="Enter zip code, city, state or country below"
                      onSelect={place => {
                        if (switcherValue === "include") {
                          handleSearchLocation(place);
                        }
                        handleSetLocations({
                          ...locations,
                          [switcherValue]: [...locations?.[switcherValue], convertGoogleLocationData(place)]
                        });
                      }}
                    />
                    {!isExpertMode && (
                      <div className="include-exclude-switcher">
                        <Button
                          onClick={() => setSwitcherValue("include")}
                          className={switcherValue === "include" ? "active" : ""}
                        >
                          Include
                        </Button>
                        <Button
                          onClick={() => setSwitcherValue("exclude")}
                          className={switcherValue === "exclude" ? "active" : ""}
                        >
                          Exclude
                        </Button>
                      </div>
                    )}
                  </div>
                  <div className="locations-chips-box">
                    {locations?.[switcherValue]?.map((val, i) => (
                      <StyledChip
                        key={i.toString()}
                        label={val.formattedAddress}
                        onDelete={() =>
                          handleSetLocations({
                            ...locations,
                            [switcherValue]: locations?.[switcherValue]?.filter(
                              value => value.formattedAddress !== val.formattedAddress
                            ),
                            geometries:
                              switcherValue === "include"
                                ? locations?.geometries.filter(value => value.formattedAddress !== val.formattedAddress)
                                : locations?.geometries
                          })
                        }
                      />
                    ))}
                  </div>
                  <BeginnerCard className="tips-description">
                    <div className="tips-box">
                      <div className="tips-title">Location targeting tips:</div>
                      <div className="tip-item">
                        <img src={TipIcon} alt="" />
                        Exclude locations where your target audience will not be
                      </div>
                      <div className="tip-item">
                        <img src={TipIcon} alt="" />
                        Include locations where people want your product or service
                      </div>
                    </div>
                    <div className="common-text-box">
                      We will only display ads to people searching for businesses like your in your selection locations.
                    </div>
                  </BeginnerCard>
                </>
              )}
              {!isExpertMode && (
                <StepBtnBox>
                  <Button onClick={handleBack}>Back</Button>
                  <Button disabled={getIsDisabled()} onClick={handleConfirm}>
                    Confirm
                  </Button>
                </StepBtnBox>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  clientWidth: state.userProfile.clientWidth
});

export default connect(mapStateToProps)(SelectLocation);
