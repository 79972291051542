import React from "react";
import Loader from "shared/Loader";
import EmptyPageCard from "shared/EmptyPageCard";
import "./styles.scss";

const DashboardContent = ({
  children,
  isLoading,
  isEmpty,
  emptyBtnLabel,
  emptyBtnClick,
  emptyTitle,
  className,
  emptySubtitle
}) => {
  return (
    <div className={`dashboard-content ${className || ""}`}>
      {isLoading ? (
        <Loader empty isDashboardContent />
      ) : (
        <>
          {isEmpty ? (
            <EmptyPageCard
              btnLabel={emptyBtnLabel}
              onBtnClick={emptyBtnClick}
              title={emptyTitle}
              subtitle={emptySubtitle}
            />
          ) : (
            children
          )}
        </>
      )}
    </div>
  );
};

export default DashboardContent;
