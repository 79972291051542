import { takeEvery, call, put, select } from "redux-saga/effects";
import { dsToast } from "shared/dsToast";
import UserProfileInfo from "services/userProfile";
import WebPushService from "services/webPushService";

function* handleGetUserInfo({ payload }) {
  try {
    if (payload) {
      yield put({ type: "SET_USER_INFO", payload });
    } else {
      const oldUserInfo = yield select(state => state.userProfile.userInfo);
      const accountRole = yield select(state => state.me.accountRole);
      let virtualCredit = "";
      if (accountRole === "account-admin") {
        virtualCredit = yield call(UserProfileInfo.getVirtualCredit);
      }
      const userInfo = yield call(UserProfileInfo.getUserInfo);
      const userAccountInfo = yield call(UserProfileInfo.getUserInfoById, userInfo.id);
      const permission = yield call(WebPushService.requestPushNotificationPermissions);
      yield put({
        type: "SET_USER_INFO",
        payload: {
          ...oldUserInfo,
          ...userInfo,
          ...userAccountInfo,
          virtualCredit,
          hasWebPushPermission: permission === "granted"
        }
      });
    }
  } catch (e) {
    console.log(e);
    yield dsToast.error("We cannot get your data. Please try refreshing the page");
  }
}

export default function* userProfileSagas() {
  yield takeEvery("GET_USER_INFO", handleGetUserInfo);
}
