import React, { useState, useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, LinearProgress, SwipeableDrawer } from "@material-ui/core";

import { BEGINNER_LOCATION, BEGINNER_BUDGET } from "pathnameVariables";
import BeginnerCampaignCreation from "services/beginnerCampaignCreation";
import CampaignsCreation from "services/campaignCreation";
import debounce from "helpers/debounce";
import { setTargetAds, setPrevState, handlePrevStep } from "redux/actions/beginnerMode";

import StyledTextField from "shared/StyledTextField";
import StyledChip from "shared/StyledChip";
import Loader from "shared/Loader";

import TipIcon from "assets/images/tipIcon.svg";
import CloseIcon from "assets/images/closeBlue.svg";

import StepContent from "../components/StepContent";
import BeginnerCard from "../components/BeginnerCard";
import StepBtnBox from "../components/StepBtnBox";

import "./styles.scss";

const keywordTips = [
  {
    title: "Think like a customer",
    text: "Your keywords should match the terms your potential customers would use to find your products or services."
  },
  {
    title: "General keywords reach more people",
    text:
      "Select more general keywords if you'd prefer to reach as many people as possible. No matter how general your keywords are, they should always be relevant to your ads and website."
  }
];

const TargetAds = ({
  campaignId,
  adGroupId,
  handleSetPrevState,
  handleSetTargetAds,
  targetAds,
  describeBusiness,
  handlePreviousStep,
  clientWidth
}) => {
  const { push } = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [keywords, setKeywords] = useState([]);
  const [isKeywordsLoading, setIsKeywordLoading] = useState(false);
  const [isNoResult, setIsNoResult] = useState(false);
  const [openKeywordsTips, setOpenKeywordsTips] = useState(false);

  const handleBack = () => {
    setIsLoading(true);
    handlePreviousStep(() => push(BEGINNER_LOCATION));
  };

  const handleNextStep = () => {
    setIsLoading(true);
    const keywordsData = {
      campaignId,
      adGroupId,
      suggestions: targetAds
    };
    BeginnerCampaignCreation.sendTargetAds(keywordsData).then(data => {
      handleSetPrevState(data.campaignCreation.prevState);
      push(BEGINNER_BUDGET);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    setIsLoading(true);
    CampaignsCreation.getWebsiteKeywords(describeBusiness?.websiteUrl)
      .then(data => {
        if (data?.keywords?.length) {
          setKeywords(data.keywords);
        }
      })
      .finally(() => setIsLoading(false));
  }, [describeBusiness]);

  const handleGetKeywords = val => {
    CampaignsCreation.getServicesKeywords(val)
      .then(data => {
        if (data?.results?.length) {
          setIsNoResult(false);
          setKeywords(data?.results?.map(value => value.keyword));
        } else {
          setIsNoResult(true);
        }
      })
      .catch(() => setIsNoResult(true))
      .finally(() => setIsKeywordLoading(false));
  };

  const debounceOnChange = useCallback(debounce(handleGetKeywords, 500), []);

  const keywordsTips = () => (
    <div className="keyword-tips-box">
      <Button className="close-btn" onClick={() => setOpenKeywordsTips(false)}>
        <img src={CloseIcon} alt="" />
      </Button>
      {keywordTips?.map((val, i) => (
        <div key={i.toString()} className="tip-item">
          <div className="item-title">
            <img src={TipIcon} alt="" />
            {val.title}
          </div>
          {val.text}
        </div>
      ))}
    </div>
  );

  return (
    <div className="beginner-target-ads-wrapper">
      {isLoading ? (
        <Loader empty isDashboardContent />
      ) : (
        <>
          <div className="target-ads-content-wrapper">
            <StepContent
              title="Target your ads to recent searches"
              subtitle="We show your ad to people who recently searched for products and services you offer"
              className="target-ads-content"
            >
              <BeginnerCard>
                <StyledTextField
                  label="Add keywords or phrases"
                  onKeyDown={e => {
                    if (e.key === "Enter" && !targetAds?.find(val => val === e.target.value)) {
                      handleSetTargetAds([...targetAds, e.target.value]);
                    }
                  }}
                  onChange={e => {
                    setIsKeywordLoading(true);
                    debounceOnChange(e.target.value);
                  }}
                />
                <div className="keywords-box">
                  {isKeywordsLoading ? (
                    <LinearProgress />
                  ) : (
                    <>
                      {isNoResult ? (
                        <div className="no-result">No result</div>
                      ) : (
                        keywords?.map(val => (
                          <StyledChip
                            key={val}
                            onClick={() => handleSetTargetAds([...targetAds, val])}
                            label={`+ ${val}`}
                            disabled={targetAds?.find(value => value === val)}
                          />
                        ))
                      )}
                    </>
                  )}
                </div>
              </BeginnerCard>
              {targetAds?.length > 0 && (
                <BeginnerCard className="selected-keywords-card">
                  <div className="card-title">Your products and services</div>
                  <div className="keywords-box">
                    {targetAds?.map(val => (
                      <StyledChip
                        key={val}
                        onDelete={() => handleSetTargetAds(targetAds?.filter(value => value !== val))}
                        label={val}
                      />
                    ))}
                  </div>
                </BeginnerCard>
              )}
              <StepBtnBox>
                <Button onClick={handleBack}>Back</Button>
                <Button onClick={handleNextStep}>Confirm</Button>
              </StepBtnBox>
            </StepContent>
          </div>
          {clientWidth > 1060 ? (
            keywordsTips()
          ) : (
            <>
              <SwipeableDrawer
                open={openKeywordsTips}
                anchor="right"
                onClose={() => setOpenKeywordsTips(false)}
                onOpen={() => setOpenKeywordsTips(true)}
                className="keywords-drawer-wrapper"
              >
                {keywordsTips()}
              </SwipeableDrawer>
              <Button onClick={() => setOpenKeywordsTips(true)} className="open-keywords-tips-btn">
                <img src={TipIcon} alt="" />
              </Button>
            </>
          )}
        </>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  clientWidth: state.userProfile.clientWidth,
  campaignId: state.beginnerMode.campaignId,
  adGroupId: state.beginnerMode.adGroupId,
  describeBusiness: state.beginnerMode.describeBusiness,
  targetAds: state.beginnerMode.targetAds
});

const mapDispatchToProps = dispatch => ({
  handleSetPrevState: payload => dispatch(setPrevState(payload)),
  handlePreviousStep: payload => dispatch(handlePrevStep(payload)),
  handleSetTargetAds: payload => dispatch(setTargetAds(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(TargetAds);
