import React, { useRef } from "react";

import Loader from "shared/Loader";
import TablePagination from "shared/TablePagination";
import CustomScrollBar from "shared/CustomScrollBar";

import "./styles.scss";

const AdminTable = ({
  withoutPagination,
  fullHeight,
  rows,
  columns,
  columnsWidth,
  onColumnClick,
  onRowClick,
  isLoading,
  pagesAmount,
  onPrevClick,
  onPageClick,
  onNextClick,
  currentPage
}) => {
  const ref = useRef();
  return (
    <div className={`admin-table-card-wrapper ${fullHeight ? "full-height" : ""}`}>
      <CustomScrollBar elemRef={ref} />
      {isLoading && (
        <div className="admin-table-loader-box">
          <Loader empty isDashboardContent />
        </div>
      )}
      <div className="admin-table-wrapper">
        <div className="admin-table">
          <div className="admin-table-content">
            <div className="admin-table-head">
              {columns?.map((val, i) => (
                <div
                  key={i.toString()}
                  onClick={() => val.sortQuery && onColumnClick(i)}
                  className={`table-head-column ${val.sortQuery ? "pointer" : ""}`}
                  style={
                    val.isFixedWidth
                      ? { minWidth: columnsWidth[i], maxWidth: columnsWidth[i] }
                      : { minWidth: columnsWidth[i] }
                  }
                >
                  <div className={`head-column-name ${val.isAction ? "action-btn-box" : ""}`}>
                    {val.label}
                    {val.sortQuery && (
                      <div
                        className={`sort-arrow ${val.sort ? "active" : ""} ${val.direction === "DESC" ? "to-top" : ""}`}
                      />
                    )}
                  </div>
                </div>
              ))}
            </div>
            <div className="admin-table-body" ref={ref}>
              {rows?.map(val => (
                <div
                  className={`admin-table-row ${onRowClick ? "cursor-pointer" : ""}`}
                  onClick={() => onRowClick && onRowClick(val.data)}
                >
                  {val?.columns?.map((column, index) => (
                    <div
                      className={`table-body-column ${column.isBold ? "bold" : ""}`}
                      style={
                        column.isFixedWidth
                          ? { minWidth: columnsWidth[index], maxWidth: columnsWidth[index] }
                          : { minWidth: columnsWidth[index] }
                      }
                    >
                      <div>
                        <div
                          className={`body-column-label ${column.isStatus ? "status" : ""} ${
                            column.status ? "green" : "red"
                          } ${column.isAction ? "action-btn-box" : ""} ${column.isDisabled ? "disabled" : ""}`}
                        >
                          {column.label}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {!withoutPagination && (
        <TablePagination
          onNextClick={onNextClick}
          onPageClick={onPageClick}
          onPrevClick={onPrevClick}
          pagesAmount={pagesAmount}
          currentPage={currentPage}
        />
      )}
    </div>
  );
};

export default AdminTable;
