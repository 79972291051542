import { call, debounce } from "redux-saga/effects";
import { logout } from "../actions";
import { SndAuth0Client } from "../../../../security/Auth0Client";

function* logoutSaga() {
  yield call(SndAuth0Client.logoutAsync);
}

export default function* handleLogoutSaga() {
  yield debounce(300, logout.toString(), logoutSaga);
}
