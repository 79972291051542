/* eslint-disable */
import { Auth0Client } from "@auth0/auth0-spa-js";
import jwt from "jsonwebtoken";

export class SndAuth0Client {
  static _accessToken = null;

  constructor() {
    SndAuth0Client._auth0Client = null;
  }

  static async isAuthenticatedAsync() {
    return SndAuth0Client._auth0Client.isAuthenticated();
  }

  static getUserAsync() {
    return SndAuth0Client._auth0Client.getUser();
  }

  static logoutAsync() {
    SndAuth0Client._accessToken = null;
    return SndAuth0Client._auth0Client.logout({
      returnTo: window.location.origin
    });
  }

  static loginWithRedirectAsync(email) {
    return SndAuth0Client._auth0Client.loginWithRedirect({
      redirect_uri: window.location.origin,
      fragment: email ? `signUpEmail=${email}` : ''
    });
  }

  static async initAsync(domain, clientId, audience, onRedirectCallback = () => {}) {
    if (SndAuth0Client._auth0Client){
      return;
    }

    if (!domain || !clientId) {
      throw new Error("Domain or ClientId does not provided");
    }
    const options = {
      domain,
      client_id: clientId,
      redirect_uri: window.location.origin,
    };
    if (audience) {
      options.audience = audience;
    }

    SndAuth0Client._auth0Client = new Auth0Client(options);
    if (SndAuth0Client._hasAuthParams()) {
      try {
        const {
          appState,
        } = await SndAuth0Client._auth0Client.handleRedirectCallback();
        onRedirectCallback(appState);
      } catch (e) {
        SndAuth0Client.initAsync(domain, clientId, audience, onRedirectCallback);
      }

    } else {
      await SndAuth0Client._auth0Client.checkSession();
    }
  }

  static async getTokenSilentlyAsync() {
    return new Promise(async (resolve, reject) => {
      if (SndAuth0Client._isTokenValid()) {
        return resolve(this._accessToken);
      }

      try {
        SndAuth0Client._accessToken = await this._auth0Client.getTokenSilently();
        return resolve(this._accessToken);
      } catch (e) {
        return reject(e);
      }
    });
  }

  static _isTokenValid() {
    try {
      const accessToken = jwt.decode(this._accessToken);
      const currentTimestamp = Math.floor(Date.now() / 1000);
      return accessToken.exp >= currentTimestamp;
    } catch (e) {
      return false;
    }
  }

  static _hasAuthParams() {
    const CODE_RE = /[?&]code=[^&]+/;
    const ERROR_RE = /[?&]error=[^&]+/;
    // Auth0 verification code interacts with snd email confirmation query params, so we check pathname exactly.
    return !window.location.pathname.includes("/email-confirm") && CODE_RE.test(window.location.search) || ERROR_RE.test(window.location.search);;
  }
}
