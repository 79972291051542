import React from "react";

import { onlyNumberString } from "helpers/onlyNumberString";

import StyledTextField from "shared/StyledTextField";
import CustomCheckbox from "shared/CustomCheckbox";

import ExpertModeCard from "../../components/ExpertModeCard";

import "./styles.scss";

export const methods = [
  {
    label: "Standard",
    value: "0"
  },
  {
    label: "Accelerated",
    value: "1"
  }
];

const Budget = ({ input }) => {
  return (
    <ExpertModeCard id="budget-card" title="Budget" className="budget-card-wrapper">
      <StyledTextField
        label="Enter the average you want to spend each day"
        placeholder="US dollar"
        value={input?.value?.average}
        onChange={e => input?.onChange({ ...input?.value, average: onlyNumberString(e.target.value) })}
      />
      <div className="card-subtitle">Delivery method</div>
      <div className="methods-box">
        {methods?.map(val => (
          <CustomCheckbox
            key={val.value}
            onChange={() => input?.onChange({ ...input?.value, method: val.value })}
            checked={val.value === input?.value?.method}
            isTypeRadio
            label={val.label}
          />
        ))}
      </div>
      <div className="card-text-box">
        For the month, you won't pay more than your daily budget times the average number of days in a month. Some days
        you might spend more or less than your daily budget
      </div>
    </ExpertModeCard>
  );
};

export default Budget;
