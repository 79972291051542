import React, { useEffect, useState } from "react";
import { Button, Popover } from "@material-ui/core";

import { SETTINGS } from "pathnameVariables";
import { campaignsTableColumns } from "constants/tableColumns";

import ArrowIcon from "assets/images/grayBottomArrow.svg";

import StatisticCard from "../../components/StatisticCard";

import "./styles.scss";

const ColumnBtn = ({ state, setState, options }) => {
  const [open, setOpen] = useState(null);
  return (
    <>
      <Button onClick={e => setOpen(e.target)} className={open ? "active" : ""}>
        <div>{state?.label}</div>
        <img src={ArrowIcon} alt="" />
      </Button>
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={() => setOpen(null)}
        className="overview-campaign-table-popover"
        anchorOrigin={{
          vertical: 20,
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
        {options?.map(val => (
          <Button
            key={val.value}
            className={`option-item ${val.value === state.value ? "active" : ""}`}
            onClick={() => {
              setState(val);
              setOpen(null);
            }}
          >
            {val.label}
          </Button>
        ))}
      </Popover>
    </>
  );
};

const CampaignsList = ({ data }) => {
  const tableColumns = campaignsTableColumns.filter(val => val.value !== "name");
  const [column1, setColumn1] = useState(tableColumns[0]);
  const [column2, setColumn2] = useState(tableColumns[1]);
  const [column3, setColumn3] = useState(tableColumns[2]);
  const [tableRows, setTableRows] = useState([]);

  useEffect(() => {
    setTableRows(
      data?.map(val => [
        val?.columns?.find(value => value.columnName === "name")?.label,
        val?.columns?.find(value => value.columnName === column1.value)?.label,
        val?.columns?.find(value => value.columnName === column2.value)?.label,
        val?.columns?.find(value => value.columnName === column3.value)?.label
      ])
    );
  }, [data, column1, column2, column3]);

  return (
    <StatisticCard id="campaigns" title="Campaigns" btnTitle="Settings" href={SETTINGS}>
      <div className="campaigns-list-wrapper">
        <div className="campaigns-list-head">
          <div className="column">Name</div>
          <ColumnBtn
            options={tableColumns?.filter(val => val.value !== column2.value && val.value !== column3.value)}
            setState={setColumn1}
            state={column1}
          />
          <ColumnBtn
            options={tableColumns?.filter(val => val.value !== column1.value && val.value !== column3.value)}
            setState={setColumn2}
            state={column2}
          />
          <ColumnBtn
            options={tableColumns?.filter(val => val.value !== column2.value && val.value !== column1.value)}
            setState={setColumn3}
            state={column3}
          />
        </div>
        <div className="campaigns-list-body">
          {tableRows?.map((val, i) => (
            <div key={i.toString()} className="body-row">
              {val?.map((value, index) => (
                <div key={`${value}${index}`} className="body-column">
                  <span>{value}</span>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </StatisticCard>
  );
};

export default CampaignsList;
