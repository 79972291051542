import React, { useEffect } from "react";
import propTypes from "prop-types";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import Loader from "../Loader";
import { authorizeAsync } from "./actions";
import { getMeSelector } from "./selectors";
import "./styles.scss";

const bc = "auth-guard-container";

const Auth = ({ domain, clientId, audience, children, me, authorizeActionAsync }) => {
  useEffect(() => {
    authorizeActionAsync(domain, clientId, audience);
  }, [audience, authorizeActionAsync, clientId, domain]);

  return me.isAuthLoading ? (
    <div className={bc}>
      <Loader isLoading size={100} />
    </div>
  ) : (
    children
  );
};
Auth.propTypes = {
  me: propTypes.any.isRequired,
  audience: propTypes.string.isRequired,
  clientId: propTypes.string.isRequired,
  domain: propTypes.string.isRequired,
  authorizeActionAsync: propTypes.func.isRequired,
  children: propTypes.element.isRequired
};

const mapStateToProps = createStructuredSelector({
  me: getMeSelector
});

const mapDispatchToProps = dispatch => ({
  authorizeActionAsync: (domain, clientId, audience) => {
    dispatch(
      authorizeAsync.request({
        domain,
        clientId,
        audience
      })
    );
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Auth);
