import React from "react";
import CustomCheckbox from "shared/CustomCheckbox";
import "./styles.scss";

export const gender = [
  {
    label: "Male",
    value: "male"
  },
  {
    label: "Female",
    value: "female"
  },
  {
    label: "Unknown",
    value: "unknownGender"
  }
];

export const age = [
  {
    label: "18-24",
    value: "age18"
  },
  {
    label: "25-34",
    value: "age25"
  },
  {
    label: "35-44",
    value: "age35"
  },
  {
    label: "45-54",
    value: "age45"
  },
  {
    label: "55-64",
    value: "age55"
  },
  {
    label: "65+",
    value: "age65"
  },
  {
    label: "Unknown",
    value: "unknownAge"
  }
];

export const parental = [
  {
    label: "Not a parent",
    value: "notParental"
  },
  {
    label: "Parent",
    value: "parental"
  },
  {
    label: "Unknown",
    value: "unknownStatus"
  }
];

export const income = [
  {
    label: "Top 10%",
    value: "income10"
  },
  {
    label: "11-20%",
    value: "income20"
  },
  {
    label: "21-30%",
    value: "income30"
  },
  {
    label: "31-40%",
    value: "income40"
  },
  {
    label: "41-50%",
    value: "income50"
  },
  {
    label: "Lower 50%",
    value: "lower50"
  },
  {
    label: "Unknown",
    value: "unknownIncome"
  }
];

const Demographics = ({ input }) => {
  const handleOnChange = (val, key) => {
    if (input?.value[key].includes(val)) {
      return input?.onChange({ ...input?.value, [key]: input?.value[key].filter(value => value !== val) });
    }
    return input?.onChange({ ...input?.value, [key]: [...input?.value[key], val] });
  };
  return (
    <div className="demographics-card-wrapper">
      <div className="demographics-card-head">
        <div className="demographics-card-title">Demographics</div>
        <div className="card-main-subtitle">
          Reach people based on age, gender, parental status, or household income
        </div>
      </div>
      <div className="table-scroll-box">
        <div className="demographics-card-table">
          <div className="demographics-card-table-column">
            <div className="column-title">Gender</div>
            {gender?.map(val => (
              <CustomCheckbox
                label={val.label}
                checked={input?.value?.genders.includes(val.value)}
                onChange={() => handleOnChange(val.value, "genders")}
              />
            ))}
          </div>
          <div className="demographics-card-table-column">
            <div className="column-title">Age</div>
            {age?.map(val => (
              <CustomCheckbox
                label={val.label}
                checked={input?.value?.ageRanges.includes(val.value)}
                onChange={() => handleOnChange(val.value, "ageRanges")}
              />
            ))}
          </div>
          <div className="demographics-card-table-column">
            <div className="column-title">Parental status</div>
            {parental?.map(val => (
              <CustomCheckbox
                label={val.label}
                checked={input?.value?.parentalStatus.includes(val.value)}
                onChange={() => handleOnChange(val.value, "parentalStatus")}
              />
            ))}
          </div>
          <div className="demographics-card-table-column">
            <div className="column-title">Parental status</div>
            {income?.map(val => (
              <CustomCheckbox
                label={val.label}
                checked={input?.value?.incomeRanges.includes(val.value)}
                onChange={() => handleOnChange(val.value, "incomeRanges")}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Demographics;
