import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import ReactJson from "react-json-view";
import { connect } from "react-redux";
import moment from "moment";

import Admin from "services/admin";

import DashboardContent from "shared/DashboardContent";
import AdminTable from "shared/AdminTable";
import Loader from "shared/Loader";
import ModalWrapper from "shared/ModalWrapper";

import CloseIcon from "assets/images/closeBlue.svg";
import EmptyIcon from "assets/images/smallEmptyIcon.svg";

import "./styles.scss";

const columnsWidth = ["90px", "250px", "250px", "200px", "90px"];
const mobileColumnsWidth = ["90px", "150px", "150px", "180px", "90px"];

const initialColumns = [
  {
    label: "Id",
    sort: false,
    isFixedWidth: true,
    direction: "",
    sortQuery: "campaign.id"
  },
  {
    label: "Campaign name",
    sort: false,
    direction: "",
    sortQuery: "campaign.name"
  },
  {
    label: "User",
    sort: false,
    direction: "",
    sortQuery: "user.email"
  },
  {
    label: "Creation date",
    sort: true,
    direction: "DESC",
    sortQuery: "campaign.createdAt"
  },
  {
    label: "Status",
    sort: false,
    isFixedWidth: true,
    direction: "",
    sortQuery: "campaign.isActive"
  }
];

const AdminCampaigns = ({ searchInputValue, clientWidth }) => {
  const [isLoading, stIsLoading] = useState(true);
  const [columns, setColumns] = useState(initialColumns);
  const [rows, setRows] = useState(null);
  const [campaignsCount, setCampaignsCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [openCampaignInfoModal, setOpenCampaignInfoModal] = useState(false);
  const [isModalError, setIsModalError] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [campaignJson, setCampaignJson] = useState({});

  const handleGetCampaigns = () => {
    stIsLoading(true);
    const queryData = {
      amount: 30,
      page: currentPage,
      email: searchInputValue,
      sortBy: columns.find(val => val.sort).sortQuery,
      direction: columns.find(val => val.sort).direction
    };
    Admin.getAllCampaigns(queryData).then(data => {
      setCampaignsCount(data?.count);
      setRows(
        data?.campaigns?.map(val => ({
          data: val,
          columns: [
            { label: val.campaign.id, isBold: true, isFixedWidth: true },
            { label: val.campaign.name },
            { label: val.user.email },
            { label: moment(val.campaign.createdAt).format("MMM DD, YYYY HH:mm:ss") },
            {
              label: val.campaign.isActive ? "Active" : "Inactive",
              isStatus: true,
              status: val.campaign.isActive,
              isFixedWidth: true
            }
          ]
        }))
      );
      stIsLoading(false);
    });
  };

  useEffect(() => {
    handleGetCampaigns();
  }, [columns, currentPage, searchInputValue]);

  const handleSort = index => {
    setColumns(
      columns?.map((val, i) => {
        if (index === i) {
          return { ...val, sort: true, direction: val.direction === "DESC" ? "ASC" : "DESC" };
        }
        return { ...val, sort: false, direction: "" };
      })
    );
  };

  const getCampaignJson = campaignId => {
    setOpenCampaignInfoModal(true);
    setIsModalLoading(true);
    Admin.getCampaignJson(campaignId)
      .then(data => {
        setCampaignJson(data);
        setIsModalError(false);
      })
      .catch(() => setIsModalError(true))
      .finally(() => setIsModalLoading(false));
  };

  return (
    <DashboardContent>
      {!rows ? (
        <Loader empty isDashboardContent />
      ) : (
        <>
          <AdminTable
            fullHeight
            rows={rows || []}
            columns={columns}
            columnsWidth={clientWidth > 767 ? columnsWidth : mobileColumnsWidth}
            onColumnClick={handleSort}
            onRowClick={row => getCampaignJson(row?.campaign?.id)}
            isLoading={isLoading}
            pagesAmount={Math.ceil(campaignsCount / 30)}
            onPrevClick={() => setCurrentPage(currentPage - 1)}
            onPageClick={page => setCurrentPage(page)}
            onNextClick={() => setCurrentPage(currentPage + 1)}
            currentPage={currentPage}
          />
          <ModalWrapper open={openCampaignInfoModal} handleClose={() => setOpenCampaignInfoModal(false)}>
            <div className="campaign-details-modal-content">
              <div className="campaign-details-modal-head">
                Campaign details
                <Button onClick={() => setOpenCampaignInfoModal(false)}>
                  <img src={CloseIcon} alt="" />
                </Button>
              </div>
              <div className="code-box">
                {isModalLoading ? (
                  <Loader empty isDashboardContent />
                ) : (
                  <>
                    {isModalError ? (
                      <div className="error-box">
                        <img src={EmptyIcon} alt="" />
                        <div>Sorry, we are unable to upload data for this campaign.</div>
                        <div className="error-subtitle">Please try again later</div>
                      </div>
                    ) : (
                      <ReactJson src={campaignJson} />
                    )}
                  </>
                )}
              </div>
            </div>
          </ModalWrapper>
        </>
      )}
    </DashboardContent>
  );
};

const mapStateToProps = state => ({
  clientWidth: state.userProfile.clientWidth,
  searchInputValue: state.admin.searchInputValue
});

export default connect(mapStateToProps)(AdminCampaigns);
