import { ApiService } from "services/ApiService";

export default class UserProfileInfo {
  static async getUserInfo() {
    return ApiService._requestAsync({
      url: `/users/user-data`,
      method: "GET"
    });
  }

  static async updateUserInfo(data) {
    return ApiService._requestAsync({
      url: `/users/user-data`,
      method: "PUT",
      data: {
        role: 1,
        ...data
      }
    });
  }

  static async getUserInfoById(userId) {
    return ApiService._requestAsync({
      url: `/users/${userId}`,
      method: "GET"
    });
  }

  static async getVirtualCredit() {
    return ApiService._requestAsync({
      url: "/users/virtual-credit",
      method: "GET"
    });
  }

  static async updatePassword(data) {
    return ApiService._requestAsync({
      url: "/users/change-password",
      method: "PUT",
      data
    });
  }

  static async getTransactionHistory() {
    return ApiService._requestAsync({
      url: "/users/history-transaction",
      method: "GET"
    });
  }

  static async payForSubscription() {
    return ApiService._requestAsync({
      url: "/users/pay-for-subscription",
      method: "POST"
    });
  }

  static async addPaymentCard(data) {
    return ApiService._requestAsync({
      url: `/users/cards`,
      method: "POST",
      data
    });
  }

  static async getPaymentCards() {
    return ApiService._requestAsync({
      url: `/users/cards`,
      method: "GET"
    });
  }

  static async removePaymentCard(cardId) {
    return ApiService._requestAsync({
      url: `/users/cards`,
      method: "DELETE",
      data: {
        id: cardId
      }
    });
  }

  static async setDefaultPaymentCard(cardId) {
    return ApiService._requestAsync({
      url: `/users/cards/set-primary`,
      method: "PUT",
      data: {
        id: cardId
      }
    });
  }

  static async getNotificationSettings() {
    return ApiService._requestAsync({
      url: `/accounts/me/notification-settings`,
      method: "GET"
    });
  }

  static async updateNotificationSettings(data) {
    return ApiService._requestAsync({
      url: `/accounts/me/notification-settings`,
      method: "PUT",
      data
    });
  }

  static async setTheme(data) {
    return ApiService._requestAsync({
      url: `/users/change-theme`,
      method: "PUT",
      data: {
        isDarkTheme: data
      }
    });
  }
}
