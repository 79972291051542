import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { withRouter } from "react-router-dom";
import hasNoAccess from "../../assets/images/hasNoAccess.png";
import Logo from "../../shared/Logo";
import useStyles from "./style";

const Confirm = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <CssBaseline />
      <div className={classes.linearGradientBG} />
      <div className={classes.paper}>
        <>
          <Logo />
          <div>
            <img className={classes.image} src={hasNoAccess} alt="email not confirmed" />
          </div>
          <div className={classes.message}>Have no access</div>
          <div className={classes.description}>Sorry you do not have access to the web address you entered</div>
        </>
      </div>
    </div>
  );
};

export default withRouter(Confirm);
