import React, { useEffect, useState } from "react";

import { DEVICES } from "pathnameVariables";
import { diagramSelectOptions } from "constants/tableColumns";
import { colors } from "constants/chartColors";
import { allCampaignsData } from "helpers/statisticHelpFunctions";

import CustomLineBarChart from "shared/CustomLineBarChart";

import StatisticCard from "../../components/StatisticCard";
import TabSelection from "../../components/TabSelection";

import "./styles.scss";

const deviceTabs = [
  {
    value: "device",
    label: "Devices"
  },
  {
    value: "os",
    label: "Operating System"
  }
];

const DeviceAndOs = ({ devicesStatistic, osStatistic }) => {
  const [convertData, setConvertData] = useState(null);
  const [currentTab, setCurrentTab] = useState(deviceTabs[1]);
  const [firstSelectOption, setFirstSelectOption] = useState(diagramSelectOptions[0]);
  const [secondSelectOption, setSecondSelectOption] = useState(diagramSelectOptions[1]);
  const [currentData, setCurrentData] = useState([]);

  useEffect(() => {
    if (devicesStatistic && osStatistic) {
      setConvertData({
        device: allCampaignsData(devicesStatistic, "device_type", "deviceTypeStatistic").map((val, i) => ({
          label: val.label.replace(val.label[0], val.label[0].toUpperCase()),
          color: colors[i],
          ...val
        })),
        os: allCampaignsData(osStatistic, "operating_system_family_name").map((val, i) => ({
          color: colors[i],
          ...val
        }))
      });
    }
  }, [devicesStatistic, osStatistic]);

  useEffect(() => {
    if (convertData) {
      setCurrentData(
        convertData[currentTab?.value].map(val => ({
          ...val,
          value1: val[firstSelectOption?.value],
          value2: val[secondSelectOption?.value]
        }))
      );
    }
  }, [convertData, firstSelectOption, secondSelectOption, currentTab]);

  return (
    <StatisticCard
      title="Devices and OS"
      id="device-and-os"
      btnTitle="Devices"
      className="device-card"
      href={DEVICES}
      isEmpty={convertData && !convertData?.device?.length && !convertData?.os?.length}
      secondHeaderContent={<TabSelection tabs={deviceTabs} onChange={val => setCurrentTab(val)} value={currentTab} />}
    >
      <CustomLineBarChart
        setTypeState={setFirstSelectOption}
        typeOptions={diagramSelectOptions?.filter(val => secondSelectOption.value !== val.value)}
        typeState={firstSelectOption}
        data={currentData.map(val => ({ ...val, value: val.value1 }))}
      />
      <CustomLineBarChart
        setTypeState={setSecondSelectOption}
        typeOptions={diagramSelectOptions?.filter(val => firstSelectOption.value !== val.value)}
        typeState={secondSelectOption}
        data={currentData.map(val => ({ ...val, value: val.value2 }))}
        withDataLabels
      />
    </StatisticCard>
  );
};

export default DeviceAndOs;
