import React, { useState } from "react";
import { Button, SwipeableDrawer } from "@material-ui/core";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { ContextMenuTrigger } from "react-contextmenu";

import {
  OVERVIEW,
  AGE,
  GENDER,
  CAMPAIGNS,
  AD_PLACEMENTS,
  LOCATIONS,
  AD_SCHEDULE,
  DEVICES,
  OPERATING_SYSTEM,
  NETWORKS,
  PIXELS,
  EXPERT_GOAL,
  SETTINGS,
  ADMIN_CAMPAIGNS,
  ADMIN_USERS,
  ADMIN_CONFIGURATIONS,
  SUB_USERS,
  BEGINNER_GOAL
} from "pathnameVariables";
import { setStatisticParams, setOpenMobileMenu, getCampaignsList } from "redux/actions/campaigns";
import { parseCampaignStatus } from "helpers/parseCampaignStatus";
import { setOpenSubUserModal } from "redux/actions/admin";

import SortArrows from "assets/images/sortArrows.svg";

import Loader from "shared/Loader/localLoader";
import ProdSwitcher from "shared/SideMenuComponents/ProdSwitcher";
import MenuLink from "shared/SideMenuComponents/MenuLink";

import CampaignContextMenu from "./CampaignContextMenu";

import "./styles.scss";

const DashboardSideMenuWrapper = ({
  campaignsList,
  handleSetStatisticParams,
  me,
  handleSetOpenSubUserModal,
  statisticParams,
  isOpenMobileMenu,
  handleOpenMobileMenu,
  clientWidth,
  handleGetCampaigns
}) => {
  const { push } = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [isShowCampaigns, setIsShowCampaigns] = useState(false);
  const [sortByStatus, setSortByStatus] = useState(false);

  const sideMenuContent = () => (
    <div className="dashboard-side-menu-wrapper">
      <div className={`side-menu-second-wrapper ${isShowCampaigns ? "active" : ""}`}>
        <div className="campaigns-list-wrapper">
          <div className="campaigns-list-content">
            {isLoading ? (
              <Loader />
            ) : (
              <>
                <Button className="sort-btn" onClick={() => setSortByStatus(!sortByStatus)}>
                  Sort by status
                  <img src={SortArrows} alt="" />
                </Button>
                <div className="campaigns-list">
                  {campaignsList
                    ?.sort((a, b) => {
                      const getParam = param => parseCampaignStatus(param).statusCode;
                      return sortByStatus ? getParam(a) - getParam(b) : getParam(b) - getParam(a);
                    })
                    .map(val => (
                      <React.Fragment key={val.id}>
                        <ContextMenuTrigger id={val.id}>
                          <Button
                            className={`campaign-item-btn ${statisticParams?.campaign?.id === val.id ? "active" : ""}`}
                            onClick={() => {
                              handleOpenMobileMenu(false);
                              if (statisticParams?.campaign?.id === val.id) {
                                handleSetStatisticParams({ ...statisticParams, campaign: null });
                              } else {
                                handleSetStatisticParams({ ...statisticParams, campaign: val });
                              }
                            }}
                          >
                            <div className="status-icon-box">
                              <img src={parseCampaignStatus(val)?.icon} alt="" />
                            </div>
                            {val.name}
                          </Button>
                        </ContextMenuTrigger>
                        <CampaignContextMenu
                          campaign={val}
                          setIsLoading={setIsLoading}
                          getCampaigns={handleGetCampaigns}
                        />
                      </React.Fragment>
                    ))}
                </div>
              </>
            )}
          </div>
        </div>
        <div className="dashboard-side-menu-content">
          <div className="side-menu-switcher-wrapper">
            <ProdSwitcher />
          </div>
          <div className="dashboard-side-menu-content-scroll">
            <div className="side-menu-content-section">
              <MenuLink label="Overview" href={OVERVIEW} />
            </div>
            <div className="side-menu-content-section">
              <MenuLink
                label="Campaigns"
                href={CAMPAIGNS}
                onBtnClick={() => push(!campaignsList?.length ? BEGINNER_GOAL : EXPERT_GOAL)}
              />
              <MenuLink
                label="Demographics"
                subLinks={[
                  {
                    label: "Age",
                    href: AGE
                  },
                  {
                    label: "Gender",
                    href: GENDER
                  }
                ]}
              />
              <MenuLink label="Ad placements" href={AD_PLACEMENTS} />
              <MenuLink label="Locations" href={LOCATIONS} />
              <MenuLink label="Ad schedule" href={AD_SCHEDULE} />
              <MenuLink
                label="Devices"
                subLinks={[
                  {
                    label: "Devices",
                    href: DEVICES
                  },
                  {
                    label: "Operating System",
                    href: OPERATING_SYSTEM
                  },
                  {
                    label: "Networks",
                    href: NETWORKS
                  }
                ]}
              />
            </div>
            <div className="side-menu-content-section">
              <MenuLink label="Pixels" href={PIXELS} />
              <MenuLink label="Settings" href={SETTINGS} />
            </div>
            {me?.accountRole === "account-admin" && (
              <div className="side-menu-content-section">
                <MenuLink onBtnClick={() => handleSetOpenSubUserModal(true)} label="Users" href={SUB_USERS} />
              </div>
            )}
            {me?.role === 2 && (
              <div className="side-menu-content-section">
                <MenuLink
                  label="Admin"
                  subLinks={[
                    {
                      label: "Campaigns",
                      href: ADMIN_CAMPAIGNS
                    },
                    {
                      label: "Users",
                      href: ADMIN_USERS
                    },
                    {
                      label: "Configurations",
                      href: ADMIN_CONFIGURATIONS
                    }
                  ]}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <Button
        className={`open-companies-btn ${isShowCampaigns ? "active" : ""}`}
        onClick={() => setIsShowCampaigns(!isShowCampaigns)}
      />
    </div>
  );

  return (
    <>
      {clientWidth > 1060 ? (
        sideMenuContent()
      ) : (
        <SwipeableDrawer
          open={isOpenMobileMenu}
          anchor="left"
          onClose={() => handleOpenMobileMenu(false)}
          onOpen={() => handleOpenMobileMenu(true)}
          className="menu-drawer-wrapper"
        >
          {sideMenuContent()}
        </SwipeableDrawer>
      )}
    </>
  );
};

const mapStateToProps = state => ({
  clientWidth: state.userProfile.clientWidth,
  campaignsList: state.campaigns.campaignsList,
  statisticParams: state.campaigns.statisticParams,
  isOpenMobileMenu: state.campaigns.isOpenMobileMenu,
  me: state.me
});

const mapDispatchToProps = dispatch => ({
  handleGetCampaigns: payload => dispatch(getCampaignsList(payload)),
  handleSetOpenSubUserModal: payload => dispatch(setOpenSubUserModal(payload)),
  handleSetStatisticParams: payload => dispatch(setStatisticParams(payload)),
  handleOpenMobileMenu: payload => dispatch(setOpenMobileMenu(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardSideMenuWrapper);
