import React from "react";
import "./styles.scss";

const SettingsCard = ({ className, title, children }) => {
  return (
    <div className={`settings-card-wrapper ${className || ""}`}>
      {title && <div className="settings-card-title">{title}</div>}
      {children}
    </div>
  );
};

export default SettingsCard;
