import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import { connect } from "react-redux";

import { downloadFile } from "helpers/fileHelper";
import Admin from "services/admin";

import DashboardContent from "shared/DashboardContent";
import ModalWrapper from "shared/ModalWrapper";
import AdminTable from "shared/AdminTable";
import Loader from "shared/Loader";

import EditUserModal from "./EditUserModal";

import "./styles.scss";

const columnsWidth = ["90px", "220px", "130px", "130px", "110px", "220px", "120px", "260px"];
const mobileColumnsWidth = ["90px", "150px", "130px", "130px", "100px", "160px", "120px", "260px"];

const initialColumns = [
  {
    label: "Id",
    sort: true,
    isFixedWidth: true,
    direction: "DESC",
    sortQuery: "id"
  },
  {
    label: "Email",
    sort: false,
    direction: "",
    sortQuery: "email"
  },
  {
    label: "First Name",
    sort: false,
    direction: "",
    sortQuery: "firstName"
  },
  {
    label: "Last Name",
    sort: false,
    direction: "",
    sortQuery: "lastName"
  },
  {
    label: "Margin",
    isFixedWidth: true
  },
  {
    label: "Invited by"
  },
  {
    label: "Confirmed",
    isFixedWidth: true
  },
  {
    label: (
      <>
        <Button onClick={() => downloadFile(`/user-management/history-transaction-all`, `all-users.csv`)}>
          Download all users
        </Button>
      </>
    ),
    isAction: true,
    isFixedWidth: true
  }
];

const AdminUsers = ({ searchInputValue, clientWidth }) => {
  const [isLoading, stIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [columns, setColumns] = useState(initialColumns);
  const [rows, setRows] = useState(null);
  const [usersCount, setUsersCount] = useState(0);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const handleDownloadTransactions = user => {
    return downloadFile(`/user-management/history-transaction/${user.accountId}`, `account-${user.accountId}.csv`);
  };

  const handleGetUsers = () => {
    stIsLoading(true);
    const queryData = {
      amount: 30,
      page: currentPage,
      email: searchInputValue,
      sortBy: columns.find(val => val.sort).sortQuery,
      direction: columns.find(val => val.sort).direction
    };
    Admin.getAllUsers(queryData).then(data => {
      setUsersCount(data?.count);
      setRows(
        data?.users?.map(val => ({
          columns: [
            { label: val.id, isBold: true, isFixedWidth: true },
            { label: val.email },
            { label: val.firstName },
            { label: val.lastName },
            { label: val.accountMargin, isFixedWidth: true },
            { label: val.invitedBy || "Without invitation", isDisabled: !val.invitedBy },
            {
              label: val.confirmed ? "Confirmed" : "Unconfirmed",
              isStatus: true,
              status: val.confirmed,
              isFixedWidth: true
            },
            {
              label: (
                <>
                  <Button
                    onClick={() => {
                      setOpenEditModal(true);
                      setSelectedUser(val);
                    }}
                  >
                    Edit
                  </Button>
                  <Button onClick={() => handleDownloadTransactions(val)}>Download</Button>
                </>
              ),
              isAction: true,
              isFixedWidth: true
            }
          ]
        }))
      );
      stIsLoading(false);
    });
  };

  useEffect(() => {
    handleGetUsers();
  }, [columns, currentPage, searchInputValue]);

  const handleSort = index => {
    setColumns(
      columns?.map((val, i) => {
        if (index === i) {
          return { ...val, sort: true, direction: val.direction === "DESC" ? "ASC" : "DESC" };
        }
        return { ...val, sort: false, direction: "" };
      })
    );
  };

  return (
    <DashboardContent>
      {!rows ? (
        <Loader empty isDashboardContent />
      ) : (
        <>
          <AdminTable
            fullHeight
            rows={rows || []}
            columns={columns}
            columnsWidth={clientWidth > 767 ? columnsWidth : mobileColumnsWidth}
            onColumnClick={handleSort}
            isLoading={isLoading}
            pagesAmount={Math.ceil(usersCount / 30)}
            onPrevClick={() => setCurrentPage(currentPage - 1)}
            onPageClick={page => setCurrentPage(page)}
            onNextClick={() => setCurrentPage(currentPage + 1)}
            currentPage={currentPage}
          />
          <ModalWrapper handleClose={() => setOpenEditModal(false)} open={openEditModal}>
            <EditUserModal handleClose={() => setOpenEditModal(false)} selectedUser={selectedUser} />
          </ModalWrapper>
        </>
      )}
    </DashboardContent>
  );
};

const mapStateToProps = state => ({
  clientWidth: state.userProfile.clientWidth,
  searchInputValue: state.admin.searchInputValue
});

export default connect(mapStateToProps)(AdminUsers);
