import React from "react";
import { Button } from "@material-ui/core";

import SimpleBtn from "shared/SimpleBtn";
import StyledSelect from "shared/StyledSelect";

import RemoveIcon from "assets/images/removeIconBlue.svg";

import ExpertModeCard from "../../components/ExpertModeCard";

import "./styles.scss";

export const days = [
  {
    label: "All days",
    value: "9"
  },
  {
    label: "Mondays - Fridays",
    value: "8"
  },
  {
    label: "Saturdays - Sundays",
    value: "7"
  },
  {
    label: "Mondays",
    value: "0"
  },
  {
    label: "Tuesdays",
    value: "1"
  },
  {
    label: "Wednesdays",
    value: "2"
  },
  {
    label: "Thursdays",
    value: "3"
  },
  {
    label: "Fridays",
    value: "4"
  },
  {
    label: "Saturdays",
    value: "5"
  },
  {
    label: "Sundays",
    value: "6"
  }
];

export const time = [
  {
    label: "00:00",
    value: "0"
  },
  {
    label: "01:00",
    value: "1"
  },
  {
    label: "02:00",
    value: "2"
  },
  {
    label: "03:00",
    value: "3"
  },
  {
    label: "04:00",
    value: "4"
  },
  {
    label: "05:00",
    value: "5"
  },
  {
    label: "06:00",
    value: "6"
  },
  {
    label: "07:00",
    value: "7"
  },
  {
    label: "08:00",
    value: "8"
  },
  {
    label: "09:00",
    value: "9"
  },
  {
    label: "10:00",
    value: "10"
  },
  {
    label: "11:00",
    value: "11"
  },
  {
    label: "12:00",
    value: "12"
  },
  {
    label: "13:00",
    value: "13"
  },
  {
    label: "14:00",
    value: "14"
  },
  {
    label: "15:00",
    value: "15"
  },
  {
    label: "16:00",
    value: "16"
  },
  {
    label: "17:00",
    value: "17"
  },
  {
    label: "18:00",
    value: "18"
  },
  {
    label: "19:00",
    value: "19"
  },
  {
    label: "20:00",
    value: "20"
  },
  {
    label: "21:00",
    value: "21"
  },
  {
    label: "22:00",
    value: "22"
  },
  {
    label: "23:00",
    value: "23"
  }
];

const AdSchedule = ({ input }) => {
  const initialSchedule = { days: "9", from: "1", to: "23" };
  const handleUpdateSchedule = (key, value, index, e) => {
    return input?.onChange(input?.value.map((val, i) => (i === index ? { ...value, [key]: e.target.value } : val)));
  };
  return (
    <ExpertModeCard title="Ad schedule" className="ad-schedule-card-wrapper" isDefaultOpen={false}>
      <div className="card-subtitle">Days</div>
      {input?.value?.map((value, index) => (
        <div key={index.toString()} className="card-field-set">
          <StyledSelect
            className="long-field"
            options={days}
            value={value?.days}
            onChange={e => handleUpdateSchedule("days", value, index, e)}
          />
          <StyledSelect
            className="short-field"
            options={time}
            value={value?.from}
            onChange={e => handleUpdateSchedule("from", value, index, e)}
          />
          <div className="text-between-fields">to</div>
          <StyledSelect
            className="short-field"
            options={time.slice(time.indexOf(value?.from) + 1)}
            value={value?.to}
            onChange={e => handleUpdateSchedule("to", value, index, e)}
          />
          {input?.value?.length > 1 && (
            <Button
              className="remove-btn"
              onClick={() => input?.onChange(input?.value.filter((val, i) => i !== index))}
            >
              <img src={RemoveIcon} alt="" />
            </Button>
          )}
        </div>
      ))}
      <SimpleBtn onClick={() => input?.onChange([...input?.value, initialSchedule])}>+ Add</SimpleBtn>
      <div className="card-text-box">Based on your current time zone</div>
    </ExpertModeCard>
  );
};

export default AdSchedule;
