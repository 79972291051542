import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button } from "@material-ui/core";

import ArrowLeft from "assets/images/arrowLeftBlue.svg";
import ArrowLeftWhite from "assets/images/arrowLeftWhite.svg";
import ArrowRight from "assets/images/arrowRightBlue.svg";
import ArrowRightWhite from "assets/images/arrowRightWhite.svg";

import "./styles.scss";

const TablePagination = ({ onPrevClick, onNextClick, onPageClick, pagesAmount = 0, currentPage, userInfo }) => {
  const [pagesArr, setPagesArr] = useState(new Array(pagesAmount).fill(0));

  useEffect(() => {
    const pages = pagesAmount >= 4 ? pagesAmount : 4;
    setPagesArr(new Array(pages).fill(0));
  }, [pagesAmount]);

  return (
    <div className="table-pagination-wrapper">
      <Button disabled={currentPage === 1} onClick={onPrevClick}>
        <img src={userInfo?.isDarkTheme ? ArrowLeftWhite : ArrowLeft} alt="" />
      </Button>
      <div className="pages-btn-box">
        {pagesArr?.map((v, i) => (
          <Button
            disabled={i + 1 > pagesAmount}
            className={currentPage === i + 1 ? "active" : ""}
            onClick={() => onPageClick(i + 1)}
          >
            {i + 1}
          </Button>
        ))}
      </div>
      <Button disabled={currentPage === pagesAmount} onClick={onNextClick}>
        <img src={userInfo?.isDarkTheme ? ArrowRightWhite : ArrowRight} alt="" />
      </Button>
    </div>
  );
};

const mapStateToProps = state => ({
  userInfo: state.userProfile.userInfo
});

export default connect(mapStateToProps)(TablePagination);
