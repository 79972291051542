import { ApiService } from "services/ApiService";
import moment from "moment";

export default class CampaignsService {
  static async getCampaignsList() {
    return ApiService._requestAsync({
      url: `/campaigns/all-data`,
      method: "GET"
    });
  }

  static async getCampaignData(id) {
    return ApiService._requestAsync({
      url: `/campaigns/all-data/${id}`,
      method: "GET"
    });
  }

  static async getCampaignsStatistic(statisticType, statisticParams, query) {
    return ApiService._requestAsync({
      url: `/api/v2/dashboard/statistic-${statisticType}?start=${statisticParams?.startDate?.getTime("en-US", {
        timeZone: "UTC"
      })}&end=${statisticParams?.endDate?.getTime("en-US", { timeZone: "UTC" })}${
        statisticParams?.campaign?.id ? `&campaignId=${statisticParams?.campaign?.id}` : ""
      }&filter=clicks&filter=convs_rate&filter=cpm&filter=imps&filter=total_cost${query || ""}`,
      method: "GET"
    });
    // return ApiService._requestAsync({
    //   url: `/api/v2/dashboard/statistic-${statisticType}?start=${new Date("Wed Dec 01 2019 00:00:00 GMT+0300").getTime()}&end=${new Date("Wed Jun 01 2020 00:00:000 GMT+0300").getTime()}${
    //     statisticParams?.campaign?.id ? `&campaignId=${statisticParams?.campaign?.id}` : ""
    //   }&filter=clicks&filter=convs_rate&filter=cpm&filter=imps&filter=total_cost${query || ""}`,
    //   method: "GET"
    // });
  }

  static async enableCampaign(campaignId) {
    return ApiService._requestAsync({
      url: `/campaigns/timer/start`,
      method: "PUT",
      data: {
        campaignId
      }
    });
  }

  static async disableCampaign(campaignId) {
    return ApiService._requestAsync({
      url: `/campaigns/timer/stop`,
      method: "PUT",
      data: {
        campaignId
      }
    });
  }
}
