import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";

import { BEGINNER_UPLOAD_AD, BEGINNER_CONFIRM } from "pathnameVariables";
import BeginnerCampaignCreation from "services/beginnerCampaignCreation";
import UserProfileInfo from "services/userProfile";
import { setCardId, setPrevState, handlePrevStep } from "redux/actions/beginnerMode";

import Loader from "shared/Loader";
import ModalWrapper from "shared/ModalWrapper";

import { PaymentCard, AddCardBtn } from "pages/UserSettings/Balance";
import AddPaymentCard from "pages/UserSettings/Balance/AddPaymentCard";

import StepContent from "../components/StepContent";
import StepBtnBox from "../components/StepBtnBox";
import HelpFooter from "../components/HelpFooter";

import "./styles.scss";

const Payment = ({ campaignId, handleSetPrevState, handleSetCardId, cardId, userInfo, handlePreviousStep }) => {
  const { push } = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [paymentCardsList, setPaymentCardsList] = useState([]);
  const [openAddCardModal, setOpenAddCardModal] = useState(false);

  const handleBack = () => {
    setIsLoading(true);
    handlePreviousStep(() => push(BEGINNER_UPLOAD_AD));
  };

  const handleNextStep = () => {
    setIsLoading(true);
    const paymentData = {
      campaignId,
      cardId
    };
    BeginnerCampaignCreation.sendPayment(paymentData).then(data => {
      handleSetPrevState(data.campaignCreation.prevState);
      push(BEGINNER_CONFIRM);
      setIsLoading(false);
    });
  };

  const getPaymentCardsList = () => {
    UserProfileInfo.getPaymentCards().then(data => {
      setPaymentCardsList(data);
      if (data?.find(val => val.paymentMethodType === "primary")) {
        handleSetCardId(data?.find(val => val.paymentMethodType === "primary").id);
      }
      setIsLoading(false);
    });
  };

  const handleRemoveCard = card => {
    setIsLoading(true);
    UserProfileInfo.removePaymentCard(card.id).then(() => getPaymentCardsList());
  };

  const handleMakeCardPrimary = card => {
    handleSetCardId(card.id);
    UserProfileInfo.setDefaultPaymentCard(card.id).then(() => getPaymentCardsList());
  };

  useEffect(() => {
    getPaymentCardsList();
  }, []);

  return (
    <div className="beginner-payment-step-wrapper">
      {isLoading ? (
        <Loader empty isDashboardContent />
      ) : (
        <div className="payment-content-wrapper">
          <StepContent title="Confirm payment method" className="payment-content">
            <div className="payment-card-list">
              {paymentCardsList?.map(val => (
                <PaymentCard
                  data={val}
                  key={val.id}
                  onClick={handleMakeCardPrimary}
                  onRemoveClick={handleRemoveCard}
                  isPrimary={val.id === cardId}
                />
              ))}
              <AddCardBtn onClick={() => setOpenAddCardModal(true)} />
              <ModalWrapper handleClose={() => setOpenAddCardModal(false)} open={openAddCardModal}>
                <AddPaymentCard
                  handleClose={() => setOpenAddCardModal(false)}
                  userInfo={userInfo}
                  getPaymentCardsList={getPaymentCardsList}
                />
              </ModalWrapper>
            </div>
            <StepBtnBox>
              <Button onClick={handleBack}>Back</Button>
              <Button
                disabled={!paymentCardsList?.find(val => val.paymentMethodType === "primary")}
                onClick={handleNextStep}
              >
                Confirm
              </Button>
            </StepBtnBox>
            <div className="payment-description">
              You'll see a temporary $50 authorization on your card, which is typically removed in a week.
              <br />
              <br />
              You’ll be charged every 30 days or when reach your billing threshold, whichever comes first.{" "}
              <a target="_blank" href="https://help.datasys.com">
                Learn more
              </a>
              <br />
              <br />
              By countinuing, you agree to the{" "}
              <a target="_blank" href="https://searched.com/terms">
                Searched Terms
              </a>
              . They include whatever legal text your lawyers will place here.
            </div>
          </StepContent>
          <HelpFooter />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  campaignId: state.beginnerMode.campaignId,
  cardId: state.beginnerMode.cardId,
  userInfo: state.userProfile.userInfo
});

const mapDispatchToProps = dispatch => ({
  handleSetPrevState: payload => dispatch(setPrevState(payload)),
  handlePreviousStep: payload => dispatch(handlePrevStep(payload)),
  handleSetCardId: payload => dispatch(setCardId(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(Payment);
