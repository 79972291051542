import { takeEvery, call, put } from "redux-saga/effects";
import { dsToast } from "shared/dsToast";
import CampaignsService from "services/campaigns";

function* handleGetCampaignsList() {
  try {
    const campaignsList = yield call(CampaignsService.getCampaignsList);
    yield put({ type: "SET_CAMPAIGNS_LIST", payload: campaignsList });
  } catch (e) {
    console.log(e);
    yield dsToast.error("We cannot get a list of your campaigns, please try again later");
  }
}

export default function* campaignsSagas() {
  yield takeEvery("GET_CAMPAIGNS_LIST", handleGetCampaignsList);
}
