export const campaignStatusVariants = [
  {
    status: "All",
    statusCode: 0
  },
  {
    status: "Blocked",
    statusCode: 1
  },
  {
    status: "Rejected",
    statusCode: 2
  },
  {
    status: "Running",
    statusCode: 3
  },
  {
    status: "Ready to run",
    statusCode: 4
  },
  {
    status: "Pending",
    statusCode: 5
  }
];