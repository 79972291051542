import React, { useEffect, useState } from "react";

import { CAMPAIGNS } from "pathnameVariables";
import { diagramSelectOptions } from "constants/tableColumns";
import { getAreaChartData } from "helpers/statisticHelpFunctions";

import DiagramSelect from "shared/DiagramSelect";
import StyledAreaChart from "shared/StyledAreaChart";

import StatisticCard from "../../components/StatisticCard";

const CampaignStatistic = ({ data }) => {
  const [campaignStatisticChart, setCampaignStatisticChart] = useState(null);
  const [campaignsBlueSelect, setCampaignsBlueSelect] = useState(diagramSelectOptions[0]);
  const [campaignsPinkSelect, setCampaignsPinkSelect] = useState(diagramSelectOptions[1]);

  useEffect(() => {
    if (data && campaignsBlueSelect && campaignsPinkSelect) {
      const expectedCampaignValue = {
        blue: campaignsBlueSelect,
        pink: campaignsPinkSelect
      };
      setCampaignStatisticChart(getAreaChartData(data, expectedCampaignValue, "statisticDetails"));
    }
  }, [data, campaignsBlueSelect, campaignsPinkSelect]);

  return (
    <StatisticCard
      withExpandAbility
      title="Campaigns"
      btnTitle="Campaigns"
      isEmpty={campaignStatisticChart && !campaignStatisticChart?.length}
      href={CAMPAIGNS}
      id="campaign-statistic"
      secondHeaderContent={
        <div className="data-selection-box">
          <DiagramSelect
            options={diagramSelectOptions?.filter(val => campaignsPinkSelect.value !== val.value)}
            value={campaignsBlueSelect}
            onChange={val => setCampaignsBlueSelect(val)}
          />
          <DiagramSelect
            options={diagramSelectOptions?.filter(val => campaignsBlueSelect.value !== val.value)}
            value={campaignsPinkSelect}
            onChange={val => setCampaignsPinkSelect(val)}
            pink
          />
        </div>
      }
    >
      <StyledAreaChart data={campaignStatisticChart || []} />
    </StatisticCard>
  );
};

export default CampaignStatistic;
