import React, { useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";

import { BEGINNER_BUDGET, BEGINNER_PAYMENT, BEGINNER_CONFIRM } from "pathnameVariables";
import BeginnerCampaignCreation from "services/beginnerCampaignCreation";
import { setUploadAd, setPrevState, handlePrevStep } from "redux/actions/beginnerMode";

import Loader from "shared/Loader";
import StyledTextField from "shared/StyledTextField";
import AdsDropFilesZone from "shared/AdsDropFilesZone";

import StepContent from "../components/StepContent";
import StepBtnBox from "../components/StepBtnBox";

import "./styles.scss";

const UploadAd = ({
  campaignId,
  adGroupId,
  handleSetPrevState,
  handleSetUploadAd,
  uploadAd,
  handlePreviousStep,
  me
}) => {
  const { push } = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [urlError, setUrlError] = useState("");

  const handleBack = () => {
    setIsLoading(true);
    handlePreviousStep(() => push(BEGINNER_BUDGET));
  };

  const handleNextStep = () => {
    setIsLoading(true);
    const uploadAdData = {
      campaignId,
      adGroupId,
      url: uploadAd.url,
      files: [...uploadAd.files, ...uploadAd.customFiles]
    };
    BeginnerCampaignCreation.sendUploadAd(uploadAdData).then(data => {
      handleSetPrevState(data.campaignCreation.prevState);
      push(me?.accountRole === "account-user" ? BEGINNER_CONFIRM : BEGINNER_PAYMENT);
      setIsLoading(false);
    });
  };

  const checkOnHttps = e => {
    const { value } = e.target;
    const url = value.includes("https://") || value.includes("http://") ? value : `https://${value}`;
    return handleSetUploadAd({ ...uploadAd, url });
  };

  return (
    <div className="beginner-upload-ad-step-wrapper">
      {isLoading ? (
        <Loader empty isDashboardContent />
      ) : (
        <StepContent title="Upload an ad">
          <StyledTextField
            error={urlError}
            helperText={urlError}
            label="Final URL"
            placeholder="example.com"
            value={uploadAd?.url}
            onChange={e => {
              handleSetUploadAd({ ...uploadAd, url: e.target.value });
              setUrlError("");
            }}
            onBlur={checkOnHttps}
          />
          <div className="upload-ad-title">Most popular ads</div>
          <div className="upload-ad-subtitle">
            Please upload at least 5 ads or images. 89% of all ad impressions are made up of these 4 sizes
          </div>
          <AdsDropFilesZone onChange={handleSetUploadAd} rootObj={uploadAd} />
          <StepBtnBox>
            <Button onClick={handleBack}>Back</Button>
            <Button disabled={!uploadAd?.url || urlError} onClick={handleNextStep}>
              Confirm
            </Button>
          </StepBtnBox>
        </StepContent>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  me: state.me,
  campaignId: state.beginnerMode.campaignId,
  adGroupId: state.beginnerMode.adGroupId,
  uploadAd: state.beginnerMode.uploadAd
});

const mapDispatchToProps = dispatch => ({
  handleSetPrevState: payload => dispatch(setPrevState(payload)),
  handlePreviousStep: payload => dispatch(handlePrevStep(payload)),
  handleSetUploadAd: payload => dispatch(setUploadAd(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadAd);
