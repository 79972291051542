import React from "react";
import Loader from "shared/Loader";
import "./styles.scss";

const StepContent = ({ title, subtitle, children, className, isLoading }) => {
  return (
    <div className={`beginner-step-content-wrapper ${className || ""}`}>
      {isLoading ? (
        <Loader empty isDashboardContent />
      ) : (
        <>
          {title && <div className={`beginner-step-title ${!subtitle ? "only-title" : ""}`}>{title}</div>}
          {subtitle && <div className="beginner-step-subtitle">{subtitle}</div>}
          {children}
        </>
      )}
    </div>
  );
};

export default StepContent;
