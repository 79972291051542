import CircularProgress from "@material-ui/core/CircularProgress";
import React from "react";
import "./styles.scss";
import Loader from "../Loader";

const bc = "global-loader";

export default ({ isLoading = true }) =>
  isLoading ? (
    <div className={bc}>
      <Loader isLoading empty/>
    </div>
  ) : null;
