import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import { dsToast } from "shared/dsToast";
import { connect } from "react-redux";
import moment from "moment";

import Admin from "services/admin";
import { setOpenSubUserModal, setSubUsersList } from "redux/actions/admin";

import DashboardContent from "shared/DashboardContent";
import AdminTable from "shared/AdminTable";
import ModalWrapper from "shared/ModalWrapper";

import "./styles.scss";

const columnsWidth = ["90px", "220px", "130px", "130px", "210px", "130px", "150px"];
const mobileColumnsWidth = ["90px", "150px", "130px", "130px", "180px", "130px", "150px"];

const initialColumns = [
  {
    label: "Id",
    sort: false,
    isFixedWidth: true,
    direction: "",
    sortQuery: true
  },
  {
    label: "Email",
    sort: false,
    direction: "",
    sortQuery: true
  },
  {
    label: "First Name",
    sort: false,
    direction: "",
    sortQuery: true
  },
  {
    label: "Last Name",
    sort: false,
    direction: "",
    sortQuery: true
  },
  {
    label: "Invited at",
    sort: true,
    direction: "DESC",
    isFixedWidth: true,
    sortQuery: true
  },
  {
    label: "Invite status",
    sort: false,
    isFixedWidth: true,
    direction: "",
    sortQuery: true
  },
  {
    label: "",
    isFixedWidth: true
  }
];

const SubUsers = ({ handleSetOpenSubUserModal, handleSetSubUsersList, subUsersList, clientWidth }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isEmpty, setIsEmpty] = useState(false);
  const [columns, setColumns] = useState(initialColumns);
  const [rows, setRows] = useState([]);
  const [removedUser, setRemovedUser] = useState(null);

  useEffect(() => {
    if (subUsersList) {
      if (!subUsersList?.length) {
        setIsEmpty(true);
      } else {
        setIsEmpty(false);
      }
      setRows(
        subUsersList
          ?.sort((a, b) => (a.invitedAt < b.invitedAt ? 1 : -1))
          .map(val => ({
            columns: [
              { label: val.id, isFixedWidth: true, isBold: true },
              { label: val.email },
              { label: val.firstName },
              { label: val.lastName },
              { label: moment(val.invitedAt).format("MMM DD, YYYY HH:mm:ss"), isFixedWidth: true },
              {
                label: val.inviteStatus ? "Active" : "Pending",
                isStatus: true,
                status: val.inviteStatus,
                isFixedWidth: true
              },
              {
                label: (
                  <Button disabled={!val.inviteStatus} className="remove-btn" onClick={() => setRemovedUser(val)}>
                    Remove
                  </Button>
                ),
                isFixedWidth: true
              }
            ]
          }))
      );
    }
  }, [subUsersList]);

  const handleGetSubUsers = () => {
    setIsLoading(true);
    Admin.getSubUsers().then(data => {
      handleSetSubUsersList(data);
      setIsLoading(false);
    });
  };

  const handleRemoveUser = () => {
    setIsLoading(true);
    Admin.deleteSubUser(removedUser?.id)
      .then(() => {
        handleGetSubUsers();
        dsToast.success("User was deleted successfully");
      })
      .catch(() => dsToast.error("Sorry, something went wrong. Please try again later"))
      .finally(() => {
        setRemovedUser(null);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    handleGetSubUsers();
  }, []);

  const handleSort = index => {
    setColumns(
      columns?.map((val, i) => {
        if (index === i) {
          return { ...val, sort: true, direction: val.direction === "DESC" ? "ASC" : "DESC" };
        }
        return { ...val, sort: false, direction: "" };
      })
    );
  };

  useEffect(() => {
    const sortColumnIndex = columns?.findIndex(val => val.sort);
    setRows(
      rows?.sort((a, b) => {
        const val1 = a.columns[sortColumnIndex];
        const val2 = b.columns[sortColumnIndex];
        if (columns[sortColumnIndex]?.direction === "DESC") {
          return val1 < val2 ? 1 : -1;
        }
        return val1 > val2 ? 1 : -1;
      })
    );
  }, [columns]);

  return (
    <DashboardContent
      isLoading={isLoading}
      isEmpty={isEmpty}
      emptyTitle="You haven't invited anyone yet"
      emptyBtnLabel="Invite user"
      emptyBtnClick={() => handleSetOpenSubUserModal(true)}
    >
      <AdminTable
        fullHeight
        withoutPagination
        rows={rows || []}
        columns={columns}
        columnsWidth={clientWidth > 767 ? columnsWidth : mobileColumnsWidth}
        onColumnClick={handleSort}
      />
      <ModalWrapper handleClose={() => setRemovedUser(null)} open={removedUser}>
        <div className="confirm-remove-user-modal">
          <div className="remove-user-modal-title">Do you want to remove a user?</div>
          <div className="btn-box">
            <Button onClick={() => setRemovedUser(null)}>Discard</Button>
            <Button onClick={handleRemoveUser}>Remove user</Button>
          </div>
        </div>
      </ModalWrapper>
    </DashboardContent>
  );
};

const mapStateToProps = state => ({
  clientWidth: state.userProfile.clientWidth,
  subUsersList: state.admin.subUsersList
});

const mapDispatchToProps = dispatch => ({
  handleSetOpenSubUserModal: payload => dispatch(setOpenSubUserModal(payload)),
  handleSetSubUsersList: payload => dispatch(setSubUsersList(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(SubUsers);
