import React from "react";

import StyledTextField from "shared/StyledTextField";
import ExpertModeCard from "../../components/ExpertModeCard";

const Name = ({ input }) => {
  return (
    <ExpertModeCard id="name-card" title="Name">
      <StyledTextField value={input?.value} onChange={e => input?.onChange(e.target.value)} />
    </ExpertModeCard>
  );
};

export default Name;
