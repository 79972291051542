import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { dsToast } from "shared/dsToast";
import { Button } from "@material-ui/core";
import { connect } from "react-redux";

import { isValidUrl } from "helpers/validateFunctions";
import { setPrevState, setDescribeBusiness, setAdGroupId, handlePrevStep } from "redux/actions/beginnerMode";
import { BEGINNER_GOAL, BEGINNER_LOCATION } from "pathnameVariables";
import BeginnerCampaignCreation from "services/beginnerCampaignCreation";

import StyledTextField from "shared/StyledTextField";

import StepContent from "../components/StepContent";
import BeginnerCard from "../components/BeginnerCard";
import StepBtnBox from "../components/StepBtnBox";

import "./styles.scss";

const DescribeBusiness = ({
  campaignId,
  describeBusiness,
  handleSetPrevState,
  handleSetDescribeBusiness,
  handleSetAdGroupId,
  handlePreviousStep
}) => {
  const { push } = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [nameError, setNameError] = useState("");
  const [urlError, setUrlError] = useState("");

  const handleBack = () => {
    setIsLoading(true);
    handlePreviousStep(() => push(BEGINNER_GOAL));
  };

  const checkOnHttps = e => {
    const { value } = e.target;
    if (value) {
      const url = value.includes("https://") || value.includes("http://") ? value : `https://${value}`;
      if (isValidUrl(url)) {
        handleSetDescribeBusiness({ ...describeBusiness, websiteUrl: url });
      } else {
        setUrlError("Wrong URL format");
      }
    }
  };

  const handleNextStep = () => {
    if (urlError) {
      return dsToast.error("Wrong URL format");
    }
    setIsLoading(true);
    const data = {
      campaignId: Number(campaignId),
      name: describeBusiness?.name,
      websiteUrl: describeBusiness?.websiteUrl
    };
    return BeginnerCampaignCreation.sendDescribeBusiness(data)
      .then(val => {
        handleSetAdGroupId(val.adGroup.id);
        handleSetPrevState(val.campaignCreation.prevState);
        push(BEGINNER_LOCATION);
      })
      .catch(() => dsToast.error("Sorry, something went wrong. Please try again later"))
      .finally(() => setIsLoading(false));
  };

  return (
    <StepContent
      className="describe-business-wrapper"
      title="Describe your business"
      subtitle="The info will be used to create an ad that reaches the right customer"
      isLoading={isLoading}
    >
      <BeginnerCard>
        <StyledTextField
          error={nameError}
          helperText={nameError}
          label="Business name"
          value={describeBusiness?.name}
          onChange={e => {
            handleSetDescribeBusiness({ ...describeBusiness, name: e.target.value });
            setNameError("");
          }}
          onBlur={e => {
            if (e.target.value && e.target.value.length < 3) {
              setNameError("Business name must be longer than or equal to 3 characters");
            }
          }}
        />
        <div className="name-counter">{`${describeBusiness?.name?.length} / 120`}</div>
        <StyledTextField
          error={urlError}
          helperText={urlError}
          label="Business website"
          placeholder="www.example.com"
          value={describeBusiness?.websiteUrl}
          onChange={e => {
            setUrlError("");
            handleSetDescribeBusiness({ ...describeBusiness, websiteUrl: e.target.value });
          }}
          onBlur={checkOnHttps}
        />
      </BeginnerCard>
      <StepBtnBox>
        <Button onClick={handleBack}>Back</Button>
        <Button
          disabled={!describeBusiness?.name || urlError || nameError || !describeBusiness?.websiteUrl || !campaignId}
          onClick={handleNextStep}
        >
          Confirm
        </Button>
      </StepBtnBox>
    </StepContent>
  );
};

const mapStateToProps = state => ({
  campaignId: state.beginnerMode.campaignId,
  describeBusiness: state.beginnerMode.describeBusiness
});

const mapDispatchToProps = dispatch => ({
  handleSetPrevState: payload => dispatch(setPrevState(payload)),
  handleSetAdGroupId: payload => dispatch(setAdGroupId(payload)),
  handlePreviousStep: payload => dispatch(handlePrevStep(payload)),
  handleSetDescribeBusiness: payload => dispatch(setDescribeBusiness(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(DescribeBusiness);
