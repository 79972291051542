import React from "react";
import HeatMap from "react-heatmap-grid";
import "./styled.scss";

const StyledHeatMapChart = ({ yLabelWidth = 30, xLabels, yLabels, data, xLabelsVisibility }) => {
  const cellWidth = `calc(${100 / xLabels?.length}% - ${yLabelWidth / xLabels?.length}px - 1px)`;
  return (
    <div style={{ fontSize: "13px" }} className="styled-heat-map-chat-wrapper">
      <HeatMap
        xLabels={xLabels || []}
        yLabels={yLabels || []}
        xLabelsLocation="bottom"
        xLabelsVisibility={xLabelsVisibility}
        xLabelWidth={document?.getElementById("cell-content")?.clientWidth}
        yLabelWidth={yLabelWidth}
        data={data || []}
        title={() => ""}
        height={(270 / yLabels?.length) - (16 / yLabels?.length)}
        cellStyle={(background, value, min, max) => {
          const bgOpacity = 1 - (max - value) / (max - min) < 0.06 ? 0.06 : 1 - (max - value) / (max - min);
          const bg = !value ? 0.06 : bgOpacity;
          return {
            background: `rgb(240, 174, 255, ${bg})`,
            fontSize: "11.5px",
            color: "#444",
            width: cellWidth,
            borderRadius: 1,
            position: "relative"
          };
        }}
        cellRender={value => {
          return (
            <div id="cell-content" className="cell-content-wrapper">
              <div className="cell-content">{value}</div>
            </div>
          );
        }}
      />
    </div>
  );
};

export default StyledHeatMapChart;
