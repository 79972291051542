import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import CampaignsService from "services/campaigns";
import TrackingPixel from "services/trackingPixel";
import { EXPERT_GOAL } from "pathnameVariables";

import DashboardContent from "shared/DashboardContent";

import { getCampaignsTableRows } from "pages/Dashboard/Campaigns";

import CampaignStatistic from "./cards/CampaignStatistic";
import CampaignsList from "./cards/CampaignsList";
import GenderAndAge from "./cards/GenderAndAge";
import DaysOfWeek from "./cards/DaysOfWeek";
import DeviceAndOs from "./cards/DeviceAndOs";
import Networks from "./cards/Networks";
import Goals from "./cards/Goals";

import "./styles.scss";

const Overview = ({ statisticParams }) => {
  const { push } = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [campaignStatistic, setCampaignStatistic] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  const [demographicStatistic, setDemographicStatistic] = useState([]);
  const [adScheduleStatistic, setAdScheduleStatistic] = useState([]);
  const [devicesStatistic, setDevicesStatistic] = useState([]);
  const [osStatistic, setOsStatistic] = useState([]);
  const [networkStatistic, setNetworkStatistic] = useState([]);
  const [goalsList, setGoalsList] = useState([]);

  useEffect(() => {
    TrackingPixel.getGoalsList().then(data => setGoalsList(data?.map(val => ({ value: val.id, label: val.name }))));
  }, []);

  useEffect(() => {
    setIsLoading(true);
    Promise.all([
      CampaignsService.getCampaignsStatistic("campaigns", statisticParams).then(data => {
        setCampaignStatistic(data);
        setTableRows(getCampaignsTableRows(data));
      }),
      CampaignsService.getCampaignsStatistic("demographics", statisticParams).then(data => {
        setDemographicStatistic(data);
      }),
      CampaignsService.getCampaignsStatistic("ad-schedules", statisticParams).then(data => {
        setAdScheduleStatistic(data);
      }),
      CampaignsService.getCampaignsStatistic("devices", statisticParams).then(data => {
        setDevicesStatistic(data);
      }),
      CampaignsService.getCampaignsStatistic("os", statisticParams).then(data => {
        setOsStatistic(data);
      }),
      CampaignsService.getCampaignsStatistic("network", statisticParams).then(data => {
        setNetworkStatistic(data);
      }),
      CampaignsService.getCampaignsStatistic("network", statisticParams).then(data => {
        setNetworkStatistic(data);
      })
    ]).finally(() => setIsLoading(false));
  }, [statisticParams]);

  return (
    <DashboardContent
      isLoading={isLoading}
      isEmpty={!tableRows?.length}
      emptyTitle="You have no campaigns yet"
      emptyBtnClick={() => push(EXPERT_GOAL)}
      emptyBtnLabel="Create campaign"
    >
      <div className="overview-page-content">
        <CampaignStatistic data={campaignStatistic} />
        <CampaignsList data={tableRows} />
        <GenderAndAge data={demographicStatistic} />
        <DeviceAndOs devicesStatistic={devicesStatistic} osStatistic={osStatistic} />
        <Networks data={networkStatistic} />
        <DaysOfWeek data={adScheduleStatistic} />
        <Goals goalsList={goalsList} />
      </div>
    </DashboardContent>
  );
};

const mapStateToProps = state => ({
  statisticParams: state.campaigns.statisticParams
});

export default connect(mapStateToProps)(Overview);
