import React from "react";
import { connect } from "react-redux";
import { Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";

import Arrow from "assets/images/arrowDropDownBlue.svg";
import ArrowWhite from "assets/images/arrowDropDownWhite.svg";

import "./styles.scss";

const MultiCardContainer = ({ children, title, subtitle, userInfo }) => {
  return (
    <Accordion className="multi-card-container">
      <AccordionSummary
        className="multi-card-head"
        expandIcon={<img src={userInfo?.isDarkTheme ? ArrowWhite : Arrow} alt="" />}
      >
        <div>
          <div className="multi-card-title">{title}</div>
          <div className="multi-card-subtitle">{subtitle}</div>
        </div>
      </AccordionSummary>
      <AccordionDetails className="expert-mode-card-body">{children}</AccordionDetails>
    </Accordion>
  );
};

const mapStateToProps = state => ({
  userInfo: state.userProfile.userInfo
});

export default connect(mapStateToProps)(MultiCardContainer);
