// @flow
import { call, put, takeLatest } from "redux-saga/effects";
import { authorizeAsync } from "../actions";
import { SndAuth0Client } from "../../../../security/Auth0Client";

import { ApiService } from "../../../../services/ApiService";

const logger = console;

function* authSaga({ payload }) {
  try {
    yield call(SndAuth0Client.initAsync, payload.domain, payload.clientId, payload.audience);
    const isAuth = yield call(SndAuth0Client.isAuthenticatedAsync);
    if (!isAuth) {
      yield call(SndAuth0Client.loginWithRedirectAsync);
    } else {
      let accountScope = null;
      const authUser = yield call(SndAuth0Client.getUserAsync);
      try {
        accountScope = yield call(ApiService.getMyAccountScopeAsync);
      } catch (e) {
        if (e.response.status === 404) {
          yield call(ApiService.registerAccountAsync, {
            userId: authUser.sub,
            email: authUser.email,
            firstName: authUser.given_name,
            lastName: authUser.family_name
          });
        } else {
          throw e;
        }
      }

      yield put(
        authorizeAsync.success({
          accountId: accountScope?.accountId,
          userId: accountScope?.userId,
          role: accountScope?.role,
          auth0UserId: accountScope?.auth0UserId,
          accountRole: accountScope?.accountRole
        })
      );
    }
  } catch (e) {
    logger.error("authSaga", e);
    yield put(authorizeAsync.fail(e));
  }
}

export default function* handleAuthSaga(): any {
  yield takeLatest(authorizeAsync.request.toString(), authSaga);
}
