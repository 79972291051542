export const convertGoogleLocationData = (location, radius = 0) => {
  return {
    lat: location?.lat,
    lng: location?.lon,
    formattedAddress: location?.display_name,
    radius,
    countryCode: null,
    country: null,
    city: null,
    region: null,
    regionCode: null,
    postalCode: null
  };
};
