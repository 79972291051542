import React, { useImperativeHandle, useRef, useState } from "react";
import { dsToast } from "shared/dsToast";
import { Button } from "@material-ui/core";
import {
  CardCVCElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  injectStripe,
  StripeProvider
} from "react-stripe-elements";

import UserProfileInfo from "services/userProfile";

import StyledTextField from "shared/StyledTextField";
import Loader from "shared/Loader";

import Close from "assets/images/closeBlue.svg";

import "./styles.scss";

const createOptions = () => {
  return {
    style: {
      base: {
        fontSize: "14px",
        color: "#6979B4",
        position: "relative",
        fontWeight: "600",
        "::placeholder": {
          color: "#6979B4",
          opacity: 0.7
        }
      },
      invalid: {
        color: "#F63E49"
      }
    }
  };
};

const elFonts = [
  {
    src:
      "https://fonts.googleapis.com/css2?family=Montserrat:wght@500&family=Open+Sans:wght@400;600;700&family=Roboto:wght@500&display=swap",
    family: "Roboto",
    style: "normal"
  }
];

const StripeInput = ({ component: Component, inputRef, ...props }) => {
  const elementRef = useRef();
  useImperativeHandle(inputRef, () => ({
    focus: () => elementRef.current.focus
  }));
  return (
    <Component
      options={{ fonts: elFonts }}
      {...createOptions()}
      onReady={element => (elementRef.current = element)}
      {...props}
    />
  );
};

const AddPaymentCard = ({ stripe, handleClose, userInfo, getPaymentCardsList }) => {
  const [isLoading, stIsLoading] = useState(false);
  const [cardNumberError, setCardNumberError] = useState("");
  const [expirationDateError, setExpirationDateError] = useState("");
  const [cvcError, setCvcError] = useState("");
  const [resultError, setResultError] = useState("");

  const handleCardNumber = ({ error }) => {
    error && error.message ? setCardNumberError(error.message) : setCardNumberError("");
  };

  const handleExpirationDate = ({ error }) => {
    error && error.message ? setExpirationDateError(error.message) : setExpirationDateError("");
  };

  const handleCVC = ({ error }) => {
    error && error.message ? setCvcError(error.message) : setCvcError("");
  };

  const handleAddCard = () => {
    if (stripe && !cardNumberError && !expirationDateError && !cvcError) {
      stIsLoading(true);
      stripe.createToken().then(result => {
        if (!result.error) {
          const data = {
            userId: userInfo.id,
            stripeTokenId: result?.token.id
          };
          UserProfileInfo.addPaymentCard(data)
            .then(() => {
              getPaymentCardsList();
              handleClose();
            })
            .catch(() => dsToast.error("Sorry, something went wrong. Please try again later"))
            .finally(() => stIsLoading(false));
        } else {
          setResultError(result.error.message);
          stIsLoading(false);
        }
      });
    }
  };

  return (
    <div className="add-payment-modal-content">
      {isLoading && (
        <div className="loader-box">
          <Loader empty isDashboardContent />
        </div>
      )}
      <div className="payment-modal-head">
        Add payment card
        <Button className="close-modal-btn" onClick={handleClose}>
          <img src={Close} alt="" />
        </Button>
      </div>
      <div className="payment-modal-body">
        <StyledTextField
          error={cardNumberError}
          helperText={cardNumberError}
          label="Card number"
          type="number"
          onChange={handleCardNumber}
          inputProps={{
            inputComponent: StripeInput,
            inputProps: {
              component: CardNumberElement
            }
          }}
        />
        <StyledTextField
          error={expirationDateError}
          helperText={expirationDateError}
          label="Expires date"
          type="number"
          onChange={handleExpirationDate}
          inputProps={{
            inputComponent: StripeInput,
            inputProps: {
              component: CardExpiryElement
            }
          }}
        />
        <StyledTextField
          error={cvcError}
          helperText={cvcError}
          label="Security code"
          type="number"
          onChange={handleCVC}
          inputProps={{
            inputComponent: StripeInput,
            inputProps: {
              component: CardCVCElement
            }
          }}
        />
      </div>
      {resultError && <div className="error-result">{resultError}</div>}
      <div className="payment-modal-btn-box">
        <Button onClick={handleClose}>Discard</Button>
        <Button onClick={handleAddCard}>Save</Button>
      </div>
    </div>
  );
};

const SplitFieldsForm = injectStripe(AddPaymentCard);

export default ({ handleClose, userInfo, getPaymentCardsList }) => (
  <StripeProvider apiKey="pk_live_51IT9xzEtPOG8R4BiquNkSUux6QlMrRs02qWTaLTT9yXFbKrQtoYceafAJJBl7a2etjziR7iIuBm0L4CH3tDH9ntY0076SaQFlw">
    <Elements>
      <SplitFieldsForm handleClose={handleClose} userInfo={userInfo} getPaymentCardsList={getPaymentCardsList} />
    </Elements>
  </StripeProvider>
);
