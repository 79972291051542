import React, { useEffect, useState } from "react";
import { dsToast } from "shared/dsToast";
import { connect } from "react-redux";

import CampaignsService from "services/campaigns";
import { placementsTableColumns } from "constants/tableColumns";
import { campaignStatusVariants } from "constants/campaignStatusVariants";
import { parseCampaignStatus } from "helpers/parseCampaignStatus";
import { allCampaignsData } from "helpers/statisticHelpFunctions";

import DashboardContent from "shared/DashboardContent";
import DashboardTableCard from "shared/DashboardTableCard";

const AdPlacements = ({ statisticParams }) => {
  const [isEmpty, setIsEmpty] = useState(false);
  const [campaignsStatus, setCampaignsStatus] = useState(campaignStatusVariants[0]);
  const [isLoading, setIsLoading] = useState(true);
  const [initialStatisticData, setInitialStatisticData] = useState([]);
  const [statisticData, setStatisticData] = useState([]);
  const [tableRows, setTableRows] = useState([]);

  const getData = data => {
    const columnName = index => ({ columnName: placementsTableColumns[index].value });
    return allCampaignsData(data, "publisher_name")?.map(val => {
      return {
        rowName: val.label,
        columns: [
          { label: val.label, isFirst: true, noIcon: true, ...columnName(0) },
          { label: val.clicks, ...columnName(1) },
          { label: val.convs_rate, ...columnName(2) },
          { label: val.cpm, ...columnName(3) },
          { label: val.imps, ...columnName(4) }
        ]
      };
    });
  };

  useEffect(() => {
    setIsLoading(true);
    CampaignsService.getCampaignsStatistic("placements", statisticParams)
      .then(data => {
        setInitialStatisticData(data);
        if (!getData(data)?.length) {
          setIsEmpty(true);
        } else {
          setIsEmpty(false);
        }
      })
      .catch(() => dsToast.error("We cannot get gender statistics. Please try again later."))
      .finally(() => setIsLoading(false));
  }, [statisticParams]);

  useEffect(() => {
    setTableRows(getData(statisticData));
  }, [statisticData]);

  useEffect(() => {
    setStatisticData(
      initialStatisticData.filter(val => {
        if (campaignsStatus?.statusCode) {
          return parseCampaignStatus(val.campaign)?.statusCode === campaignsStatus?.statusCode;
        }
        return val;
      })
    );
  }, [campaignsStatus, initialStatisticData]);

  return (
    <DashboardContent isLoading={isLoading} isEmpty={isEmpty} emptyTitle="Try to select other dates">
      <DashboardTableCard
        fullHeight
        rows={tableRows}
        columns={placementsTableColumns}
        setCampaignsStatus={setCampaignsStatus}
        campaignsStatus={campaignsStatus}
      />
    </DashboardContent>
  );
};

const mapStateToProps = state => ({
  statisticParams: state.campaigns.statisticParams
});

export default connect(mapStateToProps)(AdPlacements);
