import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import { dsToast } from "shared/dsToast";

import CampaignsCreation from "services/campaignCreation";
import { EXPERT_CREATION, BEGINNER_GOAL, TERMS } from "pathnameVariables";

import GoalCard from "shared/GoalCard";
import Loader from "shared/Loader";

import GoalSales from "assets/images/goalSales.svg";
import GoalClients from "assets/images/goalClients.svg";
import GoalTraffic from "assets/images/goalTraffic.svg";
import GoalAttention from "assets/images/goalBrand.svg";
import GoalAwareness from "assets/images/goalBrand2.svg";
import GoalApp from "assets/images/goalApp.svg";
import GoalCustom from "assets/images/goalCustom.svg";

import "./styles.scss";

const goals = [
  {
    name: "Sales",
    description: "Grow your sales",
    goalType: 1,
    id: 2,
    icon: GoalSales
  },
  {
    name: "Potential Clients",
    description: "Get more clients",
    goalType: 2,
    id: 3,
    icon: GoalClients
  },
  {
    name: "Website Traffic",
    description: "Get more traffic",
    goalType: 3,
    id: 4,
    icon: GoalTraffic
  },
  {
    name: "Brand Attention",
    description: "Get more attention",
    goalType: 6,
    id: 7,
    icon: GoalAttention
  },
  {
    name: "Brand Awareness",
    description: "Brand Awareness and reach",
    goalType: 5,
    id: 6,
    icon: GoalAwareness
  },
  {
    name: "App Promotion",
    description: "Promote your app",
    goalType: 4,
    id: 5,
    icon: GoalApp
  },
  {
    name: "Custom",
    description: "Creat without goal’s guidance",
    goalType: 0,
    id: 1,
    icon: GoalCustom
  }
];

const GoalSelection = ({ userData }) => {
  const { push } = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [creatingCampaign, setCreatingCampaign] = useState(null);

  useEffect(() => {
    if (userData?.accountId) {
      CampaignsCreation.initExpertCreation(userData.accountId).then(data => {
        setCreatingCampaign(data.campaignCreation);
        setIsLoading(false);
      });
    }
  }, [userData]);

  const handleSelectGoal = goal => {
    setIsLoading(true);
    CampaignsCreation.sendCampaignGoal({ campaignId: creatingCampaign.campaignId, goalType: goal.goalType })
      .then(data => push(`${EXPERT_CREATION}?id=${data.campaignCreation.campaignId}`))
      .catch(() => {
        setIsLoading(false);
        dsToast.error("Sorry, something went wrong. Try again later");
      });
  };

  return (
    <div className="goal-selection-wrapper">
      <div className="goal-selection-content">
        {isLoading ? (
          <Loader empty isDashboardContent />
        ) : (
          <>
            <div className="goal-selection-title">Please select campaign’s goal</div>
            <div className="goals-list">
              {goals?.map(val => (
                <GoalCard
                  key={val.id}
                  onClick={() => handleSelectGoal(val)}
                  title={val.name}
                  subtitle={val.description}
                  img={val.icon}
                />
              ))}
            </div>
            <div className="to-beginner-mode">
              New to Searched? <Link to={BEGINNER_GOAL}>Switch to beginner mode</Link>
            </div>
            <div className="terms-link-box">
              <Link to={TERMS}>Terms and Conditions</Link>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  userData: state.userProfile.userInfo
});

export default connect(mapStateToProps)(GoalSelection);
