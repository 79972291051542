import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import { dsToast } from "shared/dsToast";

import { onlyNumberString } from "helpers/onlyNumberString";
import Admin from "services/admin";

import CustomSwitcher from "shared/CustomSwitcher";
import StyledTextField from "shared/StyledTextField";

import CloseIcon from "assets/images/closeBlue.svg";

import "./styles.scss";
import Loader from "../../../shared/Loader";

const EditUserModal = ({ selectedUser, handleClose }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isConfirmedEmail, setIsConfirmedEmail] = useState(false);
  const [isUseMargin, setIsUseMargin] = useState(false);
  const [isDisabledPayment, setIsDisabledPayment] = useState(false);
  const [isAccountActive, setIsAccountActive] = useState(false);
  const [cpm, setCpm] = useState("");
  const [margin, setMargin] = useState("");
  const [balance, setBalance] = useState("");

  const inputOnChange = (setFunk, e) => {
    if (onlyNumberString(e.target.value) || e.target.value === "") {
      setFunk(onlyNumberString(e.target.value) || e.target.value);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    if (selectedUser?.accountId) {
      Promise.all([
        Admin.getUserAccount(selectedUser?.accountId).then(data => {
          setMargin(data.accountMargin);
          setIsUseMargin(data.useSpecificMargin);
          setIsDisabledPayment(data.disableCreditCardBilling);
          setIsConfirmedEmail(data.confirmed);
          setIsAccountActive(data.isAccountActive);
        }),
        Admin.getUserBalanceAndCpm(selectedUser?.accountId).then(data => {
          setCpm(data.cpm ? data.cpm * 1000 : "");
          setBalance(data.balanceCapAmount);
        })
      ])
        .then(() => setIsLoading(false))
        .catch(() => {
          dsToast.error("Sorry we cant upload user data");
          handleClose();
        });
    }
  }, [selectedUser?.accountId]);

  const handleSaveChanges = () => {
    setIsLoading(true);
    const accountData = {
      emailConfirmed: isConfirmedEmail,
      accountMargin: margin ? Number(margin) : null,
      useSpecificMargin: isUseMargin,
      disableCreditCardBilling: isDisabledPayment,
      isActive: isAccountActive
    };
    const balanceAndCpmData = {
      cpm: Number(cpm) ? Number(cpm) / 1000 : null,
      balanceCapAmount: Number(balance) ? Number(balance) : null
    };
    const catchAction = () => dsToast.error("Sorry, something went wrong. Please try again later");
    Promise.all([
      Admin.updateUserAccount(selectedUser.accountId, accountData).catch(catchAction),
      Admin.updateUserBalanceAndCpm(selectedUser.accountId, balanceAndCpmData).catch(catchAction)
    ])
      .then(() => {
        dsToast.success("Changes has been saved");
        handleClose();
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <div className="edit-user-modal-content">
      {isLoading && (
        <div className="loader-box">
          <Loader empty isDashboardContent />
        </div>
      )}
      <div className="edit-user-modal-head">
        <div>
          {`${selectedUser?.firstName} ${selectedUser?.lastName}`}
          <div className="user-email">{selectedUser?.email}</div>
        </div>
        <Button onClick={handleClose}>
          <img src={CloseIcon} alt="" />
        </Button>
      </div>
      <div className="edit-user-modal-body">
        <CustomSwitcher
          label="Confirmed email"
          value={isConfirmedEmail}
          onChange={newValue => setIsConfirmedEmail(newValue)}
        />
        <CustomSwitcher
          label="Use specific margin"
          value={isUseMargin}
          onChange={newValue => setIsUseMargin(newValue)}
        />
        <CustomSwitcher
          label="Disable user payment"
          value={isDisabledPayment}
          onChange={newValue => setIsDisabledPayment(newValue)}
        />
        <CustomSwitcher
          label="Activating"
          value={isAccountActive}
          onChange={newValue => setIsAccountActive(newValue)}
        />
        <StyledTextField label="CPM" value={cpm} onChange={e => inputOnChange(setCpm, e)} />
        <div className="double-input-box">
          <StyledTextField
            disabled={!isUseMargin}
            label="Margin. %"
            value={margin}
            onChange={e => inputOnChange(setMargin, e)}
          />
          <StyledTextField label="Balance cap amount" value={balance} onChange={e => inputOnChange(setBalance, e)} />
        </div>
      </div>
      <div className="edit-user-modal-btn-box">
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleSaveChanges}>Save changes</Button>
      </div>
    </div>
  );
};

export default EditUserModal;
