import React from "react";
import propTypes from "prop-types";
import Loader from "../../../shared/Loader";
import "./styles.scss";

const bc = "dm-loader";

const AppLoader = ({ isLoading, size }) =>
  isLoading ? (
    <div className={bc}>
      <Loader isLoading empty></Loader>
    </div>
  ) : null;

Loader.defaultProps = {
  size: 25
};
Loader.propTypes = {
  isLoading: propTypes.bool.isRequired,
  size: propTypes.number
};

export default AppLoader;
