import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { dsToast } from "shared/dsToast";

import UserProfileInfo from "services/userProfile";

import { AddCardBtn, PaymentCard } from "pages/UserSettings/Balance";
import AddPaymentCard from "pages/UserSettings/Balance/AddPaymentCard";

import ModalWrapper from "shared/ModalWrapper";

import ExpertModeCard from "../../components/ExpertModeCard";

import "./styles.scss";

const Payment = ({ userInfo, paymentCardsList, setPaymentCardsList }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [openAddCardModal, setOpenAddCardModal] = useState(false);

  const getPaymentCardsList = () => {
    UserProfileInfo.getPaymentCards().then(data => {
      setPaymentCardsList(data);
      setIsLoading(false);
    });
  };

  const handleRemoveCard = card => {
    setIsLoading(true);
    UserProfileInfo.removePaymentCard(card.id)
      .then(() => getPaymentCardsList())
      .catch(() => {
        dsToast.error("Sorry, we cannot remove payment card. Please try again later");
        setIsLoading(false);
      });
  };

  const handleMakeCardPrimary = card => {
    UserProfileInfo.setDefaultPaymentCard(card.id)
      .then(() => getPaymentCardsList())
      .catch(() => {
        dsToast.error("Sorry, we cannot make as primary this payment card. Please try again later");
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getPaymentCardsList();
  }, []);

  return (
    <ExpertModeCard id="payment-card" title="Payment" className="select-payment-card-wrapper" isLoading={isLoading}>
      <div className="card-main-subtitle">
        By countinuing, you agree to the{" "}
        <a target="_blank" href="https://searched.com/terms">
          Searched Terms
        </a>
        . They include whatever legal text your lawyers will place here.
      </div>
      <div className="payment-card-list">
        {paymentCardsList?.map(val => (
          <PaymentCard
            data={val}
            key={val.id}
            onClick={handleMakeCardPrimary}
            onRemoveClick={handleRemoveCard}
            isPrimary={val.paymentMethodType === "primary"}
          />
        ))}
        <AddCardBtn onClick={() => setOpenAddCardModal(true)} />
        <ModalWrapper handleClose={() => setOpenAddCardModal(false)} open={openAddCardModal}>
          <AddPaymentCard
            handleClose={() => setOpenAddCardModal(false)}
            userInfo={userInfo}
            getPaymentCardsList={getPaymentCardsList}
          />
        </ModalWrapper>
      </div>
    </ExpertModeCard>
  );
};

const mapStateToProps = state => ({
  userInfo: state.userProfile.userInfo
});

export default connect(mapStateToProps)(Payment);
