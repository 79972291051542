import React, { useEffect } from "react";
import { Button } from "@material-ui/core";
import { Field, reduxForm, getFormValues, initialize } from "redux-form";
import { connect } from "react-redux";
import { dsToast } from "shared/dsToast";

import UserProfileInfo from "services/userProfile";
import { isValidEmail, isValidUrl } from "helpers/validateFunctions";
import { getUserInfo } from "redux/actions/userProfile";

import StyledTextField from "shared/StyledTextField";

import Close from "assets/images/closeBlue.svg";

import "./styles.scss";

let EditProfileModal = ({ handleClose, formValues, userInfo, initialize, handleGetUserInfo }) => {
  const initialValues = {
    firstName: userInfo?.firstName || "",
    lastName: userInfo?.lastName || "",
    email: userInfo?.email || "",
    nameOfBusiness: userInfo?.nameOfBusiness || "",
    address1: userInfo?.address1 || "",
    address2: userInfo?.address2 || "",
    city: userInfo?.city || "",
    state: userInfo?.state || "",
    zip: userInfo?.zip || "",
    phone: userInfo?.phone || "",
    url: userInfo?.url || "",
    instagram: userInfo?.instagram || "",
    facebook: userInfo?.facebook || ""
  };

  useEffect(() => {
    initialize(initialValues);
  }, [userInfo]);

  const getChangedValues = () => {
    if (formValues) {
      return Object.keys(formValues)
        .map(key => {
          if (initialValues[key] !== formValues[key]) {
            return key;
          }
          return null;
        })
        .filter(val => val);
    }
    return [];
  };

  const updateData = () => {
    if (!formValues.firstName || !formValues.lastName) {
      return dsToast.error("Name should not be empty");
    }
    if (!isValidEmail(formValues.email)) {
      return dsToast.error("Email must be an email");
    }
    if (formValues.url && !isValidUrl(formValues.url)) {
      return dsToast.error("Not valid url address");
    }
    return UserProfileInfo.updateUserInfo(formValues)
      .then(() => {
        dsToast.success("Changes was saved successful");
        handleGetUserInfo();
        handleClose();
      })
      .catch(() => dsToast.error("Sorry, something went wrong. Please tru again later"));
  };

  return (
    <div className="edit-profile-modal-content">
      <div className="edit-profile-head">
        Profile Details
        <Button className="close-modal-btn" onClick={handleClose}>
          <img src={Close} alt="" />
        </Button>
      </div>
      <div className="edit-modal-body">
        <div className="edit-modal-scroll-box">
          <div className="double-input-box">
            <Field name="firstName" label="First Name" component={StyledTextField} />
            <Field name="lastName" label="Last Name" component={StyledTextField} />
          </div>
          <Field disabled name="email" label="Email address" component={StyledTextField} />
          <Field name="nameOfBusiness" label="Name of business" component={StyledTextField} />
          <Field name="address1" label="Address #1" component={StyledTextField} />
          <Field name="address2" label="Address #2" component={StyledTextField} />
          <div className="triple-input-box">
            <Field name="city" label="City" component={StyledTextField} />
            <Field name="state" label="State" component={StyledTextField} />
            <Field name="zip" label="Zip code" component={StyledTextField} />
          </div>
          <Field name="phone" label="Phone" component={StyledTextField} />
          <Field name="url" label="URL" component={StyledTextField} />
          <Field name="instagram" label="Instagram" component={StyledTextField} />
          <Field name="facebook" label="Facebook" component={StyledTextField} />
        </div>
      </div>
      <div className="btn-box">
        <Button onClick={handleClose}>Cancel</Button>
        <Button disabled={!getChangedValues()?.length} onClick={updateData}>
          Save changes
        </Button>
      </div>
    </div>
  );
};

EditProfileModal = reduxForm({
  form: "edit-profile"
})(EditProfileModal);

const mapStateToProps = state => ({
  formValues: getFormValues("edit-profile")(state),
  userInfo: state.userProfile.userInfo
});

const mapDispatchToProps = dispatch => ({
  initialize,
  handleGetUserInfo: payload => dispatch(getUserInfo(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(EditProfileModal);
