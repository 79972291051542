import { ApiService } from "services/ApiService";

export default class TrackingPixel {
  static async getPixelsList() {
    return ApiService._requestAsync({
      url: `/tracking-pixel/tracking-pixels`,
      method: "GET"
    });
  }

  static async getPixelGoals(pixelId) {
    return ApiService._requestAsync({
      url: `/goals/pixel/${pixelId}`,
      method: "GET"
    });
  }

  static async sendPixel(email, pixelId) {
    return ApiService._requestAsync({
      url: `/web-push/pixel`,
      method: "POST",
      data: {
        email,
        pixelId
      }
    });
  }

  static async createGoal(data) {
    return ApiService._requestAsync({
      url: `/goals`,
      method: "POST",
      data
    });
  }

  static async getGoalsList() {
    return ApiService._requestAsync({
      url: `/goals`,
      method: "GET"
    });
  }

  static async getGoalStatistic(goalId, statisticParams) {
    return ApiService._requestAsync({
      url: `/goals/statistic?startDate=${new Date(statisticParams?.startDate)?.toISOString()}&endDate=${new Date(
        statisticParams?.endDate
      )?.toISOString()}&goalId=${goalId}`,
      method: "GET"
    });
  }
}
