import React from "react";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import "./styles.scss";

const CustomChartTooltip = ({ valueStyles, label, value, children }) => {
  return (
    <Tooltip
      classes={{ popper: "bar-tooltip-wrapper" }}
      title={
        <div className="bar-tooltip-content">
          <div className="bar-name">{label}</div>
          <div style={valueStyles} className="bar-value">{value}</div>
        </div>
      }
    >
      {children}
    </Tooltip>
  );
};

export default CustomChartTooltip;
