import React from "react";
import "./styles.scss";

const Loader = ({ saving, empty, isDashboardContent }) => {
  return (
    <div className={`main-loader-wrapper ${isDashboardContent ? "loader-dashboard-content" : ""}`}>
      <div className="datasys-logo-load">
        <div className="datasys-lines-wrapper">
          <div className="white-line"></div>
          <div className="white-line"></div>
          <div className="white-line"></div>
          <div className="white-line"></div>
          <div className="white-line"></div>
          <div className="white-line"></div>
          <div className="white-line"></div>
          <div className="white-line"></div>
        </div>
      </div>
      {/*<div className="main-loader">*/}
      {/*  <svg*/}
      {/*    className="circular"*/}
      {/*    xmlns="http://www.w3.org/2000/svg"*/}
      {/*    width="52"*/}
      {/*    height="52"*/}
      {/*    fill="none"*/}
      {/*    viewBox="0 0 52 52"*/}
      {/*  >*/}
      {/*    <path*/}
      {/*      fill="#213991"*/}
      {/*      d="M26 52c14.36 0 26-11.64 26-26a2.595 2.595 0 0 0-2.606-2.606A2.595 2.595 0 0 0 46.788 26c0 11.465-9.322 20.788-20.788 20.788-11.466 0-20.788-9.322-20.788-20.788C5.212 14.534 14.534 5.212 26 5.212a2.595 2.595 0 0 0 2.606-2.606A2.595 2.595 0 0 0 26 0C11.64 0 0 11.64 0 26s11.64 26 26 26z"*/}
      {/*    />*/}
      {/*  </svg>*/}
      {/*  <svg className="dots" xmlns="http://www.w3.org/2000/svg" width="52" height="52" fill="none" viewBox="0 0 52 52">*/}
      {/*    <path*/}
      {/*      fill="#6979B4"*/}
      {/*      d="M36.366 7.991a2.69 2.69 0 0 0 1.331.348c.87 0 1.796-.464 2.259-1.332.695-1.216.29-2.838-.927-3.533-1.274-.694-2.837-.29-3.59.927-.695 1.274-.29 2.837.927 3.59z"*/}
      {/*    />*/}
      {/*    <path*/}
      {/*      fill="#1F4FFF"*/}
      {/*      d="M46.268 16.909c.463 0 .868-.116 1.332-.348 1.274-.752 1.679-2.316.926-3.532-.753-1.274-2.316-1.68-3.532-.927-1.274.695-1.68 2.317-.927 3.59.406.753 1.274 1.217 2.2 1.217z"*/}
      {/*    />*/}
      {/*  </svg>*/}
      {/*</div>*/}
      {!empty && <h2 className="loader-title">We’re {saving ? "saving" : "loading"} data...</h2>}
    </div>
  );
};

export default Loader;
