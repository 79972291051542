import React from "react";
import { Button } from "@material-ui/core";
import "./styles.scss";

const CustomCheckbox = ({ checked, onChange, onClick, isTypeRadio, label, ...restProps }) => {
  return (
    <Button
      {...restProps}
      className="custom-checkbox-wrapper"
      onClick={e => {
        if (onChange) {
          onChange(!checked);
        }
        if (onClick) {
          onClick(e);
        }
      }}
    >
      <div className={`custom-checkbox ${checked ? "active" : ""} ${isTypeRadio ? "radio" : ""}`} />
      {label && <div className="custom-checkbox-label">{label}</div>}
    </Button>
  );
};

export default CustomCheckbox;
