export default function getMapZoomByLocationType(locationType) {
  switch (locationType) {
    case 'country':
      return 3;
    case 'administrative':
      return 4;
    case 'administrative_area_level_1':
      return 5;
    case 'administrative_area_level_2':
      return 7;
    case 'locality':
      return 9;
    case 'street_number':
      return 13;
    default:
      return 10;
  }
}