import React from "react";

import CustomCheckbox from "shared/CustomCheckbox";
import ExpertModeCard from "../../components/ExpertModeCard";

import "./styles.scss";

const ContentExclusions = ({ input, contentExclusions }) => {

  const handleChange = (key, val) => {
    if (input?.value?.[key].find(value => value.name === val.name)) {
      return input?.onChange({ ...input?.value, [key]: input?.value[key].filter(value => value.name !== val.name) });
    }
    return input?.onChange({ ...input?.value, [key]: [...input?.value[key], val] });
  };

  return (
    <ExpertModeCard title="Content exclusions" className="content-exclusions-card-wrapper" isDefaultOpen={false}>
      <div className="card-main-subtitle">Show ads all content</div>
      <div className="content-exclusions-scroll-box">
        <div className="content-exclusions">
          <div className="exclusion-column">
            <div className="card-main-subtitle">Digital content labels</div>
            {contentExclusions?.digital_content_label?.map(val => (
              <CustomCheckbox
                onChange={() => handleChange("digitalContent", val)}
                checked={input?.value?.digitalContent?.find(value => value.name === val.name)}
                label={val.name}
              />
            ))}
          </div>
          <div className="exclusion-column">
            <div className="card-main-subtitle">Content type</div>
            {contentExclusions?.content_type?.map(val => (
              <CustomCheckbox
                onChange={() => handleChange("contentType", val)}
                checked={input?.value?.contentType?.find(value => value.name === val.name)}
                label={val.name}
              />
            ))}
          </div>
          <div className="exclusion-column">
            <div className="card-main-subtitle">Sensitive content</div>
            {contentExclusions?.sensitive_content?.map(val => (
              <CustomCheckbox
                onChange={() => handleChange("sensitiveContent", val)}
                checked={input?.value?.sensitiveContent?.find(value => value.name === val.name)}
                label={val.name}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="card-text-box">
        Use end date none for always on advertising or schedule a stop date for this campaign.
      </div>
    </ExpertModeCard>
  );
};

export default ContentExclusions;
