import { handleActions } from "redux-actions";
import { setUserInfo, setClientWidth } from "redux/actions/userProfile";

const initialState = {
  userInfo: {
    isDarkTheme: localStorage?.getItem("isDarkTheme")
  },
  clientWidth: document.documentElement.clientWidth
};

const handleSetUserInfo = (state, { payload }) => {
  return { ...state, userInfo: payload };
};

const handleSetClientWidth = (state, { payload }) => {
  return { ...state, clientWidth: payload };
};

const reducer = handleActions(
  {
    [setUserInfo]: handleSetUserInfo,
    [setClientWidth]: handleSetClientWidth
  },
  { ...initialState }
);

export default reducer;
