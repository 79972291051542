import React from "react";
import { Button } from "@material-ui/core";
import NoDataImage from "assets/images/noDataImage.svg";
import "./styles.scss";

const EmptyPageCard = ({ title, subtitle, onBtnClick, btnLabel }) => {
  return (
    <div className="empty-page-card-wrapper">
      <img src={NoDataImage} alt="" />
      <div className="empty-page-card-title">{title || "There is no data"}</div>
      {subtitle && <div className="empty-page-card-subtitle">{subtitle}</div>}
      {onBtnClick && <Button onClick={onBtnClick} className="empty-page-card-btn">{btnLabel}</Button>}
    </div>
  );
};

export default EmptyPageCard;
