import React, { useEffect, useState } from "react";
import { Button, Popover } from "@material-ui/core";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { PROFILE_SETTINGS, BALANCE, NOTIFICATIONS, TERMS } from "pathnameVariables";
import themeColors from "constants/themeColors";
import { logout } from "containers/components/Auth/actions";
import { setUserInfo } from "redux/actions/userProfile";
import { setOpenMobileMenu, setStatisticParams } from "redux/actions/campaigns";
import UserProfileInfo from "services/userProfile";

import CustomSwitcher from "shared/CustomSwitcher";

import DefaultAvatar from "assets/images/defaultAvatar.svg";
import SettingsIcon from "assets/images/settingsIcon.svg";
import BalanceIcon from "assets/images/balanceIcon.svg";
import NotificationsIcon from "assets/images/notificationsIcon.svg";
import LogoutIcon from "assets/images/logoutIcon.svg";
import BurgerMenu from "assets/images/mobileBurgerMenu.svg";
import RemoveCross from "assets/images/blueRemoveCross.svg";
import ThemeIcon from "assets/images/menuThemeIcon.svg";
import TermsIcon from "assets/images/prPol.png";

import "./styles.scss";

const Header = ({
  leftSideContent,
  userInfo,
  handleLogout,
  me,
  handleOpenMobileMenu,
  withoutMenuBtn,
  statisticParams,
  handleSetStatisticParams,
  handleSetUserInfo
}) => {
  const [openPopover, setOpenPopover] = useState(null);

  useEffect(() => {
    const root = document.documentElement;
    if (root) {
      themeColors.forEach(val => root.style.setProperty(val.name, userInfo?.isDarkTheme ? val.dark : val.current));
    }
  }, [userInfo?.isDarkTheme]);

  const handleChangeTheme = newVal => {
    if (newVal) {
      localStorage.setItem("isDarkTheme", "true");
    } else {
      localStorage.removeItem("isDarkTheme");
    }
    handleSetUserInfo({ ...userInfo, isDarkTheme: newVal });
    UserProfileInfo.setTheme(newVal).then(() => {});
  };

  return (
    <div className="header-wrapper">
      <div className="left-side-content">
        {!withoutMenuBtn && (
          <div className="mobile-menu-btn-box">
            <Button className="open-mobile-menu-btn" onClick={() => handleOpenMobileMenu(true)}>
              <img src={BurgerMenu} alt="" />
            </Button>
            {statisticParams?.campaign && (
              <div className="campaign-name">
                <span className="name">{statisticParams?.campaign?.name}</span>
                <Button onClick={() => handleSetStatisticParams({ ...statisticParams, campaign: null })}>
                  <img src={RemoveCross} alt="" />
                </Button>
              </div>
            )}
          </div>
        )}
        {leftSideContent}
      </div>
      <div className="right-side-content">
        <Button className="help-btn" onClick={() => window.open("http://help.datasys.com", "_blank")}>
          Help
        </Button>
        <Button
          className={`user-actions-btn ${openPopover ? "active" : ""}`}
          onClick={e => setOpenPopover(e.currentTarget)}
        >
          <img src={DefaultAvatar} alt="" />
        </Button>
        <Popover
          open={Boolean(openPopover)}
          anchorEl={openPopover}
          onClose={() => setOpenPopover(null)}
          className="profile-menu-popover"
          anchorOrigin={{
            vertical: 40,
            horizontal: "center"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
        >
          <div className="profile-menu">
            <div className="profile-info">
              <div className="name">{`${userInfo?.firstName} ${userInfo?.lastName}`}</div>
              {me?.accountRole === "account-admin" && (
                <div className="balance">{`You've used: $${userInfo?.virtualCredit}`}</div>
              )}
            </div>
            <div className="profile-links">
              <Link to={PROFILE_SETTINGS} className="profile-link">
                <div className="link-icon-box">
                  <img src={SettingsIcon} alt="" />
                </div>
                Profile settings
              </Link>
              {me?.accountRole === "account-admin" && (
                <Link to={BALANCE} className="profile-link">
                  <div className="link-icon-box">
                    <img src={BalanceIcon} alt="" />
                  </div>
                  Balance
                </Link>
              )}
              <Link to={NOTIFICATIONS} className="profile-link">
                <div className="link-icon-box">
                  <img src={NotificationsIcon} alt="" />
                </div>
                Notifications
              </Link>
              <div className="profile-link no-link">
                <div className="link-icon-box">
                  <img src={ThemeIcon} alt="" />
                </div>
                Dark mode
                <CustomSwitcher value={userInfo?.isDarkTheme} onChange={handleChangeTheme} />
              </div>
            </div>
            <div className="profile-links">
              <Link to={TERMS} className="profile-link">
                <div className="link-icon-box">
                  <img src={TermsIcon} alt="" />
                </div>
                Terms & Conditions
              </Link>
              <Button className="profile-link logout-btn" onClick={() => handleLogout()}>
                <div className="link-icon-box">
                  <img src={LogoutIcon} alt="" />
                </div>
                Logout
              </Button>
            </div>
          </div>
        </Popover>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  userInfo: state.userProfile.userInfo,
  statisticParams: state.campaigns.statisticParams,
  me: state.me
});

const mapDispatchToProps = dispatch => ({
  handleLogout: () => dispatch(logout()),
  handleSetUserInfo: payload => dispatch(setUserInfo(payload)),
  handleSetStatisticParams: payload => dispatch(setStatisticParams(payload)),
  handleOpenMobileMenu: payload => dispatch(setOpenMobileMenu(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
