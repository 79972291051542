import axios from "axios";
import queryString from "query-string";
import config from "../config";
import { SndAuth0Client } from "../security/Auth0Client";

export default async ({
  url,
  method = "GET",
  data = {},
  headers = {},
  responseType = "json",
  tokenRequired = true
}) => {
  const baseUrl = queryString.parseUrl(url);
  let accessToken = null;
  if (tokenRequired) {
    accessToken = await SndAuth0Client.getTokenSilentlyAsync();
  }
  const reqOptions = {
    url: baseUrl.url.indexOf("http") !== -1 ? baseUrl.url : config.api.endpoint(url),
    method,
    responseType,
    headers: {
      "Content-Type": "application/json",
      Authorization: tokenRequired ? `Bearer ${accessToken}` : undefined,
      ...headers
    },
    timeout: 60000,
    data
  };

  return axios(reqOptions).then(res => {
    if (res.status >= 200 && res.status <= 300) {
      return res.data;
    }

    return res;
  });
};
