import { createAction } from "redux-actions";

export const setCampaignId = createAction("SET_CAMPAIGN_ID");
export const setGoal = createAction("SET_GOAL");
export const setAdGroupId = createAction("SET_AD_GROUP_ID");
export const handlePrevStep = createAction("HANDLE_PREV_STEP");
export const setPrevState = createAction("SET_PREV_STEP");
export const setDescribeBusiness = createAction("SET_DESCRIBE_BUSINESS");
export const setLocations = createAction("SET_LOCATIONS");
export const setTargetAds = createAction("SET_TARGET_ADS");
export const setBudget = createAction("SET_BUDGET");
export const setEstimate = createAction("SET_ESTIMATE");
export const setUploadAd = createAction("SET_UPLOAD_AD");
export const setCardId = createAction("SET_CARD_ID");
export const setCampaignName = createAction("SET_CAMPAIGN_NAME");
export const clearBeginnerStore = createAction("CLEAR_BEGINNER_STORE");
