import axios from "axios";

export class HttpService {
  static async requestAsync({ url, method = "GET", data = {}, headers = {}, responseType = "json" }) {
    const reqOptions = {
      url,
      method,
      responseType,
      headers,
      timeout: 60000,
      data
    };
    return axios(reqOptions).then(res => {
      if (res.status >= 200 && res.status <= 300) {
        return res.data;
      }
      return res;
    });
  }
}
