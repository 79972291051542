import React, { useEffect, useState } from "react";
import { dsToast } from "shared/dsToast";
import { connect } from "react-redux";

import CampaignsService from "services/campaigns";
import { adScheduleTableColumns } from "constants/tableColumns";
import { campaignStatusVariants } from "constants/campaignStatusVariants";

import DashboardContent from "shared/DashboardContent";
import DashboardTableCard from "shared/DashboardTableCard";

const AdSchedule = ({ statisticParams }) => {
  const [isEmpty, setIsEmpty] = useState(false);
  const [campaignsStatus, setCampaignsStatus] = useState(campaignStatusVariants[0]);
  const [isLoading, setIsLoading] = useState(true);
  const [tableRows, setTableRows] = useState([]);
  const [initialAllStatistic, setInitialAllStatistic] = useState([]);

  const handleGetStatistic = () => {
    setIsLoading(true);
    const statusCode = campaignsStatus?.statusCode || "";
    CampaignsService.getCampaignsStatistic("ad-schedules", statisticParams, `&status=${statusCode}`)
      .then(data => {
        const columnName = index => ({ columnName: adScheduleTableColumns[index].value });
        setTableRows(
          data[0]?.statisticDetails?.map(val => ({
            rowName: val.date,
            columns: [
              {
                label: val.date,
                isFirst: true,
                noIcon: true,
                sortDate: `${val.dimensions.day}, ${val.dimensions.hour}`,
                ...columnName(0)
              },
              { label: val.metrics.clicks, ...columnName(1) },
              { label: val.metrics.convs_rate, ...columnName(2) },
              { label: val.metrics.cpm?.toFixed(2), ...columnName(3) },
              { label: val.metrics.imps, ...columnName(4) }
            ]
          }))
        );
        if (!data[0]?.statisticDetails?.length && !initialAllStatistic?.length) {
          setIsEmpty(true);
        } else {
          setIsEmpty(false);
        }
        if (!initialAllStatistic?.length) {
          setInitialAllStatistic(data[0]?.statisticDetails);
        }
      })
      .catch(() => dsToast.error("We cannot get gender statistics. Please try again later."))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    handleGetStatistic();
  }, [statisticParams, campaignsStatus]);

  return (
    <DashboardContent isLoading={isLoading} isEmpty={isEmpty} emptyTitle="Try to select other dates">
      <DashboardTableCard
        fullHeight
        rows={tableRows}
        columns={adScheduleTableColumns}
        setCampaignsStatus={setCampaignsStatus}
        campaignsStatus={campaignsStatus}
      />
    </DashboardContent>
  );
};

const mapStateToProps = state => ({
  statisticParams: state.campaigns.statisticParams
});

export default connect(mapStateToProps)(AdSchedule);
