import React, { useEffect, useState } from "react";
import "./styles.scss";

const CustomScrollBar = ({ elemRef }) => {
  const [scroll, setScroll] = useState({
    top: 0,
    sliderHeight: 0,
    isShow: true,
    height: 0
  });

  useEffect(() => {
    if (elemRef?.current) {
      const { scrollHeight, clientHeight } = elemRef?.current;
      const heightPercent = (clientHeight * 100) / scrollHeight;
      const sliderHeight = (heightPercent * clientHeight) / 100;

      setScroll({
        ...scroll,
        sliderHeight: Math.round(sliderHeight),
        isShow: clientHeight !== Math.round(sliderHeight),
        height: clientHeight
      });
    }
  }, [elemRef?.current]);

  useEffect(() => {
    if (elemRef?.current) {
      elemRef.current.onscroll = () => {
        const { scrollHeight, scrollTop, clientHeight } = elemRef?.current;
        const heightPercent = (clientHeight * 100) / scrollHeight;
        const sliderHeight = (heightPercent * clientHeight) / 100;
        const scrollTopPercent = (scrollTop * 100) / scrollHeight;

        setScroll({
          top: Math.round((scrollTopPercent * clientHeight) / 100),
          sliderHeight: Math.round(sliderHeight),
          isShow: clientHeight !== Math.round(sliderHeight),
          height: clientHeight
        });
      };
    }
  }, [elemRef?.current?.scrollTop]);
  return (
    <>
      {scroll.isShow && (
        <div style={{ height: `${scroll.height}px` }} className="custom-scroll-bar">
          <div style={{ height: `${scroll.sliderHeight}px`, top: `${scroll.top}px` }} className="slider" />
        </div>
      )}
    </>
  );
};

export default CustomScrollBar;
