import React from "react";

import StyledSlider from "shared/StyledSlider";

import ExpertModeCard from "../../components/ExpertModeCard";

import "./styles.scss";

const KeywordExpansion = ({ input }) => {
  return (
    <ExpertModeCard title="Keyword Expansion" className="keyword-expansion-card-wrapper">
      <div className="card-main-subtitle">Increase your reach based on the settings selected above</div>
      <div className="slider-wrapper">
        <div className="slider-title">
          <span>Off</span>
          <span>More reach</span>
        </div>
        <StyledSlider min={1} max={7} value={input?.value} onChange={(e, newValue) => input?.onChange(newValue)} />
      </div>
      <div className="card-text-box">
        Reach more users by letting look for high-performing keywords similar to yours. Expanding reach can increase
        impressions, clicks, and conversions.
      </div>
    </ExpertModeCard>
  );
};

export default KeywordExpansion;
