import React from "react";
// import { Button } from "@material-ui/core";
// import Dropzone from "react-dropzone";
//
// import EmptyIcon from "assets/images/audienceCsvIcon.svg";
// import CsvIcon from "assets/images/csvIcon.svg";
// import RemoveIcon from "assets/images/removeIconBlue.svg";

import Keywords from "../../components/Keywords";

import "./styles.scss";

const Audiences = ({ input }) => {
  // const [activeTab, setActiveTab] = useState(0);

  return (
    <div className="audiences-card-wrapper">
      <div className="audiences-card-head">Edit targeted audiences</div>
      <Keywords input={input} />
      {/*<div className={`audiences-tab-panel-header ${activeTab ? "second-tab-active" : ""}`}>*/}
      {/*  <Button onClick={() => setActiveTab(0)}>Keywords</Button>*/}
      {/*  <Button onClick={() => setActiveTab(1)}>Upload</Button>*/}
      {/*</div>*/}
      {/*{!activeTab ? (*/}
      {/*  <Keywords input={input} />*/}
      {/*) : (*/}
      {/*  <div className="upload-tab">*/}
      {/*    <div className="upload-csv-box">*/}
      {/*      <img src={CsvIcon} alt="" />*/}
      {/*      Please upload the file in the*/}
      {/*      <br />*/}
      {/*      CSV format*/}
      {/*      <Dropzone*/}
      {/*        onDropRejected={() => {}}*/}
      {/*        onDropAccepted={file =>*/}
      {/*          input?.onChange({ ...input?.value, csvFiles: [...input?.value?.csvFiles, file[0]] })*/}
      {/*        }*/}
      {/*        accept=".csv, text/csv"*/}
      {/*      >*/}
      {/*        {({ getRootProps, getInputProps }) => (*/}
      {/*          <>*/}
      {/*            <input {...getInputProps()} />*/}
      {/*            <Button {...getRootProps()}>Upload</Button>*/}
      {/*          </>*/}
      {/*        )}*/}
      {/*      </Dropzone>*/}
      {/*    </div>*/}
      {/*    <div className="uploaded-files">*/}
      {/*      {input?.value?.csvFiles?.length ? (*/}
      {/*        input?.value?.csvFiles?.map(val => (*/}
      {/*          <div className="file-item">*/}
      {/*            <div>{val.name}</div>*/}
      {/*            <Button*/}
      {/*              onClick={() => {*/}
      {/*                return input?.onChange({*/}
      {/*                  ...input?.value,*/}
      {/*                  csvFiles: input?.value?.csvFiles.filter(value => value.name !== val.name)*/}
      {/*                });*/}
      {/*              }}*/}
      {/*            >*/}
      {/*              <img src={RemoveIcon} alt="" />*/}
      {/*            </Button>*/}
      {/*          </div>*/}
      {/*        ))*/}
      {/*      ) : (*/}
      {/*        <div className="empty-list">*/}
      {/*          <img src={EmptyIcon} alt="" />*/}
      {/*          Your audience will build on the basis of data from the integration chosen*/}
      {/*        </div>*/}
      {/*      )}*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*)}*/}
    </div>
  );
};

export default Audiences;
