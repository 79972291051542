import React, { useState } from "react";
import { Button } from "@material-ui/core";
import DeveloperModeIcon from "@material-ui/icons/DeveloperMode";
import EmailIcon from "@material-ui/icons/Email";
import { dsToast } from "shared/dsToast";

import TrackingPixel from "services/trackingPixel";

import StyledTextField from "shared/StyledTextField";

import Close from "assets/images/closeBlue.svg";

import "./styles.scss";

const InstallInstruction = ({ code }) => {
  return (
    <div className="install-instruction-wrapper">
      <div className="instruction">1. Copy the pixel code from the box below.</div>
      <div className="instruction">
        2. Find the header of your website, or locate the header template in your CMS or web platform.
      </div>
      <div className="instruction">
        3. Paste the pixel code into the bottom of the header section of your website, just above the closing head tag.
        Install the base code on every page of your website.
      </div>
      <div className="code">
        {`<!-- Searched Pixel Code -->`}
        <br />
        {`<script defer src="https://audiencepsynch.com/tm/pixel/${code}"></script>`}
        <br />
        {`<!-- End Searched Pixel Code -->`}
      </div>
    </div>
  );
};

const SetupPixelModal = ({ handleClose, pixel }) => {
  const [isPixelSetup, setIsPixelSetup] = useState(false);
  const [isEmailSetup, setIsEmailSetup] = useState(false);
  const [recipient, setRecipient] = useState("");

  const handleSend = () => {
    const MAIL_FORMAT = /^(([^<>()\[\].,;:\s@"]+(\.[^<>()\[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
    const emailValidation = value => value && MAIL_FORMAT.test(value);
    if (emailValidation(recipient)) {
      TrackingPixel.sendPixel(recipient, pixel?.id)
        .then(() => {
          dsToast.success("Pixes script has been successfully sent to the recipient's email");
          handleClose();
        })
        .catch(() => dsToast.error("Sorry, something went wrong"));
    } else {
      dsToast.error("Invalid email address");
    }
  };

  return (
    <div className="setup-pixel-modal-wrapper">
      <div className="setup-pixel-modal-head">
        {isPixelSetup
          ? "Setup Pixel"
          : isEmailSetup
          ? "Email pixel install instructions"
          : "Connect your website activity using a pixel"}
        <Button className="close-modal-btn" onClick={handleClose}>
          <img src={Close} alt="" />
        </Button>
      </div>
      <div className="setup-pixel-modal-body">
        {!isPixelSetup && !isEmailSetup && (
          <>
            <div className="body-title">You can install now or email a developer install instructions</div>
            <div className="body-subtitle">
              Installing a pixel is easy. Simply copy 1 line of javascript into your website HEAD section and save.
            </div>
            <Button className="modal-action-btn" onClick={() => setIsPixelSetup(true)}>
              <div className="icon-box">
                <DeveloperModeIcon />
              </div>
              <div className="text-box">
                <div className="body-title">Install a pixel now</div>
                <div className="body-subtitle">
                  Most websites are fully supported and connecting your site is easy...
                  <br />
                  <span>Best for:</span> Going live immediately needed
                </div>
              </div>
            </Button>
            <Button className="modal-action-btn" onClick={() => setIsEmailSetup(true)}>
              <div className="icon-box">
                <EmailIcon />
              </div>
              <div className="text-box">
                <div className="body-title">Email install instructions to a developer</div>
                <div className="body-subtitle">
                  If you don't have direct access to your site's codebase you can send instructions and documentation to
                  your tech support. Make sure that you include the pixel ID in your email.
                  <br />
                  <span>Best for:</span> No current access to website code base
                </div>
              </div>
            </Button>
          </>
        )}
        {(isPixelSetup || isEmailSetup) && (
          <>
            {isEmailSetup && (
              <StyledTextField
                label="Send to"
                placeholder="Add recipient"
                value={recipient}
                onChange={e => setRecipient(e.target.value)}
              />
            )}
            <div className="body-title">
              The pixel is a snippet of JavaScript code that is added to the header section of your website.
            </div>
            <InstallInstruction code={pixel?.trackingPixelUuid} />
          </>
        )}
      </div>
      {(isPixelSetup || isEmailSetup) && (
        <div className="button-box">
          <Button
            onClick={() => {
              setIsPixelSetup(false);
              setIsEmailSetup(false);
            }}
          >
            Back
          </Button>
          <Button disabled={isEmailSetup && !recipient} onClick={() => (isPixelSetup ? handleClose() : handleSend())}>
            {isPixelSetup ? "Cancel" : "Send"}
          </Button>
        </div>
      )}
    </div>
  );
};

export default SetupPixelModal;
