import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import { dsToast } from "shared/dsToast";

import TrackingPixel from "services/trackingPixel";

import StyledTextField from "shared/StyledTextField";
import StyledSelect from "shared/StyledSelect";
import StyledChip from "shared/StyledChip";
import Loader from "shared/Loader";

import Close from "assets/images/closeBlue.svg";

import "./styles.scss";

const viewOptions = [
  {
    value: "path",
    label: "View a path"
  },
  {
    value: "query_string",
    label: "View a query string"
  },
  {
    value: "url",
    label: "View a URL"
  },
  {
    value: "domain",
    label: "View a domain",
    disabled: true
  }
];

const viewThatOptions = [
  {
    value: "contains",
    label: "Contains"
  },
  {
    value: "not_contains",
    label: "Doesn't contain"
  },
  {
    value: "equals",
    label: "Equals"
  },
  {
    value: "not_equals",
    label: "Doesn't equals"
  }
];

const GoalCreationModal = ({ handleClose, handleGetGoals, pixelId, goalInfo }) => {
  const [goalName, setGoalName] = useState("");
  const [view, setView] = useState("");
  const [viewThat, setViewThat] = useState("");
  const [keywordField, setKeywordField] = useState("");
  const [chips, setChips] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleDeleteChip = chip => {
    setChips(chips.filter(val => val !== chip));
  };

  const handleAddChip = () => {
    setChips([...chips, keywordField]);
    setKeywordField("");
  };

  useEffect(() => {
    if (goalInfo) {
      const viewValue = Object.keys(goalInfo?.rule)[0];
      const viewThatValue = Object.keys(goalInfo?.rule[viewValue])[0];
      setGoalName(goalInfo?.name);
      setView(viewValue);
      setViewThat(viewThatValue);
      setChips(goalInfo?.rule[viewValue][viewThatValue]);
    } else {
      setView(viewOptions[0]?.value);
      setViewThat(viewThatOptions[0]?.value);
    }
  }, [goalInfo]);

  const handleCreateGoal = () => {
    const data = {
      countType: "hybrid",
      pixelId: Number(pixelId),
      rule: {
        [view]: {
          [viewThat]: chips
        }
      },
      name: goalName,
      conversionCategoryCustom: goalName,
      conversionCategoryId: 9,
      postViewExpireMinutes: 43200,
      postClickExpireMinutes: 43200,
      minMinutesPerConv: 0
    };
    if (!goalName) {
      return dsToast.success("Please enter goal name");
    }
    if (!chips.length) {
      return dsToast.success("Please add keywords");
    }
    setIsLoading(true);
    return TrackingPixel.createGoal(data)
      .then(() => {
        handleClose();
        handleGetGoals();
        dsToast.success("Goal was added successfully");
      })
      .catch(e => dsToast.error(e.response?.data?.message))
      .finally(() => setIsLoading(false));
  };

  return (
    <div className="goal-creation-modal-content">
      {isLoading && (
        <div className="modal-loader-box">
          <Loader empty isDashboardContent />
        </div>
      )}
      <div className="goal-creation-modal-head">
        {goalInfo ? "Goal details" : "Create new goal"}
        <Button className="close-modal-btn" onClick={handleClose}>
          <img src={Close} alt="" />
        </Button>
      </div>
      <div className="goal-creation-modal-body">
        <StyledTextField
          disabled={goalInfo}
          value={goalName}
          onChange={e => setGoalName(e.target.value)}
          label="Goal Name"
        />
        <div className="label">Goal Trigger</div>
        <div className={`trigger-box ${goalInfo ? "select-box" : ""}`}>
          <StyledSelect
            disabled={goalInfo}
            options={viewOptions}
            value={view}
            onChange={e => setView(e.target.value)}
          />
          <StyledSelect
            disabled={goalInfo}
            options={viewThatOptions}
            value={viewThat}
            onChange={e => setViewThat(e.target.value)}
          />
          {!goalInfo && (
            <div className="keyword-field-box">
              <StyledTextField
                value={keywordField}
                onChange={e => setKeywordField(e.target.value)}
                onKeyDown={e => e.key === "Enter" && handleAddChip()}
              />
              <Button disabled={!keywordField} onClick={handleAddChip}>
                Add
              </Button>
            </div>
          )}
        </div>
        <div className="chip-box">
          {Array.isArray(chips) && chips?.map(val => (
            <StyledChip label={val} onDelete={() => !goalInfo && handleDeleteChip(val)} />
          ))}
        </div>
      </div>
      {!goalInfo && (
        <div className="btn-box">
          <Button disabled={!goalName || !chips?.length} onClick={handleCreateGoal}>
            Save goal
          </Button>
        </div>
      )}
    </div>
  );
};

export default GoalCreationModal;
