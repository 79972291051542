import React from "react";
import "./styles.scss";

const DashboardWrapper = ({ children }) => {
  return (
    <div className="dashboard-wrapper">
      {children}
    </div>
  );
};

export default DashboardWrapper;
