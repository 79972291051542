import React, { useEffect, useState } from "react";

import { NETWORKS } from "pathnameVariables";
import { colors } from "constants/chartColors";
import { diagramSelectOptions } from "constants/tableColumns";
import { allCampaignsData } from "helpers/statisticHelpFunctions";

import StyledPieChart from "shared/StyledPieChart";

import StatisticCard from "../../components/StatisticCard";

import "./styles.scss";

const Networks = ({ data }) => {
  const [convertData, setConvertData] = useState(null);
  const [currentData, setCurrentData] = useState([]);
  const [firstSelectOption, setFirstSelectOption] = useState(diagramSelectOptions[0]);
  const [secondSelectOption, setSecondSelectOption] = useState(diagramSelectOptions[1]);

  useEffect(() => {
    if (data) {
      setConvertData(
        allCampaignsData(data, "connection_type").map((val, i) => ({
          color: colors[i],
          ...val
        }))
      );
    }
  }, [data, firstSelectOption, secondSelectOption]);

  useEffect(() => {
    if (convertData?.length) {
      setCurrentData(
        convertData.map(val => ({
          value1: val[firstSelectOption?.value],
          value2: val[secondSelectOption?.value],
          ...val
        }))
      );
    }
  }, [convertData, firstSelectOption, secondSelectOption]);

  return (
    <StatisticCard
      className="networks-card"
      id="networks"
      title="Networks"
      href={NETWORKS}
      isEmpty={convertData && !convertData?.length}
    >
      <div className="network-charts-wrapper">
        <StyledPieChart
          data={currentData?.map(val => ({ ...val, value: val.value1 }))}
          isVerticalAlign
          inPercent
          typeOptions={diagramSelectOptions.filter(val => secondSelectOption.value !== val.value)}
          typeState={firstSelectOption}
          setTypeState={setFirstSelectOption}
        />
        <StyledPieChart
          data={currentData?.map(val => ({ ...val, value: val.value2 }))}
          isVerticalAlign
          inPercent
          typeOptions={diagramSelectOptions.filter(val => firstSelectOption.value !== val.value)}
          typeState={secondSelectOption}
          setTypeState={setSecondSelectOption}
        />
      </div>
    </StatisticCard>
  );
};

export default Networks;
