import React from "react";
import "./styles.scss";

const GoalCard = ({ img, onClick, title, subtitle, key }) => {
  return (
    <div key={key} className="goal-card-wrapper" onClick={onClick}>
      <img src={img} alt="" />
      <div className="card-text-box">
        {title && <div className="card-title">{title}</div>}
        {subtitle && <div className="card-subtitle">{subtitle}</div>}
      </div>
    </div>
  );
};

export default GoalCard;
