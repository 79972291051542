import { makeStyles } from "@material-ui/core/styles";
import ImgBG from "../../assets/images/back.svg";

export default makeStyles(theme => ({
  container: {
    width: "100%",
    height: "100vh",
    background: `url(${ImgBG})`,
    backgroundSize: "cover",
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "relative"
  },
  linearGradientBG: {
    position: "absolute",
    background: "linear-gradient(180deg, #2E4494 0%, #15245B 100%)",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: "-1"
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: 570,
    background: "white",
    padding: "10px 40px 30px",
    borderRadius: 4,
    marginBottom: "5%",
    zIndex: 5
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  submit: {
    textTransform: "none",
    padding: 16,
    fontSize: 18,
    color: "#EBC7E3",
    boxShadow: "none",
    background: "#213991",
    borderRadius: 4,
    "&:hover": {
      boxShadow: "none"
    }
  },
  textField: {
    marginTop: 20,
    "& :before": {
      borderBottom: "2px solid black"
    },
    "& label": {
      color: "#000000",
      fontSize: 20,
      fontFamily: "'Neutrif Pro, medium'"
    },
    "& input": {
      padding: "6px 0 10px",
      fontFamily: "'Neutrif Pro, medium'"
    }
  },
  linkToLogin: {
    margin: "20px 0 28px",
    color: "black",
    fontSize: 16,
    textDecoration: "none",
    background: "transparent",
    borderRadius: 0,
    borderBottom: "1px solid black",
    padding: 2,
    fontFamily: "'Neutrif Pro'",
    "&:hover": {
      color: "#213991",
      borderBottom: "1px solid #213991"
    }
  },
  message: {
    color: "#213991",
    fontSize: 30,
    lineHeight: "99%",
    fontFamily: "'Neutrif Pro'",
    fontWeight: "bold",
    textAlign: "center",
    marginTop: 20
  },
  description: {
    maxWidth: 260,
    fontFamily: "'Neutrif Pro'",
    fontSize: 16,
    fontWeight: 600,
    lineHeight: "22px",
    textAlign: "center",
    letterSpacing: 0.165828,
    color: "#6979B4",
    margin: "12px 0"
  },
  image: {
    margin: "38px 0"
  },
  line: {
    height: "2px",
    width: "100%",
    background: "#6979B4",
    opacity: 0.4,
    margin: "36px 0"
  },
  resendEmail: {
    fontFamily: "'Neutrif Pro, medium'",
    fontSize: 14,
    lineHeight: "16px",
    letterSpacing: "0.1351px"
  },
  logout: {
    fontFamily: "'Neutrif Pro, medium'",
    fontSize: 14,
    lineHeight: "16px",
    letterSpacing: "0.1351px",
    margin: "10px 0"
  }
}));
