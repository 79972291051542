export const EMAIL_CONFIRM = "/email-confirm";
export const TERMS = "/terms";
export const EMAIL_NOT_CONFIRM = "/email-not-confirmed";
export const NOT_ACCESS = "/has-no-access";
export const WELCOME = "/welcome";

// DASHBOARD

export const DASHBOARD = "/dashboard";
export const OVERVIEW = `${DASHBOARD}/overview`;
export const CAMPAIGNS = `${DASHBOARD}/campaigns`;
export const AGE = `${DASHBOARD}/age`;
export const GENDER = `${DASHBOARD}/gender`;
export const AD_PLACEMENTS = `${DASHBOARD}/ad-groups`;
export const LOCATIONS = `${DASHBOARD}/locations`;
export const AD_SCHEDULE = `${DASHBOARD}/ad-schedule`;
export const DEVICES = `${DASHBOARD}/devices`;
export const OPERATING_SYSTEM = `${DASHBOARD}/operating-system`;
export const NETWORKS = `${DASHBOARD}/networks`;
export const PIXELS = `${DASHBOARD}/pixels`;
export const PIXEL_GOALS = `${DASHBOARD}/pixel-goals`;
export const SETTINGS = `${DASHBOARD}/settings`;
export const SUB_USERS = `${DASHBOARD}/sub-users`;
// ADMIN ROUTS
export const ADMIN_CAMPAIGNS = `${DASHBOARD}/adm-campaigns`;
export const ADMIN_USERS = `${DASHBOARD}/adm-users`;
export const ADMIN_CONFIGURATIONS = `${DASHBOARD}/adm-configurations`;

// USER SETTINGS

export const USER_SETTINGS = `/user-settings`;
export const PROFILE_SETTINGS = `${USER_SETTINGS}/profile`;
export const BALANCE = `${USER_SETTINGS}/balance`;
export const NOTIFICATIONS = `${USER_SETTINGS}/notifications`;

// EXPERT CAMPAIGN CREATION

export const EXPERT_MODE = "/expert-creation";
export const EXPERT_GOAL = `${EXPERT_MODE}/goal`;
export const EXPERT_CREATION = `${EXPERT_MODE}/setting`;

// BEGINNER CAMPAIGN CREATION

export const BEGINNER_MODE = "/beginner-creation";
export const BEGINNER_GOAL = `${BEGINNER_MODE}/goal`;
export const BEGINNER_DESCRIBE_BUS = `${BEGINNER_MODE}/describe-business`;
export const BEGINNER_LOCATION = `${BEGINNER_MODE}/location`;
export const BEGINNER_TARGET_ADS = `${BEGINNER_MODE}/target-ads`;
export const BEGINNER_BUDGET = `${BEGINNER_MODE}/budget`;
export const BEGINNER_UPLOAD_AD = `${BEGINNER_MODE}/upload-ad`;
export const BEGINNER_PAYMENT = `${BEGINNER_MODE}/payment`;
export const BEGINNER_CONFIRM = `${BEGINNER_MODE}/confirm`;
