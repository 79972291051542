import React, { useEffect } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import { dsToast } from "shared/dsToast";
import hasNoAccess from "../../assets/images/invite.svg";
import Logo from "../../shared/Logo";
import useStyles from "./style";
import makeApiCall from "../../helpers/makeApiCall";
import { SndAuth0Client } from "../../security/Auth0Client";

const Confirm = () => {
  const classes = useStyles();

  const queryParams = queryString.parse(window.location.search);

  useEffect(() => {
    makeApiCall({
      url: `/common/is-user-invite?code=${queryParams.code}&email=${queryParams.email}`,
      tokenRequired: false
    }).then(res => {
      if (res) {
        SndAuth0Client.initAsync("id.datasys.com", "ZNE0d2UEVYTU2KcBC80QAiO03TLxDH8F", "api.searchndeploy.com");
        SndAuth0Client.loginWithRedirectAsync(queryParams.email);
      } else {
        dsToast.error("Something went wrong");
      }
    });
  }, []);

  return (
    <div className={classes.container}>
      <CssBaseline />
      <div className={classes.linearGradientBG} />
      <div className={classes.paper}>
        <>
          <Logo />
          <div>
            <img className={classes.image} src={hasNoAccess} alt="email not confirmed" />
          </div>
          <div className={classes.message}>Welcome to Searched.com</div>
          <div className={classes.description}>Please, waite a second, we redirect you to registration page</div>
        </>
      </div>
    </div>
  );
};

export default withRouter(Confirm);
