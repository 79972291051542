import React, { useRef } from "react";
import { formatterAxisData, getRoundValue } from "helpers/statisticHelpFunctions";
import CustomChartTooltip from "shared/CustomChartTooltip";
import "./styles.scss";

const getYValues = data => {
  const rightArr = ["0"];
  if (data?.length && data[0]?.tooltip) {
    const maxValue = Math.max.apply(
      null,
      data?.map(val => Math.round(val.value))
    );
    new Array(maxValue).fill(0).forEach((value, i) => {
      const minValue = Math.min.apply(
        null,
        data?.map(val => Math.round(val.value))
      );
      if (i + 1 === minValue || i + 1 === maxValue || Math.round(maxValue / 2) === i + 1) {
        rightArr.push(formatterAxisData(i + 1));
      }
      return value;
    });
  }
  if (rightArr?.length > 1) {
    return rightArr.reverse();
  }
  return ["0", "30", "60", "100"].reverse();
};

const CustomVerticalBarChart = ({ data, isShort }) => {
  const ref = useRef();

  const getBarHeight = val => {
    const maxHeight = 240;
    const maxValue = Math.max.apply(
      null,
      data?.map(val => val.value)
    );
    const heightPercent = (val * 100) / maxValue;
    if (val) {
      return `${Math.round((heightPercent * maxHeight) / 100)}px`;
    }
    return "5px";
  };

  const isShowXLabel = index => {
    if (data?.length <= 10 || ["0", "7", "12", "17", "23"].includes(index.toString())) {
      return true;
    }
  };

  return (
    <div className="custom-vertical-bar-chart">
      <div className="custom-vertical-bar-chart-content" ref={ref}>
        <div className="y-axis">
          {getYValues(data)?.map(val => (
            <div key={val} className="axis-value">
              {val}
              <div style={{ width: `${ref?.current?.clientWidth}px` }} className="value-line" />
            </div>
          ))}
        </div>
        <div className="bars-wrapper">
          <div className="bars-list">
            {data?.map((val, i) => (
              <div
                key={val.label}
                style={{ height: getBarHeight(val.value) }}
                className={`bar-item ${isShort ? "short" : ""}`}
              >
                <CustomChartTooltip
                  value={`${val?.tooltip}: ${getRoundValue(Number(val.value))}`}
                  label={val.label}
                  valueStyles={{ color: "#1F4FFF" }}
                >
                  <div className="bar-line" />
                </CustomChartTooltip>
                {isShowXLabel(i) && <div className="bar-name">{val.label}</div>}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomVerticalBarChart;
