import React, { useEffect, useState } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { Button, TextField } from "@material-ui/core";

import {
  OVERVIEW,
  AGE,
  GENDER,
  CAMPAIGNS,
  AD_PLACEMENTS,
  LOCATIONS,
  AD_SCHEDULE,
  DEVICES,
  OPERATING_SYSTEM,
  NETWORKS,
  PIXELS,
  PIXEL_GOALS,
  SETTINGS,
  ADMIN_CAMPAIGNS,
  ADMIN_USERS,
  ADMIN_CONFIGURATIONS,
  SUB_USERS
} from "pathnameVariables";
import { getCampaignsList, setStatisticParams } from "redux/actions/campaigns";
import { setSearchInputValue, setOpenSubUserModal } from "redux/actions/admin";

import DashboardWrapper from "shared/DashboardWrapper";
import DashboardSideMenu from "shared/DashboardSideMenu";
import DashboardContentWrapper from "shared/DashboardContentWrapper";
import CustomDatePicker from "shared/CustomDatePicker";
import ModalWrapper from "shared/ModalWrapper";

import SearchIcon from "assets/images/searchIcon.svg";
import InviteIcon from "assets/images/inviteUserIcon.svg";

import AdPlacements from "./AdPlacements";
import Overview from "./Overview";
import Campaigns from "./Campaigns";
import Age from "./Age";
import Gender from "./Gender";
import Locations from "./Locations";
import AdSchedule from "./AdSchedule";
import Devices from "./Devices";
import OperatingSystem from "./OperatingSystem";
import Network from "./Network";
import Pixels from "./Pixels";
import PixelGoals from "./Pixels/PixelGoals";
import Settings from "./Settings";
import AdminCampaigns from "./AdminCampaigns";
import AdminUsers from "./AdminUsers";
import InviteUserModal from "./AdminUsers/InviteUserModal";
import AdminConfigurations from "./AdminConfigurations";
import SubUsers from "./SubUsers";

export const Dashboard = ({
  handleGetCampaignsList,
  statisticParams,
  handleSetStatisticParams,
  searchInputValue,
  handleSetSearchInputValue,
  handleSetOpenSubUserModal,
  openSubUserModal,
  me
}) => {
  const { pathname } = useLocation();
  const [openInviteUser, setOpenInviteUser] = useState(false);

  useEffect(() => {
    handleGetCampaignsList();
  }, []);

  return (
    <DashboardWrapper>
      <DashboardSideMenu />
      <DashboardContentWrapper
        headerLeftContent={
          <>
            {[ADMIN_CAMPAIGNS, ADMIN_USERS, SUB_USERS].includes(pathname) && (
              <>
                <TextField
                  className="search-head-text-field"
                  placeholder="Search by email"
                  value={searchInputValue}
                  onChange={e => handleSetSearchInputValue(e.target.value)}
                  InputProps={{ startAdornment: <img src={SearchIcon} alt="" /> }}
                />
                {pathname === ADMIN_USERS && (
                  <>
                    <Button onClick={() => setOpenInviteUser(true)} className="invite-user-btn">
                      Invite user
                      <img src={InviteIcon} alt="" />
                    </Button>
                    <ModalWrapper handleClose={() => setOpenInviteUser(false)} open={openInviteUser}>
                      <InviteUserModal handleClose={() => setOpenInviteUser(false)} />
                    </ModalWrapper>
                  </>
                )}
                {pathname === SUB_USERS && (
                  <>
                    <Button onClick={() => handleSetOpenSubUserModal(true)} className="invite-user-btn">
                      Invite user
                      <img src={InviteIcon} alt="" />
                    </Button>
                  </>
                )}
              </>
            )}
            <ModalWrapper handleClose={() => handleSetOpenSubUserModal(false)} open={openSubUserModal}>
              <InviteUserModal handleClose={() => handleSetOpenSubUserModal(false)} isSubUsers />
            </ModalWrapper>
            {![ADMIN_CAMPAIGNS, ADMIN_USERS, ADMIN_CONFIGURATIONS, SUB_USERS].includes(pathname) && (
              <div className="dashboard-content-head-content">
                <CustomDatePicker />
                {statisticParams?.campaign && (
                  <div className="selected-campaign-box">
                    {statisticParams?.campaign?.name}
                    <Button onClick={() => handleSetStatisticParams({ ...statisticParams, campaign: null })}>
                      All campaigns
                    </Button>
                  </div>
                )}
              </div>
            )}
          </>
        }
      >
        <Switch>
          <Route exact path={OVERVIEW} component={Overview} />
          <Route exact path={CAMPAIGNS} component={Campaigns} />
          <Route exact path={AGE} component={Age} />
          <Route exact path={GENDER} component={Gender} />
          <Route exact path={AD_PLACEMENTS} component={AdPlacements} />
          <Route exact path={LOCATIONS} component={Locations} />
          <Route exact path={AD_SCHEDULE} component={AdSchedule} />
          <Route exact path={DEVICES} component={Devices} />
          <Route exact path={OPERATING_SYSTEM} component={OperatingSystem} />
          <Route exact path={NETWORKS} component={Network} />
          <Route exact path={PIXELS} component={Pixels} />
          <Route exact path={PIXEL_GOALS} component={PixelGoals} />
          <Route exact path={SETTINGS} component={Settings} />

          <Route exact path={SUB_USERS} component={SubUsers} />
          {me?.role === 2 && (
            <>
              <Route exact path={ADMIN_CAMPAIGNS} component={AdminCampaigns} />
              <Route exact path={ADMIN_USERS} component={AdminUsers} />
              <Route exact path={ADMIN_CONFIGURATIONS} component={AdminConfigurations} />
            </>
          )}
        </Switch>
      </DashboardContentWrapper>
    </DashboardWrapper>
  );
};

const mapStateToProps = state => ({
  statisticParams: state.campaigns.statisticParams,
  searchInputValue: state.admin.searchInputValue,
  openSubUserModal: state.admin.openSubUserModal,
  me: state.me
});

const mapDispatchToProps = dispatch => ({
  handleGetCampaignsList: payload => dispatch(getCampaignsList(payload)),
  handleSetStatisticParams: payload => dispatch(setStatisticParams(payload)),
  handleSetOpenSubUserModal: payload => dispatch(setOpenSubUserModal(payload)),
  handleSetSearchInputValue: payload => dispatch(setSearchInputValue(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
