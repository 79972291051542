import React, { useState } from "react";
import moment from "moment";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import { Button, Popover } from "@material-ui/core";

import { setStatisticParams } from "redux/actions/campaigns";

import LeftArrow from "assets/images/arrowLeftBlue.svg";
import LeftArrowWhite from "assets/images/arrowLeftWhite.svg";
import RightArrow from "assets/images/arrowRightBlue.svg";
import RightArrowWhite from "assets/images/arrowRightWhite.svg";

import "./styles.scss";

const months = [
  {
    label: "January",
    value: "01"
  },
  {
    label: "February",
    value: "02"
  },
  {
    label: "March",
    value: "03"
  },
  {
    label: "April",
    value: "04"
  },
  {
    label: "May",
    value: "05"
  },
  {
    label: "June",
    value: "06"
  },
  {
    label: "July",
    value: "07"
  },
  {
    label: "August",
    value: "08"
  },
  {
    label: "September",
    value: "09"
  },
  {
    label: "October",
    value: "10"
  },
  {
    label: "November",
    value: "11"
  },
  {
    label: "December",
    value: "12"
  }
];
const rangeVariants = [
  {
    label: "Current day",
    value: "day",
    action: () => ({ startDate: new Date(), endDate: new Date() })
  },
  {
    label: "Current week",
    value: "week",
    action: () => ({ startDate: new Date(moment().startOf("week")), endDate: new Date(moment().endOf("week")) })
  },
  {
    label: "Last 7 days",
    value: "7_days",
    action: () => ({ startDate: new Date(moment().subtract(7, "d")), endDate: new Date() })
  },
  {
    label: "Current month",
    value: "month",
    action: () => ({ startDate: new Date(moment().startOf("month")), endDate: new Date(moment().endOf("month")) })
  },
  {
    label: "Last 3 month",
    value: "3_month",
    action: () => ({
      startDate: new Date(
        moment()
          .subtract(2, "month")
          .startOf("month")
      ),
      endDate: new Date(moment().endOf("month"))
    })
  },
  {
    label: "Current year",
    value: "year",
    action: () => ({ startDate: new Date(moment().startOf("year")), endDate: new Date() })
  }
];

export const CalendarPopover = ({ children, state, setState, vertical = "top", horizontal = "center" }) => {
  return (
    <Popover
      open={Boolean(state)}
      anchorEl={state}
      onClose={() => setState(null)}
      className="custom-date-range-picker-wrapper"
      anchorOrigin={{
        vertical,
        horizontal
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center"
      }}
    >
      {children}
    </Popover>
  );
};

export const ProdDatePicker = ({ userInfo, ...props }) => {
  const [isOpenMonths, setIsOpenMonths] = useState(false);
  const [isOpenYears, setIsOpenYears] = useState(false);

  return (
    <div className={`prod-date-picker ${isOpenMonths ? "hide-body" : ""}`}>
      <DatePicker
        {...props}
        inline
        formatWeekDay={nameOfDay => nameOfDay.substr(0, 1)}
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled
        }) => (
          <div className="custom-header">
            <Button
              className="change-month-btn"
              onClick={decreaseMonth}
              disabled={prevMonthButtonDisabled || isOpenMonths || isOpenYears}
            >
              <img src={userInfo?.isDarkTheme ? LeftArrowWhite : LeftArrow} alt="" />
            </Button>
            <div className="year-month-btn-box">
              <Button
                onClick={() => {
                  setIsOpenMonths(!isOpenMonths);
                  setIsOpenYears(false);
                }}
                className="month-btn"
              >
                {moment(date).format("MMM")}
              </Button>
              <Button
                onClick={() => {
                  setIsOpenYears(!isOpenYears);
                  setIsOpenMonths(false);
                }}
              >
                {moment(date).format("YYYY")}
              </Button>
            </div>
            <Button
              className="change-month-btn"
              onClick={increaseMonth}
              disabled={nextMonthButtonDisabled || isOpenMonths || isOpenYears}
            >
              <img src={userInfo?.isDarkTheme ? RightArrowWhite : RightArrow} alt="" />
            </Button>
            {isOpenMonths && (
              <div className="custom-months-box">
                {months?.map((val, i) => (
                  <Button
                    className={val.value === moment(date).format("MM") ? "active" : ""}
                    key={val.value}
                    onClick={() => {
                      changeMonth(i);
                      setIsOpenMonths(false);
                    }}
                  >
                    {val.label}
                  </Button>
                ))}
              </div>
            )}
            {isOpenYears && (
              <div className="custom-months-box">
                {new Array(moment().diff("1950-01-01", "years")).fill(0).map((val, i) => {
                  const value = Number(moment().format("YYYY")) - i;
                  return (
                    <Button
                      className={`${value}` === moment(date).format("YYYY") ? "active" : ""}
                      key={i.toString()}
                      onClick={() => {
                        changeYear(value);
                        setIsOpenYears(false);
                      }}
                    >
                      {value}
                    </Button>
                  );
                })}
              </div>
            )}
          </div>
        )}
      />
    </div>
  );
};

const CustomDatePicker = ({ statisticParams, handleSetStatisticParams, userInfo }) => {
  const initialDataRange = { startDate: statisticParams?.startDate, endDate: statisticParams?.endDate };
  const [openPopover, setOpenPopover] = useState(null);
  const [dateRange, setDataRange] = useState(initialDataRange);
  const [activeRange, setActiveRange] = useState(null);

  return (
    <>
      <Button
        className={`custom-date-range-picker-btn ${openPopover ? "active" : ""}`}
        onClick={e => setOpenPopover(e.currentTarget)}
      >
        {`${moment(statisticParams.startDate).format("MMM DD, YYYY")} — ${moment(statisticParams.endDate).format(
          "MMM DD, YYYY"
        )}`}
      </Button>
      <CalendarPopover setState={setOpenPopover} state={openPopover} vertical={36} horizontal={208}>
        <div className="calendar-wrapper">
          <div className="range-variants-box">
            {rangeVariants.map(val => (
              <Button
                className={activeRange === val.value ? "active" : ""}
                onClick={() => {
                  setDataRange(val?.action());
                  setActiveRange(val.value);
                }}
                key={val.value}
              >
                {val.label}
              </Button>
            ))}
          </div>
          <div className="react-calendar-wrapper">
            <ProdDatePicker
              selected={dateRange.startDate}
              onChange={dates => setDataRange({ startDate: dates[0], endDate: dates[1] })}
              startDate={dateRange.startDate}
              endDate={dateRange.endDate}
              userInfo={userInfo}
              selectsRange
            />
            <div className="custom-date-picker-btn-box">
              <Button
                onClick={() => {
                  setDataRange(initialDataRange);
                  setOpenPopover(null);
                }}
              >
                Cancel
              </Button>
              <Button
                disabled={!dateRange.startDate || !dateRange.endDate}
                onClick={() => {
                  handleSetStatisticParams({ ...statisticParams, ...dateRange });
                  setOpenPopover(null);
                }}
              >
                OK
              </Button>
            </div>
          </div>
        </div>
      </CalendarPopover>
    </>
  );
};

const mapStateToProps = state => ({
  userInfo: state.userProfile.userInfo,
  statisticParams: state.campaigns.statisticParams
});

const mapDispatchToProps = dispatch => ({
  handleSetStatisticParams: payload => dispatch(setStatisticParams(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomDatePicker);
