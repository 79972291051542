import React, { useEffect, useState } from "react";
import { dsToast } from "shared/dsToast";
import { connect } from "react-redux";

import CampaignsService from "services/campaigns";
import { ageTableColumns, diagramSelectOptions } from "constants/tableColumns";
import { campaignStatusVariants } from "constants/campaignStatusVariants";
import { parseCampaignStatus } from "helpers/parseCampaignStatus";

import DashboardContent from "shared/DashboardContent";
import CustomVerticalBarChart from "shared/CustomVerticalBarChart";

import DashboardDiagramWrapper from "shared/DashboardDiagramCard";
import DashboardTableCard from "shared/DashboardTableCard";

export const sumStatistic = (rawData, arrKey, labelKey, secondKey) => {
  const getObj = obj => {
    if (secondKey) {
      return obj?.[arrKey][secondKey];
    }
    return obj?.[arrKey];
  };
  return getObj(rawData[0])?.map(val => {
    const metrics = {
      clicks: 0,
      convs_rate: 0,
      cpm: 0,
      imps: 0
    };
    Object.keys(metrics).forEach(key =>
      rawData.forEach(
        value =>
          (metrics[key] += getObj(value).find(
            dateDetails => dateDetails[labelKey] === val[labelKey]
          ).statisticDetails[key])
      )
    );
    return {
      label: val[labelKey],
      ...metrics
    };
  });
};

const Age = ({ statisticParams }) => {
  const [isEmpty, setIsEmpty] = useState(false);
  const [campaignsStatus, setCampaignsStatus] = useState(campaignStatusVariants[0]);
  const [isLoading, setIsLoading] = useState(true);
  const [initialStatisticData, setInitialStatisticData] = useState([]);
  const [convertStatisticData, setConvertStatisticData] = useState([]);
  const [statisticData, setStatisticData] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  const [diagramDataType, setDiagramDataType] = useState(diagramSelectOptions[0]);
  const [diagramData, setDiagramData] = useState([]);

  const getData = data => {
    const columnName = index => ({ columnName: ageTableColumns[index].value });
    return sumStatistic(data, "sumAgeStatistic", "age_bucket")?.map(val => {
      return {
        rowName: val.label,
        columns: [
          { label: val.label, isFirst: true, noIcon: true, ...columnName(0) },
          { label: val.clicks || 0, ...columnName(1) },
          { label: val.convs_rate || 0, ...columnName(2) },
          { label: val.cpm?.toFixed(2) || 0, ...columnName(3) },
          { label: val.imps || 0, ...columnName(4) }
        ]
      };
    });
  };

  useEffect(() => {
    setDiagramData(
      convertStatisticData?.map(val => ({ ...val, value: val[diagramDataType.value], tooltip: diagramDataType.label }))
    );
  }, [convertStatisticData, diagramDataType]);

  useEffect(() => {
    setIsLoading(true);
    CampaignsService.getCampaignsStatistic("demographics", statisticParams)
      .then(data => {
        setInitialStatisticData(data);
        setConvertStatisticData(sumStatistic(data, "sumAgeStatistic", "age_bucket"));
        if (!getData(data)?.length) {
          setIsEmpty(true);
        } else {
          setIsEmpty(false);
        }
      })
      .catch(() => dsToast.error("We cannot get age statistics. Please try again later."))
      .finally(() => setIsLoading(false));
  }, [statisticParams]);

  useEffect(() => {
    setTableRows(getData(statisticData));
  }, [statisticData]);

  useEffect(() => {
    setStatisticData(
      initialStatisticData.filter(val => {
        if (campaignsStatus?.statusCode) {
          return parseCampaignStatus(val.campaign)?.statusCode === campaignsStatus?.statusCode;
        }
        return val;
      })
    );
  }, [campaignsStatus, initialStatisticData]);

  return (
    <DashboardContent isLoading={isLoading} isEmpty={isEmpty} emptyTitle="Try to select other dates">
      <DashboardDiagramWrapper
        pinkSelect={diagramDataType}
        setPinkSelect={setDiagramDataType}
        options={diagramSelectOptions}
      >
        <CustomVerticalBarChart data={diagramData?.sort((a, b) => (a.label > b.label ? 1 : -1))} />
      </DashboardDiagramWrapper>
      <DashboardTableCard
        rows={tableRows}
        columns={ageTableColumns}
        setCampaignsStatus={setCampaignsStatus}
        campaignsStatus={campaignsStatus}
      />
    </DashboardContent>
  );
};

const mapStateToProps = state => ({
  statisticParams: state.campaigns.statisticParams
});

export default connect(mapStateToProps)(Age);
