const config = {
  // This for local development
  development: {
    api: {
      protocol: process.env.REACT_APP_SERVER_APP_PROTOCOL,
      hostname: process.env.REACT_APP_SERVER_APP_PORT ?
        `${process.env.REACT_APP_SERVER_APP_HOSTNAME}:${process.env.REACT_APP_SERVER_APP_PORT}` :
        `${process.env.REACT_APP_SERVER_APP_HOSTNAME}`,
      get serverUrl() {
        return `${this.protocol}://${this.hostname}`;
      },
      endpoint(suffix) {
        return `${this.serverUrl}${suffix.indexOf("v2") !== -1 ? suffix : `/api/v1${suffix}`}`;
      }
    }
  },
  production: {
    api: {
      protocol: process.env.REACT_APP_SERVER_APP_PROTOCOL,
      hostname: process.env.REACT_APP_SERVER_APP_PORT ?
        `${process.env.REACT_APP_SERVER_APP_HOSTNAME}:${process.env.REACT_APP_SERVER_APP_PORT}` :
        `${process.env.REACT_APP_SERVER_APP_HOSTNAME}`,
      get serverUrl() {
        return `${this.protocol}://${this.hostname}`;
      },
      endpoint(suffix) {
        return suffix.indexOf("v2") !== -1 ? suffix : `/api/v1${suffix}`;
      }
    }
  }
};

export default config[process.env.NODE_ENV];
