import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import { dsToast } from "shared/dsToast";
import { connect } from "react-redux";

import Admin from "services/admin";
import { isValidEmail } from "helpers/validateFunctions";
import { setSubUsersList } from "redux/actions/admin";

import Loader from "shared/Loader";
import StyledTextField from "shared/StyledTextField";

import CloseIcon from "assets/images/closeBlue.svg";

import "./styles.scss";

const InviteUserModal = ({ handleClose, isSubUsers, handleSetSubUsersList, subUsersList }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  const handleGetSubUsers = () => {
    Admin.getSubUsers().then(data => handleSetSubUsersList(data));
  };

  useEffect(() => {
    handleGetSubUsers();
  }, []);

  const handleSendInvitation = () => {
    if (!isValidEmail(email)) {
      return setError("Email is not valid");
    }
    if (isSubUsers && subUsersList?.includes(email)) {
      return setError("User with this email already invited");
    }
    setIsLoading(true);
    const api = isSubUsers ? Admin.inviteSubUserByEmail : Admin.inviteByEmail;
    return api(email)
      .then(() => {
        if (isSubUsers) {
          handleGetSubUsers();
        }
        dsToast.success("The invitation was sent successfully");
        handleClose();
      })
      .catch(() => dsToast.error("Sorry, something went wrong. Please try again later"))
      .finally(() => setIsLoading(false));
  };

  return (
    <div className="invite-user-modal">
      {isLoading && (
        <div className="loader-box">
          <Loader empty isDashboardContent />
        </div>
      )}
      <div className="invite-user-head">
        Invite user by email
        <Button onClick={handleClose}>
          <img src={CloseIcon} alt="" />
        </Button>
      </div>
      <StyledTextField
        error={error}
        helperText={error}
        label="User email"
        placeholder="example@email.com"
        value={email}
        onChange={e => {
          setEmail(e.target.value);
          setError("");
        }}
      />
      <Button disabled={!email} onClick={handleSendInvitation} className="invite-btn">
        Send invitation
      </Button>
    </div>
  );
};

const mapStateToProps = state => ({
  subUsersList: state.admin.subUsersList
});

const mapDispatchToProps = dispatch => ({
  handleSetSubUsersList: payload => dispatch(setSubUsersList(payload))
});

export default connect(null, mapDispatchToProps)(InviteUserModal);
