import React from "react";
import { connect } from "react-redux";
import { ContextMenu, ContextMenuTrigger } from "react-contextmenu";
import { Button } from "@material-ui/core";

import { parseCampaignStatus } from "helpers/parseCampaignStatus";
import CampaignsService from "services/campaigns";

import { dsToast } from "shared/dsToast";

import "./styles.scss";

const CampaignContextMenu = ({ campaign, setIsLoading, getCampaigns }) => {
  const handleManageCampaign = () => {
    setIsLoading(true);
    const successAction = () => {
      getCampaigns();
      setIsLoading(false);
      dsToast.success(`Campaign updated successful`);
    };
    const errorAction = () => {
      setIsLoading(false);
      dsToast.error("Sorry, something went wrong. Please try again later");
    };
    if (campaign.isRunning) {
      CampaignsService.disableCampaign(campaign?.id)
        .then(successAction)
        .catch(errorAction);
    } else {
      CampaignsService.enableCampaign(campaign?.id)
        .then(successAction)
        .catch(errorAction);
    }
  };

  return (
    <ContextMenu id={campaign?.id} className="campaign-context-menu">
      <div className="campaign-current-status">
        Status:{" "}
        <span style={{ color: parseCampaignStatus(campaign).color }}>{parseCampaignStatus(campaign).status}</span>
      </div>
      <Button className="enable-btn" disabled={campaign?.status !== 2} onClick={handleManageCampaign}>
        {campaign.isRunning ? "Disable campaign" : "Enable campaign"}
      </Button>
    </ContextMenu>
  );
};

export default CampaignContextMenu;
