import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { dsToast } from "shared/dsToast";
import moment from "moment";

import CampaignsService from "services/campaigns";
import { parseCampaignStatus } from "helpers/parseCampaignStatus";
import { settingsTableColumns } from "constants/tableColumns";
import { campaignStatusVariants } from "constants/campaignStatusVariants";
import { EXPERT_GOAL, EXPERT_CREATION } from "pathnameVariables";

import DashboardTableCard from "shared/DashboardTableCard";
import DashboardContent from "shared/DashboardContent";

import { rotation } from "pages/ExpertMode/CampaignCreation/cards/AdRotation";
import { methods } from "pages/ExpertMode/CampaignCreation/cards/Budget";
import { days, time } from "pages/ExpertMode/CampaignCreation/cards/AdSchedule";

import "./styles.scss";

const Campaigns = () => {
  const { push } = useHistory();
  const [campaignsStatus, setCampaignsStatus] = useState(campaignStatusVariants[0]);
  const [isLoading, setIsLoading] = useState(true);
  const [tableRows, setTableRows] = useState([]);

  const handleGetCampaigns = () => {
    setIsLoading(true);
    CampaignsService.getCampaignsList()
      .then(data => {
        const columnName = index => ({ columnName: settingsTableColumns[index].value });
        setTableRows(
          data.map(val => {
            return {
              campaignInfo: val,
              columns: [
                {
                  label: val.name,
                  onClick: () => push(`${EXPERT_CREATION}?id=${val.id}&update="true"`),
                  isFirst: true,
                  withContext: true,
                  ...columnName(0)
                },
                { label: parseCampaignStatus(val).status, ...columnName(1) },
                { label: `${val.budget.amount} ${val.budget.currency}`, ...columnName(2) },
                { label: `${val.bidings[0].payFor}`, ...columnName(3) },
                { label: methods.find(value => value.value === val.deliveryMethod.toString()).label, ...columnName(4) },
                { label: rotation.find(value => value.value === val.adRotation.toString()).label, ...columnName(5) },
                { label: moment(val.campaignStartDate).format("YYYY-MM-DD"), ...columnName(6) },
                {
                  label: val.campaignEndDate ? moment(val.campaignEndDate).format("YYYY-MM-DD") : "Till stop",
                  ...columnName(7)
                },
                { label: val.targetLanguages.map(value => value.languageCodeISO).join(", "), ...columnName(8) },
                {
                  label: val.targetLocation.includes.map(value => value.formattedAddress || value.country).join(", "),
                  ...columnName(9)
                },
                {
                  label: val.adSchedules
                    .map(value => {
                      const getLabel = (arr, key) => arr.find(item => item.value === value[key].toString())?.label;
                      return `${getLabel(days, "days")}, ${getLabel(time, "from")} - ${getLabel(time, "to")}`;
                    })
                    .join(", "),
                  ...columnName(10)
                },
                {
                  label: val.targetNetworkDevices.isAllNetworkDevices
                    ? "All device types"
                    : val.targetNetworkDevices.selectedDeviceTypes.map(value => value.name).join(", "),
                  ...columnName(11)
                },
                { label: val.isLocked ? "Locked" : "Not locked", ...columnName(12) }
              ]
            };
          })
        );
      })
      .catch(() => dsToast.error("Sorry, we cant upload data"))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    handleGetCampaigns();
  }, []);

  return (
    <DashboardContent
      isEmpty={!tableRows?.length}
      isLoading={isLoading}
      emptyTitle="You have no campaigns yet"
      emptyBtnClick={() => push(EXPERT_GOAL)}
      emptyBtnLabel="Create campaign"
      className="dashboard-settings-page"
    >
      <DashboardTableCard
        fullHeight
        rows={tableRows}
        columns={settingsTableColumns}
        setCampaignsStatus={setCampaignsStatus}
        campaignsStatus={campaignsStatus}
        getCampaigns={handleGetCampaigns}
        setIsLoading={setIsLoading}
      />
    </DashboardContent>
  );
};

export default Campaigns;
