import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { CAMPAIGNS } from "pathnameVariables";

import LogoutIcon from "assets/images/mainLogo.svg";
import LogoutIconWhite from "assets/images/mainLogoWhite.svg";

import "./styles.scss";

const LogoLink = ({ userInfo }) => {
  return (
    <Link to={CAMPAIGNS} className="header-logo-link">
      <img src={userInfo?.isDarkTheme ? LogoutIconWhite : LogoutIcon} alt="" />
    </Link>
  );
};

const mapStateToProps = state => ({
  userInfo: state.userProfile.userInfo
});

export default connect(mapStateToProps)(LogoLink);
