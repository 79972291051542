import React, { useEffect, useState, useCallback } from "react";
import { TextField } from "@material-ui/core";
import { uniq } from "lodash";

import CampaignsCreation from "services/campaignCreation";
import debounce from "helpers/debounce";

import StyledTextField from "shared/StyledTextField";
import Loader from "shared/Loader";
import StyledChip from "shared/StyledChip";

import NoDataImage from "assets/images/noDataImage.svg";

import "./styles.scss";

const Keywords = ({ input }) => {
  const [isKeywordLoading, setIsKeywordLoading] = useState(false);
  const [suggestedKeywords, setSuggestedKeywords] = useState([]);
  const [keyWordMessage, setKeyWordMessage] = useState("");
  const [keywords, setKeywords] = useState("");

  useEffect(() => {
    setKeywords(input?.value?.keywords?.join("\r\n"));
  }, [input?.value?.keywords]);

  const handleAddKeyword = keyword => {
    const keywordsArr = uniq([
      ...keywords
        .split(/\r?\n/)
        .filter(v => v.length > 0)
        .map(e => e.trim()),
      keyword.trim()
    ]);
    return input?.onChange({ ...input?.value, keywords: keywordsArr });
  };

  const keywordInputOnBlur = () => {
    const keywordsArr = uniq(
      keywords
        .split(/\r?\n/)
        .filter(v => v.length > 0)
        .map(e => e.trim())
    );
    return input?.onChange({ ...input?.value, keywords: keywordsArr });
  };

  const handleGetWebsiteKeywords = val => {
    setKeyWordMessage("");
    setIsKeywordLoading(true);
    CampaignsCreation.getWebsiteKeywords(val)
      .then(data => {
        if (data.message) {
          setKeyWordMessage(`${data.message[0].toUpperCase()}${data.message.slice(1)}`);
        }
        if (data.keywords) {
          setSuggestedKeywords(data.keywords);
        }
      })
      .finally(() => setIsKeywordLoading(false));
  };

  const handleGetServicesKeywords = val => {
    setKeyWordMessage("");
    setIsKeywordLoading(true);
    CampaignsCreation.getServicesKeywords(val)
      .then(data => {
        if (!data.results?.length) {
          setKeyWordMessage("Keywords not found");
        } else {
          setSuggestedKeywords(data.results.map(value => value.keyword));
        }
      })
      .finally(() => setIsKeywordLoading(false));
  };

  const debounceWebsite = useCallback(debounce(handleGetWebsiteKeywords, 1000), []);
  const debounceServices = useCallback(debounce(handleGetServicesKeywords, 1000), []);

  return (
    <div className="keywords-card-wrapper">
      <div className="text-field-part">
        <TextField
          placeholder="Where you want your ads to show"
          multiline
          rowsMax={4}
          value={keywords}
          onChange={e => setKeywords(e.target.value)}
          onBlur={keywordInputOnBlur}
        />
      </div>
      <div className="keywords-part">
        <div className="keywords-card-title">Get keyword ideas</div>
        <StyledTextField onChange={e => debounceWebsite(e.target.value)} label="Enter a related website" />
        <StyledTextField onChange={e => debounceServices(e.target.value)} label="Enter a related services" />
        <div className="keywords-list-wrapper">
          {isKeywordLoading ? (
            <Loader empty isDashboardContent />
          ) : (
            <>
              {keyWordMessage && (
                <div className="message-box">
                  <img src={NoDataImage} alt="" />
                  {keyWordMessage}
                </div>
              )}
              {suggestedKeywords?.length > 0 && (
                <div className="keywords-list">
                  {suggestedKeywords?.map((val, i) => (
                    <StyledChip
                      disabled={input?.value?.keywords?.includes(val.trim())}
                      key={i.toString()}
                      onClick={() => handleAddKeyword(val)}
                      label={`+ ${val}`}
                    />
                  ))}
                </div>
              )}
              {!keyWordMessage && !suggestedKeywords?.length && (
                <div className="keywords-description">
                  We only show keyword ideas that are relevant to your business. To get ideas, enter your landing
                  page, a related website, or words or phrases that describe your product or service in the field
                  above.
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Keywords;
