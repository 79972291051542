import React, { useEffect, useState } from "react";

import { GENDER } from "pathnameVariables";
import { diagramSelectOptions } from "constants/tableColumns";
import { colors } from "constants/chartColors";
import { getRoundValue } from "helpers/statisticHelpFunctions";

import DiagramSelect from "shared/DiagramSelect";
import StyledPieChart from "shared/StyledPieChart";
import StyledHeatMapChart from "shared/StyledHeatMapChart";
import { sumStatistic } from "pages/Dashboard/Age";

import StatisticCard from "../../components/StatisticCard";
import TabSelection from "../../components/TabSelection";

import "./styles.scss";

const genderAgeTabs = [
  {
    value: "age",
    label: "Age"
  },
  {
    value: "genAge",
    label: "Gender and Age"
  },
  {
    value: "gender",
    label: "Gender"
  }
];

const GenderAndAge = ({ data }) => {
  const [genderTab, setGenderTab] = useState(genderAgeTabs[0]);
  const [genderDataType, setGenderDataType] = useState(diagramSelectOptions[0]);
  const [statisticData, setStatisticData] = useState(null);
  const [currentData, setCurrentData] = useState([]);

  useEffect(() => {
    if (data) {
      setStatisticData({
        gender: sumStatistic(data, "sumGenderStatistic", "gender")?.map((val, i) => ({
          ...val,
          label: val.label === "m" ? "Male" : val.label === "f" ? "Female" : "Unknown",
          color: colors[i]
        })),
        age: sumStatistic(data, "sumAgeStatistic", "age_bucket")?.map((val, i) => ({
          color: colors[i],
          ...val
        }))
      });
    }
  }, [data]);

  useEffect(() => {
    if (statisticData) {
      const tabData = statisticData[genderTab.value];
      if (genderTab.value !== "genAge") {
        setCurrentData(tabData?.map(val => ({ ...val, value: getRoundValue(val[genderDataType.value]) })));
      } else {
        const getGenAgeData = () => {
          const sumFemale = sumStatistic(data, "sumAgeAndGender", "age_bucket", "f");
          const sumMale = sumStatistic(data, "sumAgeAndGender", "age_bucket", "m");
          const sumUnknown = sumStatistic(data, "sumAgeAndGender", "age_bucket", "u");
          const xLabels = statisticData?.age?.map(val => val.label)?.sort();
          const yLabels = ["Unknown", "Male", "Female"];
          const result = [sumUnknown, sumMale, sumFemale]?.map(val => {
            return val?.map(value => getRoundValue(value[genderDataType?.value]));
          });
          return { data: result, xLabels, yLabels };
        };
        setCurrentData([getGenAgeData()]);
      }
    }
  }, [statisticData, genderTab, genderDataType, data]);

  return (
    <StatisticCard
      title="Gender and Age"
      id="gender-and-age"
      btnTitle="Gender"
      className="gender-card"
      href={GENDER}
      isEmpty={statisticData && !statisticData?.gender?.length && !statisticData?.age?.length}
      footMobileContent={
        <DiagramSelect
          options={diagramSelectOptions}
          value={genderDataType}
          onChange={val => setGenderDataType(val)}
          isDefault
        />
      }
      secondHeaderContent={
        <>
          <TabSelection tabs={genderAgeTabs} onChange={val => setGenderTab(val)} value={genderTab} />
          <DiagramSelect
            className="head-select"
            options={diagramSelectOptions}
            value={genderDataType}
            onChange={val => setGenderDataType(val)}
            isDefault
          />
        </>
      }
    >
      {genderTab?.value === "genAge" ? (
        <StyledHeatMapChart
          yLabelWidth={56}
          data={currentData[0]?.data}
          xLabels={currentData[0]?.xLabels}
          yLabels={currentData[0]?.yLabels}
        />
      ) : (
        <StyledPieChart data={currentData?.sort((a, b) => (a.label > b.label ? 1 : -1))} />
      )}
    </StatisticCard>
  );
};

export default GenderAndAge;
