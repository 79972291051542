import React, { useState } from "react";
import { Button } from "@material-ui/core";

import { isValidUrl } from "helpers/validateFunctions";

import StyledTextField from "shared/StyledTextField";
import SimpleBtn from "shared/SimpleBtn";
import AdsDropFilesZone from "shared/AdsDropFilesZone";

import RemoveIcon from "assets/images/removeIconBlue.svg";

import ExpertModeCard from "../../components/ExpertModeCard";

import "./styles.scss";

const Ads = ({ input }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [urlError, setUrlError] = useState("");

  const checkOnHttps = e => {
    const { value } = e.target;
    const url = value.includes("https://") || value.includes("http://") ? value : `https://${value}`;
    return input?.onChange({ ...input?.value, url });
  };

  const handleUpdateSuffix = (key, value, index, e) => {
    return input?.onChange({
      ...input?.value,
      suffixes: input?.value?.suffixes?.map((val, i) => (i === index ? { ...value, [key]: e.target.value } : val))
    });
  };

  return (
    <ExpertModeCard id="ads-card" title="Ads" className="ads-card-wrapper">
      <div className="card-main-subtitle">
        Create an ad now, or skip this step and create one later. Your campaign won't run without an ad
      </div>
      <div className={`tab-header ${activeTab ? "second-tab-active" : ""}`}>
        <Button onClick={() => setActiveTab(0)}>Upload display ads</Button>
        <Button onClick={() => setActiveTab(1)}>Upload an ad</Button>
      </div>
      <div className="tabs-content">
        {!activeTab ? (
          <>
            <StyledTextField
              className="url-field"
              helperText={urlError}
              label="Final URL"
              value={input?.value?.url}
              onChange={e => {
                setUrlError("");
                return input?.onChange({ ...input?.value, url: e.target.value });
              }}
              onBlur={checkOnHttps}
            />
            <div className="section-title">Ad Url Options</div>
            <div className="card-subtitle">Final URL suffix</div>
            {input?.value?.suffixes?.map((value, index) => (
              <div key={index.toString()} className="card-field-set">
                <StyledTextField
                  className="long-field"
                  placeholder="Name"
                  value={value?.name}
                  onChange={e => handleUpdateSuffix("name", value, index, e)}
                />
                <div className="text-between-fields">=</div>
                <StyledTextField
                  className="long-field"
                  placeholder="Value"
                  value={value?.value}
                  onChange={e => handleUpdateSuffix("value", value, index, e)}
                />
                {input?.value?.suffixes?.length > 1 && (
                  <Button
                    className="remove-btn"
                    onClick={() =>
                      input?.onChange({
                        ...input?.value,
                        suffixes: input?.value?.suffixes.filter((val, i) => i !== index)
                      })
                    }
                  >
                    <img src={RemoveIcon} alt="" />
                  </Button>
                )}
              </div>
            ))}
            <SimpleBtn
              onClick={() =>
                input?.onChange({ ...input?.value, suffixes: [...input?.value?.suffixes, { name: "", value: "" }] })
              }
            >
              + Add
            </SimpleBtn>
          </>
        ) : (
          <>
            <div className="section-title">Most popular ads</div>
            <div className="card-main-subtitle">
              Please upload at least 5 ads or images. 89% of all ad impressions are made up of these 4 sizes
            </div>
            <AdsDropFilesZone onChange={input?.onChange} rootObj={input?.value} />
          </>
        )}
      </div>
    </ExpertModeCard>
  );
};

export default Ads;
