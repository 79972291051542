import React, { useState } from "react";
import { TextField, InputAdornment, IconButton } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import "./styles.scss";

const StyledTextField = ({ input, label, className, type, inputProps, disabled, ...restProps }) => {
  const [isShowPassword, setIsShowPassword] = useState(false);
  const props = input ? { ...input } : { ...restProps };
  const getInputType = () => {
    if (type === "password") {
      return isShowPassword ? "text" : "password";
    }
    return type;
  };
  return (
    <div className={`styled-text-field-wrapper ${className || ""}`}>
      {label && <div className="styled-text-field-label">{label}</div>}
      <TextField
        {...props}
        disabled={disabled}
        className="styled-text-field"
        type={getInputType()}
        InputProps={{
          ...inputProps,
          endAdornment: type === "password" && (
            <InputAdornment position="end">
              <IconButton
                className="pass-btn"
                onClick={() => setIsShowPassword(!isShowPassword)}
                onMouseDown={e => e.preventDefault()}
              >
                {isShowPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          )
        }}
      />
    </div>
  );
};

export default StyledTextField;
