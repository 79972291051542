import React, { useState } from "react";
import { connect } from "react-redux";
import { Button } from "@material-ui/core";
import moment from "moment";
import { dsToast } from "shared/dsToast";

import UserProfileInfo from "services/userProfile";
import { getUserInfo } from "redux/actions/userProfile";

import EmptyIcon from "assets/images/smallEmptyIcon.svg";
import PlusIcon from "assets/images/plusGray.svg";
import PaymentCardBg from "assets/images/paymentCardBg.svg";
import RemoveIcon from "assets/images/removeIconBlue.svg";

import ModalWrapper from "shared/ModalWrapper";

import SettingsCard from "../components/SettingsCard";
import AddPaymentCard from "./AddPaymentCard";

import "./styles.scss";

const TransactionItem = ({ data, key }) => {
  return (
    <div key={key} className="transaction-item">
      <div className="date-box">
        <div className="date">{moment(data?.createdAt).format("MMM DD, YYYY")}</div>
        <div className="card">
          {data?.cardDetails?.last4} {data?.cardDetails?.brand}
        </div>
      </div>
      <div className="status-box">
        <div className="amount">${data?.totalCost?.toFixed(2)}</div>
        <div className={`status ${data?.status === "failure" ? "error" : ""}`}>
          {data?.status === "failure" ? "FAILURE" : "SUCCESS"}
        </div>
      </div>
    </div>
  );
};

export const PaymentCard = ({ data, key, onClick, onRemoveClick, isPrimary }) => {
  return (
    <div key={key} className={`payment-card-wrapper ${isPrimary ? "active" : ""}`}>
      <img className="bg-image" src={PaymentCardBg} alt="" />
      <div
        onClick={e => {
          if (!e.target.className.includes("Mui")) {
            onClick(data);
          }
        }}
        className="payment-card-content"
      >
        <Button onClick={() => onRemoveClick(data)} className="remove-card-btn">
          <img src={RemoveIcon} alt="" className="Mui-img" />
        </Button>
        <div className="payment-card-data">
          <div>{data?.definition?.brand}</div>
          <div>{`${moment(data?.definition?.exp_month?.toString()).format("MM")}/${moment(
            data?.definition?.exp_year?.toString()
          ).format("YY")}`}</div>
        </div>
        <div className="card-number">
          {new Array(3).fill(0).map(() => (
            <div>****</div>
          ))}
          <div>{data?.definition?.last4}</div>
        </div>
      </div>
    </div>
  );
};

export const AddCardBtn = props => {
  return (
    <Button {...props} className="add-card-btn">
      <img src={PlusIcon} alt="" />
    </Button>
  );
};

const Balance = ({
  userInfo,
  transactionHistory,
  getTransactionHistory,
  handleSetUserInfo,
  paymentCardsList,
  getPaymentCardsList
}) => {
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [openAddCardModal, setOpenAddCardModal] = useState(false);

  const handlePay = () => {
    UserProfileInfo.payForSubscription()
      .then(() => {
        UserProfileInfo.getVirtualCredit().then(data => handleSetUserInfo({ ...userInfo, virtualCredit: data }));
      })
      .catch(e => dsToast.error(e.response.data.message))
      .finally(() => {
        getTransactionHistory();
      });
  };

  const handleMakeCardPrimary = card => {
    UserProfileInfo.setDefaultPaymentCard(card.id).then(() => getPaymentCardsList());
  };

  const handleRemoveCard = card => {
    UserProfileInfo.removePaymentCard(card.id).then(() => getPaymentCardsList());
  };

  return (
    <div className="balance-settings-wrapper">
      <div className="cards-box">
        <SettingsCard title="Your Balance">
          <div className="card-subtitle">
            Your next automatic payment is in 30 days or when you reach your payment threshold, whichever comes first.
          </div>
          <div className="used-amount-box">
            <span>{`$${userInfo?.virtualCredit}`}</span>
            <div className="card-subtitle">You’ve used</div>
          </div>
          <Button
            className="make-pay-btn"
            disabled={!userInfo?.virtualCredit || !userInfo?.account?.disableCreditCardBilling}
            onClick={() => setOpenConfirmModal(true)}
          >
            Make Payment
          </Button>
          <ModalWrapper handleClose={() => setOpenConfirmModal(false)} open={openConfirmModal}>
            <div className="confirm-pay-modal-content">
              Your account will be charged
              <div className="btn-box">
                <Button onClick={() => setOpenConfirmModal(false)}>Disagree</Button>
                <Button onClick={handlePay}>Agree</Button>
              </div>
            </div>
          </ModalWrapper>
        </SettingsCard>
        <SettingsCard title="Transactions">
          {transactionHistory && !transactionHistory?.length ? (
            <div className="empty-box">
              <img src={EmptyIcon} alt="" />
              <div>
                Your transactions list
                <br />
                is empty
              </div>
            </div>
          ) : (
            transactionHistory?.map(val => <TransactionItem data={val} key={val.id} />)
          )}
        </SettingsCard>
      </div>
      <div className="cards-box">
        <SettingsCard title="How you pay" className="how-pay-card">
          <div className="card-list">
            {paymentCardsList?.map(val => (
              <PaymentCard
                data={val}
                key={val.id}
                onClick={handleMakeCardPrimary}
                onRemoveClick={handleRemoveCard}
                isPrimary={val.paymentMethodType === "primary"}
              />
            ))}
            <AddCardBtn onClick={() => setOpenAddCardModal(true)} />
            <ModalWrapper handleClose={() => setOpenAddCardModal(false)} open={openAddCardModal}>
              <AddPaymentCard
                handleClose={() => setOpenAddCardModal(false)}
                userInfo={userInfo}
                getPaymentCardsList={getPaymentCardsList}
              />
            </ModalWrapper>
          </div>
        </SettingsCard>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  userInfo: state.userProfile.userInfo
});

const mapDispatchToProps = dispatch => ({
  handleSetUserInfo: payload => dispatch(getUserInfo(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(Balance);
