import React, { useEffect, useState } from "react";
import { dsToast } from "shared/dsToast";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import CampaignsService from "services/campaigns";
import { parseCampaignStatus } from "helpers/parseCampaignStatus";
import { campaignsTableColumns, diagramSelectOptions, diagramSortOptions } from "constants/tableColumns";
import { campaignStatusVariants } from "constants/campaignStatusVariants";
import { getAreaChartData } from "helpers/statisticHelpFunctions";
import { EXPERT_GOAL } from "pathnameVariables";

import EmptyIcon from "assets/images/smallEmptyIcon.svg";

import DashboardDiagramWrapper from "shared/DashboardDiagramCard";
import DashboardTableCard from "shared/DashboardTableCard";
import DashboardContent from "shared/DashboardContent";
import StyledAreaChart from "shared/StyledAreaChart";

import "./styles.scss";

export const getCampaignsTableRows = data => {
  const columnName = index => ({ columnName: campaignsTableColumns[index].value });
  return data.map(val => {
    return {
      campaignInfo: val.campaign,
      columns: [
        { label: val.campaign.name, isFirst: true, ...columnName(0) },
        { label: parseCampaignStatus(val.campaign)?.status, ...columnName(1) },
        { label: val.sumStatistic?.clicks || 0, ...columnName(2) },
        { label: val.sumStatistic?.convs_rate || 0, ...columnName(3) },
        { label: val.sumStatistic?.cpm?.toFixed(2) || 0, ...columnName(4) },
        { label: val.sumStatistic?.imps || 0, ...columnName(5) }
      ]
    };
  });
};

const Campaigns = ({ statisticParams }) => {
  const { push } = useHistory();
  const [campaignsStatus, setCampaignsStatus] = useState(campaignStatusVariants[0]);
  const [blueSelect, setBlueSelect] = useState(diagramSelectOptions[0]);
  const [pinkSelect, setPinkSelect] = useState(diagramSelectOptions[1]);
  const [isLoading, setIsLoading] = useState(true);
  const [tableRows, setTableRows] = useState([]);
  const [rawStatistics, setRawStatistics] = useState([]);
  const [diagramData, setDiagramData] = useState(null);
  const [diagramSort, setDiagramSort] = useState(diagramSortOptions[0]);

  useEffect(() => {
    setIsLoading(true);
    CampaignsService.getCampaignsStatistic("campaigns", statisticParams)
      .then(data => {
        setRawStatistics(data);
        setTableRows(getCampaignsTableRows(data));
      })
      .catch(() => dsToast.error("We cannot get campaign statistics. Please try again later."))
      .finally(() => setIsLoading(false));
  }, [statisticParams]);

  useEffect(() => {
    if (rawStatistics && blueSelect && pinkSelect && diagramSort) {
      const expectedCampaignValue = {
        blue: blueSelect,
        pink: pinkSelect
      };
      setDiagramData(
        getAreaChartData(
          rawStatistics,
          expectedCampaignValue,
          "statisticDetails",
          diagramSort.dateFormat,
          diagramSort.value
        )
      );
    }
  }, [rawStatistics, blueSelect, pinkSelect, diagramSort]);

  return (
    <DashboardContent
      isLoading={isLoading}
      isEmpty={!tableRows?.length}
      emptyTitle="You have no campaigns yet"
      emptyBtnClick={() => push(EXPERT_GOAL)}
      emptyBtnLabel="Create campaign"
      className="campaigns-page-wrapper"
    >
      <DashboardDiagramWrapper
        pinkSelect={pinkSelect}
        setPinkSelect={setPinkSelect}
        blueSelect={blueSelect}
        setBlueSelect={setBlueSelect}
        options={diagramSelectOptions}
        sortOptions={diagramSortOptions}
        setSortValue={setDiagramSort}
        sortValue={diagramSort}
      >
        {diagramData && !diagramData?.length ? (
          <div className="empty-statistic-card">
            <img src={EmptyIcon} alt="" />
            <div>There is no data</div>
            <div className="empty-card-subtitle">Try to select other dates</div>
          </div>
        ) : (
          <StyledAreaChart data={diagramData || []} />
        )}
      </DashboardDiagramWrapper>
      <DashboardTableCard
        rows={tableRows}
        columns={campaignsTableColumns}
        setCampaignsStatus={setCampaignsStatus}
        campaignsStatus={campaignsStatus}
      />
    </DashboardContent>
  );
};

const mapStateToProps = state => ({
  statisticParams: state.campaigns.statisticParams
});

export default connect(mapStateToProps)(Campaigns);
