import React, { useEffect, useState } from "react";
import { dsToast } from "shared/dsToast";
import { Button } from "@material-ui/core";

import Admin from "services/admin";
import { onlyNumberString } from "helpers/onlyNumberString";
import { isValidEmail } from "helpers/validateFunctions";

import DashboardContent from "shared/DashboardContent";
import Loader from "shared/Loader";
import AdminTable from "shared/AdminTable";
import StyledTextField from "shared/StyledTextField";

import "./styles.scss";

const AdminConfigurations = () => {
  const [isBtnDisables, setIsBtnDisables] = useState(false);
  const [isLoading, stIsLoading] = useState(true);
  const [initialValues, setInitialValues] = useState(null);
  const [appnexusMargin, setAppnexusMargin] = useState("");
  const [balanceCap, setBalanceCap] = useState("");
  const [cpm, setCpm] = useState("");
  const [globalEmail, setGlobalEmail] = useState("");

  const inputOnChange = (setFunk, e) => {
    if (onlyNumberString(e.target.value) || e.target.value === "") {
      setFunk(onlyNumberString(e.target.value) || e.target.value);
    }
  };

  const rows = [
    {
      columns: [
        { label: "Balance cap amount", isBold: true },
        { label: <StyledTextField value={balanceCap} onChange={e => inputOnChange(setBalanceCap, e)} /> }
      ]
    },
    {
      columns: [
        { label: "Appnexus Margin", isBold: true },
        { label: <StyledTextField value={appnexusMargin} onChange={e => inputOnChange(setAppnexusMargin, e)} /> }
      ]
    },
    {
      columns: [
        { label: "CPM", isBold: true },
        { label: <StyledTextField value={cpm} onChange={e => inputOnChange(setCpm, e)} /> }
      ]
    },
    {
      columns: [
        { label: "Global admin email", isBold: true },
        { label: <StyledTextField value={globalEmail} onChange={e => setGlobalEmail(e.target.value)} /> }
      ]
    }
  ];

  useEffect(() => {
    Admin.getConfigurations().then(data => {
      stIsLoading(false);
      setInitialValues(data);
    });
  }, []);

  useEffect(() => {
    if (initialValues) {
      setBalanceCap(initialValues?.balanceCap?.amount);
      setAppnexusMargin(initialValues?.appnexusMargin);
      setCpm(initialValues?.cpm ? initialValues?.cpm * 1000 : "");
      setGlobalEmail(initialValues?.globalEmail);
    }
  }, [initialValues]);

  const handleUpdateConfigurations = () => {
    setIsBtnDisables(true);
    const newData = {
      appnexusMargin: Number(appnexusMargin),
      balanceCapAmount: Number(balanceCap),
      cpm: Number(cpm) ? Number(cpm) / 1000 : null,
      globalEmail: globalEmail || null
    };
    if (globalEmail && !isValidEmail(globalEmail)) {
      return dsToast.error("Email is not valid");
    }
    return Admin.updateConfigurations(newData)
      .then(data => {
        dsToast.success("Changes has been saved");
        setInitialValues(data);
      })
      .catch(() => dsToast.error("Sorry, something went wrong. Please try again later"))
      .finally(() => setIsBtnDisables(false));
  };

  const isDisabled =
    Number(appnexusMargin) === initialValues?.appnexusMargin &&
    Number(balanceCap) === initialValues?.balanceCap?.amount &&
    Number(cpm) === initialValues?.cpm * 1000 &&
    globalEmail === initialValues?.globalEmail;

  return (
    <DashboardContent>
      {isLoading ? (
        <Loader empty isDashboardContent />
      ) : (
        <>
          <AdminTable
            withoutPagination
            rows={rows || []}
            columns={[{ label: "Name" }, { label: "Value" }]}
            columnsWidth={["50%", "50%"]}
          />
          <Button
            disabled={isDisabled || isBtnDisables}
            onClick={handleUpdateConfigurations}
            className="update-configuration-btn"
          >
            Update Configuration
          </Button>
        </>
      )}
    </DashboardContent>
  );
};

export default AdminConfigurations;
