import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";

import { BEGINNER_DESCRIBE_BUS, BEGINNER_TARGET_ADS } from "pathnameVariables";
import { setPrevState, setLocations, handlePrevStep } from "redux/actions/beginnerMode";
import BeginnerCampaignCreation from "services/beginnerCampaignCreation";
import SelectLocation from "shared/SelectLocation";

import "./styles.scss";

const usCoords = {
  city: null,
  country: "United States",
  countryCode: "US",
  formattedAddress: "United States",
  lat: 37.09024,
  lng: -95.712891,
  postalCode: null,
  radius: 0,
  region: null,
  regionCode: null
};

const Location = ({ campaignId, handleSetPrevState, handleSetLocations, locations, handlePreviousStep }) => {
  const { push } = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const handleBack = () => {
    setIsLoading(true);
    handlePreviousStep(() => push(BEGINNER_DESCRIBE_BUS));
  };

  const handleNextStep = () => {
    setIsLoading(true);
    const getInclude = () => {
      if (locations?.currentTab) {
        return locations?.include;
      }
      return locations?.isUS ? [usCoords] : locations?.radiusLocation;
    };
    const locationData = {
      campaignId,
      isAllLocations: false,
      exclude: locations?.currentTab ? locations?.exclude : [],
      include: getInclude(),
      polygons: [],
      zipCodeList: locations?.isInBulk && locations?.currentTab ? locations?.postalCodes : []
    };
    BeginnerCampaignCreation.sendLocations(locationData).then(data => {
      handleSetPrevState(data.campaignCreation.prevState);
      push(BEGINNER_TARGET_ADS);
    });
  };

  return (
    <div className="beginner-location-step-wrapper">
      <SelectLocation
        isLoading={isLoading}
        handleBack={handleBack}
        handleConfirm={handleNextStep}
        handleSetLocations={handleSetLocations}
        locations={locations}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  campaignId: state.beginnerMode.campaignId,
  locations: state.beginnerMode.locations
});

const mapDispatchToProps = dispatch => ({
  handleSetPrevState: payload => dispatch(setPrevState(payload)),
  handlePreviousStep: payload => dispatch(handlePrevStep(payload)),
  handleSetLocations: payload => dispatch(setLocations(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(Location);
