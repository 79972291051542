import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { Button } from "@material-ui/core";
import html2canvas from "html2canvas";

import ExpandIcon from "assets/images/expandIcon.svg";
import ExpandIconWhite from "assets/images/expandIconWhite.svg";
import Arrow from "assets/images/arrowRightBlue.svg";
import WhiteArrow from "assets/images/arrowRightWhite.svg";
import EmptyIcon from "assets/images/smallEmptyIcon.svg";
import DownloadIcon from "assets/images/downloadBlue.svg";
import DownloadIconWhite from "assets/images/downloadWhite.svg";

import "./styles.scss";

const StatisticCard = ({
  children,
  headerContent,
  secondHeaderContent,
  title,
  href,
  id,
  btnTitle,
  withExpandAbility,
  isEmpty,
  className,
  footMobileContent,
  userInfo
}) => {
  const { push } = useHistory();
  const [isExpand, setIsExpand] = useState(false);

  const downloadHtmlAsPngAsync = async () => {
    const options = {
      selector: `#${id}`,
      width: 800,
      height: 450,
      name: id,
      fontFamily: "SegoeUI-bold"
    };
    const canvas = await html2canvas(document.querySelector(options.selector), options);
    const dataUrl = canvas.toDataURL(options.name);
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.href = dataUrl;
    a.download = options.name;
    a.click();
    document.body.removeChild(a);
  };

  return (
    <div id={id} className={`overview-statistic-card-wrapper ${className || ""} ${isExpand ? "active" : ""}`}>
      <div className="statistic-card-head">
        {title}
        {!isEmpty && (
          <div className="right-header-content">
            {headerContent}
            {withExpandAbility && (
              <Button
                onClick={() => setIsExpand(!isExpand)}
                className={`card-icon-btn expand ${isExpand ? "active" : ""}`}
              >
                <img src={userInfo?.isDarkTheme ? ExpandIconWhite : ExpandIcon} alt="" />
              </Button>
            )}
            {id && (
              <Button onClick={downloadHtmlAsPngAsync} className="card-icon-btn">
                <img src={userInfo?.isDarkTheme ? DownloadIconWhite : DownloadIcon} alt="" />
              </Button>
            )}
          </div>
        )}
      </div>
      {isEmpty ? (
        <div className="overview-statistic-card-empty">
          <img src={EmptyIcon} alt="" />
          <div>There is no data</div>
          <div className="empty-card-subtitle">Try to select other dates</div>
        </div>
      ) : (
        <>
          {secondHeaderContent && <div className="statistic-card-second-head">{secondHeaderContent}</div>}
          <div className={`statistic-card-body ${secondHeaderContent ? "small-height" : ""}`}>{children}</div>
        </>
      )}
      <div className="statistic-card-footer">
        <Button onClick={() => push(href)} className="link-btn">
          {btnTitle || title}
          <img src={userInfo?.isDarkTheme ? WhiteArrow : Arrow} alt="" />
        </Button>
        <div className="foot-mobile-content">{footMobileContent}</div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  userInfo: state.userProfile.userInfo
});

export default connect(mapStateToProps)(StatisticCard);
