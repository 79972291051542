import React, { useState } from "react";
import { connect } from "react-redux";
import { Button, Popover } from "@material-ui/core";

import Arrow from "assets/images/arrowDropDownBlue.svg";
import WhiteArrow from "assets/images/arrowDropDownWhite.svg";

import "./styles.scss";

const DiagramSelect = ({ options, value, onChange, pink, isDefault, className, userInfo }) => {
  const [openPopover, setOpenPopover] = useState(null);
  return (
    <>
      <Button
        className={`dashboard-diagram-select-btn ${className || ""} ${openPopover ? "active" : ""} ${
          pink ? "pink" : ""
        } ${isDefault ? "default-view" : ""} ${userInfo?.isDarkTheme ? "black-theme" : ""}`}
        onClick={e => setOpenPopover(e.currentTarget)}
      >
        {value?.label}
        <img src={userInfo?.isDarkTheme ? WhiteArrow : Arrow} alt="" />
      </Button>
      <Popover
        open={Boolean(openPopover)}
        anchorEl={openPopover}
        onClose={() => setOpenPopover(null)}
        className="dashboard-diagram-popover"
        anchorOrigin={{
          vertical: 44,
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
        {options?.map(val => (
          <Button
            key={val.value}
            className={`option-item ${value.value === val.value ? "active" : ""}`}
            onClick={() => {
              setOpenPopover(null);
              onChange(val);
            }}
          >
            <img src={val.icon} alt="" />
            {val.label}
          </Button>
        ))}
      </Popover>
    </>
  );
};

const mapStateToProps = state => ({
  userInfo: state.userProfile.userInfo
});

export default connect(mapStateToProps)(DiagramSelect);
