import React from "react";
import { connect } from "react-redux";
import { Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";

import Loader from "shared/Loader";

import Arrow from "assets/images/arrowDropDownBlue.svg";
import ArrowWhite from "assets/images/arrowDropDownWhite.svg";

import "./styles.scss";

const ExpertModeCard = ({ title, children, isDefaultOpen = true, className, id, userInfo, isLoading }) => {
  return (
    <Accordion id={id} className={`expert-mode-card-wrapper ${className || ""}`} defaultExpanded={isDefaultOpen}>
      {isLoading && (
        <div className="expert-mode-card-loader-box">
          <Loader empty isDashboardContent />
        </div>
      )}
      <AccordionSummary expandIcon={<img src={userInfo?.isDarkTheme ? ArrowWhite : Arrow} alt="" />}>
        <div className="expert-mode-card-title">{title}</div>
      </AccordionSummary>
      <AccordionDetails className="expert-mode-main-card-body">{children}</AccordionDetails>
    </Accordion>
  );
};

const mapStateToProps = state => ({
  userInfo: state.userProfile.userInfo
});

export default connect(mapStateToProps)(ExpertModeCard);
