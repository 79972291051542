import React from "react";

import DiagramSelect from "shared/DiagramSelect";
import CustomChartTooltip from "shared/CustomChartTooltip";

import { getRoundValue } from "helpers/statisticHelpFunctions";

import "./styles.scss";

const CustomLineBarChart = ({ typeState, setTypeState, typeOptions, data, withDataLabels }) => {
  const getBarItemPercent = item => {
    const totalAmount = data?.reduce((acc, value) => acc + Number(value.value), 0);
    if (totalAmount) {
      return (item.value * 100) / totalAmount;
    }
    return 0;
  };

  const dataValues = () => (
    <>
      {data?.map(val => (
        <div key={`key${val.color}`} className="data-item">
          <div className="item-indicator" style={{ background: val.color }} />
          {getRoundValue(getBarItemPercent(val))}%
        </div>
      ))}
    </>
  );

  return (
    <>
      <div className="custom-line-bar-chart-wrapper">
        <div className="line-bar-chart-head">
          <DiagramSelect options={typeOptions} value={typeState} onChange={val => setTypeState(val)} isDefault />
          <div className="data-values-box">
            {dataValues()}
          </div>
        </div>
        <div className={`line-bar-chart ${!data?.find(val => val.value > 0) ? "empty" : ""}`}>
          {data?.map(val => (
            <div className="chart-bar-item" key={`key${val.color}`} style={{ width: `${getBarItemPercent(val)}%` }}>
              <CustomChartTooltip
                value={`${typeState?.label}: ${getRoundValue(Number(val.value))}`}
                label={val.label}
                valueStyles={{ color: val.color }}
              >
                <div className="bar-content-wrapper">
                  <div className="bar-gradient" />
                  <div className="bar-content" style={{ background: val.color }} />
                </div>
              </CustomChartTooltip>
            </div>
          ))}
        </div>
        <div className="data-values-box mobile">
          {dataValues()}
        </div>
      </div>
      {withDataLabels && (
        <div className="custom-line-bar-chart-labels">
          {data?.map(val => (
            <div className="data-label">
              <div style={{ background: val.color }} />
              {val.label}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default CustomLineBarChart;
