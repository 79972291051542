import React from "react";

import StyledSelect from "shared/StyledSelect";
import StyledChip from "shared/StyledChip";
import SimpleBtn from "shared/SimpleBtn";

import ExpertModeCard from "../../components/ExpertModeCard";

import "./styles.scss";

const Languages = ({ input, languages }) => {
  return (
    <ExpertModeCard title="Languages" className="languages-card-wrapper">
      <StyledSelect
        label="Select the languages your customers speak"
        value=""
        onChange={e => input?.onChange([...input?.value, languages.find(val => val.code === e.target.value)])}
        valueFormat={val => val.code}
        labelFormat={val => val.name}
        options={languages?.filter(val => !input?.value.find(value => value.code === val.code))}
        disabled={input?.value?.length === languages?.length}
      />
      <div className="chip-box">
        {input?.value?.length === languages?.length ? (
          <StyledChip label="All languages" onDelete={() => input?.onChange([])} />
        ) : (
          input?.value?.map(val => (
            <StyledChip
              label={val.name}
              onDelete={() => input?.onChange(input?.value?.filter(value => value.code !== val.code))}
            />
          ))
        )}
      </div>
      <SimpleBtn disabled={input?.value?.length === languages?.length} onClick={() => input?.onChange(languages)}>
        + Add all
      </SimpleBtn>
    </ExpertModeCard>
  );
};

export default Languages;
