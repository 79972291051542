import React, { useState, useRef } from "react";
import { Button, Popover } from "@material-ui/core";
import { json2csv } from "json-2-csv";

import { campaignStatusVariants } from "constants/campaignStatusVariants";

import CustomCheckbox from "shared/CustomCheckbox";

import { DownloadIcon, FilterIcon } from "assets/images/svgComponents";

import "./styles.scss";

const TableToolPopover = ({ state, setState, vertical = "bottom", horizontal = "center", children }) => {
  return (
    <Popover
      open={Boolean(state)}
      anchorEl={state}
      onClose={() => setState(null)}
      className="campaigns-table-tool-popover"
      anchorOrigin={{
        vertical,
        horizontal
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center"
      }}
    >
      {children}
    </Popover>
  );
};

const TableToolBar = ({
  campaignsStatus,
  setCampaignsStatus,
  selectedColumns,
  setSelectedColumns,
  isShowFilters,
  setIsShowFilters,
  initialColumnsState,
  tableData
}) => {
  const ref = useRef();
  const [sortPopover, setSortPopover] = useState(null);
  const [columnsPopover, setColumnsPopover] = useState(null);

  const handleExportCsv = () => {
    json2csv(
      tableData,
      (e, csv) => {
        const hiddenElement = document.createElement("a");
        hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(csv)}`;
        hiddenElement.target = "_blank";
        hiddenElement.download = "Exported_Data.csv";
        hiddenElement.click();
      },
      {
        delimiter: {
          wrap: '"',
          field: ",",
          eol: "\n"
        },
        prependHeader: true,
        sortHeader: false,
        excelBOM: true,
        trimHeaderValues: true,
        trimFieldValues: true
      }
    );
  };

  return (
    <div className="table-tool-bal-wrapper">
      <Button className="sort-by-status-btn" onClick={() => setSortPopover(ref.current)}>
        Campaigns Status:{" "}
        <span ref={ref} className={`sort-value ${sortPopover ? "active" : ""}`}>
          {campaignsStatus?.status}
        </span>
      </Button>
      <TableToolPopover state={sortPopover} setState={setSortPopover} vertical={33} horizontal={60}>
        {campaignStatusVariants?.map(val => (
          <Button
            key={val.statusCode}
            className={`filter-item ${val.statusCode === campaignsStatus?.statusCode ? "active" : ""}`}
            onClick={() => setCampaignsStatus(val)}
          >
            {val.status}
          </Button>
        ))}
      </TableToolPopover>
      <div className="table-options-box">
        <Button
          className={`filter-btn ${isShowFilters ? "active" : ""}`}
          onClick={() => {
            if (isShowFilters) {
              setSelectedColumns(selectedColumns.map(val => ({ ...val, columnFilter: "" })));
            }
            setIsShowFilters(!isShowFilters);
          }}
        >
          {isShowFilters ? "Hide filters" : "Add filters"}
        </Button>
        <Button className="icon-btn" onClick={handleExportCsv}>
          <DownloadIcon />
        </Button>
        <Button
          onClick={e => setColumnsPopover(e.currentTarget)}
          className={`icon-btn ${columnsPopover ? "active" : ""}`}
        >
          <FilterIcon />
        </Button>
        <TableToolPopover state={columnsPopover} setState={setColumnsPopover} vertical={27}>
          <Button className="filter-item top-btn" onClick={() => setSelectedColumns(initialColumnsState)}>
            <CustomCheckbox checked={!selectedColumns.find(val => !val.isShow)} />
            Select all
          </Button>
          {selectedColumns?.map((val, index) => (
            <Button
              key={index.toString()}
              className="filter-item"
              onClick={() => {
                setSelectedColumns(
                  selectedColumns.map((column, i) => {
                    if (i === index) {
                      return { ...column, isShow: !val.isShow, columnFilter: "" };
                    }
                    return column;
                  })
                );
              }}
            >
              <CustomCheckbox checked={val.isShow} />
              {val.label}
            </Button>
          ))}
        </TableToolPopover>
      </div>
    </div>
  );
};

export default TableToolBar;
