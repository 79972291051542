export const colors = [
  "#213991",
  "#C9BBB1",
  "#F0AEE1",
  "#9ADBA8",
  "#F0AEAE",
  "#124DF5",
  "#242424",
  "#F63E49",
  "#FFA93F",
  "#C7CDE3"
];
