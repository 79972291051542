import React, { useState } from "react";
import { dsToast } from "shared/dsToast";
import Dropzone from "react-dropzone";
import { Button } from "@material-ui/core";

import { convertImageFileToAppFileFormat, getImgSize } from "helpers/fileHelper";

import AddIcon from "assets/images/addWhiteBlueIcon.svg";
import CloseIcon from "assets/images/closeBlue.svg";
import RemoveIcon from "assets/images/removeIconBlue.svg";

import "./styles.scss";

const DropZoneCard = ({
  cardIcon,
  expectedImgSize,
  title,
  subtitle,
  handleSave,
  handleSaveAsCustom,
  imgInfo,
  clearDropZone,
  isCustom,
  isEmptyBtn,
  ...props
}) => {
  const [error, setError] = useState("");
  const [customFile, setCustomFile] = useState(null);
  return (
    <div {...props} className="drop-zone-card">
      {imgInfo?.imageUrl && !customFile && (
        <Button onClick={clearDropZone} className="remove-file-btn">
          <img src={RemoveIcon} alt="" />
        </Button>
      )}
      <Dropzone
        maxSize={10240000}
        accept="image/jpeg, image/png"
        onDropRejected={() => {
          if (isCustom) {
            dsToast.error("Image is overweight");
          } else {
            clearDropZone();
            setError("Image is overweight");
          }
        }}
        onDropAccepted={file => {
          if (file.length > 1) {
            if (isCustom) {
              dsToast.error("Upload only one image");
            } else {
              clearDropZone();
              setError("Upload only one image");
            }
          } else {
            getImgSize(file[0]).then(data => {
              const isRightSize = expectedImgSize?.width && data.height === expectedImgSize?.height;
              if (isCustom || isEmptyBtn || isRightSize) {
                setError("");
                convertImageFileToAppFileFormat(file[0]).then(handleSave);
              } else {
                convertImageFileToAppFileFormat(file[0]).then(info => setCustomFile(info));
                setError(`Wrong size: ${data.width} x ${data.height}`);
              }
            });
          }
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <Button {...getRootProps()} className={`drop-zone ${isEmptyBtn ? "empty-btn" : ""}`}>
            <input {...getInputProps()} />
            {isEmptyBtn ? <img src={AddIcon} alt="" /> : <img src={imgInfo?.imageUrl || cardIcon} alt="" />}
            {isEmptyBtn && "Custom size ads"}
          </Button>
        )}
      </Dropzone>
      {!isEmptyBtn && (
        <>
          {customFile && (
            <div className="add-as-custom-btn-wrapper">
              <Button
                onClick={() => {
                  setCustomFile(null);
                  setError("");
                }}
                className="close-custom-btn"
              >
                <img src={CloseIcon} alt="" />
              </Button>
              <Button
                onClick={() => {
                  handleSaveAsCustom(customFile);
                  setCustomFile(null);
                  setError("");
                }}
                className="add-as-custom-btn"
              >
                <img src={AddIcon} alt="" />
                Add as custom ad size
              </Button>
            </div>
          )}
          <div className="drop-zone-card-title">{title}</div>
          <div className={`drop-zone-text ${error ? "error" : "subtitle"}`}>{error || imgInfo?.name || subtitle}</div>
        </>
      )}
    </div>
  );
};

export default DropZoneCard;
