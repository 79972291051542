import { ApiService } from "services/ApiService";

export default class Admin {
  static async getAllCampaigns({ amount, page, email, sortBy, direction }) {
    return ApiService._requestAsync({
      url: `/admin-panel/all-campaigns?campaignPerPage=${amount}&page=${page}${email ? `&email=${email}` : ""}${
        sortBy ? `&sortBy=${sortBy}` : ""
      }${direction ? `&direction=${direction}` : ""}`,
      method: "GET"
    });
  }

  static async getCampaignJson(campaignId) {
    return ApiService._requestAsync({
      url: `/admin-panel/all-data-of-campaign/${campaignId}`,
      method: "GET"
    });
  }

  static async getAllUsers({ amount, page, email, sortBy, direction }) {
    return ApiService._requestAsync({
      url: `/user-management/all-campaigns-by-user?campaignPerPage=${amount}&page=${page}${email ? `&email=${email}` : ""}${
        sortBy ? `&sortBy=${sortBy}` : ""
      }${direction ? `&direction=${direction}` : ""}`,
      method: "GET"
    });
  }

  static async getUserAccount(accountId) {
    return ApiService._requestAsync({
      url: `/user-management/all-campaigns-by-user/${accountId}`,
      method: "GET"
    });
  }

  static async getUserBalanceAndCpm(accountId) {
    return ApiService._requestAsync({
      url: `/accounts/${accountId}/settings`,
      method: "GET"
    });
  }

  static async updateUserAccount(accountId, data) {
    return ApiService._requestAsync({
      url: `/user-management/update-account/${accountId}`,
      method: "PUT",
      data
    });
  }

  static async updateUserBalanceAndCpm(accountId, data) {
    return ApiService._requestAsync({
      url: `/accounts/${accountId}/settings`,
      method: "PUT",
      data
    });
  }

  static async getConfigurations() {
    return ApiService._requestAsync({
      url: `/general-settings`,
      method: "GET"
    });
  }

  static async updateConfigurations(data) {
    return ApiService._requestAsync({
      url: `/general-settings`,
      method: "PUT",
      data
    });
  }

  static async inviteByEmail(email) {
    return ApiService._requestAsync({
      url: `/user-management/invite-user`,
      method: "POST",
      data: {
        email,
        type: "email"
      }
    });
  }

  static async inviteSubUserByEmail(email) {
    return ApiService._requestAsync({
      url: `/accounts/invite-user`,
      method: "POST",
      data: { email }
    });
  }

  static async getSubUsers() {
    return ApiService._requestAsync({
      url: `/accounts/members`,
      method: "GET"
    });
  }

  static async deleteSubUser(userId) {
    return ApiService._requestAsync({
      url: `/accounts/delete-user`,
      method: "DELETE",
      data: { userId }
    });
  }
}
