import React, { useState } from "react";
import { Button, Popover } from "@material-ui/core";

import CustomCheckbox from "shared/CustomCheckbox";
import DiagramSelect from "shared/DiagramSelect";

import { FilterIcon } from "assets/images/svgComponents";

import "./styles.scss";

const DashboardDiagramWrapper = ({
  children,
  blueSelect,
  setBlueSelect,
  pinkSelect,
  setPinkSelect,
  options,
  sortOptions,
  sortValue,
  setSortValue
}) => {
  const [openSortPopover, setOpenSortPopover] = useState(null);

  return (
    <div className="dashboard-diagram-wrapper">
      <div className="diagram-header">
        <div className="diagram-select-box">
          {blueSelect && (
            <DiagramSelect
              options={options?.filter(val => pinkSelect?.value !== val.value)}
              value={blueSelect}
              onChange={val => setBlueSelect(val)}
            />
          )}
          {pinkSelect && (
            <DiagramSelect
              options={options?.filter(val => blueSelect?.value !== val.value)}
              value={pinkSelect}
              onChange={val => setPinkSelect(val)}
              pink
            />
          )}
        </div>
        {sortOptions && (
          <Button
            onClick={e => setOpenSortPopover(e.currentTarget)}
            className={`filter-btn ${openSortPopover ? "active" : ""}`}
          >
            <FilterIcon />
          </Button>
        )}
        <Popover
          open={Boolean(openSortPopover)}
          anchorEl={openSortPopover}
          onClose={() => setOpenSortPopover(null)}
          className="dashboard-diagram-popover"
          anchorOrigin={{
            vertical: 28,
            horizontal: "center"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
        >
          {sortOptions?.map(val => (
            <Button
              key={val.value}
              className={`option-item ${sortValue.value === val.value ? "active" : ""}`}
              onClick={() => setSortValue(val)}
            >
              <CustomCheckbox checked={sortValue.value === val.value} isTypeRadio />
              {val.label}
            </Button>
          ))}
        </Popover>
      </div>
      <div className="diagram-card-content-wrapper">
        <div className="diagram-card-content">{children}</div>
      </div>
    </div>
  );
};

export default DashboardDiagramWrapper;
