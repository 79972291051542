const commonTableRows = [
  {
    label: "Clicks",
    value: "clicks"
  },
  {
    label: "Conversion Rate",
    value: "cnv_rate"
  },
  {
    label: "CPM",
    value: "cpm"
  },
  {
    label: "Impressions",
    value: "imp"
  }
];

export const campaignsTableColumns = [
  {
    label: "Name",
    value: "name"
  },
  {
    label: "Status",
    value: "status"
  },
  ...commonTableRows
];

export const adScheduleTableColumns = [
  {
    label: "Schedule",
    value: "date"
  },
  ...commonTableRows
];

export const devicesTableColumns = [
  {
    label: "Device",
    value: "device"
  },
  ...commonTableRows
];

export const networksTableColumns = [
  {
    label: "Network",
    value: "network"
  },
  ...commonTableRows
];

export const OSTableColumns = [
  {
    label: "OS",
    value: "os"
  },
  ...commonTableRows
];

export const locationsTableColumns = [
  {
    label: "Location",
    value: "location"
  },
  ...commonTableRows
];

export const placementsTableColumns = [
  {
    label: "Placement",
    value: "place"
  },
  ...commonTableRows
];

export const ageTableColumns = [
  {
    label: "Age",
    value: "age"
  },
  ...commonTableRows
];

export const genderTableColumns = [
  {
    label: "Gender",
    value: "gender"
  },
  ...commonTableRows
];

export const pixelTableColumns = [
  {
    label: "Domain",
    value: "domain"
  },
  {
    label: "Status",
    value: "status"
  },
  {
    label: "Actions",
    value: "actions"
  }
];

export const goalsTableColumns = [
  {
    label: "Goal name",
    value: "goal"
  },
  {
    label: "Actions",
    value: "actions"
  }
];

export const settingsTableColumns = [
  {
    label: "Name",
    value: "name"
  },
  {
    label: "Status",
    value: "status"
  },
  {
    label: "Budget",
    value: "budget"
  },
  {
    label: "Bid strategy type",
    value: "strategy_type"
  },
  {
    label: "Delivery method",
    value: "delivery"
  },
  {
    label: "Ad rotation",
    value: "ad_rotation"
  },
  {
    label: "Start date",
    value: "start_date"
  },
  {
    label: "End date",
    value: "end_date"
  },
  {
    label: "Languages",
    value: "languages"
  },
  {
    label: "Location",
    value: "location"
  },
  {
    label: "Ad schedule",
    value: "ad_schedule"
  },
  {
    label: "Devices",
    value: "devices"
  },
  {
    label: "Locked",
    value: "locked"
  }
];

export const diagramSelectOptions = [
  {
    label: "Clicks",
    value: "clicks"
  },
  {
    label: "Conversion Rate",
    value: "convs_rate"
  },
  {
    label: "CPM",
    value: "cpm"
  },
  {
    label: "Impressions",
    value: "imps"
  }
];

export const diagramSortOptions = [
  {
    label: "Daily",
    value: "day",
    dateFormat: "MMM DD"
  },
  {
    label: "Weekly",
    value: "week",
    dateFormat: "MMM DD"
  },
  {
    label: "Monthly",
    value: "month",
    dateFormat: "MMM DD"
  },
  {
    label: "Quarterly",
    value: "quarter",
    dateFormat: "MMM DD, YYYY"
  },
  {
    label: "Yearly",
    value: "year",
    dateFormat: "MMM DD, YYYY"
  }
];
