// @flow
import { useContext } from "react";
import { ReactReduxContext } from "react-redux";

export default (): {
  isAuthLoading: boolean,
  isAuth: boolean,
  accountId: number,
  role: number,
  auth0UserId: number,
  userId: number,
  isBlocked: boolean
} => {
  const { store } = useContext(ReactReduxContext);
  const { getState } = store;
  const state = getState();

  return state.me;
};
