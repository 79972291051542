import React, { useEffect, useState } from "react";
import moment from "moment";

import { AD_SCHEDULE } from "pathnameVariables";
import { diagramSelectOptions } from "constants/tableColumns";
import { time } from "constants/timeArr";
import { colors } from "constants/chartColors";
import { convertStatistic, getChartCommonData } from "helpers/statisticHelpFunctions";

import DiagramSelect from "shared/DiagramSelect";
import StyledPieChart from "shared/StyledPieChart";
import StyledHeatMapChart from "shared/StyledHeatMapChart";
import CustomVerticalBarChart from "shared/CustomVerticalBarChart";

import StatisticCard from "../../components/StatisticCard";
import TabSelection from "../../components/TabSelection";

import "./styles.scss";

const genderAgeTabs = [
  {
    value: "days",
    label: "Days"
  },
  {
    value: "hAndD",
    label: "Hours and Days"
  },
  {
    value: "hours",
    label: "Hours"
  }
];

const DaysOfWeek = ({ data }) => {
  const [daysOfWeekTab, setDaysOfWeekTab] = useState(genderAgeTabs[0]);
  const [daysOfWeekDataType, setDaysOfWeekTDataType] = useState(diagramSelectOptions[0]);
  const [statisticData, setStatisticData] = useState(null);
  const [currentData, setCurrentData] = useState([]);
  const [hoursAndDaysRawData, setHoursAndDaysRawData] = useState([]);

  useEffect(() => {
    const daysConvertStatistic = () => {
      const dayStatistic = convertStatistic(data, "day");
      return ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map(val => {
        let commonDayData = [];
        dayStatistic
          .filter(value => moment(value.name).format("ddd") === val)
          .forEach(value => {
            commonDayData = [...commonDayData, ...value.data];
            return value;
          });
        return {
          name: val,
          data: commonDayData
        };
      });
    };

    const getHoursData = () => {
      const hoursData = convertStatistic(data, "hour");
      return time.map(val => {
        if (hoursData.find(value => value.name.includes(val))) {
          return {
            name: val,
            data: hoursData.find(value => value.name.includes(val)).data
          };
        }
        return { name: val, data: [] };
      });
    };
    if (data) {
      setHoursAndDaysRawData(
        convertStatistic(data, "day", "hour").map(val => ({
          label: val.name,
          ...getChartCommonData(val?.data)
        }))
      );
      setStatisticData({
        days: daysConvertStatistic().map((val, i) => ({
          label: val.name,
          color: colors[i],
          ...getChartCommonData(val?.data)
        })),
        hours: getHoursData().map(val => ({
          label: val.name,
          ...getChartCommonData(val?.data)
        }))
      });
    }
  }, [data]);

  useEffect(() => {
    if (statisticData) {
      const tabData = statisticData[daysOfWeekTab.value];
      if (daysOfWeekTab.value !== "hAndD") {
        setCurrentData(
          tabData?.map(val => ({ ...val, value: val[daysOfWeekDataType.value], tooltip: daysOfWeekDataType.label }))
        );
      } else {
        const getGenAgeData = () => {
          const xLabels = time;
          const yLabels = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
          const result = yLabels.map(val => {
            const typeData = hoursAndDaysRawData.filter(value =>
              moment(value.label.split(" | ")[0])
                .format("ddd")
                .includes(val)
            );
            return xLabels.map(value => {
              if (typeData.find(vl => vl.label.split(" | ")[1] === value)) {
                return typeData.find(vl => vl.label.split(" | ")[1] === value)[daysOfWeekDataType?.value];
              }
              return 0;
            });
          });
          return {
            data: result,
            xLabels: new Array(24).fill(0).map((val, index) => index + 1),
            yLabels
          };
        };
        setCurrentData([getGenAgeData()]);
      }
    }
  }, [statisticData, daysOfWeekTab, daysOfWeekDataType]);

  return (
    <StatisticCard
      title="Days of week"
      id="days-of-week"
      btnTitle="Ad schedule"
      className={`week-days-card ${daysOfWeekTab?.value === "days" ? "flex-height" : ""}`}
      href={AD_SCHEDULE}
      isEmpty={statisticData && !statisticData?.days?.length && !statisticData?.hours?.length}
      footMobileContent={
        <DiagramSelect
          options={diagramSelectOptions}
          value={daysOfWeekDataType}
          onChange={val => setDaysOfWeekTDataType(val)}
          isDefault
        />
      }
      secondHeaderContent={
        <>
          <TabSelection tabs={genderAgeTabs} onChange={val => setDaysOfWeekTab(val)} value={daysOfWeekTab} />
          <DiagramSelect
            className="head-select"
            options={diagramSelectOptions}
            value={daysOfWeekDataType}
            onChange={val => setDaysOfWeekTDataType(val)}
            isDefault
          />
        </>
      }
    >
      {daysOfWeekTab?.value === "hAndD" ? (
        <StyledHeatMapChart
          yLabelWidth={30}
          data={currentData[0]?.data}
          xLabels={currentData[0]?.xLabels}
          yLabels={currentData[0]?.yLabels}
          xLabelsVisibility={new Array(24).fill(0).map((_, i) => i % 2 === 0)}
        />
      ) : daysOfWeekTab?.value === "hours" ? (
        <CustomVerticalBarChart isShort data={currentData} />
      ) : (
        <StyledPieChart data={currentData} />
      )}
    </StatisticCard>
  );
};

export default DaysOfWeek;
